<template>
  <v-col xs="12" sm="12" md="12" lg="12">
    <v-responsive width="100%" v-if="$vuetify.breakpoint.xsOnly" />
    <v-card elevation="5" rounded>
      <v-row class="mx-1 mb-1">
        <v-col xs="12" sm="12" md="3" lg="2">
          <v-text-field type="date" label="From date" outlined dense v-model="search.from_date">
          </v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="3" lg="2">
          <v-text-field type="date" label="End date" outlined dense v-model="search.end_date">
          </v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="3" lg="2" v-if="(ROLE == 'Owner')">
          <v-select :items="branch" outlined dense label="Branch" item-text="name" item-value="id"
            v-model="search.branch_id"></v-select>
        </v-col>
        <v-col xs="12" sm="12" md="3" lg="3">
          <v-text-field type="text" label="Search" outlined dense v-model="search.search">
          </v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="3" lg="2">
          <v-btn small color="darkblue" class="white--text" @click.stop="init">Submit <v-icon
              right>mdi-magnify</v-icon></v-btn>
          <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
            <v-icon right>mdi-close-circle-multiple </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table :loading="loading" :headers="headers" :items="Ingoings.data" class="elevation-1" hide-default-footer>
        <template v-slot:[`item.grand_total`]="{ item }">
          <b>{{ item.grand_total | currency("", 3, { symbolOnLeft: false, }) }}</b>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip color="dark" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" color="primary" v-bind="attrs" icon small @click.stop="view(item.id)" v-on="on">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View Ingoing</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "IngoingList",
  data() {
    return {
      headers: [
        { text: "Ingoing No.", value: "no_ingoing" },
        { text: "Purchase Order No.", value: "purchase.no_po" },
        { text: "Ingoing Date.", value: "date" },
        { text: "Supplier", value: "supplier.name" },
        { text: "In", value: "" },
        { text: "In Type", value: "" },
        // { text: "Branch", value: "branch.name" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      disabled: false,
      loading: false,
      loading_download: false,
      branch: [],
      search: {
        from_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
        end_date: new Date().toJSON().slice(0, 10),
        search: null,
        branch_id: null,
      },
    };
  },

  methods: {
    ...mapMutations("ingoing", ["CLEAR_STATE", "SET_INGOINGS", "SET_INGOING", "SET_CURRENT_PAGE"]),

    async init() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        search: this.search.search,
        branch: this.search.branch_id,
        from_date: this.search.from_date,
        end_date: this.search.end_date,
      };


      await this.$axios
        .get("/transaction/ingoings", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_INGOINGS(data.in_goings);
          this.loading = false;
          this.disabled = false;
        });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch.data;
        });
    },

    async edit(id) {
      this.loading = true;
      await this.$axios
        .get("/transaction/ingoings/" + id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_INGOING(data.in_goings);
          this.loading = false;
          this.$router.push("/inventory-in/edit");
        })
        .catch(() => {
          this.loading = true;
        });
    },

    view(id) {
      this.$router.push("/inventory-in/view/" + id);
    },
    // printAll() {
    //   window.open(
    //     process.env.VUE_APP_API_URL + "/quotation_list/pdf?from_date=" + this.search.from_date + "&end_date=" + this.search.end_date,
    //     "_blank"
    //   );

    // },

    searching() {
      this.page = 1;
      this.init();
    },
    clear() {
      this.page = 1;
      this.search = {
        search: "",
        branch_id: "",
        from_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
        end_date: new Date().toJSON().slice(0, 10),
      };
      this.init();
    },
  },

  created() {
    // this.init();
    this.getBranch();
  },

  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.init();
    },
  },
  computed: {
    ...mapGetters("ingoing", {
      Ingoings: "Ingoings",
    }),
    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    currentPage: {
      get() {
        return this.Ingoings.current_page;
      },
      set(val) {
        return this.$store.commit("ingoing/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.Ingoings.last_page;
      },
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  background-color: #ebebeb59;
}

::v-deep th {
  color: #000 !important;
}
</style>