<template>
    <main-layout>
        <base-header>
            <template #icon> mdi-file</template>
            <template #name>Order </template>
            <template #breadcrumbs>
                <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                    <template #divider>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>
            </template>
        </base-header>
        <v-row dense class="mx-1">
            <v-responsive width="100%" />
            <v-tabs align-tabs="center" v-model="tab">
                <v-tab href="#list">List</v-tab>
                <v-tab-item value="list">
                    <BranchList />
                </v-tab-item>
                <v-tab href="#branch-detail"> Detail </v-tab>
                <v-tab-item value="branch-detail">
                    <BranchDetail />
                </v-tab-item>
            </v-tabs>
        </v-row>
    </main-layout>
</template>
<script>
import MainLayout from "@/layouts/MainLayout.vue";
import BaseHeader from "@/components/base/BaseHeader.vue";
import BranchList from "./List.vue";
import BranchDetail from "./detail.vue";

export default {
    name: "CustomIndex",

    created() {
        this.breadcrumbs = [
            { text: "Transaction", disabled: false, to: "/dashboard" },
            { text: "Branch Sales Order", disabled: true },
        ];
    },

    components: {
        BranchList,
        BranchDetail,
        MainLayout,
        BaseHeader,
    },

};
</script>