<template>
    <v-card>
        <v-form ref="form_data" v-model="form_valid" lazy-validation>
            <v-row class="mx-1 mt-2">
                <v-col lg="6" sm="12" md="6">
                    <v-toolbar-title> Add Item</v-toolbar-title>
                </v-col>
                <v-col lg="6" sm="12" md="6">
                    <v-btn color="primary" class="float-end mx-1" depressed small @click.stop="addNewRow">Add Row</v-btn>
                </v-col>
            </v-row>
            <v-divider class="mx-4 mt-2"></v-divider>
            <v-row class="mx-1 mt-2">
                <v-col lg="12" sm="12" md="12">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr v-for="(form_data, k) in invoice_products" :key="k">
                                    <td>
                                        <v-select :items="item" v-model="form_data.item" label="Select Item" hide-details
                                            outlined dense></v-select>
                                    </td>
                                    <td>
                                        <v-select :items="size" v-model="form_data.size" label="Select Size" hide-details
                                            outlined dense></v-select>
                                    </td>
                                    <td>
                                        <v-text-field type="number" v-model="form_data.qty" label="Qty" outlined dense hide-details
                                            :rules="rule_qtys"></v-text-field>
                                    </td>
                                    <td>
                                        <v-btn color="error" @click="deleteRow(k, form_data)" outlined small><v-icon>mdi-close</v-icon></v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <v-row class="mx-1" style="margin-top: 50px;">
                <v-col lg="12" sm="12" md="12">
                    <v-btn color="primary" class="float-end mx-1" depressed small @click.stop="addItem">Submit</v-btn>
                    <v-btn color="error" class="float-end" @click="closeProductDialog" outlined small>Cancel</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <br>
    </v-card>
</template>
  
<script>
export default {
    name: "ItemModal",

    data() {
        return {
            item: [
                "Shoes",
                "T-Shirt",
                "Hoodie",
                "Jacket",
                "Socks",
                "Hat",
                "Gloves",
                "Pants",
                "Sweater",
                "Sweatshirt",
                "Sweatpants",
                "Sweatshirts",
            ],
            size: [
                "S",
                "M",
                "L",
                "XL",
                "XXL",
                "XXXL",
                "XXXXL",
                "XXXXXL",
                "XXXXXXL",
            ],

            invoice_products: [{
                name : '',
                size : '',
                qty : 0
            }],

            form_valid: true,
            form_data: {
                name: "",
                size: "",
                qty: 0,
            },
        };
    },

    methods: {
        addNewRow() {
            this.invoice_products.push({
                name : '',
                size : '',
                qty : 0
            });
        },

        deleteRow(index, form_data) {
            var idx = this.invoice_products.indexOf(form_data);
            console.log(idx, index);
            if (idx > -1) {
                this.invoice_products.splice(idx, 1);
            }
        },

        addItem: async function () {
            this.$store.commit("promo/SET_DETAILS_ITEM", this.form_data);
            this.$emit("closeProductDialog")
        },
        closeProductDialog() {
            this.$emit("closeProductDialog");
        },
    }
};
</script>