<template>
  <v-tabs>
    <v-tab href="#group"> Group </v-tab>
    <v-tab-item value="group">
      <GroupIndex />
    </v-tab-item>
    <v-tab href="#sub-group"> Sub Group </v-tab>
    <v-tab-item value="sub-group">
      <SubGroupIndex />
    </v-tab-item>
    <v-tab href="#unit"> Unit </v-tab>
    <v-tab-item value="unit">
      <UnitIndex />
    </v-tab-item>
    <v-tab href="#pph"> Pph </v-tab>
    <v-tab-item value="pph">
      <PphIndex />
    </v-tab-item>
    <v-tab href="#vat"> Vat </v-tab>
    <v-tab-item value="vat">
      <VatIndex />
    </v-tab-item>
    <!-- <v-tab href="#payment-method"> Payment Method </v-tab>
    <v-tab-item value="payment-method">
      <PaymentMethodIndex />
    </v-tab-item> -->
    <v-tab href="#payment-card"> Payment Card </v-tab>
    <v-tab-item value="payment-card">
      <PaymentCardIndex />
    </v-tab-item>
    <v-tab href="#type-in-out">Type InOut</v-tab>
    <v-tab-item value="type-in-out">
      <TypeInOut />
    </v-tab-item>
    <v-tab href="#bank-account"> Bank Account </v-tab>
    <v-tab-item value="bank-account">
      <BankAccount />
    </v-tab-item>
    <!-- <v-tab href="#group-patty-cash"> Group Patty Cash </v-tab>
    <v-tab-item value="group-patty-cash">
      <GroupPattyCash />
    </v-tab-item> -->
    <!-- <v-tab href="#email-account"> Email Account </v-tab>
    <v-tab-item value="email-account">
      <EmailAccount />
    </v-tab-item> -->
  </v-tabs>
</template>
<script>
import { mapGetters } from "vuex";
import GroupIndex from "./Group.vue";
import SubGroupIndex from "./SubGroup.vue";
// import GroupPattyCash from "./GroupPattyCash.vue";
import UnitIndex from "./Unit.vue";
import PphIndex from "./Pph.vue";
import VatIndex from "./Vat.vue";
// import PaymentMethodIndex from "./PaymentMethod.vue";
import PaymentCardIndex from "./PaymentCard.vue";
import BankAccount from "./BankAccount.vue";
import TypeInOut from "./TypeInOut.vue";
// import EmailAccount from "./EmailAccount.vue";
export default {
  name: "CustomIndex",
  components: {
    GroupIndex,
    SubGroupIndex,
    // GroupPattyCash,
    UnitIndex,
    PphIndex,
    VatIndex,
    // PaymentMethodIndex,
    PaymentCardIndex,
    BankAccount,
    TypeInOut,
    // EmailAccount,
  },

  computed: {
    ...mapGetters("auth", {
      ACCESS_TOKEN: "AccessToken",
      TOKEN: "Token",
    }),
  },
};
</script>