<template>
    <div>
        <base-title title="Payment Card" subtitle="List Data Payment Card" />
        <v-data-table :headers="headers" :items="PaymentCards.data" 
            class="elevation-1" :loading="datatableLoading" loading-text="Loading... Please wait" hide-default-footer>
            <template v-slot:[`item.is_active`]="{ item }">
                <v-chip x-small :color="getColor(item.is_active)" dark>
                    {{ item.is_active ? "Active" : "Not Active" }}
                </v-chip></template>
                <template v-slot:[`item.image`]="{ item }">
                <v-img :src="item.image" max-height="50" max-width="50" contain></v-img>
            </template>
            <template v-slot:no-data> No data </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="text-center">
                    <v-col cols="12"> <strong>ID:</strong>{{ item.id }} </v-col>
                </td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon color="warning" class="mr-2" @click.stop="editItem(item)">
                    mdi-pencil-box-outline
                </v-icon>
            </template>

            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-text-field v-model="search.search" append-icon="mdi-magnify" label="Search" outlined dense
                        hide-details></v-text-field>
                    <v-btn color="darkblue" class="white--text mx-1 mb-2" small @click.stop="searching">Submit <v-icon
                            right>mdi-magnify</v-icon></v-btn>
                    <v-btn small color="secondary" class="white--text mx-1 mb-2" @click.stop="clear">Clear
                        <v-icon right>mdi-close-circle-multiple </v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialogForm" max-width="450px" persistent>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" small elevation="1" color="primary" class="ma-2 white--text"
                                @click="loader = 'loading3'">
                                Create
                                <v-icon right dark>mdi-plus-circle </v-icon>
                            </v-btn>
                        </template>
                        <v-form ref="form_data" v-model="form_valid" lazy-validation>
                            <v-card class="rounded-lg">
                                <v-card-title class="mb-3">
                                    <span class="headline">{{ form_title }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-text-field v-model="form_data.name" label="Name" outlined dense :rules="rule_name">
                                    </v-text-field>
                                    <v-select label="Branch" :items="branch" v-model="form_data.branch_id" dense v-if="(ROLE == 'Owner')"
                                        item-text="name" item-value="id" outlined></v-select>
                                    <v-file-input label="Image" outlined dense v-model="form_data.image" id="file"
                                        ref="file" v-on:change="handleFileUpload()"></v-file-input>
                                    <v-switch dense v-model="form_data.is_active" label="USE">
                                    </v-switch>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn small color="error darken-1" @click.stop="closedialogForm">Cancel
                                        <v-icon right dark>mdi-close </v-icon>
                                    </v-btn>
                                    <v-btn small color="primary" class="ma-2 white--text" @click.stop="save"
                                        :loading="btnLoading" :disabled="!form_valid || btnLoading">
                                        Save
                                        <v-icon right dark>mdi-content-save </v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                </v-toolbar>
            </template>
        </v-data-table>
        <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { datatableMixin } from "@/mixins/datatable.js";
import BaseTitle from "@/components/base/BaseTitle.vue";
export default {
    components: { BaseTitle },
    name: "PaymentCardView",

    mixins: [datatableMixin],

    data: () => ({
        headers: [
            { text: "Name", value: "name" },
            { text: "Payment Method", value: "payment_method.name" },
            { text: "Image", value: "image" },
            { text: "Status", value: "is_active" },
            { text: "Action", value: "actions", sortable: false, width: 100 },
        ],

        list_payment: [],
        branch: [],
        file: '',
        page : 1,
        disabled: false,
        form_data: {
            name: "",
            payment_method_id: "",
            image: "",
            branch_id: "",
            is_active: true,
        },
        form_default: {
            name: "",
            payment_method_id: "",
            image: "",
            branch_id: "",
            is_active: true,
        },

        search: {
            search: null,
        },
    }),

    created() {
        this.getBranch();
    },

    methods: {
        ...mapMutations("payment_card", ["SET_CURRENT_PAGE"]),
        initialize: async function () {
            this.datatableLoading = true;
            let params = {
                page: this.page,
                search: this.search.search,
            };
            await this.$axios
                .get("/master/card_payment_type", {
                    params: params,
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.datatable = data.card_payment_type.data;
                    this.$store.commit("payment_card/SET_PAYMENT_CARDS", data.card_payment_type);
                    this.datatableLoading = false;
                })
                .catch(() => {
                    this.datatableLoading = false;
                });
        },

        addItem() {
            this.$axios
                .get("/master/", {
                    headers: { Authorization: this.$store.getters["auth/Token"] },
                })
                .then(({ data }) => {
                    this.list_payment = data.group.data;
                });
            this.dialogfrm = true;
        },

        searching() {
            this.page = 1;
            this.initialize();
        },

        clear() {
            this.page = 1;
            this.search = {
                search: "",
            };
            this.initialize();
        },

        handleFileUpload() {
            this.file = this.$refs.file.files;
        },

        save: async function () {
            if (this.$refs.form_data.validate()) {
                this.btnLoading = true;
                let formData = new FormData();
                formData.append('file', this.file);
                this.image = this.file;
                if (this.editedIndex > -1) {
                    await this.$axios
                        .post(
                            "/master/card_payment_type/" + this.form_data.id,
                            {
                                _method: "POST",
                                name: this.form_data.name,
                                payment_method_id: 2,
                                image: this.form_data.image ?? null,
                                is_active: this.form_data.is_active,
                            },
                            {
                                headers: {
                                    Authorization: this.$store.getters["auth/Token"],
                                    'Content-Type': 'multipart/form-data'
                                },
                            }
                        )
                        .then(({ data }) => {
                            Object.assign(this.datatable[this.editedIndex], data.card_payment_type);
                            this.closedialogForm();
                            this.btnLoading = false;
                        })
                        .catch(() => {
                            this.btnLoading = false;
                        });
                } else {
                    await this.$axios
                        .post(
                            "/master/card_payment_type",
                            {
                                name: this.form_data.name,
                                payment_method_id: 2,
                                image: this.form_data.image ?? null,
                                is_active: this.form_data.is_active,
                            },
                            {
                                headers: {
                                    Authorization: this.$store.getters["auth/Token"],
                                    'Content-Type': 'multipart/form-data'
                                },
                            }
                        )
                        .then(() => {
                            this.closedialogForm();
                            this.btnLoading = false;
                            this.initialize();
                        })
                        .catch(() => {
                            this.btnLoading = false;
                        });
                }
            }
        },

        // get branch
        async getBranch() {
            await this.$axios("/master/branches?active=1", {
                headers: {
                    Authorization: this.$store.getters["auth/Token"],
                },
            }).then(({ data }) => {
                this.branch = data.branch.data;
            });
        },

        editItem(item) {
            this.id = item.id;
            this.editedIndex = this.datatable.indexOf(item);
            item.image = null
            this.form_data = Object.assign({}, item);
            this.$axios
                .get("/master/card_payment_type", {
                    headers: { Authorization: this.$store.getters["auth/Token"] },
                })
                .then(({ data }) => {
                    this.list_payment = data.card_payment_type.data;
                });

            this.dialogForm = true;
        },
    },

    watch: {
        currentPage(newValue) {
            this.page = newValue;
            this.initialize();
        },
    },
    computed: {
        form_title() {
            return this.editedIndex === -1 ? "Create Payment Card" : "Edit Payment Card";
        },

        ...mapGetters("auth", {
            ROLE: "Role",
        }),

        ...mapGetters("payment_card", {
            PaymentCards: "PaymentCards",
        }),

        currentPage: {
            get() {
                return this.PaymentCards.current_page;
            },
            set(val) {
                return this.$store.commit("payment_card/SET_CURRENT_PAGE", val);
            },
        },
        lastPage: {
            get() {
                return this.PaymentCards.last_page;
            },
        },

    },
};
</script>