const state = () => ({
    discounts: {},
    discount: {
        id: null,
        name: null,
        item_id: null,
        item_name: null,
        percentage: 0,
        from_date: new Date().toJSON().slice(0, 10),
        end_date: new Date().toJSON().slice(0, 10),
        count_item: 0,
        details: [],
    },
    page: 1,
});

const mutations = {
    SET_DISCOUNTS(state, payload) {
        state.discounts = payload;
    },

    SET_DISCOUNT(state, payload) {
        state.discount.id = payload.id;
        state.discount.item_name = payload.name;
        state.discount.percentage = payload.percentage;
        state.discount.from_date = payload.from_date;
        state.discount.end_date = payload.end_date;
        state.discount.details = payload.details;
    },

    SET_DETAILS(state, payload) {
        state.discount.details.push({
            product_id: payload.id,
            product_name: payload.name,
            product_description: payload.specification,
            product_unit: payload.unit.name,
            product_price: payload.price_sell,
        })
    },

    SET_CURRENT_PAGE(state, payload) {
        state.discounts.current_page = payload;
    },

    CLEAR_STATE(state) {
        state.discount = {
            id: null,
            name: null,
            item_id: null,
            item_name: null,
            percentage: 0,
            from_date: new Date().toJSON().slice(0, 10),
            end_date: new Date().toJSON().slice(0, 10),
            details: [],

        }
    }
};

const getters = {
    Discounts: state => state.discounts,
    Discount: state => state.discount,
    Page: state => state.page,
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};
