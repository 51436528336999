<template>
  <div>
    <v-row class="mt-1 mx-1">
      <base-column :lg="3" :md="4" :sm="12">
        <v-text-field v-model="PurchaseInvoice.no_po" readonly placeholder="Purchase Order Number" outlined dense
          :append-icon="'mdi-magnify'" hide-details @click:append="openPurchaseDialog()"></v-text-field>
      </base-column>
    </v-row>
    <br>
    <!-- <v-divider class="mx-2"></v-divider> -->
    <v-row dense class="mx-3 mt-4">
      <base-column :lg="4" :md="6" :sm="12">
        <v-card max-width="100%">
          <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title>
              <v-icon>mdi-account</v-icon>
              Supplier
              {{ PurchaseInvoice.supplier_name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-app-bar>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>Email : {{ PurchaseInvoice.supplier_email }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Phone : {{ PurchaseInvoice.supplier_phone }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Address : {{ PurchaseInvoice.supplier_address }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </base-column>
      <base-column :lg="4" :md="6" :sm="12">
        <v-card max-width="100%">
          <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title>
              <v-icon>mdi-map-marker</v-icon>
              Shipping Address
              {{ PurchaseInvoice.customer_name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-app-bar>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>Email : {{ PurchaseInvoice.customer_email }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Phone : {{ PurchaseInvoice.customer_phone }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Address : {{ PurchaseInvoice.customer_address }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </base-column>
      <v-row dense class="mx-3 mt-1">
        <base-column :lg="6" :md="6" :sm="12">
          <v-select label="Payment Method" item-value="id" item-text="name" outlined dense
            v-model="PurchaseInvoice.payment_method_id" :items="payment_method" :rules="rule_payment_method"
            readonly></v-select>
          <v-textarea rows="2" label="Remark" outlined dense v-model="PurchaseInvoice.remark"></v-textarea>
        </base-column>
        <base-column :lg="6" :md="6" :sm="12">
          <v-text-field label="Type" outlined dense v-model="PurchaseInvoice.type"></v-text-field>
          <v-select label="Branch" item-value="id" item-text="name" v-if="(ROLE == 'Owner')" outlined dense
            v-model="PurchaseInvoice.branch_id" :items="branch" readonly></v-select>
        </base-column>
      </v-row>
    </v-row>
    <br>
    <v-row dense class="mx-3 mt-1">
      <base-column :lg="3" :md="3" :sm="12">
        <v-text-field type="date" label="Purchase Invoice Date" outlined dense v-model="PurchaseInvoice.date"
          :rules="rule_invoice_date"></v-text-field>
        <!-- <v-textarea rows="2" label="Remark" outlined dense v-model="PurchaseInvoice.remark"></v-textarea> -->
      </base-column>
      <base-column :lg="3" :md="3" :sm="12">
        <v-text-field :rules="rule_disc" type="number" outlined dense label="Discount" append-icon="mdi-percent"
          v-model="PurchaseInvoice.discount_percent"></v-text-field>
      </base-column>
      <base-column :lg="3" :md="3" :sm="12">
        <v-switch label="Use VAT" class="mt-2" v-model="PurchaseInvoice.use_vat"></v-switch>
      </base-column>
    </v-row>
    <v-divider class="mx-3"></v-divider>
    <v-dialog v-if="dialogPurchaseOrders" v-model="dialogPurchaseOrders" max-width="1200px" persistent>
      <purchase-order-modal v-on:closePurchaseDialog="closePurchaseDialog" v-on:selectPurchase="selectPurchase" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import BaseColumn from "@/components/base/BaseColumn.vue";
import PurchaseOrderModal from "@/components/modal/PurchaseOrderInvoiceModal.vue";
export default {
  name: "PurchaseInvoiceForm",

  data() {
    return {
      supplier: [],
      dialogPurchaseOrders: false,
      status_invoice: "PARTIAL",
      payment_method: [],
      branch: [],
    };
  },
  created() {
    this.getPaymentMethod();
    this.getBranch();
  },

  methods: {
    ...mapMutations("purchase_invoice", [
      "SET_PURCHASE_INVOICE",
    ]),

    openPurchaseDialog() {
      this.dialogPurchaseOrders = true;
    },
    closePurchaseDialog() {
      this.dialogPurchaseOrders = false;
    },

    selectPurchase(purchase_order) {
      this.$axios
        .get("/transaction/purchases/" + purchase_order.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit("purchase_invoice/CLEAR_STATE");
          this.$store.commit("purchase_invoice/SET_INV_FROM_PO", data.purchase_orders);
        })
        .catch(() => {
          this.loading = true;
        });
    },


    async getPaymentMethod() {
      await this.$axios("/master/payment-method", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.payment_method = data.payment_method.data;
      });
    },

    async getBranch() {
      await this.$axios("/master/branches", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.branch = data.branch.data;
      });
    },

  },

  computed: {
    ...mapState("purchase_invoice", {
      PurchaseInvoice: (state) => state.purchase_invoice,
    }),

    ...mapState("purchase_order", {
      PurchaseOrder: (state) => state.purchase_order,
    }),
    ...mapGetters("auth", {
      ROLE: "Role",
    }),
  },
  components: {
    BaseColumn,
    "purchase-order-modal": PurchaseOrderModal,
  },
};
</script>