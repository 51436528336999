const state = () => ({
    branch_sales_orders: {},
    branch_sales_orders_detail: {},
    branch_sales_order: {
        id: null,
        no_so: null,
        bill_no: null,
        opening_id: null,
        bank_account_id: null,
        number_account: null,
        name_account: null,
        bank_name: null,
        pph_id: null,
        customer_id: null,
        customer_name: null,
        customer_phone: null,
        customer_address: null,
        customer_email: null,
        branch_id: null,
        branch_name: null,
        branch_email: null,
        branch_phone: null,
        branch_address: null,
        table_id: null,
        no_order: null,
        status: null,
        type: null,
        order_date: null,
        delivery_date: null,
        opening_date: null,
        amount: null,
        change_amount: null,
        use_pph: false,
        use_vat: false,
        subtotal: 0,
        discount_amount: 0,
        discount_percent: 0,
        discount_nominal: 0,
        voucher_amount: 0,
        promo_amount: 0,
        pph: 0,
        pph_percentage: 0,
        vat: 0,
        grand_total: 0,
        no_sequence: 0,
        is_faid: false,
        flag: null,
        details: [],

    },
    page: 1,
})

const mutations = {
    SET_BRANCH_SALES_ORDERS(state, payload) {
        state.branch_sales_orders = payload
    },

    SET_BRANCH_SALES_ORDERS_DETAIL(state, payload) {
        state.branch_sales_orders_detail = payload
    },

    SET_BRANCH_SALES_ORDER(state, payload) {
        state.branch_sales_order.no_so = payload.no_so
        state.branch_sales_order.customer_id = payload.customer_id
        state.branch_sales_order.customer_name = payload.customer.name
        state.branch_sales_order.customer_phone = payload.customer.phone
        state.branch_sales_order.customer_address = payload.customer.address
        state.branch_sales_order.customer_email = payload.customer.email
        state.branch_sales_order.branch_id = payload.branch.id
        state.branch_sales_order.branch_name = payload.branch.name
        state.branch_sales_order.branch_email = payload.branch.email
        state.branch_sales_order.branch_phone = payload.branch.phone
        state.branch_sales_order.branch_address = payload.branch.address
        state.branch_sales_order.table_id = payload.table_id
        state.branch_sales_order.no_order = payload.no_order
        state.branch_sales_order.status = payload.status
        state.branch_sales_order.type = payload.type
        state.branch_sales_order.order_date = payload.order_date
        state.branch_sales_order.delivery_date = payload.delivery_date
        state.branch_sales_order.opening_date = payload.opening_date
        state.branch_sales_order.amount = payload.amount
        state.branch_sales_order.change_amount = payload.change_amount
        state.branch_sales_order.use_pph = payload.use_pph
        state.branch_sales_order.use_vat = payload.use_vat
        state.branch_sales_order.subtotal = payload.subtotal
        state.branch_sales_order.discount_amount = payload.discount_amount
        state.branch_sales_order.discount_percent = payload.discount_percent
        state.branch_sales_order.discount_nominal = payload.discount_nominal
        state.branch_sales_order.voucher_amount = payload.voucher_amount
        state.branch_sales_order.pph = payload.pph
        state.branch_sales_order.vat = payload.vat
        state.branch_sales_order.grand_total = payload.grand_total
        state.branch_sales_order.details = payload.details
    },

    SET_CURRENT_PAGE_LIST(state, payload) {
        state.branch_sales_orders.current_page = payload;
    },

    SET_CURRENT_PAGE_DETAIL(state, payload) {
        state.branch_sales_orders_detail.current_page = payload;
    }
}

const getters = {
    BranchSalesOrders: state => state.branch_sales_orders,
    BranchSalesOrdersDetail: state => state.branch_sales_orders_detail,
    BranchSalesOrder: state => state.branch_sales_order,
    Page: state => state.page
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}