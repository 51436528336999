import StoreManagementTabs from '@/views/transaction/store_management/order/tabs.vue'
import StoreManagementProduct from '@/views/transaction/store_management/product/Index.vue'


export default  [
    {
        path: '/store-management/tabs',
        name: 'store-management.tabs',
        meta: { title: 'S-ERP | Store Management' },
        component: StoreManagementTabs
    },
    {
        path: '/store-management/product',
        name: 'store-management.product',
        meta: { title: 'S-ERP | Store Management' },
        component: StoreManagementProduct
    }
]
