<template>
  
  <div v-if="Ingoing.type == 'MATERIAL'">
    <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-toolbar-title> Item List</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-simple-table class="mx-2 mb-3" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" width="1%">#</th>
            <th class="text-left" width="20%">Item Name</th>
            <th class="text-left" width="20%">Description</th>
            <th class="text-left" width="10%">Unit</th>
            <th class="text-left" width="10%">Price</th>
            <th class="text-left" width="10%">Qty Po</th>
            <th class="text-left" width="10%">Qty Remaining</th>
            <th class="text-left" width="10%">Qty</th>
            <th class="text-left" width="10%">Balance</th>
          </tr>
        </thead>
        <tbody>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="dialogProduct" v-if="dialogProduct" max-width="1200px" persistent><products-modal
        v-on:closeProductDialog="closeProductDialog" v-on:selectProduct="selectProduct" />
    </v-dialog>
  </div>
  <div v-else>
    <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-toolbar-title> Product List</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-simple-table class="mx-2 mb-3" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" width="1%">#</th>
            <th class="text-left" width="20%">Product Name</th>
            <th class="text-left" width="20%">Description</th>
            <th class="text-left" width="10%">Unit</th>
            <th class="text-left" width="10%">Price</th>
            <th class="text-left" width="10%">Qty Po</th>
            <th class="text-left" width="10%">Qty Remaining</th>
            <th class="text-left" width="10%">Qty</th>
            <th class="text-left" width="10%">Balance</th>
          </tr>
        </thead>
        <tbody>
        </tbody>
        <br>
      </template>
    </v-simple-table>
    <v-dialog v-model="dialogProduct" v-if="dialogProduct" max-width="1200px" persistent><products-modal
        v-on:closeProductDialog="closeProductDialog" v-on:selectProduct="selectProduct" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProductsModal from "@/components/modal/ProductsModal.vue";

export default {
  name: "IngoingFormItem",
  data() {
    return {
      dialogProduct: false,
    };
  },
  methods: {
    preventOverflow() {
      this.Ingoing.details.forEach((item) => {
        if (item.qty_in > item.qty) {
          item.qty_in = item.qty;
        }
      });
    },
    openProductDialog() {
      this.dialogProduct = true;
    },
    closeProductDialog() {
      this.dialogProduct = false;
    },
    selectProduct(val) {
      if (this.Ingoing.details.find((item) => item.item_id === val.id)) {
        this.Ingoing.details.find((item) => item.item_id === val.id).qty++;
        return;
      }
      this.$store.commit("ingoing/SET_DETAILS", val);
      this.dialogProduct = false;
    },

    removeProduct(index) {
      this.Ingoing.details.splice(index, 1);
    },
  },

  computed: {
    ...mapState("ingoing", {
      Ingoing: (state) => state.ingoing,
    }),
  },

  components: {
    "products-modal": ProductsModal,
  },
};
</script>
