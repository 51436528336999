import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import Auth from "./modules/auth";
import User from "./modules/users";
import Notification from "./modules/notification";
import Global from "./modules/global";
import Dashboard from "./modules/dashboard/dashboard";
import Company from "./modules/master/company";

//Master
import Item from './modules/master/item';
import ItemIngredient from './modules/master/item_ingredient';
import MasterVariant from './modules/master/master_variant';
import Customer from './modules/master/customer';
import Employee from './modules/master/employee';
import Supplier from './modules/master/supplier';
import Unit from './modules/master/unit';
import Pph from './modules/master/pph';
import Vat from './modules/master/vat';
import Group from './modules/master/group';
import GroupTable from './modules/master/group_table';
import SubGroup from './modules/master/sub_group';
import BankAccount from './modules/master/bank_account';
import Promo from './modules/master/promo';
import Variant from './modules/master/variant';
import Table from './modules/master/table';
import Voucher from './modules/master/voucher';
import Discount from './modules/master/discount';
import Branch from './modules/master/branch';
import PaymentMethod from './modules/master/payment_method';
import PaymentCard from './modules/master/payment_card';
import AccountPattyCash from './modules/master/account_patty_cash';
import PettyCash from './modules/transaction/petty_cash';
import Device from './modules/master/device';
import Service from './modules/master/service';

//Transaction
import SalesOrder from './modules/transaction/sales_order';
import SalesInvoice from './modules/transaction/sales_invoice';
import DailySalesOrder from './modules/transaction/daily_sales_order';
import BranchSalesOrder from './modules/transaction/branch_sales_order';
import PurchaseOrder from './modules/transaction/purchase_order';
import RequestOrder from './modules/transaction/request_order';
import PurchaseInvoice from './modules/transaction/purchase_invoice';
import Ingoing from './modules/transaction/ingoing';
import Outgoing from './modules/transaction/outgoing';
import StockOpname from './modules/transaction/stock_opname';


Vue.use(Vuex)

const secureLS = new SecureLS({
  encodingType: 'aes',
  encryptionSecret: 'vue-cart-persist'
});
const authstate = createPersistedState({
  key: 's-erp-application',
  storage: {
    getItem: (key) => secureLS.get(key),
    setItem: (key, value) => secureLS.set(key, value),
    removeItem: (key) => secureLS.remove(key),
  },
});

const store = new Vuex.Store({
  modules: {
    auth: Auth,
    user: User,
    global: Global,
    nofication: Notification,
    company: Company,
    dashboard: Dashboard,
    unit: Unit,
    item: Item,
    item_ingredient: ItemIngredient,
    master_variant: MasterVariant,
    customer: Customer,
    employee: Employee,
    suppliers: Supplier,
    pph: Pph,
    vat: Vat,
    group: Group,
    payment_method: PaymentMethod,
    payment_card : PaymentCard,
    sub_group: SubGroup,
    bank_account: BankAccount,
    promo: Promo,
    variant: Variant,
    voucher: Voucher,
    table: Table,
    group_table: GroupTable,
    discount: Discount,
    branch: Branch,
    account_patty_cash: AccountPattyCash,
    petty_cash: PettyCash,
    device: Device,
    service: Service,

    sales_order: SalesOrder,
    daily_sales_order: DailySalesOrder,
    branch_sales_order: BranchSalesOrder,
    purchase_order: PurchaseOrder,
    request_order :RequestOrder,
    ingoing: Ingoing,
    outgoing: Outgoing,
    purchase_invoice: PurchaseInvoice,
    sales_invoice: SalesInvoice,
    stock_opname: StockOpname,
  },
  plugins: [authstate],
});

export default store;
