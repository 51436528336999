const state = () => ({
    tables: {},
    page : 0
})

const mutations = {
    SET_TABLES(state, payload) {
        state.tables = payload
    },
    SET_CURRENT_PAGE(state, payload) {
        state.tables.current_page = payload
    }
}

const getters = {
    Tables: state => state.tables,
    Page: state => state.page
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}