
const state = () => ({
    units: {},
    page : 1
})

const mutations = {
    SET_UNITS(state, payload) {
        state.units = payload;
    },

    SET_CURRENT_PAGE (state, payload) {
        state.units.current_page = payload
    },
}
const getters = {
    Units: state => state.units,
    Page: state => state.page,
}


export default {
    namespaced: true,
    state,
    mutations,
    getters
}
