<template>
  <main-layout>
    <base-header>
      <template #icon> mdi-file</template>
      <template #name> Create Sales Order</template>
      <template #breadcrumbs>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </template>
    </base-header>

    <v-card class="mx-1">
      <!-- App Bar -->
      <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
        <v-toolbar-title>Sales Order Information</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small color="secondary" class="ma-2" @click="backForm()">
          <v-icon>mdi-arrow-left</v-icon> Back
        </v-btn>
        <v-btn small color="secondary" class="ma-2" @click="clearForm()">
          Clear
          <v-icon>mdi-close-circle-multiple </v-icon>
        </v-btn>
        <v-btn small class="ma-2" color="primary" @click.stop="store" :loading="btnLoading"
          :disabled="!form_valid || btnLoading">
          Save
          <v-icon right>mdi-content-save-all</v-icon>
        </v-btn>
      </v-app-bar>
      <v-form ref="SalesOrder" v-model="form_valid" lazy-validation>
        <form-sales-order />
        <form-item />
      </v-form>
    </v-card>
  </main-layout>
</template>

<script>
import Form from "./Form.vue";
import FormItem from "./FormItem.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import BaseHeader from "@/components/base/BaseHeader.vue";
import { mapMutations } from "vuex";
import _ from "lodash";
export default {
  name: "SalesOrderCreate",

  data() {
    return {
      btnLoading: false,
      form_valid: true,
      breadcrumbs: [],

    };
  },
  created() {
    this.breadcrumbs = [
      { text: "Transaction", disabled: false },
      { text: "Sales Order", disabled: true },
      { text: "Create", disabled: true },
    ];
  },

  methods: {
    ...mapMutations("sales_order", ["CLEAR_STATE","CLEAR_DETAILS_VARIANT"]),
    store: async function () {
      if (this.$refs.SalesOrder.validate()) {
        if (this.SalesOrder.customer_id == null) {
          alert("Please select customer")
          return false;
        } else if (this.SalesOrder.bank_account_id == null) {
          alert("Please select bank account")
          return false;
        } else if (this.SalesOrder.details.length == 0) {
          alert("Please add product")
          return false;
        } else {
          this.SalesOrder.subtotal = parseFloat(_.sumBy(this.SalesOrder.details, function (t) {
          let total = (parseFloat(t?.price) * t?.qty)
          return total
        }).toFixed(2))
          this.SalesOrder.discount_amount = parseFloat(this.SalesOrder.subtotal) * parseFloat(this.SalesOrder.discount_percent) / 100;
          this.SalesOrder.afterDiscount = parseFloat(this.SalesOrder.subtotal - this.SalesOrder.discount_amount);
          if (this.SalesOrder.use_vat == true) {
            this.SalesOrder.vat = parseInt((this.SalesOrder.afterDiscount * 11)) / 100;
          } else {
            this.SalesOrder.vat = 0;
          }
          this.SalesOrder.pph = parseFloat(this.SalesOrder.afterDiscount * this.SalesOrder.pph_percentage) / 100;
          this.SalesOrder.grand_total = parseFloat(this.SalesOrder.afterDiscount) + parseFloat(this.SalesOrder.vat) - parseFloat(this.SalesOrder.pph);
          // console.log(this.SalesOrder);
          this.btnLoading = true;
          await this.$axios
            .post("/transaction/sales-orders", this.SalesOrder,
              {
                headers: { Authorization: this.$store.getters["auth/Token"] },
              })
            .then(() => {
              this.CLEAR_STATE();
              this.btnLoading = false;
              this.$router.push({ name: "sales-order.index" });
            })
            .catch(() => {
              this.btnLoading = false;
            });
        }
      }
    },
    backForm() {
      this.CLEAR_STATE();
      this.CLEAR_DETAILS_VARIANT();
      this.$router.push({ name: "sales-order.index" });
    },

    clearForm() {
      this.CLEAR_STATE();
      this.CLEAR_DETAILS_VARIANT();
    },
  },
  computed: {
    SalesOrder() {
      return this.$store.getters["sales_order/SalesOrder"];
    },
  },

  components: {
    MainLayout,
    BaseHeader,
    "form-sales-order": Form,
    "form-item": FormItem,
  },
};
</script>
