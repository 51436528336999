const state = () => ({
    devices: {},
    device: {
        id: null,
        name: null,
        type: null,
        connection_type: null,
        ip_address: null,
        port: null,
        baud_rate: null,
        use_default: false,
        is_active: false,
    },

    page: 1,

})

const mutations = {
    SET_DEVICES(state, payload) {
        state.devices = payload
    },

    SET_DEVICE(state, payload) {
        state.device = {
            id: payload.id,
            name: payload.name,
            type: payload.type,
            connection_type: payload.connection_type,
            ip_address: payload.ip_address,
            port: payload.port,
            baud_rate: payload.baud_rate,
            use_default: payload.use_default,
            is_active: payload.is_active,

        }
    },

    SET_CURRENT_PAGE(state, payload) {
        state.devices.current_page = payload
    },

    CLEAR_DEVICE(state) {
        state.devices = {
            id: null,
            name: null,
            type: null,
            connection_type: null,
            ip_address: null,
            port: null,
            baud_rate: null,
            use_default: false,
            is_active: false,
        }
    },
}

const getters = {
    Devices: state => state.devices,
    Device: state => state.device,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}
