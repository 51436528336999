const state = () => ({
    services: {},
    service: {
        id: null,
        type: null,
        percentage : null,
        use_default: false,
        is_active: false,
    },

    page: 1,

})

const mutations = {
    SET_SERVICES(state, payload) {
        state.services = payload
    },

    SET_SERVICE(state, payload) {
        state.service = {
            id: payload.id,
            type : payload.type,
            percentage : payload.percentage,
            use_default: payload.use_default,
            is_active: payload.is_active,

        }
    },

    SET_CURRENT_PAGE(state, payload) {
        state.services.current_page = payload
    },

    CLEAR_STATE(state) {
        state.service = {
            id: null,
            type: null,
            percentage : null,
            use_default: false,
            is_active: false,
            
        }
    },
}

const getters = {
    Services: state => state.services,
    Service: state => state.service,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}
