<template>
  <div>
    <base-title title="Create Device" subtitle="Please fill in the Device information in the form below" />
    <v-form ref="form_data" v-model="form_valid" lazy-validation>
      <v-row class="mx-1 mt-2">
        <base-column :lg="3" :md="4" :sm="12">
          <v-text-field label="Device Name" v-model="form_data.device_name" :rules="rule_device_name" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="3" :md="4" :sm="12">
          <v-text-field label="Type" v-model="form_data.type" :rules="rule_type" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="3" :md="4" :sm="12">
        <v-select :items="type" item-text="name" item-value="value"  label="Type" v-model="form_data.type"  outlined dense></v-select>
        </base-column>
        <base-column :lg="3" :md="4" :sm="12">
          <v-text-field label="IP Address" v-model="form_data.ip_address" :rules="rule_ip_address" outlined dense></v-text-field>
        </base-column>
      </v-row>
      <v-row class="mx-1 mt-2">
        <base-column :lg="3" :md="4" :sm="12">
          <v-text-field type="number" label="Port" v-model="form_data.port"  outlined dense></v-text-field>
        </base-column>
        <base-column :lg="3" :md="4" :sm="12">
          <v-text-field  label="Device Position" v-model="form_data.device_position"  outlined dense></v-text-field>
        </base-column>
        <base-column :lg="3" :md="4" :sm="12">
          <v-switch label="Print Option" v-model="form_data.use_default" color="primary" class="mt-0 pt-1"></v-switch>
        </base-column>
        <base-column :lg="3" :md="4" :sm="12">
        <v-switch label="Is Active" v-model="form_data.is_active" color="primary" class="mt-0 pt-1"></v-switch>
        </base-column>
      </v-row>
      <v-row class="mx-1">
        <back-button />
        <v-btn color="primary" small class="ma-1" @click.stop="update" :loading="btnLoading"
          :disabled="!form_valid || btnLoading">Save <v-icon right>mdi-content-save</v-icon></v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import BackButton from "@/components/base/BackButton.vue";
import BaseTitle from "@/components/base/BaseTitle.vue";
import BaseColumn from "@/components/base/BaseColumn.vue";
import { mapGetters } from "vuex";
export default {
  components: { BaseTitle, BaseColumn, BackButton },
  name: "SupplierCreate",
  data() {
    return {
      btnLoading: false,
      form_valid: true,
      branch: [],
      type : [
        {name : "Wifi", value : "wifi"},
        {name : "Bluetooth", value : "bluetooth"},
        {name : "LAN", value : "lan"},

      ],

      form_data: {
       device_name : "",
       type : "",
       connection_type : "",
       ip_address : "",
       port : "",
       use_default : false,
       is_active :false,
      },
    };
  },

  created() {
    this.findOne();
    this.getBranch();
  },

  methods: {
    update: async function () {
      if (this.$refs.form_data.validate()) {
        this.btnLoading = true;
        await this.$axios
          .post(
            "/master/device-connection-settings/" + this.$route.params.id,
            {
              _method: "PUT",
              device_name : this.form_data.device_name,
              type : this.form_data.type,
              connection_type : this.form_data.connection_type,
              ip_address : this.form_data.ip_address,
              port : this.form_data.port,
              device_position : this.form_data.device_position,
              use_default : this.form_data.use_default,
              is_active : this.form_data.is_active,
            },
            {
              headers: {
                Authorization: this.TOKEN,
              },
            }
          )
          .then(() => {
            this.btnLoading = false;
            this.$router.push({ name: "device.index" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },

    async getBranch() {
      await this.$axios("/master/branches?active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.branch = data.branch.data;
      });
    },

    findOne() {
      this.$axios
        .get("/master/device-connection-settings/" + this.$route.params.id, {
          headers: {
            Authorization: this.TOKEN,
          },
        })
        .then(({ data }) => {
          this.form_data.device_name = data.devices.device_name
          this.form_data.type = data.devices.type
          this.form_data.connection_type = data.devices.connection_type
          this.form_data.ip_address = data.devices.ip_address
          this.form_data.port = data.devices.port
          this.form_data.device_position = data.devices.device_position
          this.form_data.use_default = data.devices.use_default
          this.form_data.is_active = data.devices.is_active

        })
        .catch(() => {
          this.$router.push({ name: "device.index" });
        });
    },
  },

  computed: {
    ...mapGetters("auth", {
      TOKEN: "Token",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),
  },
};
</script>