<template>
  <div>
    <base-title title="Create Account Petty Cash"
      subtitle="Please fill in the account patty cash information in the form below" />
    <!-- Form -->
    <v-form ref="form_data" v-model="form_valid" lazy-validation>
      <v-row class="mx-1 mt-2">
        <base-column :lg="4" :md="4" :sm="12">
          <v-select label="Group Patty Cash" :items="group_patty_cash" v-model="form_data.group_petty_cash_id"
            item-text="name" item-value="id" :rules="rule_group_patty_cash" outlined dense></v-select>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Name" v-model="form_data.name" :rules="rule_name" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-textarea label="Description" rows="2" v-model="form_data.description" outlined dense></v-textarea>
        </base-column>
      </v-row>
      <v-row>
        <v-container>
          <back-button />
          <v-btn color="primary" small class="ma-1" @click.stop="store" :loading="btnLoading"
            :disabled="!form_valid || btnLoading">Save <v-icon right>mdi-content-save</v-icon></v-btn>
        </v-container>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BackButton from "@/components/base/BackButton.vue";
import BaseTitle from "@/components/base/BaseTitle.vue";
import BaseColumn from "@/components/base/BaseColumn.vue";
export default {
  components: { BaseTitle, BaseColumn, BackButton },
  name: "AccountPettyCashCreate",
  data() {
    return {
      btnLoading: false,
      form_valid: true,
      branch: [],
      group_patty_cash: [],

      form_data: {
        group_petty_cash_id: "",
        name: "",
        description: "",
      },
    };
  },
  created() {
    this.getBranch();
    this.getGroupPattyCash();
  },

  methods: {
    store: async function () {
      if (this.$refs.form_data.validate()) {
        this.btnLoading = true;
        await this.$axios
          .post(
            "/transaction/petty-cash-categories",
            {
              group_petty_cash_id: this.form_data.group_petty_cash_id,
              name: this.form_data.name,
              description: this.form_data.description,
            },
            {
              headers: {
                Authorization: this.$store.getters["auth/Token"],
              },
            }
          )
          .then(() => {
            this.btnLoading = false;
            this.$router.push({ name: "account-petty-cash.index" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },

    async getGroupPattyCash() {
      await this.$axios("/master/group-petty-cashes", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.group_patty_cash = data.group_petty_cashes.data;
      });
    },

    async getBranch() {
      await this.$axios("/master/branches?active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.branch = data.branch.data;
      });
    },
  },

  computed: {
    ...mapGetters("auth", {
      ROLE: "Role",
    }),
  },
};
</script>