const state = () => ({
    promos: {},
    promo: {
        id: null,
        name: null,
        start_date : new Date().toJSON().slice(0, 10),
        end_date : new Date().toJSON().slice(0, 10),
        start_time :null,
        end_time : null,
        details : [],
    },

})

const mutations = {
    SET_PROMOS(state, payload) {
        state.promos = payload;
    },

    SET_PROMO(state, payload) {
        state.promo = {
            id: payload.id,
            name: payload.name,
            start_date : payload.start_date,
            end_date : payload.end_date,
            start_time : payload.start_time,
            end_time : payload.end_time,
            details : payload.details,
            
        };
    },

    SET_DETAILS_ITEM(state, payload) {
        state.promo.details.push({
            name: payload.name,
            size : payload.size,
            qty: payload.qty,
        })
    },

    CLEAR_STATE(state) {
        state.promo = {
            id: null,
            name: null,
            start_date : new Date().toJSON().slice(0, 10),
            end_date : new Date().toJSON().slice(0, 10),
            start_time :null,
            end_time : null,
            details : [],
        };
    },

    SET_CURRENT_PAGE(state, payload) {
        state.promos.current_page = payload;
    },
}

const getters = {
    Promos: state => state.promos,
    Promo: state => state.promo,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}

