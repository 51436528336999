<template>
  <v-col xs="12" sm="12" md="12" lg="12">
    <v-responsive width="100%" v-if="$vuetify.breakpoint.xsOnly" />
    <v-card elevation="5" rounded>
      <v-row class="mx-1 mb-1">
        <v-col xs="12" sm="12" md="3" lg="2">
          <v-text-field type="date" label="From date" outlined dense v-model="search.from_date">
          </v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="3" lg="2">
          <v-text-field type="date" label="End date" outlined dense v-model="search.end_date">
          </v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="3" lg="2">
          <v-select :items="status" outlined dense label="Status" v-model="search.status"></v-select>
        </v-col>
        <v-col xs="12" sm="12" md="3" lg="2">
          <v-text-field type="text" label="Search" outlined dense v-model="search.search">
          </v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="3" lg="4">
          <v-btn small color="darkblue" class="white--text mx-1" @click.stop="init">Submit <v-icon
              right>mdi-magnify</v-icon></v-btn>
          <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
            <v-icon right>mdi-close-circle-multiple </v-icon>
          </v-btn>
          <!-- <v-btn type="csv" name="filename.xls" small elevation="1" color="success" class="white--text float-end"
            @click.stop="exportExcell()">
            Export Excell
            <v-icon class="float-end ma-2">mdi-file-excel</v-icon>
          </v-btn> -->
        </v-col>
      </v-row>
      <v-data-table :loading="loading" :headers="headers" :items="data" class="elevation-1" hide-default-footer>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip color="secondary" small v-if="item.status == 'WAITING'">
            WAITING
          </v-chip>
          <v-chip color="primary" small v-else-if="item.status == 'ONPROGRESS'">
            ON PROGRESS
          </v-chip>
          <v-chip color="success" small v-else-if="item.status == 'DONE'">
            DONE
          </v-chip>
          <v-chip color="danger" small v-else-if="item.status == 'CANCEL'">
            CANCEL
          </v-chip>
          <v-chip color="warning" small v-else-if="item.status == 'PAID PARTIAL'">
            PAID PARTIAL
          </v-chip>
        </template>
        <template v-slot:[`item.grand_total`]="{ item }">
          <b>{{ item.grand_total | currency("", 2, { symbolOnLeft: false, }) }}</b>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip color="dark" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" color="secondary" v-bind="attrs" icon small @click.stop="view(item.id)" v-on="on">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View Sales Invoice</span>
          </v-tooltip>
          <v-tooltip color="dark" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" color="secondary" v-bind="attrs" icon small @click.stop="edit(item.id)" v-on="on">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit Sales Invoice</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "SalesInvoiceList",
  data() {
    return {
      headers: [
        { text: "No.Sales Invoice", value: "no_invoice" },
        { text: "Customer", value: "customer_name" },
        { text: "Branch", value: "branch_name" },
        { text: "Sales Invoice Date", value: "date" },
        { text: "Grand Total", value: "grand_total" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      data: [],
      disabled: false,
      loading: false,
      loading_download: false,
      status: ["WAITING", "ONPROGRESS", "DONE", "CANCEL", "VOID"],
      search: {
        from_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
        end_date: new Date().toJSON().slice(0, 10),
        search: null,
      },
    };
  },

  methods: {
    ...mapMutations("sales_invoice", ["CLEAR_STATE", "SET_SALES_INVOICES", "SET_CURRENT_PAGE"]),

    async init() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        status: this.search.status,
        search: this.search.search,
        from_date: this.search.from_date,
        end_date: this.search.end_date,
      };

      await this.$axios
        .get("http://localhost:3000/sales_invoice", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.data = data;
          this.loading = false;
          this.disabled = false;
        });
    },

    async edit(id) {
      this.loading = true;
      await this.$axios
        .get("/transaction/sales-orders/" + id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_SALES_ORDER(data.sales_orders);
          this.loading = false;
          this.$router.push("/sales-invoice/edit");
        })
        .catch(() => {
          this.loading = true;
        });
    },

    exportExcell: async function () {
      await this.$axios
        .get(
          "/transaction/sales-orders/export",
          {
            headers: {
              Authorization: this.$store.getters["auth/Token"],
            },
          }
        ).then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const a = document.createElement('a');
          a.href = url;
          a.setAttribute('download', 'sales-order.xlsx');
          document.body.appendChild(a);
          a.click();
        })
        .catch(() => {
        });
    },

    view(id) {
      this.$router.push("/sales-invoice/view/" + id);
    },


    searching() {
      this.page = 1;
      this.init();
    },
    clear() {
      this.page = 1;
      this.search = {
        status: "",
        search: "",
        from_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
        end_date: new Date().toJSON().slice(0, 10),
      };
      this.init();
    },
  },

  created() {
    this.init();
  },

  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.init();
    },
  },
  computed: {
    ...mapGetters("sales_invoice", {
      SalesInvoices: "SalesInvoices",
    }),

    currentPage: {
      get() {
        return this.SalesInvoices.current_page;
      },
      set(val) {
        return this.$store.commit("sales_invoice/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.SalesInvoices.last_page;
      },
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  background-color: #ebebeb59;
}

::v-deep th {
  color: #000 !important;
}
</style>