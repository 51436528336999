<template>
  <div>
    <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-toolbar-title> Item List</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn outlined small class="ma-1" color="primary" @click="openProductDialog()" v-if="!Ingoing.no_po">
        Add Item
        <v-icon right>mdi-plus</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-simple-table class="mx-2 mb-3" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" width="1%">#</th>
            <th class="text-left" width="20%">Item Name</th>
            <th class="text-left" width="25%">Description</th>
            <th class="text-left" width="5%">Unit</th>
            <th class="text-left" width="10%">Price</th>
            <th class="text-left" width="10%">Qty Po</th>
            <th class="text-left" width="10%">Qty In</th>
            <th class="text-left" width="10%">Qty</th>
            <th class="text-left" width="10%">Balance</th>
            <th class="text-left" width="15%">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr height="80" v-for="(row, index) in PurchaseInvoice.details" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              {{ row.item_name ?? row.item.name }}
            </td>
            <td>
              {{ row.item_description ?? row.item.description }}
            </td>
            <td>{{ row.item_unit ?? row.item?.unit?.name }}</td>
            <td>
              <b> {{ row.price ?? row?.item?.price_buy | currency("", 0, { symbolOnLeft: false }) }}
              </b>
            </td>
            <td> <b> {{ row.qty_po }} </b>
            </td>
            <td>
              <b> {{ row.qty_out ?? 0 }}
              </b>
            </td>
            <td>
              <v-text-field v-model="row.qty_in" @change="preventOverflow" :disabled="row.qty_po == row.qty_out"
                :readonly="PurchaseInvoice.no_invoice" placeholder="Qty In" type="number" outlined dense
                hide-details></v-text-field>
            </td>
            <td>
              <b>
                {{
                  row.qty_balance = (row.qty_po ?? 0) - (row.qty_out ?? 0) - (row.qty_in ?? 0)
                }}
              </b>
            </td>
            <td>
              <b>
                {{
                  row.total = (row.qty_in ?? 0) * (row.price ?? row?.item?.price_buy) | currency("", 0, {
                    symbolOnLeft:
                      false
                  })
                }}
              </b>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table class="mx-2 mb-3" fixed-header>
      <thead>
        <tr>
          <th class="text-left" width="45%"></th>
          <th class="text-left" width="10%"></th>
          <th class="text-left" width="10%"></th>
          <th class="text-left" width="20%"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
          </td>
          <td></td>
          <td class="text-right">
            Sub Total : <br />
            Discount : <br />
            Vat : <br />
            <h3>Total :</h3>
          </td>
          <td>
            <b>{{ sub_total | currency("", 0, { symbolOnLeft: false }) }}
            </b>
            <hr />
            <b>{{
              discount | currency("", 0, { symbolOnLeft: false }) }}
              |
              <v-icon color="success" small>mdi-arrow-bottom-right-thin
              </v-icon>
              <b class="success--text">{{ afterDiscount | currency("", 0, { symbolOnLeft: false }) }}</b>
            </b>
            <hr />
            <b>{{ vat | currency("", 0, { symbolOnLeft: false }) }}</b>
            <hr />
            <b class="primary--text"> {{ grand_total | currency("", 0, { symbolOnLeft: false }) }}</b>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-model="dialogProduct" v-if="dialogProduct" max-width="1200px" persistent><products-modal
        v-on:closeProductDialog="closeProductDialog" v-on:selectProduct="selectProduct" />
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import ProductsModal from "@/components/modal/ProductsModal.vue";

export default {
  name: "PurchaseInvoiceFormItem",
  data() {
    return {
      dialogProduct: false,
    };
  },
  methods: {
    preventOverflow() {
      this.PurchaseInvoice.details.forEach((item) => {
        if (item.qty_in > item.qty) {
          item.qty_in = item.qty;
        }
      });
    },
    openProductDialog() {
      this.dialogProduct = true;
    },
    closeProductDialog() {
      this.dialogProduct = false;
    },
    selectProduct(val) {
      if (this.PurchaseInvoice.details.find((item) => item.item_id === val.id)) {
        this.PurchaseInvoice.details.find((item) => item.item_id === val.id).qty++;
        return;
      }
      this.$store.commit("purchase_invoice/SET_DETAILS", val);
      this.dialogProduct = false;
    },

    removeProduct(index) {
      this.PurchaseInvoice.details.splice(index, 1);
    },
  },

  computed: {
    ...mapState("purchase_invoice", {
      PurchaseInvoice: (state) => state.purchase_invoice,
    }),

    sub_total() {
      return _.sumBy(this.PurchaseInvoice.details, function (t) {
        return (t.price * t.qty_in)
      });
    },

    discount() {
      if (this.PurchaseInvoice.discount_percent > 100 || this.PurchaseInvoice.discount_percent < 0) {
        return "Discount not valid value";
      }
      return parseFloat(this.sub_total) * parseFloat(this.PurchaseInvoice.discount_percent) / 100;
    },

    afterDiscount() {
      let discount = parseFloat(this.sub_total - this.discount);
      if (discount > 0) {
        return discount;
      } else {
        return 0;
      }
    },

    vat() {
      if (this.PurchaseInvoice.use_vat) {
        return parseFloat((this.afterDiscount * 11)) / 100;
      }
      else {
        return 0;
      }
    },

    grand_total() {
      return parseFloat(this.afterDiscount) + parseFloat(this.vat);
    },
  },

  components: {
    "products-modal": ProductsModal,
  },
};
</script>
