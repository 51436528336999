<template>
  <div>
    <v-row class="mt-1 mx-1">
      <base-column :lg="3" :md="4" :sm="12">
        <v-text-field v-model="PurchaseOrder.no_po" readonly placeholder="Purchase Order Number" outlined dense
          :append-icon="'mdi-magnify'" hide-details @click:append="openPurchaseDialog()"></v-text-field>
      </base-column>
      <base-column :lg="3" :md="4" :sm="12">
        <v-text-field v-model="PurchaseOrder.no_so" readonly placeholder="Sales Order Number" outlined dense
          :append-icon="'mdi-magnify'" hide-details @click:append="openSalesOrderDialog()"></v-text-field>
      </base-column>
    </v-row>
    <v-divider class="mx-4 mb-4 mt-6"></v-divider>
    <v-row class="mb-4 mt-3 mx-1">
      <base-column :lg="3" :md="3" :sm="12">
        <v-card max-width="100%" class="mb-2">
          <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title>
              <v-icon>mdi-account</v-icon>
              Supplier
              {{ PurchaseOrder.supplier_name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn outlined small color="primary" @click="openSupplierDialog">
              Choose
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-app-bar>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>Email : {{ PurchaseOrder.supplier_email }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Phone : {{ PurchaseOrder.supplier_phone }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Address : {{ PurchaseOrder.supplier_address }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </base-column>
      <base-column :lg="3" :md="3" :sm="12">
        <v-card max-width="100%" class="mb-2">
          <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title>
              <v-icon>mdi-map-marker</v-icon>
              Shipping Address
              {{ PurchaseOrder.shipping_name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn outlined small color="primary" @click="openShippingDialog">
              Choose
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-app-bar>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>Email : {{ PurchaseOrder.customer_id }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Phone : {{ PurchaseOrder.customer_phone }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Address : {{ PurchaseOrder.customer_address }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </base-column>
      <base-column :lg="2" :md="2" :sm="12">
        <v-select label="Purchase Type" :items="PURCHASE_TYPE" v-model="PurchaseOrder.type" outlined dense
          :rules="rule_type" item-id="name" @change="changePurchaseType" :disabled="DISABLED_PO_TYPE"></v-select>
        <v-text-field :rules="rule_disc" type="number" outlined dense label="Discount" append-icon="mdi-percent"
          v-model="PurchaseOrder.discount_percent"></v-text-field>
      </base-column>
      <base-column :lg="2" :md="2" :sm="12">
        <v-text-field type="date" label="Purchase Date" outlined dense v-model="PurchaseOrder.purchase_date"
          :rules="rule_purchase_date"></v-text-field>
        <v-switch label="Use VAT" class="mt-2" v-model="PurchaseOrder.use_vat"></v-switch>
      </base-column>
      <base-column :lg="2" :md="2" :sm="12">
        <v-select label="Payment Method" item-value="id" item-text="name" outlined dense
          v-model="PurchaseOrder.payment_method_id" :items="payment_method" :rules="rule_payment_method"></v-select>
        <v-select label="Branch" item-value="id" item-text="name" v-if="(ROLE == 'Owner')" outlined dense
          v-model="PurchaseOrder.branch_id" :items="branch"></v-select>
      </base-column>
    </v-row>
    <v-dialog v-if="dialogSupplier" v-model="dialogSupplier" max-width="1200px" persistent>
      <supplier-modal v-on:closeSupplierDialog="closeSupplierDialog" v-on:selectSupplier="selectSupplier" />
    </v-dialog>

    <v-dialog v-if="dialogShipping" v-model="dialogShipping" max-width="1200px" persistent>
      <shipping-modal v-on:closeShippingDialog="closeShippingDialog" v-on:selectCustomer="selectCustomer" />
    </v-dialog>

    <v-dialog v-if="dialogPurchaseOrders" v-model="dialogPurchaseOrders" max-width="1200px" persistent>
      <purchase-order-modal v-on:closePurchaseDialog="closePurchaseDialog" v-on:selectPurchase="selectPurchase" />
    </v-dialog>

    <v-dialog v-if="dialogSalesOrder" v-model="dialogSalesOrder" max-width="1200px" persistent>
      <sales-order-modal v-on:closeSalesOrderDialog="closeSalesOrderDialog" v-on:selectSalesOrder="selectSalesOrder" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import BaseColumn from "@/components/base/BaseColumn.vue";
import SupplierModal from '@/components/modal/SupplierModal.vue';
import ShippingModal from "@/components/modal/ShippingModal.vue";
import PurchaseOrderModal from "@/components/modal/PurchaseOrderModal.vue";
import SalesOrderModal from "@/components/modal/SalesOrderModal.vue";
export default {
  name: "PurchaseOrderForm",

  data() {
    return {
      supplier: [],
      dialogSupplier: false,
      dialogShipping: false,
      dialogPurchaseOrders: false,
      dialogSalesOrder: false,
      payment_method: [],
      branch: [],
      disabled_po_type: false,
    };
  },
  created() {
    this.getPaymentMethod();
    this.getBranch();
  },

  methods: {
    ...mapMutations("purchase_order", [
      "SET_PURCHASE_ORDER",
      "SET_SUPPLIER",
    ]),

    openSupplierDialog() {
      this.dialogSupplier = true;
    },

    closeSupplierDialog() {
      this.dialogSupplier = false;
    },

    openShippingDialog() {
      this.dialogShipping = true;
    },
    closeShippingDialog() {
      this.dialogShipping = false;
    },

    openPurchaseDialog() {
      this.dialogPurchaseOrders = true;
    },
    closePurchaseDialog() {
      this.dialogPurchaseOrders = false;
    },

    selectPurchase(purchase_order) {
      this.$axios
        .get("/transaction/purchases/" + purchase_order.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit("purchase_order/CLEAR_STATE");
          this.$store.commit("purchase_order/SET_PO", data.purchase_orders);

        })
        .catch(() => {
          this.loading = true;
        });
    },

    openSalesOrderDialog() {
      this.dialogSalesOrder = true;
    },
    closeSalesOrderDialog() {
      this.dialogSalesOrder = false;
    },

    selectSalesOrder(sales_order) {
      this.$axios
        .get("/transaction/sales-orders/" + sales_order.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit("purchase_order/CLEAR_STATE");
          this.$store.commit("purchase_order/SET_SO", data.sales_orders);

        })
        .catch(() => {
          this.loading = true;
        });
    },

    selectCustomer(val) {
      this.$store.commit("purchase_order/SET_SHIPPING", val);
    },

    selectSupplier(val) {
      this.SET_SUPPLIER({
        id: val.id,
        name: val.name,
        phone: val.phone,
        email: val.email,
        address: val.address,
      });
      this.dialogSupplier = false;
    },

    async getPaymentMethod() {
      await this.$axios("/master/payment-method", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.payment_method = data.payment_method.data;
      });
    },

    async getBranch() {
      await this.$axios("/master/branches", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.branch = data.branch.data;
      });
    },

    changePurchaseType(val) {
      if (val == "MATERIAL") {
        this.$store.commit("purchase_order/CLEAR_MATERIAL");
      } else {
        this.$store.commit("purchase_order/CLEAR_STATE");
      }
    },

  },

  computed: {
    ...mapState("purchase_order", {
      PurchaseOrder: (state) => state.purchase_order,
      PURCHASE_TYPE: (state) => state.purchase_type,
      DISABLED_PO_TYPE: (state) => state.disabled_po_type,
    }),
    ...mapGetters("auth", {
      ROLE: "Role",
    }),
  },
  components: {
    BaseColumn,
    SupplierModal,
    "shipping-modal": ShippingModal,
    "purchase-order-modal": PurchaseOrderModal,
    "sales-order-modal": SalesOrderModal,
  },
};
</script>