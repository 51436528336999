const state = () => ({
    sub_groups: [],
    page : 1
})

const mutations = {
    SET_SUBGROUPS(state, payload) {
        state.sub_groups = payload;
    },

    SET_CURRENT_PAGE(state, payload) {
        state.sub_groups.current_page = payload
    },
    CLEAR_STATE(state) {
        state.sub_groups = [];
    },
}
const actions = {
    clearState({ commit }) {
        commit("CLEAR_STATE");
    },
}
const getters = {
    SubGroups: state => state.sub_groups,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
