<template>
  <main-layout>
    <base-header>
      <template #icon> mdi-file</template>
      <template #name> Create Purchase Invoice Adjustment</template>
      <template #breadcrumbs>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </template>
    </base-header>

    <v-card class="mx-1">
      <!-- App Bar -->
      <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
        <v-toolbar-title>Purchase Invoice Adjustment Information</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small color="secondary" class="ma-2" @click="backForm()">
          <v-icon>mdi-arrow-left</v-icon> Back
        </v-btn>
        <v-btn small color="secondary" class="ma-2" @click="clearForm()">
          Clear
          <v-icon>mdi-close-circle-multiple </v-icon>
        </v-btn>
        <v-btn small class="ma-2" color="primary" @click.stop="store" :loading="btnLoading"
          :disabled="!form_valid || btnLoading">
          Save
          <v-icon right>mdi-content-save-all</v-icon>
        </v-btn>
      </v-app-bar>
      <v-form ref="PurchaseInvoice" v-model="form_valid" lazy-validation>
        <form-purchase-adjustment />
        <form-item />
      </v-form>
    </v-card>
  </main-layout>
</template>

<script>
import Form from "./Form.vue";
import FormItem from "./FormItem.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import BaseHeader from "@/components/base/BaseHeader.vue";
import { mapMutations } from "vuex";
export default {
  name: "PurchaseAdjustmentCreate",

  data() {
    return {
      btnLoading: false,
      form_valid: true,
    };
  },
  created() {
    this.breadcrumbs = [
      { text: "Transaction", disabled: false },
      { text: "Purchase Invoice Adjustment", disabled: true },
      { text: "Create", disabled: true },
    ];
  },

  methods: {
    ...mapMutations("purchase_invoice", ["CLEAR_STATE"]),
    store: async function () {
      if (this.$refs.PurchaseInvoice.validate()) {
        this.btnLoading = true;
        this.PurchaseInvoice.sub_total = this.PurchaseInvoice.details.reduce(
          (acc, cur) => acc + cur.total,
          0
        );
        this.PurchaseInvoice.discount = parseFloat(this.PurchaseInvoice.sub_total) * parseFloat(this.PurchaseInvoice.discount_percent) / 100;
        this.PurchaseInvoice.afterDiscount = parseFloat(this.PurchaseInvoice.sub_total - this.PurchaseInvoice.discount);
        if (this.PurchaseInvoice.use_vat == true) {
          this.PurchaseInvoice.vat = parseFloat((this.PurchaseInvoice.afterDiscount * 11)) / 100;
        } else {
          this.PurchaseInvoice.vat = 0;
        }
        this.PurchaseInvoice.grand_total = parseFloat(this.PurchaseInvoice.afterDiscount) + parseFloat(this.PurchaseInvoice.vat);
        await this.$axios
          .post("/transaction/purchase-invoice", this.PurchaseInvoice,
            {
              headers: { Authorization: this.$store.getters["auth/Token"] },
            })
          .then(() => {
            this.btnLoading = false;
            this.$router.push({ name: "purchase-adjustment.index" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },
    backForm() {
      this.CLEAR_STATE();
      this.$router.push({ name: "purchase-adjustment.index" });
    },

    clearForm() {
      this.CLEAR_STATE();
    },
  },
  computed: {
    PurchaseInvoice() {
      return this.$store.getters["purchase_invoice/PurchaseInvoice"];
    },
  },

  components: {
    MainLayout,
    BaseHeader,
    "form-purchase-adjustment": Form,
    "form-item": FormItem,
  },
};
</script>
