const state = () => ({
    payment_methods: [],

})

const mutations = {
    SET_PAYMENT_METHODS(state, payload) {
        state.payment_methods = payload;
    },

    CLEAR_STATE(state) {
        state.payment_methods = [];
    },
}

const getters = {
    PaymentMethods: state => state.payment_methods,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}
