<template>
  <div>
    <v-row class="mt-2 mx-1">
      <base-column :lg="3" :md="4" :sm="12">
        <v-text-field v-model="Outgoing.no_so" readonly placeholder="Sales Order Number" outlined dense
          :append-icon="'mdi-magnify'" hide-details @click:append="openSalesOrderDialog()"></v-text-field>
      </base-column>
    </v-row>
    <v-divider class="mx-4 mt-6"></v-divider>
    <v-row class="mt-3 mx-1">
      <base-column :lg="3" :md="6" :sm="12">
        <v-card max-width="100%" height="95%">
          <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title>
              <v-icon>mdi-account</v-icon>
              {{ Outgoing.customer_name ?? "Customer" }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-app-bar>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>Email : {{ Outgoing.customer_email }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Phone : {{ Outgoing.customer_phone }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Address : {{ Outgoing.customer_address }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </base-column>
      <v-row class="mt-1 mx-1">
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field type="date" label="Outgoing Date" outlined dense v-model="Outgoing.date"
            :rules="rule_outgoing_date"></v-text-field>
          <v-textarea label="Remark" outlined dense v-model="Outgoing.remark" rows="3"></v-textarea>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-select label="Outgoing Type" item-value="id" item-text="name" outlined dense
            v-model="Outgoing.type_in_out_id" :items="type_out" :rules="rule_type_out"></v-select>
          <!-- <v-select :items="branch" outlined dense label="Branch" item-text="name" item-value="id" v-model="branch_id"
            v-if="(ROLE == 'Owner')"></v-select> -->
        </base-column>
      </v-row>
    </v-row>
    <br>
    <v-divider class="mx-3"></v-divider>
    <v-dialog v-if="dialogSalesOrder" v-model="dialogSalesOrder" max-width="1200px" persistent>
      <sales-order-modal v-on:closeSalesOrderDialog="closeSalesOrderDialog" v-on:selectSalesOrder="selectSalesOrder" />
    </v-dialog>
    <v-dialog v-if="dialogOutgoing" v-model="dialogOutgoing" max-width="1200px" persistent>
      <outgoing-modal v-on:closeOutgoingDialog="closeOutgoingDialog" v-on:selectOutgoing="selectOutgoing" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import BaseColumn from "@/components/base/BaseColumn.vue";
import SalesOrderModal from "@/components/modal/SalesOrderModal.vue";
import OutgoingModal from "@/components/modal/OutgoingModal.vue";
export default {
  name: "IngoingForm",

  data() {
    return {
      dialogSalesOrder: false,
      dialogOutgoing: false,
      payment_method: [],
      type_out: [],
      branch: [],
    };
  },
  created() {
    this.getPaymentMethod();
    this.getBranch();
    this.getTypeInOut();
  },

  methods: {
    ...mapMutations("outgoing", [
      "SET_OUTGOING",
    ]),

    openSalesOrderDialog() {
      this.dialogSalesOrder = true;
    },
    closeSalesOrderDialog() {
      this.dialogSalesOrder = false;
    },

    selectSalesOrder(sales_order) {
      this.$axios
        .get("/transaction/sales-orders/" + sales_order.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit("outgoing/CLEAR_STATE");
          this.$store.commit("outgoing/SET_SO_FROM_OUT", data.sales_orders);

        })
        .catch(() => {
          this.loading = true;
        });
    },

    openOutgoingDialog() {
      this.dialogOutgoing = true;
    },
    closeOutgoingDialog() {
      this.dialogOutgoing = false;
    },

    selectOutgoing(outgoing) {
      this.$axios
        .get("/transaction/outgoings/" + outgoing.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit("outgoing/CLEAR_STATE");
          this.$store.commit("outgoing/SET_OUT_FROM_OUT", data.out_goings);

        })
        .catch(() => {
          this.loading = true;
        });
    },

    async getTypeInOut() {
      await this.$axios("/master/type-in-outs?flag=OUT", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        console.log(data.type_in_outs.data);
        this.type_out = data.type_in_outs.data;
      });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch.data;
        });
    },

    async getPaymentMethod() {
      await this.$axios("/master/payment-method", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.payment_method = data.payment_method.data;
      });
    },

  },

  computed: {
    ...mapState("outgoing", {
      Outgoing: (state) => state.outgoing,
    }),
    ...mapGetters("auth", {
      ROLE: "Role",
    }),
  },
  components: {
    BaseColumn,
    "sales-order-modal": SalesOrderModal,
    "outgoing-modal": OutgoingModal,
  },
};
</script>