<template>
  <div>
    <base-title title="Promo" subtitle="List Data Promo Item" />
    <v-btn to="/master/promo/create" small elevation="1" color="primary" class="ma-3 white--text float-right"
      @click="loader = 'loading3'">
      Create
      <v-icon right dark>mdi-plus-circle </v-icon>
    </v-btn>
    <promo-list />
  </div>
</template>

<script>
import BaseTitle from "@/components/base/BaseTitle.vue";
import PromoList from "@/views/master/promo/List.vue";
export default {
  components: { PromoList, BaseTitle },
  name: "PromoIndex",
  data() {
    return {
      loading: false,

    };
  },
};
</script>