const state = () => ({
    company: {
        id: null,
        name: null,
        address: null,
        phone: null,
        email: null,
        logo: null,
        website : null,
        contact_person : null,
        description : null,
        billing_plan : null,
    },
})

const mutations = {
    SET_COMPANY(state, payload) {
        state.company = {
            id : payload.id,
            name : payload.name,
            address : payload.address,
            phone : payload.phone,
            email : payload.email,
            logo : payload.logo,
            website : payload.website,
            contact_person : payload.contact_person,
            description : payload.description,
            billing_plan : payload.billing_plan,
        }
    },
}

const getters = {
    Company : state => state.company
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}