<template>
  <div>
    <base-sub-title title="User Information" />
    <v-form ref="User" v-model="form_valid" lazy-validation>
      <v-row class="mx-1">
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Name" outlined dense v-model="User.name" :rules="rule_name"></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Phone" outlined dense v-model="User.phone"></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field type="email" label="Email" outlined dense v-model="User.email"></v-text-field>
        </base-column>
      </v-row>
      <v-row class="mx-1">
        <base-column :lg="4" :md="4" :sm="12">
          <v-textarea rows="1" label="Address" v-model="User.address" outlined dense></v-textarea>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-select label="Role" outlined dense v-model="User.role_id" :items="role" :rules="rule_role" item-text="name"
            item-value="id"></v-select>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Username" outlined dense v-model="User.username" :rules="rule_username"></v-text-field>
        </base-column>
      </v-row>
      <v-row class="mx-1">
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field v-model="User.password" label="Current Password" outlined dense
            :append-icon="show_current ? 'mdi-eye' : 'mdi-eye-off'" :type="show_current ? 'text' : 'password'"
            @click:append="show_current = !show_current" :rules="rule_old">
          </v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field v-model="User.password_confirmation" label="Password Confirmation" outlined dense
            :append-icon="show_new ? 'mdi-eye' : 'mdi-eye-off'" :type="show_new ? 'text' : 'password'"
            @click:append="show_new = !show_new" :rules="rule_new">
          </v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
        <!-- <v-text-field v-model="password_confirmation" label="Password Confirmation" outlined dense
            :append-icon="show_new ? 'mdi-eye' : 'mdi-eye-off'" :type="show_new ? 'text' : 'password'"
            @click:append="show_new = !show_new" :rules="rule_new">
          </v-text-field> -->
          <v-switch v-model="User.is_active" class="mt-1" label="USE">
          </v-switch>
        </base-column>
      </v-row>
      <v-row class="mx-1">
        <base-column :lg="4" :md="4" :sm="12" v-if="(ROLE == 'Owner')">
          <v-select label="Branch" :items="branch" v-model="User.branch_id" dense item-text="name" item-value="id"
            outlined></v-select>
        </base-column>
      </v-row>
      <v-row class="mx-1">
        <back-button />
        <v-btn color="primary" small class="ma-2" @click.stop="update" :loading="btnLoading"
          :disabled="!form_valid || btnLoading">Update
          <v-icon right>mdi-content-save</v-icon></v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import BackButton from "@/components/base/BackButton";
import BaseColumn from "@/components/base/BaseColumn";
import BaseSubTitle from "@/components/base/BaseSubTitle";
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  name: "UserEdit",
  data() {
    return {
      btnLoading: false,
      branch: [],
      form_valid: true,
      show_current: false,
      show_new: false,
      new_password: "",
      current_password: "",
      role: [],
    };
  },
  created() {
    this.getRole();
    this.getBranch();
  },

  methods: {
    ...mapMutations("user", ["CLEAR_STATE", "SET_USER"]),
    update: async function () {
      if (this.$refs.User.validate()) {
        this.btnLoading = true;
        await this.$axios
          .put("/master/user/" + this.User.id, this.User,
            {
              headers: { Authorization: this.$store.getters["auth/Token"] },
            })
          .then(() => {
            this.btnLoading = false;
            this.$router.push({ name: "users.index" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },

    async getBranch() {
      await this.$axios("/master/branches?active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.branch = data.branch.data;
      });
    },

    async getRole() {
      await this.$axios("/system/roles", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.role = data.roles.data;
      });
    },

  },

  computed: {
    ...mapGetters("user", {
      User: "user",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    ...mapState("user", {
      User: (state) => state.user,
    }),
  },

  components: {
    BaseColumn,
    BaseSubTitle,
    BackButton,
  },
};
</script>