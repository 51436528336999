<template>
    <v-col xs="12" sm="12" md="12" lg="12">
        <v-responsive width="100%" v-if="$vuetify.breakpoint.xsOnly" />
        <v-card elevation="5" rounded>
            <v-row class="mx-1 mb-1">
                <v-col xs="12" sm="12" md="6" lg="4">
                    <v-text-field label="Search With Item Name" outlined dense v-model="search.search">
                    </v-text-field>
                </v-col>
                <v-col xs="12" sm="12" md="6" lg="4">
                    <v-btn small color="darkblue" class="white--text mx-1" @click.stop="init">Submit <v-icon
                            right>mdi-magnify</v-icon></v-btn>
                    <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
                        <v-icon right>mdi-close-circle-multiple </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table :loading="loading" :headers="headers" :items="BranchSalesOrdersDetail.data" class="elevation-1"
                hide-default-footer>
                <template v-slot:[`item.sales_order.grand_total`]="{ item }">
                    <b>{{ item.sales_order.grand_total | currency("", 0, { symbolOnLeft: false, }) }}</b>
                </template>
                <template v-slot:[`item.item.price_sell`]="{ item }">
                    <b>{{ item.item.price_sell | currency("", 0, { symbolOnLeft: false, }) }}</b>
                </template>
                <template v-slot:[`item.item.service_amount`]="{ item }">
                    <b>{{ item.item.service_amount | currency("", 0, { symbolOnLeft: false, }) }}</b>
                </template>
                <template v-slot:[`item.item.ppn_amount`]="{ item }">
                    <b>{{ item.item.ppn_amount | currency("", 0, { symbolOnLeft: false, }) }}</b>
                </template>
                <template v-slot:[`item.item.total`]="{ item }">
                    <b>{{ item.item.total | currency("", 0, { symbolOnLeft: false, }) }}</b>
                </template>
                <template v-slot:[`item.total`]="{ item }">
                    <b>{{ item.item.price_sell * item.qty | currency("",
                        0, {
                        symbolOnLeft: false,
                    })
                    }}</b>
                </template>
            </v-data-table>
            <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
        </v-card>
    </v-col>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
    name: "BranchSalesOrderList",
    data() {
        return {
            headers: [
                { text: "No Order", value: "sales_order.no_so" },
                { text: "Branch", value: "item.branch.name" },
                { text: "Order Date", value: "sales_order.order_date" },
                { text: "Item Name", value: "item.name" },
                { text: "Service", value: "item.service" },
                { text: "Ppn", value: "item.ppn" },
                { text: "Price Sell", value: "item.price_sell" },
                { text: "Unit", value: "item.unit.name" },
                { text: "Total", value: "item.total" },
            ],
            loading: false,
            disabled: false,
            page: 1,
            search: {
                search: null,
            },
        };
    },
    methods: {
        ...mapMutations("branch_sales_order", ["SET_BRANCH_SALES_ORDERS_DETAIL", "SET_CURRENT_PAGE_DETAIL"]),
        async init() {
            this.loading = true;
            this.disabled = true;
            let params = {
                page: this.page,
                search: this.search.search,
            };

            await this.$axios
                .get("/transaction/sales-orders/detail-item", {
                    params: params,
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.SET_BRANCH_SALES_ORDERS_DETAIL(data.sales_orders);
                    this.loading = false;
                    this.disabled = false;
                });
        },

        searching() {
            this.page = 1;
            this.init();
        },
        clear() {
            this.search = {
                search: "",
            };
            this.init();
        },
    },

    created() {
        this.init();
    },

    watch: {
        currentPage(newValue) {
            this.page = newValue;
            this.init();
        },
    },
    computed: {
        ...mapGetters("branch_sales_order", {
            BranchSalesOrdersDetail: "BranchSalesOrdersDetail",
        }),

        currentPage: {
            get() {
                return this.BranchSalesOrdersDetail.current_page;
            },
            set(val) {
                return this.$store.commit("branch_sales_order/SET_CURRENT_PAGE_DETAIL", val);
            },
        },
        lastPage: {
            get() {
                return this.BranchSalesOrdersDetail.last_page;
            },
        },
    },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
    background-color: #ebebeb59;
}

::v-deep th {
    color: #000 !important;
}
</style>