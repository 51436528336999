const state = () => ({
    ingoings: {},
    ingoing: {
        id: null,
        no_ingoing: null,
        branch_id: null,
        purchase_id: null,
        supplier_id: null,
        supplier_name: null,
        supplier_address: null,
        supplier_phone: null,
        supplier_email: null,
        type_in_out_id: 1,
        payment_method_id: null,
        purchase_date: null,
        ingoing_date: new Date().toJSON().slice(0, 10),
        type: "PRODUCT",
        vat: 0,
        sub_total: 0,
        discount_nominal: 0,
        discount_percent: 0,
        discount: 0,
        grand_total: 0,
        remark: null,
        details: [],
    },

    page: 1,
})

const mutations = {
    SET_INGOINGS(state, payload) {
        state.ingoings = payload
    },

    SET_INGOING(state, payload) {
        state.ingoing.id = payload.id
        state.ingoing.no_po = payload.purchase.no_po
        state.ingoing.no_ingoing = payload.no_ingoing
        state.ingoing.branch_id = payload.branch.id
        state.ingoing.branch_name = payload.branch.name
        state.ingoing.branch_phone = payload.branch.phone
        state.ingoing.branch_email = payload.branch.email
        state.ingoing.branch_address = payload.branch.address
        state.ingoing.purchase_id = payload.purchase_id
        state.ingoing.supplier_id = payload.supplier.id
        state.ingoing.supplier_name = payload.supplier.name
        state.ingoing.supplier_address = payload.supplier.address
        state.ingoing.supplier_phone = payload.supplier.phone
        state.ingoing.supplier_email = payload.supplier.email
        state.ingoing.type_in_out_id = payload.type_in_out_id
        state.ingoing.payment_method_id = payload.payment_method_id
        state.ingoing.purchase_date = payload.purchase_date
        state.ingoing.ingoing_date = payload.ingoing_date
        state.ingoing.type = payload.type
        state.ingoing.vat = payload.vat
        state.ingoing.remark = payload.remark
        state.ingoing.details = payload.details
    },

    SET_IN_FROM_IN(state, payload) {
        state.ingoing.id = payload.id
        state.ingoing.no_ingoing = payload.no_ingoing
        state.ingoing.ingoing_date = payload.ingoing_date
        state.ingoing.supplier_id = payload.supplier_id
        state.ingoing.supplier_name = payload.supplier.name
        state.ingoing.supplier_address = payload.supplier.address
        state.ingoing.supplier_phone = payload.supplier.phone
        state.ingoing.supplier_email = payload.supplier.email
        state.ingoing.type_in_out_id = payload.type_in_out_id
        state.ingoing.payment_method_id = payload.payment_method_id
        state.ingoing.no_po = payload.purchase.no_po

        for (let i = 0; i < payload.details.length; i++) {
            state.ingoing.details.push({
                item_id: payload.details[i].item_id,
                item_name: payload.details[i].item.name,
                item_description: payload.details[i].item.description,
                item_unit: payload.details[i].item.unit.name,
                qty: payload.details[i].qty ?? 0,
                qty_in: payload.details[i].qty_in ?? 0,
                qty_out: payload.details[i].qty - payload.details[i].qty_in ?? 0,
                qty_balance: 0,
                price: payload.details[i].price,
            });
        }
    },

    SET_IN_FORM_PO(state, payload) {
        state.ingoing.purchase_id = payload.id;
        state.ingoing.no_po = payload.no_po;
        state.ingoing.type = "PURCHASE";
        state.ingoing.supplier_id = payload.supplier_id;
        state.ingoing.supplier_name = payload.supplier.name;
        state.ingoing.supplier_phone = payload.supplier.phone;
        state.ingoing.supplier_email = payload.supplier.email;
        state.ingoing.supplier_address = payload.supplier.address;
        state.ingoing.purchase_date = payload.purchase_date;
        state.ingoing.payment_method_id = payload.payment_method_id;
        state.ingoing.sub_total = payload.sub_total;
        state.ingoing.grand_total = payload.grand_total;
        for (let i = 0; i < payload.details.length; i++) {
            state.ingoing.details.push({
                item_id: payload.details[i].item_id,
                item_name: payload.details[i].item.name,
                item_description: payload.details[i].item.description,
                item_unit: payload.details[i].item.unit.name,
                qty: payload.details[i].qty ?? 0,
                qty_in: payload.details[i].qty - payload.details[i].qty_in ?? 0,
                qty_out: payload.details[i].qty_in ?? 0,
                qty_balance: 0,
                price: payload.details[i].price,
            });

        }
    },

    SET_SUPPLIER(state, payload) {
        state.ingoing.supplier_id = payload.id;
        state.ingoing.supplier_name = payload.name;
        state.ingoing.supplier_address = payload.address;
        state.ingoing.supplier_phone = payload.phone;
        state.ingoing.supplier_email = payload.email;
    },

    SET_SUBTOTAL(state, payload) {
        state.ingoing.sub_total = payload
    },

    SET_DISCOUNT(state, payload) {
        state.ingoing.discount = payload
    },

    SET_VAT(state, payload) {
        state.ingoing.vat = payload
    },

    SET_TOTAL(state, payload) {
        state.ingoing.total = payload
    },

    SET_DETAILS(state, payload) {
        state.ingoing.details.push({
            item_id: payload.id,
            item_name: payload.name,
            item_description: payload.description,
            item_unit: payload.unit.name,
            qty: 0,
            qty_out: 0,
            qty_in: 0,
            price: payload.price_buy,
            discount: 0,
            qty_balance: 0,
        })
    },

    SET_CURRENT_PAGE(state, payload) {
        state.ingoing.current_page = payload;
    },

    CLEAR_STATE(state) {
        state.ingoing.id = null
        state.ingoing.no_ingoing = null
        state.ingoing.branch_id = null
        state.ingoing.purchase_id = null
        state.ingoing.no_po = null
        state.ingoing.supplier_id = null
        state.ingoing.supplier_name = null
        state.ingoing.supplier_address = null
        state.ingoing.supplier_phone = null
        state.ingoing.supplier_email = null
        state.ingoing.type_in_out_id = 1
        state.ingoing.payment_method_id = null
        state.ingoing.purchase_date = null
        state.ingoing.type = "PRODUCT"
        state.ingoing.vat = 0
        state.ingoing.sub_total = 0
        state.ingoing.discount_nominal = 0
        state.ingoing.discount_percent = 0
        state.ingoing.discount = 0
        state.ingoing.grand_total = 0
        state.ingoing.remark = null
        state.ingoing.details = []
    },


}

const getters = {
    Ingoings: state => state.ingoings,
    Ingoing: state => state.ingoing,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}