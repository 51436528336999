<template>
  <v-col xs="12" sm="12" md="12" lg="12">
    <v-responsive width="100%" v-if="$vuetify.breakpoint.xsOnly" />
    <v-card elevation="5" rounded>
      <v-row class="mx-1 mb-1">
        <v-col xs="12" sm="12" md="2" lg="2">
          <v-text-field type="date" label="From date" outlined dense v-model="search.from_date">
          </v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="2" lg="2">
          <v-text-field type="date" label="End date" outlined dense v-model="search.end_date">
          </v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="2" lg="2" v-if="(ROLE == 'Owner')">
          <v-select :items="branch" item-text="name" item-value="id" outlined dense label="Branch"
            v-model="search.branch_id"></v-select>
        </v-col>
        <v-col xs="12" sm="12" md="2" lg="2">
          <v-select :items="status" outlined dense label="Status" v-model="search.status"></v-select>
        </v-col>
        <v-col xs="12" sm="12" md="2" lg="2">
          <v-text-field label="Search" outlined dense v-model="search.search">
          </v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="2" lg="2">
          <v-btn small color="darkblue" class="white--text mx-1" @click.stop="init">Submit <v-icon
              right>mdi-magnify</v-icon></v-btn>
          <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
            <v-icon right>mdi-close-circle-multiple </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table :loading="loading" :headers="headers" :items="PurchaseInvoices.data" class="elevation-1"
        hide-default-footer>
        <template v-slot:[`item.grand_total`]="{ item }">
          <b>{{ item.grand_total | currency("", 0, { symbolOnLeft: false, }) }}</b>
        </template>
        <template slot="body.append">
          <tr>
            <th colspan="4"></th>
            <th style="font-size: 15px;">{{ sumField('grand_total') | currency("", 0, { symbolOnLeft: false, }) }}</th>
          </tr>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip color="dark" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" color="primary" v-bind="attrs" icon small @click.stop="view(item.id)" v-on="on">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View Sales Invoice Adjustment</span>
          </v-tooltip>
          <v-tooltip color="dark" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" color="warning" v-bind="attrs" icon small @click.stop="edit(item.id)" v-on="on">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit Sales Invoice Adjustment</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "PurchaseInvoiceAdjustmentList",
  data() {
    return {
      headers: [
        { text: "No Invoice Adjustment", value: "no_invoice" },
        { text: "No.Sales Invoice", value: "purchase.no_po" },
        { text: "Customer", value: "supplier.name" },
        { text: "Sales Adjustment Date", value: "date" },
        { text: "Total Amount", value: "grand_total" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      disabled: false,
      loading: false,
      branch: [],
      loading_download: false,
      status: ["WAITING", "ONPROGRESS", "DONE", "VOID", "CANCEL"],
      search: {
        from_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
        end_date: new Date().toJSON().slice(0, 10),
        search: null,
      },
    };
  },

  methods: {
    ...mapMutations("purchase_invoice", ["CLEAR_STATE", "SET_PURCHASE_INVOICES", "SET_CURRENT_PAGE"]),
    sumField(key) {
      return this.PurchaseInvoices.data.reduce((a, b) => a + (b[key] || 0), 0)
    },
    async init() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        status: this.search.status,
        search: this.search.search,
        from_date: this.search.from_date,
        end_date: this.search.end_date,
      };

      await this.$axios
        .get("/transaction/purchase-invoice", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_PURCHASE_INVOICES(data.purchase_invoice);
          this.loading = false;
          this.disabled = false;
        });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch.data;
        });
    },

    async edit(id) {
      this.loading = true;
      await this.$axios
        .get("/transaction/purchases/" + id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_PURCHASE_ORDER(data.purchase_orders);
          this.loading = false;
          this.$router.push("/purchase_invoice/edit");
        })
        .catch(() => {
          this.loading = true;
        });
    },
    view(id) {
      this.$router.push("/purchase-invoice/view/" + id);
    },

    searching() {
      this.page = 1;
      this.init();
    },
    clear() {
      this.page = 1;
      this.search = {
        status: "",
        search: "",
        from_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
        end_date: new Date().toJSON().slice(0, 10),
      };
      this.init();
    },
  },

  created() {
    this.init();
    this.getBranch();
  },

  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.init();
    },
  },
  computed: {
    ...mapGetters("purchase_invoice", {
      PurchaseInvoices: "PurchaseInvoices",
    }),
    ...mapGetters("auth", {
      ROLE: "Role",
    }),
    currentPage: {
      get() {
        return this.PurchaseInvoices.current_page;
      },
      set(val) {
        return this.$store.commit("purchase_invoice/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.PurchaseInvoices.last_page;
      },
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  background-color: #ebebeb59;
}

::v-deep th {
  color: #000 !important;
}
</style>