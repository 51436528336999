<template>
  <main-layout>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" color="amber" />
    </v-overlay>
    <base-header>
      <template #icon> mdi-file</template>
      <template #name> Daily Sales Order</template>
      <template #breadcrumbs>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </template>
      <template #button>
        <v-btn color="secondary" class="float-end mb-2" small @click="backForm()">Back
          <v-icon right>mdi-arrow-left</v-icon>
        </v-btn>
      </template>
    </base-header>
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
    <v-card elevation="2" rounded class="mx-2">
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default invoice" id="invoice">
            <div class="panel-body">
              <v-row class="mx-2">
                <v-col sm="12" md="6" lg="6" class="from">
                  <p class=""><b>Order No :</b> {{ DailySalesOrder.no_so }}</p>
                  <p class=""><b>Date : </b> {{ new Date(DailySalesOrder.order_date).toLocaleDateString() }}</p>
                  <p class=""><b>Cashier : </b></p>
                  <p class=""><b>Customer : </b> {{ DailySalesOrder.customer_name }}</p>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <p class=""><b>Type :</b> {{ DailySalesOrder.type }} </p>
                  <p class=""><b>Time :</b> {{ new Date(DailySalesOrder.order_date).toLocaleTimeString() }} </p>
                  <p class=""><b>Table :</b> {{ DailySalesOrder.table ?? '-' }} </p>
                </v-col>
              </v-row>
              <v-divider class="mx-4"></v-divider>
              <v-row class="mx-2 table-row">
                <v-col lg="12">
                  <p class="mx-2">Product Information</p>
                  <v-data-table :headers="headers" :items="DailySalesOrder.details" class="elevation-3"
                    hide-default-footer :expanded.sync="expanded" show-expand item-key="name"
                    :single-expand="singleExpand">
                    <template v-slot:[`item.product_name`]="{ item }">
                      <td>{{ item.item?.name }}</td>
                    </template>
                    <template v-slot:[`item.size_name`]="{ item }">
                      <td>{{ item?.variant_size?.name }}
                        <v-chip color="primary" small>{{ item?.variant_size_price | currency("", 0, {
                          symbolOnLeft:
                            false,
                        }) }}</v-chip>
                      </td>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                      <td>{{ item.item?.description ?? '-' }}</td>
                    </template>
                    <template v-slot:[`item.unit`]="{ item }">
                      <td>{{ item.item?.unit?.name ?? '-' }}</td>
                    </template>
                    <template v-slot:[`item.price_sell`]="{ item }">
                      <td>{{ item.item?.price_sell ?? 0 | currency("", 0, {
                        symbolOnLeft:
                          false,
                      }) }}</td>
                    </template>
                    <template v-slot:[`item.qty`]="{ item }">
                      <td>{{ item.qty }}</td>
                    </template>
                    <template v-slot:[`item.amount`]="{ item }">
                      <td>{{ item.item?.price_sell * item?.qty + item?.variant_size_price | currency("", 0, {
                        symbolOnLeft:
                          false,
                      }) }}</td>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <v-row>
                          <v-col cols="10" class="mt-1">
                            <v-simple-table dense max-width="10%">
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left" style="background-color: #898F99">
                                      Name
                                    </th>
                                    <th class="text-left" style="background-color: #898F99">
                                      Price
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(rows, indexVariant) in item?.sales_order_detail_toppings"
                                    :key="indexVariant">
                                    <td>
                                      {{ rows?.topping?.name ?? 'N/A' }}
                                    </td>
                                    <td>
                                      {{ rows?.price ?? 0 | currency("", 0, {
                                        symbolOnLeft:
                                          false,
                                      }) }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-col>
                        </v-row>
                      </td>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-divider class="mx-4"></v-divider>
              <v-row class="mx-2 mt-2">
                <v-col sm="12" md="6" lg="10" class="margintop">
                  <p class="lead marginbottom">THANK YOU!</p>
                </v-col>
                <v-col sm="12" md="6" lg="2" class="text-right">
                  <p class="lead">Total : {{ DailySalesOrder.subtotal | currency("", 0, {
                    symbolOnLeft:
                      false
                  }) }}</p>
                  <p class="lead">Discount : {{ DailySalesOrder.discount_amount | currency("", 0, {
                    symbolOnLeft:
                      false
                  }) }}</p>
                  <p>VAT (11%) : {{ DailySalesOrder.vat | currency("", 0, {
                    symbolOnLeft:
                      false
                  }) }} </p>
                  <p>Pph : {{ DailySalesOrder.pph | currency("", 0, {
                    symbolOnLeft:
                      false
                  }) }} </p>
                  <p>Grand Total : {{ DailySalesOrder.grand_total | currency("", 0, {
                    symbolOnLeft:
                      false
                  }) }} </p>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <link />
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
import BaseHeader from '@/components/base/BaseHeader.vue';
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: "DailySalesOrderView",

  data() {
    return {
      loading: false,
      btnLoading: false,
      expanded: [],
      singleExpand: false,
      headers: [
        { text: "Product Name", value: "product_name", width: 200 },
        { text: "Variant By Size", value: "size_name", width: 200 },
        { text: "Description", value: "description", width: 200 },
        { text: "Unit", value: "unit", width: 100 },
        { text: "Price Sell", value: "price_sell", width: 100 },
        { text: "Qty", value: "qty", width: 100 },
        { text: "Amount", value: "amount", width: 100 },
      ],
      breadcrumbs: [
        { text: "Transaction", disabled: false },
        { text: "Daily Sales Order", disabled: false },
        { text: "Detail", disabled: true },

      ],
      // shareableLink: process.env.VUE_APP_API_URL + "/sales_order/view/" + this.$route.params.id,
    };

  },

  created() {
    this.init();
  },

  methods: {
    ...mapMutations("daily_sales_order", ["CLEAR_STATE", "SET_DAILY_SALES_ORDER"]),
    init() {
      this.loading = true;
      this.$axios
        .get("/transaction/sales-orders/" + this.$route.params.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_DAILY_SALES_ORDER(data.sales_orders);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    update: async function () {
      this.btnLoading = true;
      await this.$axios
        .put(
          "/transaction/sales-orders/set-to-void/" + this.$route.params.id,
          this.DailySalesOrder,
          {
            headers: {
              Authorization: this.$store.getters["auth/Token"],
            },
          }
        )
        .then(() => {
          this.btnLoading = false;
          this.$router.push("/sales-order");
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },

    clear() {
      this.CLEAR_STATE();
    },

    backForm() {
      this.CLEAR_STATE();
      this.$router.push("/tab-daily-sales");
    },
  },

  computed: {
    ...mapGetters("daily_sales_order", {
      DailySalesOrder: "DailySalesOrder",
    }),
  },

  components: {
    MainLayout,
    BaseHeader,
  },
}
</script>
<style>
body {
  margin-top: 20px;
  background: #eee;
}

/*Invoice*/
.invoice .top-left {
  font-size: 15px;
  color: #3ba0ff;
}

.invoice .top-right {
  text-align: right;
  padding-right: 20px;
  color: #3ba0ff;
}

.invoice .table-row {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 25px;
}

.invoice .payment-info {
  font-weight: 500;
}

.invoice .table-row .table>thead {
  border-top: 1px solid #ddd;
}

.invoice .table-row .table>thead>tr>th {
  border-bottom: none;
}

.invoice .table>tbody>tr>td {
  padding: 8px 20px;
}

.invoice .invoice-total {
  margin-right: -10px;
  font-size: 16px;
}

.invoice .last-row {
  border-bottom: 1px solid #ddd;
}

.invoice-ribbon {
  width: 100px;
  height: 180px;
  top: auto;
  overflow: hidden;
  position: absolute;
  top: 10px;
  right: 20px;
}

.ribbon-inner {
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 9px 0;
  left: -5px;
  top: 18px;
  width: 120px;
  background-color: #66c591;
  font-size: 15px;
  color: #fff;
}

.ribbon-inner:before,
.ribbon-inner:after {
  content: "";
  position: absolute;
}

.ribbon-inner:before {
  left: 0;
}

.ribbon-inner:after {
  right: 0;
}

@media(max-width:575px) {

  .invoice .top-left,
  .invoice .top-right,
  .invoice .payment-details {
    text-align: center;
  }

  .invoice .from,
  .invoice .to,
  .invoice .payment-details {
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
  }

  .invoice p.lead,
  .invoice .from p.lead,
  .invoice .to p.lead,
  .invoice .payment-details p.lead {
    font-size: 22px;
  }

  .invoice .btn {
    margin-top: 10px;
  }
}

@media print {
  .invoice {
    width: 900px;
    height: 800px;
  }
}
</style>