<template>
    <main-layout>
        <base-header>
            <template #icon> mdi-file</template>
            <template #name> Edit Purchase Invoice Adjustment</template>
            <template #breadcrumbs>
                <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                    <template #divider>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>
            </template>
        </base-header>

        <v-card class="mx-1">
            <!-- App Bar -->
            <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
                <v-toolbar-title> Reference No</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn small color="secondary" class="ma-2" @click="backForm()">
                    <v-icon>mdi-arrow-left</v-icon> Back
                </v-btn>
                <v-btn small class="ma-2" color="primary" @click.stop="update" :loading="btnLoading"
                    :disabled="!form_valid || btnLoading">
                    Update
                    <v-icon right>mdi-content-save-all</v-icon>
                </v-btn>
            </v-app-bar>

            <v-form ref="PurchaseOrder" v-model="form_valid" lazy-validation>
                <form-purchase-order />
                <form-item />
            </v-form>
        </v-card>
    </main-layout>
</template>
  
<script>
import { mapGetters, mapMutations } from "vuex";
import FormPurchaseOrder from "./Form.vue";
import FormItem from "./FormItem.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import BaseHeader from "@/components/base/BaseHeader.vue";
export default {
    name: "PurchaseOrderEdit",
    data() {
        return {
            btnLoading: false,
            form_valid: true,
        };
    },
    created() {
        this.breadcrumbs = [
            { text: "Transaction", disabled: false },
            { text: "Purchase Invoice Adjustment", disabled: true },
            { text: "Edit", disabled: true },
        ];
    },
    methods: {
        ...mapMutations("purchase_order", ["CLEAR_STATE", "SET_PURCHASE_ORDER"]),
        update: async function () {
            if (this.$refs.PurchaseOrder.validate()) {
                this.btnLoading = true;
                this.PurchaseOrder.sub_total = this.PurchaseOrder.details.reduce(
                    (acc, cur) => acc + cur.amount,
                    0
                );
                this.PurchaseOrder.discount = parseFloat(this.PurchaseOrder.sub_total) * parseFloat(this.PurchaseOrder.discount_percent) / 100;
                this.PurchaseOrder.afterDiscount = parseFloat(this.PurchaseOrder.sub_total - this.PurchaseOrder.discount);
                if (this.PurchaseOrder.use_vat == true) {
                    this.PurchaseOrder.vat = parseFloat((this.PurchaseOrder.afterDiscount * 11)) / 100;
                } else {
                    this.PurchaseOrder.vat = 0;
                }
                this.PurchaseOrder.grand_total = parseFloat(this.PurchaseOrder.afterDiscount) + parseFloat(this.PurchaseOrder.vat);
                await this.$axios
                    .put("/transaction/purchases/" + this.PurchaseOrder.id, this.PurchaseOrder,
                        {
                            headers: { Authorization: this.$store.getters["auth/Token"] },
                        })
                    .then(() => {
                        this.btnLoading = false;
                        this.CLEAR_STATE();
                        this.$router.push({ name: "purchase-adjustment.index" });
                    })
                    .catch(() => {
                        this.btnLoading = false;
                    });
            }
        },
        clear() {
            this.CLEAR_STATE();
        },
        backForm() {
            this.CLEAR_STATE();
            this.$router.push({ name: "purchase-invoice-adjustment.index" });
        },
    },
    computed: {
        ...mapGetters("purchase_order", {
            PurchaseOrder: "PurchaseOrder",
        }),
    },
    components: {
        MainLayout,
        BaseHeader,
        "form-purchase-order": FormPurchaseOrder,
        "form-item": FormItem,
    },
};
</script>