<template>
  <div>
    <base-title title="Discount" subtitle="List Data Discount" />
    <v-btn to="/master/discount/create" small elevation="1" color="primary" class=" white--text float-right mx-2"
      @click="loader = 'loading3'">
      Create
      <v-icon right dark>mdi-plus-circle </v-icon>
    </v-btn>
    <discount-list />
  </div>
</template>

<script>
import BaseTitle from "@/components/base/BaseTitle.vue";
import DiscountList from "@/views/master/discount/List.vue";
export default {
  components: { DiscountList, BaseTitle },
  name: "Discount",
  data() {
    return {
      loading: false,
    };
  },
};
</script>