const state = () => ({
    suppliers: {},
    supplier: {
        id: null,
        name: null,
        branch_id: null,
        owner: null,
        email: null,
        phone: null,
        address: null,
        is_active: null,
    },

    page: 1,

})

const mutations = {
    SET_SUPPLIERS(state, payload) {
        state.suppliers = payload
    },

    SET_SUPPLIER(state, payload) {
        state.supplier = {
            id: payload.id,
            supplier_name: payload.name,
            branch_id: payload.branch_id,
            owner: payload.owner,
            email: payload.email,
            phone: payload.phone,
            address: payload.address,
            is_active: payload.is_active,

        }
    },

    SET_CURRENT_PAGE(state, payload) {
        state.suppliers.current_page = payload
    },

    CLEAR_SUPPLIERS(state) {
        state.suppliers = {
            id: null,
            name: null,
            branch_id: null,
            owner: null,
            email: null,
            phone: null,
            address: null,
            is_active: null,
        }
    },
}

const getters = {
    Suppliers: state => state.suppliers,
    Supplier: state => state.supplier,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}
