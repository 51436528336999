<template>
    <div>
        <base-title title="Bank Account" subtitle="List Data Bank Account" />
        <v-data-table :headers="headers" :items="BankAccounts.data" class="elevation-1" hide-default-footer
            :loading="datatableLoading" loading-text="Please wait...">
            <template v-slot:no-data> No data </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="text-center">
                    <v-col cols="12"> <strong>ID:</strong>{{ item.id }} </v-col>
                </td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon color="warning" class="mr-2" @click.stop="editItem(item)">
                    mdi-pencil-box-outline
                </v-icon>
            </template>

            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-text-field v-model="search.search" append-icon="mdi-magnify" label="Search" outlined dense
                        hide-details></v-text-field>
                    <v-btn color="darkblue" class="white--text mx-1 mb-2" small @click.stop="searching">Submit <v-icon
                            right>mdi-magnify</v-icon></v-btn>
                    <v-btn small color="secondary" class="white--text mx-1 mb-2" @click.stop="clear">Clear
                        <v-icon right>mdi-close-circle-multiple </v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialogForm" max-width="450px" persistent>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" small elevation="1" color="primary" class="ma-2 white--text"
                                @click="loader = 'loading3'">
                                Create
                                <v-icon right dark>mdi-plus-circle </v-icon>
                            </v-btn>
                        </template>
                        <v-form ref="form_data" v-model="form_valid" lazy-validation>
                            <v-card class="rounded-lg">
                                <v-card-title class="mb-3">
                                    <span class="headline">{{ form_title }}</span>
                                </v-card-title>
                                <v-card-text>

                                    <v-text-field v-model="form_data.name_account" label="Account Name" outlined dense
                                        :rules="rule_name_account">
                                    </v-text-field>
                                    <v-text-field type="number" v-model="form_data.number_account" label="Number Account"
                                        outlined dense :rules="rule_account_number">
                                    </v-text-field>
                                    <v-text-field v-model="form_data.bank_name" label="Bank Name" outlined dense
                                        :rules="rule_bank_name">
                                    </v-text-field>
                                    <base-column :lg="4" :md="4" :sm="12" v-if="(ROLE == 'Owner')">
                                        <v-select label="Branch" :items="branch" v-model="form_data.branch_id" dense
                                            item-text="name" item-value="id" outlined></v-select>
                                    </base-column>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn small color="error darken-1" @click.stop="closedialogForm">Cancel
                                        <v-icon right dark>mdi-close </v-icon>
                                    </v-btn>
                                    <v-btn small color="primary" class="ma-2 white--text" @click.stop="save"
                                        :loading="btnLoading" :disabled="!form_valid || btnLoading">
                                        Save
                                        <v-icon right dark>mdi-content-save </v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                </v-toolbar>
            </template>
        </v-data-table>
        <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
    </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import { datatableMixin } from "@/mixins/datatable.js";
import BaseTitle from "@/components/base/BaseTitle.vue";

export default {
    components: { BaseTitle },
    name: "BankAccountView",
    mixins: [datatableMixin],
    data: () => ({
        headers: [
            { text: "Account Name", value: "name_account" },
            { text: "Account Number ", value: "number_account" },
            { text: "Account Bank Name", value: "bank_name" },
            { text: "Actions", value: "actions", sortable: false, width: 100 },
        ],

        branch: [],
        loading: false,

        search: {
            search: "",
        },
        disabled: false,
        page: 1,

        form_data: {
            branch_id: "",
            name_account: "",
            number_account: "",
            bank_name: "",
        },
        form_default: {
            branch_id: "",
            name_account: "",
            number_account: "",
            bank_name: "",
        },
    }),

    created() {
        this.initialize();
        this.getBranch();
    },

    methods: {
        initialize: async function () {
            this.datatableLoading = true;
            let params = {
                search: this.search.search,
                page: this.page,
            }
            await this.$axios
                .get("/master/bank-account", {
                    params: params,
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.datatable = data.bank_account.data;
                    this.$store.commit("bank_account/SET_BANK_ACCOUNTS", data.bank_account);
                    this.datatableLoading = false;
                })
                .catch(() => {
                    this.datatableLoading = false;
                });
        },

        save: async function () {
            if (this.$refs.form_data.validate()) {
                this.btnLoading = true;
                if (this.editedIndex > -1) {
                    await this.$axios
                        .post(
                            "/master/bank-account/" + this.form_data.id,
                            {
                                _method: "PUT",
                                name_account: this.form_data.name_account,
                                number_account: this.form_data.number_account,
                                bank_name: this.form_data.bank_name,
                            },
                            {
                                headers: {
                                    Authorization: this.$store.getters["auth/Token"],
                                },
                            }
                        )
                        .then(({ data }) => {
                            Object.assign(this.datatable[this.editedIndex], data.bank_account);
                            this.initialize();
                            this.closedialogForm();
                            this.btnLoading = false;
                        })
                        .catch(() => {
                            this.btnLoading = false;
                        });
                } else {
                    await this.$axios
                        .post(
                            "/master/bank-account",
                            {
                                name_account: this.form_data.name_account,
                                number_account: this.form_data.number_account,
                                bank_name: this.form_data.bank_name,
                            },
                            {
                                headers: {
                                    Authorization: this.$store.getters["auth/Token"],
                                },
                            }
                        )
                        .then(() => {
                            this.initialize();
                            this.closedialogForm();
                            this.btnLoading = false;
                        })
                        .catch(() => {
                            this.btnLoading = false;
                        });
                }
            }
        },


        searching() {
            this.page = 1;
            this.initialize();
        },

        clear() {
            this.page = 1;
            this.search = {
                search: "",
            };
            this.initialize();
        },

        async getBranch() {
            await this.$axios("/master/branches?active=1", {
                headers: {
                    Authorization: this.$store.getters["auth/Token"],
                },
            }).then(({ data }) => {
                this.branch = data.branch.data;
            });
        },
    },
    watch: {
        currentPage(newValue) {
            this.page = newValue;
            this.initialize();
        },
    },

    computed: {
        form_title() {
            return this.editedIndex === -1 ? "Create Bank Account" : "Edit Bank Account";
        },

        ...mapGetters("auth", {
            ROLE: "Role",
        }),

        ...mapGetters("bank_account", {
            BankAccounts: "BankAccounts",
        }),
        currentPage: {
            get() {
                return this.BankAccounts.current_page;
            },
            set(val) {
                return this.$store.commit("bank_account/SET_CURRENT_PAGE", val);
            },
        },
        lastPage: {
            get() {
                return this.BankAccounts.last_page;
            },
        },
    },
};
</script>