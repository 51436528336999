<template>
  <div>
    <base-title title="Item Material" subtitle="List Data Item Material" />
    <v-btn to="/master/item/tabs" small elevation="1" color="primary" class="white--text float-right mx-2"
      @click="loader = 'loading3'">
      Create
      <v-icon right dark>mdi-plus-circle </v-icon>
    </v-btn>
    <item-ingredient-list />
  </div>
</template>

<script>
import ItemIngredientList from "@/views/master/item_ingredient/List";
import BaseTitle from "@/components/base/BaseTitle";
export default {
  name: "ItemIngredientIndex",

  data() {
    return {
      form_valid: true,
      loader: "",
      expanded: [],
      search: {
        search: "",
        status: null,
      },
      loading: false,
    };
  },

  components: {
    BaseTitle,
    "item-ingredient-list": ItemIngredientList,
  },
};
</script>