<template>
  <div>
    <base-title title="Item" subtitle="List Data Items" />
    <v-btn to="/master/item/tabs" small elevation="1" color="primary" class="white--text float-right mx-2"
      @click="create()">
      Create
      <v-icon right dark>mdi-plus-circle </v-icon>
    </v-btn>
    <item-list />
  </div>
</template>

<script>
import ItemList from "@/views/master/item/List";
import BaseTitle from "@/components/base/BaseTitle";
import { mapMutations } from "vuex";
export default {
  name: "ItemIndex",

  data() {
    return {
      form_valid: true,
      loader: "",
      expanded: [],
      search: {
        search: "",
        status: null,
      },
      loading: false,
    };
  },

  methods: {
    ...mapMutations("item", ["CLEAR_STATE"]),
    create() {
      this.$router.push({ name: "item-create" });
    },
  },

  components: {
    BaseTitle,
    "item-list": ItemList,
  },
};
</script>