const state = () => ({
    vats: {},
    page: 1
})

const mutations = {
    SET_VATS(state, vats) {
        state.vats = vats
    },
    SET_CURRENT_PAGE(state, payload) {
        state.vats.current_page = payload
    }
}

const getters = {
    Vats: state => state.vats,
    Page: state => state.page
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}