<template>
  <div>
    <base-title title="Edit Variant" subtitle="Please fill in the Variant information in the form below" />
    <!-- Form -->
    <v-form ref="form_data" v-model="form_valid" lazy-validation>
      <v-row class="mx-1 mt-2">
        <base-column :lg="4" :md="6" :sm="12">
          <v-text-field label=" Name" v-model="form_data.name" :rules="rule_name" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="6" :sm="12">
          <v-select label="Item" :rules="rule_item" :items="item" v-model="form_data.item_id" item-text="name"
            item-value="id" outlined dense>
          </v-select>
        </base-column>
        <base-column :lg="4" :md="6" :sm="12">
          <v-select label="Branch" :rules="rule_branch" :items="branch" v-model="form_data.branch_id" item-text="name"
            item-value="id" outlined dense>
          </v-select>
        </base-column>
      </v-row>

      <v-row class="mx-1 mt-2">
        <base-column :lg="4" :md="6" :sm="12">
          <v-file-input label="Image" chips truncate-length="50" outlined dense></v-file-input>
        </base-column>
        <base-column :lg="4" :md="6" :sm="12">
          <v-text-field type="number" label="Price" v-model="form_data.price" :rules="rule_price" outlined
            dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="6" :sm="12">
          <v-text-field type="number" label="Stock" v-model="form_data.stock" :rules="rule_stock" outlined
            dense></v-text-field>
        </base-column>
      </v-row>

      <v-row class="mx-1 mt-2">
        <base-column :lg="2" :md="6" :sm="12">
          <v-text-field type="number" label="Weight" v-model="form_data.wight" :rules="rule_weight" outlined dense>
          </v-text-field>
        </base-column>
        <base-column :lg="2" :md="6" :sm="12">
          <v-text-field type="number" label="Height" v-model="form_data.height" :rules="rule_height" outlined dense>
          </v-text-field>
        </base-column>
        <base-column :lg="2" :md="6" :sm="12">
          <v-text-field type="number" label="Width" v-model="form_data.width" :rules="rule_width" outlined dense>
          </v-text-field>
        </base-column>
        <base-column :lg="2" :md="6" :sm="12">
          <v-text-field type="number" label="Length" v-model="form_data.length" :rules="rule_length" outlined dense>
          </v-text-field>
        </base-column>
        <base-column :lg="4" :md="6" :sm="12">
          <v-switch dense v-model="form_data.is_active" label="USE"> </v-switch>
        </base-column>
      </v-row>

      <v-row class="mx-1 mt-2">
        <base-column :lg="4" :md="6" :sm="12">
          <v-textarea label="Description" rows="3" outlined dense v-model="form_data.description"></v-textarea>
        </base-column>
      </v-row>
      <v-row>
        <v-container>
          <back-button />
          <v-btn color="primary" small class="ma-1" @click.stop="store" :loading="btnLoading"
            :disabled="!form_valid || btnLoading">Save <v-icon right>mdi-content-save</v-icon></v-btn>
        </v-container>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import BackButton from "@/components/base/BackButton.vue";
import BaseTitle from "@/components/base/BaseTitle.vue";
import BaseColumn from "@/components/base/BaseColumn.vue";
export default {
  components: { BaseTitle, BaseColumn, BackButton },
  name: "VariantCreate",
  data() {
    return {
      btnLoading: false,
      form_valid: true,
      item: [],
      branch: [
        {
          id: 1,
          name: "Branch 1"
        },
        {
          id: 2,
          name: "Branch 2"
        },
        {
          id: 3,
          name: "Branch 3"
        },
      ],

      form_data: {
        name: "",
        item_id: "",
        branch_id: "",
        price: "",
        stock: "",
        wight: "",
        height: "",
        width: "",
        length: "",
        description: "",
        is_active: true,
      },
    };
  },

  created() {
    this.getItem();
  },

  methods: {
    async getItem() {
      await this.$axios
        .get("/master/item", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.item = data.item.data;
        });
    },

    store: async function () {
      if (this.$refs.form_data.validate()) {
        this.btnLoading = true;
        await this.$axios
          .post(
            "/master/variant",
            {
              name: this.form_data.name,
              item_id: this.form_data.item_id,
              branch_id: this.form_data.branch_id,
              price: this.form_data.price,
              stock: this.form_data.stock,
              wight: this.form_data.wight,
              height: this.form_data.height,
              width: this.form_data.width,
              length: this.form_data.length,
              description: this.form_data.description,
              is_active: this.form_data.is_active,
            },
            {
              headers: {
                Authorization: this.$store.getters["auth/Token"],
              },
            }
          )
          .then(() => {
            this.btnLoading = false;
            this.$router.push({ name: "variant.index" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },
  },
};
</script>