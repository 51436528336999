<template>
  <div>
    <base-title title="Create Promo" subtitle="Please fill in the Promo information in the form below" />
    <v-form ref="form_data" v-model="form_valid" lazy-validation>
      <v-row class="mx-1 mt-2">
        <base-column :lg="4" :md="6" :sm="12">
          <v-text-field label="Promo Name" v-model="Promo.name" :rules="rule_name" outlined dense></v-text-field>
        </base-column>
      </v-row>
      <v-row class="mx-1">
        <base-column :lg="4" :md="4" :sm="12">
          <b class="mb-0">Schedule</b>
        </base-column>
      </v-row>
      <v-row class="mx-1 mt-5">
        <base-column :lg="4" :md="6" :sm="12">
          <v-text-field type="date" label="Start Date" v-model="Promo.start_date" :rules="rule_start_date" outlined
            dense></v-text-field>
          <v-text-field type="time" label="Start TIme" v-model="Promo.start_time" :rules="rule_start_time" outlined
            dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="6" :sm="12">
          <v-text-field type="date" label="End Date" v-model="Promo.end_date" :rules="rule_end_date" outlined
            dense></v-text-field>
          <v-text-field type="time" label="Expired Time" v-model="Promo.expired_time" :rules="rule_expired_time" outlined
            dense></v-text-field>
        </base-column>
      </v-row>
      <v-row class="mx-1">
        <base-column :lg="12" :md="12" :sm="12">
          <v-btn color="primary" class="float-end" outlined small @click="openProductDialog()"><v-icon
              left>mdi-plus</v-icon>
            Add Item </v-btn>
        </base-column>
      </v-row>
      <v-row class="mx-1 mt-5">
        <base-column :lg="12" :md="12" :sm="12">
          <v-data-table :loading="loading" :headers="headers" :items="Promo.details" class="elevation-1"
            hide-default-footer>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="error" class="mr-2" @click.stop="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <p>The Table is Empty. Please insert data with the above Button.</p>
            </template>

          </v-data-table>
        </base-column>
      </v-row>
      <v-row class="mx-0">
        <v-col xs="12" sm="12" md="12" lg="12">
          <v-btn color="primary" small class="ma-1 float-end" @click.stop="store" :loading="btnLoading"
            :disabled="!form_valid || btnLoading">Save<v-icon right>mdi-content-save</v-icon></v-btn>
          <v-btn color="secondary" small class="ma-1 float-end" @click.stop="clear">Clear<v-icon
              right>mdi-close-circle-multiple</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogProduct" v-if="dialogProduct" max-width="1200px" persistent><products-modal
          v-on:closeProductDialog="closeProductDialog" />
      </v-dialog>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import BaseTitle from "@/components/base/BaseTitle.vue";
import BaseColumn from "@/components/base/BaseColumn.vue";
import ProductsModal from "@/components/modal/ItemModal.vue";
export default {
  components: { BaseTitle, BaseColumn, "products-modal": ProductsModal, },
  name: "PromoCreate",
  data() {
    return {
      btnLoading: false,
      form_valid: true,
      dialogProduct: false,
      loading: false,
      branch: [],
      headers: [
        { text: "Name", value: "name", width: 200, class: "primary white--text" },
        { text: "Size", value: "size", width: 100, class: "primary white--text" },
        { text: "Qty", value: "qty", width: 100, class: "primary white--text" },
        { text: "Free Item", value: "free_item", width: 150, class: "primary white--text" },
        { text: "Free Size", value: "free_size", width: 150, class: "primary white--text" },
        { text: "Free Qty", value: "free_qty", width: 150, class: "primary white--text" },
        { text: "Action", value: "actions", sortable: false, width: 100, class: "primary white--text" },
      ],

    };
  },

  created() {
    this.getPaymentMethod();
    this.getBranch();
  },

  methods: {
    ...mapMutations("promo", ["CLEAR_STATE"]),
    async getPaymentMethod() {
      await this.$axios
        .get("/master/payment-method", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.payment_method = data.payment_method.data;
        });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch.data;
        });
    },

    store: async function () {
      if (this.$refs.form_data.validate()) {
        this.btnLoading = true;
        await this.$axios
          .post(
            "/master/promo",
            {
              code: this.form_data.code,
              name: this.form_data.name,
              payment_method_id: this.form_data.payment_method_id,
              type: this.form_data.type,
              percentage: this.form_data.percentage,
              minimum_qty: this.form_data.minimum_qty,
              qty: this.form_data.qty,
              minimum_price: this.form_data.minimum_price,
              started_at: this.form_data.started_at,
              expired_at: this.form_data.expired_at,
              description: this.form_data.description,
              is_active: this.form_data.is_active,
            },
            {
              headers: {
                Authorization: this.$store.getters["auth/Token"],
              },
            }
          )
          .then(() => {
            this.btnLoading = false;
            this.$router.push({ name: "promo.index" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },

    deleteItem(index) {
      this.Promo.details.splice(index, 1);
    },

    clear() {
      this.$store.commit("promo/CLEAR_STATE");
    },

    openProductDialog() {
      this.dialogProduct = true;
    },
    closeProductDialog() {
      this.dialogProduct = false;
    },
  },

  computed: {

    ...mapState("promo", {
      Promo: (state) => state.promo,
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),
  }
};
</script>