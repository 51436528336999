<template>
  <main-layout>
    <base-header>
      <template #icon> mdi-file</template>
      <template #name> Sales Invoice Adjustment </template>
      <template #breadcrumbs>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </template>
      <!-- to="/purchase-invoice/create" -->
      <template #button>
        <v-btn color="primary" class="float-end mb-2" small @click="create()"
          v-if="(!ROLE == 'Owner') || (ROLE == 'Manager') || (ROLE == 'Cashier')">Create
          New
          <v-icon right>mdi-plus</v-icon>
        </v-btn></template>
    </base-header>
    <v-row dense class="mx-1">
      <v-responsive width="100%" v-if="$vuetify.breakpoint.xsOnly" />

      <sales-invoice-adjustment-list />
    </v-row>
  </main-layout>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import MainLayout from "@/layouts/MainLayout.vue";
import BaseHeader from "@/components/base/BaseHeader.vue";
import SalesInvoiceAdjustmentList from "./List.vue";
export default {
  name: "SalesInvoiceAdjustmentIndex",

  created() {
    this.breadcrumbs = [
      { text: "Transaction", disabled: false, to: "/dashboard" },
      { text: "Sales Invoice Adjustment", disabled: true },
      { text: "List", disabled: true },
    ];
  },

  components: {
    MainLayout,
    BaseHeader,
    "sales-invoice-adjustment-list": SalesInvoiceAdjustmentList,
  },

  methods: {
    ...mapMutations("purchase_invoice", ["CLEAR_STATE"]),
    create() {
      this.CLEAR_STATE();
      this.$router.push("/sales-invoice-adjustment/create");
    },
  },

  computed: {
    ...mapGetters("auth", {
      CAN: "Can",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  background-color: #ebebeb59;
}

::v-deep th {
  color: #000 !important;
}
</style>