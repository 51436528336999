const state = () => ({
    purchase_invoices: {},
    purchase_invoice: {
        id: null,
        purchase_id: null,
        no_po: null,
        no_invoice: null,
        supplier_id: null,
        supplier_name: null,
        supplier_address: null,
        supplier_phone: null,
        supplier_email: null,
        customer_id: null,
        customer_name: null,
        customer_email: null,
        customer_phone: null,
        customer_address: null,
        payment_method_id: null,
        branch_id: null,
        branch_name: null,
        branch_phone: null,
        branch_email: null,
        branch_address: null,
        remark: null,
        status_invoice: null,
        type: "PURCHASE ORDER",
        date: null,
        use_vat: false,
        discount_percent: 0,
        sub_total: 0,
        discount: 0,
        vat: 0,
        grand_total: 0,
        details: [],
        details_invoice: [],
    },
    page: 1,
})

const mutations = {
    SET_PURCHASE_INVOICES(state, payload) {
        state.purchase_invoices = payload
    },

    SET_PURCHASE_INVOICE(state, payload) {
        state.purchase_invoice.id = payload.id
        state.purchase_invoice.purchase_id = payload.purchase_id
        state.purchase_invoice.no_po = payload.no_po
        state.purchase_invoice.no_invoice = payload.no_invoice
        state.purchase_invoice.supplier_id = payload.supplier_id
        state.purchase_invoice.supplier_name = payload.supplier.name
        state.purchase_invoice.supplier_address = payload.supplier.address
        state.purchase_invoice.supplier_phone = payload.supplier.phone
        state.purchase_invoice.supplier_email = payload.supplier.email
        state.purchase_invoice.customer_id = payload.customer.id
        state.purchase_invoice.customer_name = payload.customer.name
        state.purchase_invoice.customer_email = payload.customer.email
        state.purchase_invoice.customer_phone = payload.customer.phone
        state.purchase_invoice.customer_address = payload.customer.address
        state.purchase_invoice.customermethod_id = payload.payment_method_id
        state.purchase_invoice.branch_id = payload.branch.id
        state.purchase_invoice.branch_name = payload.branch.name
        state.purchase_invoice.branch_address = payload.branch.address
        state.purchase_invoice.branch_phone = payload.branch.phone
        state.purchase_invoice.branch_email = payload.branch.email
        state.purchase_invoice.remark = payload.remark
        state.purchase_invoice.date = payload.date
        state.purchase_invoice.use_vat = payload.use_vat
        state.purchase_invoice.discount_percent = payload.discount_percent
        state.purchase_invoice.sub_total = payload.sub_total
        state.purchase_invoice.discount = payload.discount
        state.purchase_invoice.vat = payload.vat
        state.purchase_invoice.grand_total = payload.grand_total
        state.purchase_invoice.details = payload.purchase_invoice_details
    },

    SET_INV_FROM_PO(state, payload) {
        state.purchase_invoice.purchase_id = payload.id;
        state.purchase_invoice.no_po = payload.no_po;
        state.purchase_invoice.supplier_id = payload.supplier_id;
        state.purchase_invoice.supplier_name = payload.supplier.name;
        state.purchase_invoice.supplier_address = payload.supplier.address;
        state.purchase_invoice.supplier_phone = payload.supplier.phone;
        state.purchase_invoice.supplier_email = payload.supplier.email;
        state.purchase_invoice.customer_id = payload.customer.id;
        state.purchase_invoice.customer_name = payload.customer.name;
        state.purchase_invoice.customer_email = payload.customer.email;
        state.purchase_invoice.customer_phone = payload.customer.phone;
        state.purchase_invoice.customer_address = payload.customer.address;
        state.purchase_invoice.payment_method_id = payload.payment_method_id;
        state.purchase_invoice.use_vat = payload.use_vat;
        state.purchase_invoice.status_invoice = payload.status_invoice;
        for (let i = 0; i < payload.details.length; i++) {
            state.purchase_invoice.details.push({
                item_id: payload.details[i].item_id,
                item_name: payload.details[i].item.name,
                item_description: payload.details[i].item.description,
                item_unit: payload.details[i].item.unit.name,
                qty_po: payload.details[i].qty ?? 0,
                qty_in: payload.details[i].qty - payload.details[i].qty_in ?? 0,
                qty_out: payload.details[i].qty_in ?? 0,
                qty_balance: 0,
                price: payload.details[i].price,
            });

        }
    },

    SET_INV_FROM_INV(state, payload) {
        for (let i = 0; i < payload.details.length; i++) {
            state.purchase_invoice.details.push({
                item_id: payload.details[i].item_id,
            });

        }
    },

    SET_DETAILS(state, payload) {
        state.ingoing.details.push({
            item_id: payload.id,
            item_name: payload.name,
            item_description: payload.description,
            item_unit: payload.unit.name,
            qty: 0,
            qty_out: 0,
            qty_in: 0,
            price: payload.price_buy,
            discount: 0,
            qty_balance: 0,
        })
    },

    SET_CURRENT_PAGE(state, payload) {
        state.purchase_invoices.current_page = payload
    },

    CLEAR_STATE(state) {
        state.purchase_invoice = {
            id: null,
            purchase_id: null,
            no_po: null,
            no_invoice: null,
            supplier_id: null,
            supplier_name: null,
            supplier_address: null,
            supplier_phone: null,
            supplier_email: null,
            customer_id: null,
            customer_name: null,
            customer_email: null,
            customer_phone: null,
            customer_address: null,
            payment_method_id: null,
            branch_id: null,
            branch_name: null,
            branch_phone: null,
            branch_email: null,
            branch_address: null,
            remark: null,
            type: "PURCHASE ORDER",
            discount_percent: 0,
            use_vat: false,
            sub_total: 0,
            discount: 0,
            vat: 0,
            grand_total: 0,
            details: [],
            details_invoice: [],
        }
    },

}

const getters = {
    PurchaseInvoices: state => state.purchase_invoices,
    PurchaseInvoice: state => state.purchase_invoice,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}