<template>
  <main-layout>
    <base-header>
      <template #icon> mdi-file</template>
      <template #name> Create Quotation</template>
      <template #breadcrumbs>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </template>
    </base-header>

    <v-card class="mx-1">
      <!-- App Bar -->
      <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
        <v-toolbar-title>Quotation Information</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small color="secondary" class="ma-2" @click="backForm()">
          <v-icon>mdi-arrow-left</v-icon> Back
        </v-btn>
        <v-btn small color="secondary" class="ma-2" @click="clearForm()">
          Clear
          <v-icon>mdi-close-circle-multiple </v-icon>
        </v-btn>
        <v-btn small class="ma-2" color="primary" @click.stop="store" :loading="btnLoading"
          :disabled="!form_valid || btnLoading">
          Save
          <v-icon right>mdi-content-save-all</v-icon>
        </v-btn>
      </v-app-bar>
      <v-form ref="Quotation" v-model="form_valid" lazy-validation>
        <form-quotation />
        <form-item />
      </v-form>
    </v-card>
  </main-layout>
</template>

<script>
import Form from "./Form.vue";
import FormItem from "./FormItem.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import BaseHeader from "@/components/base/BaseHeader.vue";
import { mapMutations } from "vuex";
export default {
  name: "QuotationCreate",

  data() {
    return {
      btnLoading: false,
      form_valid: true,
      breadcrumbs: [],

    };
  },
  created() {
    this.breadcrumbs = [
      { text: "Transaction", disabled: false },
      { text: "Quotation", disabled: true },
      { text: "Create", disabled: true },
    ];
  },

  methods: {
    ...mapMutations("sales_order", ["CLEAR_STATE"]),
    store: async function () {
      if (this.$refs.Quotation.validate()) {
        this.btnLoading = true;
        this.Quotation.subtotal = this.Quotation.details.reduce(
          (acc, cur) => acc + cur.amount,
          0
        );
        this.Quotation.discount_amount = parseFloat(this.Quotation.subtotal) * parseFloat(this.Quotation.discount_percent) / 100;
        this.Quotation.afterDiscount = parseFloat(this.Quotation.subtotal - this.Quotation.discount_amount);
        if (this.Quotation.use_vat == true) {
          this.Quotation.vat = parseInt((this.Quotation.afterDiscount * 11)) / 100;
        } else {
          this.Quotation.vat = 0;
        }
        this.Quotation.pph = parseFloat(this.Quotation.afterDiscount * this.Quotation.pph_percentage) / 100;
        this.Quotation.grand_total = parseFloat(this.Quotation.afterDiscount) + parseFloat(this.Quotation.vat) - parseFloat(this.Quotation.pph);
        await this.$axios
          .post("/transaction/sales-orders", this.Quotation,
            {
              headers: { Authorization: this.$store.getters["auth/Token"] },
            })
          .then(() => {
            this.CLEAR_STATE();
            this.btnLoading = false;
            this.$router.push({ name: "quotation.index" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },
    backForm() {
      this.CLEAR_STATE();
      this.$router.push({ name: "quotation.index" });
    },

    clearForm() {
      this.CLEAR_STATE();
    },
  },
  computed: {
    Quotation() {
      return this.$store.getters["sales_order/Quotation"];
    },
  },

  components: {
    MainLayout,
    BaseHeader,
    "form-quotation": Form,
    "form-item": FormItem,
  },
};
</script>
