<template>
    <v-tabs>
        <v-tab href="#product"> List Product </v-tab>
        <v-tab-item value="product">
            <List />
        </v-tab-item>
        <v-tab href="#material"> List Material </v-tab>
        <v-tab-item value="material">
            <Material />
        </v-tab-item>
        <v-tab href="#variant"> List Variant </v-tab>
        <v-tab-item value="variant">
            <Variant />
        </v-tab-item>
    </v-tabs>
</template>
<script>
import { mapGetters } from "vuex";
import List from "./List.vue";
import Material from "./ListMaterial.vue";
import Variant from "./ListVariant.vue";
export default {
    name: "CustomIndex",
    components: {
        List,
        Material,
        Variant
    },

    computed: {
        ...mapGetters("auth", {
            ACCESS_TOKEN: "AccessToken",
            TOKEN: "Token",
        }),
    },
};
</script>