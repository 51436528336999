//state
const getDefaultState = () => {
    return {
        token: null,
        token_type: null,
        expires_in: null,
        user: null
    }
}
const state = getDefaultState();

//mutations
const mutations = {
    setToken: (state, token) => {
        state.token = token.token;
        state.token_type = "Bearer";
        state.expires_in = token.expires_in;
    },
    setUser: (state, user) => {
        state.user = user;
    },
    resetState(state) {
        Object.assign(state, getDefaultState())
    }
}
const getters = {
    Authenticated: state => {
        return state.token != null && state.user != null;
    },
    AccessToken: state => {
        return state.token;
    },
    Token: state => {
        return state.token_type + ' ' + state.token;
    },

    User: state => {
        return state.user;
    },
    AccountSetting: (state) => (name) => {
        return state.user[name];

    },

    Can: (state) => (name) => {
        let permission = state.user.permission;
        if (typeof permission != 'undefined') {
            return permission.indexOf(name) !== -1;
        }
    },

    Role: (state) => {
        return state.user.user.roles[0].name;
    },

    Branch: (state) => {
        return state.user.user.branch_id;
    }

}
const actions = {
    afterLoginSuccess({ commit }, data) {
        commit('setToken', data.token);
        commit('setUser', data.user);
    },
    logout({ commit }) {
        commit('resetState');
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}