<template>
  <div>
    <base-title title="Employee" subtitle="List Data Employee" />
    <v-btn to="/master/employee/create" small elevation="1" color="primary" class=" white--text float-right mx-2"
      @click="loader = 'loading3'">
      Create
      <v-icon right dark>mdi-plus-circle </v-icon>
    </v-btn>
    <employee-list />
  </div>
</template>

<script>
import BaseTitle from "@/components/base/BaseTitle.vue";
import EmployeeList from "@/views/master/employee/List.vue";
export default {
  components: { EmployeeList, BaseTitle },
  name: "EmployeeIndex",
};
</script>