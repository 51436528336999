const state = () => ({
    purchase_orders: {},
    purchase_type: ["PRODUCT", "MATERIAL"],
    disabled_po_type: false,
    purchase_order: {
        id: null,
        no_po: null,
        type: null,
        sales_order_id: null,
        no_so: null,
        supplier_id: null,
        supplier_name: null,
        supplier_address: null,
        supplier_phone: null,
        supplier_email: null,
        customer_id: null,
        customer_name: null,
        customer_phone: null,
        customer_email: null,
        customer_address: null,
        payment_method_id: null,
        use_vat: false,
        payment_name: null,
        branch_id: null,
        branch_name: null,
        branch_phone: null,
        branch_email: null,
        branch_address: null,
        purchase_date: new Date().toJSON().slice(0, 10),
        pph_id: null,
        sub_total: 0,
        discount_nominal: 0,
        discount_percent: 0,
        discount: 0,
        vat: 0,
        pph: 0,
        grand_total: 0,
        status: null,
        status_invoice: null,
        details: [],
    },
    page: 1,

})

const mutations = {
    SET_PURCHASE_ORDERS(state, payload) {
        state.purchase_orders = payload
    },

    SET_SHIPPING(state, payload) {
        state.purchase_order.customer_id = payload.id;
        state.purchase_order.customer_name = payload.name;
        state.purchase_order.customer_email = payload.email;
        state.purchase_order.customer_phone = payload.phone;
        state.purchase_order.customer_address = payload.address;
        state.purchase_order.use_vat = payload.use_vat;

    },

    SET_SO(state, payload) {
        state.purchase_order.sales_order_id = payload.id;
        state.purchase_order.no_so = payload.no_so;
        state.purchase_order.type = "PRODUCT";
        state.purchase_order.customer_id = payload.customer_id;
        state.purchase_order.customer_name = payload.customer.name;
        state.purchase_order.customer_email = payload.customer.email;
        state.purchase_order.customer_phone = payload.customer.phone;
        state.purchase_order.customer_address = payload.customer.address;
        for (let i = 0; i < payload.details.length; i++) {
            state.purchase_order.details.push({
                item_id: payload.details[i].item_id,
                item_name: payload.details[i].item.name,
                item_description: payload.details[i].item.description,
                item_unit: payload.details[i].item.unit.name,
                qty: payload.details[i].qty,
                price: payload.details[i].price,
                discount: 0,
            });
        }
    },

    SET_PURCHASE_ORDER(state, payload) {
        state.purchase_order.id = payload.id
        state.purchase_order.no_po = payload.no_po
        state.purchase_order.type = payload.type
        state.purchase_order.sales_order_id = payload.sales_order_id
        state.purchase_order.no_so = payload.no_so
        state.purchase_order.supplier_id = payload.supplier_id
        state.purchase_order.supplier_name = payload.supplier.name
        state.purchase_order.supplier_address = payload.supplier.address
        state.purchase_order.supplier_phone = payload.supplier.phone
        state.purchase_order.supplier_email = payload.supplier.email
        state.purchase_order.customer_id = payload.customer.id
        state.purchase_order.customer_name = payload.customer.name
        state.purchase_order.customer_phone = payload.customer.phone
        state.purchase_order.customer_email = payload.customer.email
        state.purchase_order.customer_address = payload.customer.address
        state.purchase_order.payment_method_id = payload.payment_method_id
        state.purchase_order.use_vat = payload.use_vat
        state.purchase_order.payment_name = payload.payment.name
        state.purchase_order.branch_id = payload.branch.id
        state.purchase_order.branch_name = payload.branch.name
        state.purchase_order.branch_address = payload.branch.address
        state.purchase_order.branch_phone = payload.branch.phone
        state.purchase_order.branch_email = payload.branch.email
        state.purchase_order.branch_name = payload.branch.name
        state.purchase_order.purchase_date = payload.purchase_date
        state.purchase_order.pph_id = payload.pph_id
        state.purchase_order.remark = payload.remark
        state.purchase_order.discount_nominal = payload.discount_nominal
        state.purchase_order.discount_percent = payload.discount_percent
        state.purchase_order.discount = payload.discount
        state.purchase_order.sub_total = payload.sub_total
        state.purchase_order.vat = payload.vat
        state.purchase_order.pph = payload.pph
        state.purchase_order.grand_total = payload.grand_total
        state.purchase_order.status = payload.status
        state.purchase_order.status_invoice = payload.status_invoice
        state.purchase_order.details = payload.details


    },

    SET_DETAILS(state, payload) {
        state.purchase_order.details.push({
            item_id: payload.id,
            item_name: payload.name,
            item_description: payload.description,
            item_unit: payload.unit.name,
            price: payload.price_buy,
            qty: 1,
            discount: 0,
            total: payload.price,
        })
    },

    SET_MATERIAL(state, payload) {
        state.purchase_order.details.push({
            item_id: payload.id,
            item_name: payload.name,
            item_description: payload.description,
            item_unit: payload.unit.name,
            price: payload.price,
            qty: 1,
            discount: 0,
            total: payload.price,
        })
    },

    SET_SUPPLIER(state, payload) {
        state.purchase_order.supplier_id = payload.id;
        state.purchase_order.supplier_name = payload.name;
        state.purchase_order.supplier_address = payload.address;
        state.purchase_order.supplier_phone = payload.phone;
        state.purchase_order.supplier_email = payload.email;
    },

    SET_SUBTOTAL(state, payload) {
        state.purchase_order.grand_total = payload
    },

    SET_DISCOUNT(state, payload) {
        state.purchase_order.discount = payload
    },

    SET_VAT(state, payload) {
        state.purchase_order.vat = payload
    },

    SET_TOTAL(state, payload) {
        state.purchase_order.total = payload
    },

    SET_CURRENT_PAGE(state, payload) {
        state.purchase_order.current_page = payload;
    },

    SET_DISABLED_PO_TYPE(state) {
        state.disabled_po_type = true;
    },

    SET_PO(state, payload) {
        state.purchase_order.purchase_id = payload.id;
        state.purchase_order.no_po = payload.no_po;
        state.purchase_order.type = payload.type;
        state.purchase_order.supplier_id = payload.supplier_id;
        state.purchase_order.supplier_name = payload.supplier.name;
        state.purchase_order.supplier_phone = payload.supplier.phone;
        state.purchase_order.supplier_email = payload.supplier.email;
        state.purchase_order.supplier_address = payload.supplier.address;
        state.purchase_order.customer_id = payload.customer.id;
        state.purchase_order.customer_name = payload.customer.name;
        state.purchase_order.customer_phone = payload.customer.phone;
        state.purchase_order.customer_email = payload.customer.email;
        state.purchase_order.customer_address = payload.customer.address;
        state.purchase_order.purchase_date = payload.purchase_date;
        state.purchase_order.payment_method_id = payload.payment_method_id;
        state.purchase_order.sub_total = payload.sub_total;
        state.purchase_order.grand_total = payload.grand_total;
        state.purchase_order.status_invoice = payload.status_invoice;
        for (let i = 0; i < payload.details.length; i++) {
            state.purchase_order.details.push({
                item_id: payload.details[i].item_id,
                item_name: payload.details[i].item.name,
                item_description: payload.details[i].item.description,
                item_unit: payload.details[i].item.unit.name,
                qty: payload.details[i].qty,
                price: payload.details[i].price,
                discount: 0,
            });

        }
    },

    CLEAR_MATERIAL(state) {
        state.purchase_order = {
            id: null,
            no_po: null,
            sales_order_id: null,
            no_so: null,
            disabled_po_type: false,
            type: "MATERIAL",
            supplier_id: null,
            supplier_name: null,
            supplier_address: null,
            supplier_phone: null,
            supplier_email: null,
            customer_id: null,
            customer_name: null,
            customer_phone: null,
            customer_email: null,
            customer_address: null,
            purchase_date: new Date().toJSON().slice(0, 10),
            payment_method_id: null,
            use_vat: false,
            payment_name: null,
            branch_id: null,
            branch_name: null,
            branch_phone: null,
            branch_email: null,
            branch_address: null,
            pph_id: null,
            sub_total: 0,
            discount_nominal: 0,
            discount_percent: 0,
            discount: 0,
            vat: 0,
            pph: 0,
            grand_total: 0,
            status_invoice: null,
            details: [],
        }
    },


    CLEAR_STATE(state) {
        state.purchase_order = {
            id: null,
            no_po: null,
            disabled_po_type: false,
            type: "PRODUCT",
            supplier_id: null,
            supplier_name: null,
            supplier_address: null,
            supplier_phone: null,
            supplier_email: null,
            customer_id: null,
            customer_name: null,
            customer_phone: null,
            customer_email: null,
            customer_address: null,
            purchase_date: new Date().toJSON().slice(0, 10),
            payment_method_id: null,
            use_vat: false,
            payment_name: null,
            branch_id: null,
            branch_name: null,
            branch_phone: null,
            branch_email: null,
            branch_address: null,
            pph_id: null,
            sub_total: 0,
            discount_nominal: 0,
            discount_percent: 0,
            discount: 0,
            vat: 0,
            pph: 0,
            grand_total: 0,
            status_invoice: null,
            details: [],
        }
    }
}

const getters = {
    PurchaseOrders: state => state.purchase_orders,
    PurchaseOrder: state => state.purchase_order,
    DisabledPoType: state => state.disabled_po_type,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}