const state = () => ({
    employees: {},
    employee: {
        id: null,
        name: null,
        email: null,
        phone: null,
        address: null,
        image : null,
        is_active: null,
    },

})

const mutations = {
    SET_EMPLOYEES(state, payload) {
        state.employees = payload;
    },
    SET_EMPLOYEE(state, payload) {
        state.employee = {
            id: payload.id,
            name: payload.name,
            email: payload.email,
            phone: payload.phone,
            address: payload.address,
            image : payload.image,
            is_active: payload.is_active,
        };
    },
    CLEAR_STATE(state) {
        state.employee = {
            id: null,
            name: null,
            email: null,
            phone: null,
            address: null,
            image : null,
            is_active: null,
        };
    },
    
    SET_CURRENT_PAGE(state, payload) {
        state.employees.current_page = payload;
    },
}
const getters = {
    Employees: state => state.employees,
    Employee: state => state.employee,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}
