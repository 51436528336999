<template>
  <div>
    <v-data-table :loading="loading" :headers="headers" :items="VARIANTS.data" class="elevation-1" show-expand
      :expanded.sync="expanded" :single-expand="true" @click:row="dataTableRowClicked" hide-default-footer>
      <template v-slot:[`item.is_active`]="{ item }">
        <v-icon v-if="item.is_active == 1" color="success">
          mdi-check-circle
        </v-icon>
        <v-icon v-else color="error"> mdi-close-circle </v-icon>
      </template>
      <template v-slot:[`item.membership`]="{ item }">
        <span v-if="item.membership == 1" color="success">Yes</span>
        <span v-else color="success">No</span>
      </template>
      <template v-slot:no-data> No data </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="warning" class="mr-2" @click.stop="edit(item)">
          mdi-pencil-box-outline
        </v-icon>
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white">
          <base-column :lg="4" :md="4" :sm="6">
            <v-text-field v-model="search.keyword" append-icon="mdi-magnify" label="Search" outlined dense
              hide-details></v-text-field>
          </base-column>
          <base-column :lg="3" :md="2" :sm="6">
            <v-select class="mt-6" :items="status" outlined dense attach chips label="Status"
              v-model="search.status"></v-select></base-column>
          <v-btn small color="darkblue" class="white--text float-end mx-1" @click.stop="searching">Submit <v-icon
              right>mdi-magnify</v-icon></v-btn>
          <v-btn small color="secondary" class="white--text float-end mx-1" @click.stop="clear">Clear
            <v-icon right>mdi-close-circle-multiple </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
    </v-data-table>

    <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
  </div>
</template>

<script>
import BaseColumn from "@/components/base/BaseColumn";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "VariantList",
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Item ID", value: "item.name" },
        { text: "Branch ID", value: "branch_id" },
        { text: "Price", value: "price" },
        { text: "Stock", value: "stock" },
        { text: "Weight", value: "weight" },
        { text: "Length", value: "length" },
        { text: "Width", value: "width" },
        { text: "Height", value: "height" },
        { text: "Status", value: "is_active" },
        { text: "Action", value: "actions", sortable: false, width: 100 },
      ],
      page: 1,
      disabled: false,
      loading: false,
      status: ["Active", "Inactive"],
      search: {
        status: null,
        keyword: null,
      },
      expanded: [],
    };
  },

  created() {
    this.getVariant();
  },
  methods: {
    ...mapMutations("variant", ["SET_VARIANTS", "CLEAR_VARIANTS"]),
    async getVariant() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        status: this.search.status,
        keyword: this.search.keyword,
      };

      await this.$axios
        .get("/master/variant", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_VARIANTS(data.variant);
          this.loading = false;
          this.disabled = false;
        });
    },

    searching() {
      this.page = 1;
      this.getVariant();
    },
    clear() {
      this.page = 1;
      this.search = {
        status: "",
        search: "",
      };
      this.getVariant();
    },

    dataTableRowClicked(item) {
      if (item === this.expanded[0]) {
        this.expanded = [];
      } else {
        this.expanded = [item];
      }
    },

    edit(item) {
      this.$router.push("/master/variant/edit/" + item.id);
    },
  },
  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.getVariant();
    },
  },
  computed: {
    ...mapGetters("variant", {
      VARIANTS: "Variants",
    }),

    currentPage: {
      get() {
        return this.VARIANTS.current_page;
      },
      set(val) {
        return this.$store.commit("variant/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.VARIANTS.last_page;
      },
    },
  },

  components: {
    BaseColumn,
  },
};
</script>
