<template>
  <div>
    <base-title title="Service" subtitle="List Data Service" />
    <v-btn to="/master/service/create" small elevation="1" color="primary" class=" white--text float-right mx-2"
      @click="loader = 'loading3'">
      Create
      <v-icon right dark>mdi-plus-circle </v-icon>
    </v-btn>
    <service-list />
  </div>
</template>

<script>
import BaseTitle from "@/components/base/BaseTitle.vue";
import ServiceList from "@/views/master/master_service/List.vue";
export default {
  components: { BaseTitle, ServiceList },
  name: "ServiceIndex",
};
</script>