import Dashboard from '@/views/dashboard/Index.vue'
import CompanyProfile from '@/views/master/company_profile/Index.vue'
import AccountSetting from '@/views/master/account/Index.vue'
import User from '@/views/master/user/Index.vue'
import UserForm from '@/views/master/user/Form.vue'
import UserEdit from '@/views/master/user/Edit.vue'
import Branch from '@/views/master/branch/Index.vue'
import CreateBranch from '@/views/master/branch/Create.vue'
import EditBranch from '@/views/master/branch/Edit.vue'
import ListBranch from '@/views/master/branch/List.vue'
import Table from '@/views/master/table/Index.vue'
import GroupTable from '@/views/master/group_table/Index.vue'
import Role from '@/views/master/rbac/Role.vue'
import Permission from '@/views/master/rbac/Permission.vue'
import Custom from '@/views/master/custom/Index.vue'
import Pph from '@/views/master/pph/Index.vue'
import Unit from '@/views/master/unit/Index.vue'
import Item from '@/views/master/item/Index.vue'
import ItemForm from '@/views/master/item/Form.vue'
import ItemEdit from '@/views/master/item/Edit.vue'
import ItemTabs from '@/views/master/item/Tabs.vue'
import ItemTabsList from '@/views/master/item/TabsList.vue'
import ItemIngredient from '@/views/master/item_ingredient/Index.vue'
import ItemIngredientForm from '@/views/master/item_ingredient/Form.vue'
import ItemIngredientEdit from '@/views/master/item_ingredient/Edit.vue'
import ItemVariantEdit from '@/views/master/item/EditVariant.vue'
import Supplier from '@/views/master/supplier/Index.vue'
import CreateSupplier from '@/views/master/supplier/Create.vue'
import EditSupplier from '@/views/master/supplier/Edit.vue'
import Customer from '@/views/master/customer/Index.vue'
import CreateCustomer from '@/views/master/customer/Create.vue'
import EditCustomer from '@/views/master/customer/Edit.vue'
import Employee from '@/views/master/employee/Index.vue'
import CreateEmployee from '@/views/master/employee/Create.vue'
import EditEmployee from '@/views/master/employee/Edit.vue'
import Configuration from '@/views/master/configuration/Index.vue'
import Voucher from '@/views/master/voucher/Index.vue'
import Discount from '@/views/master/discount/Index.vue'
import CreateDiscount from '@/views/master/discount/Create.vue'
import EditDiscount from '@/views/master/discount/Edit.vue'
import Promo from '@/views/master/promo/Index.vue'
import CreatePromo from '@/views/master/promo/Create.vue'
import EditPromo from '@/views/master/promo/Edit.vue'
import Variant from '@/views/master/variant/Index.vue'
import CreateVariant from '@/views/master/variant/Create.vue'
import AccountPatyCash from '@/views/master/account_paty_cash/Index.vue'
import AccountPatyCashForm from '@/views/master/account_paty_cash/Create.vue'
import AccountPatyCashEdit from '@/views/master/account_paty_cash/Edit.vue'
import EditVariant from '@/views/master/variant/Edit.vue'
import MasterDevice from '@/views/master/master_device/Index.vue'
import MasterDeviceCreate from '@/views/master/master_device/Create.vue'
import MasterDeviceEdit from '@/views/master/master_device/Edit.vue'
import MasterService from '@/views/master/master_service/Index.vue'
import MasterServiceCreate from '@/views/master/master_service/Create.vue'
import MasterServiceEdit from '@/views/master/master_service/Edit.vue'

export default [
    {
        path: '/master',
        name: 'master',
        component: () => import('@/views/master/Index.vue'),
        children: [
            {
                path: 'dashboard',
                name: "dashboard",
                meta: { title: "S-ERP | Dashboard" },
                component: Dashboard
            },
            {
                path: 'item/tabs-list',
                name: "item.tabs-list",
                meta: { title: "S-ERP | Item" },
                component: ItemTabsList
            },
            {
                path: 'items',
                name: "item.index",
                meta: { title: "S-ERP | Item" },
                component: Item
            },
            {
                path: 'item/form',
                name: "item.form",
                meta: { title: "S-ERP | Item" },
                component: ItemForm
            },
            {
                path: 'item/tabs',
                name: "item.tabs",
                meta: { title: "S-ERP | Item" },
                component: ItemTabs
            },
            {
                path: '/item/edit',
                name: "item.edit",
                meta: { title: "S-ERP |Item" },
                component: ItemEdit
            },
            {
                path: 'item-ingredients',
                name: "item_ingredient.index",
                meta: { title: "S-ERP | Item Ingredient" },
                component: ItemIngredient
            },
            {
                path: 'item-ingredient/form',
                name: "item_ingredient.form",
                meta: { title: "S-ERP | Item Ingredient" },
                component: ItemIngredientForm
            },
            {
                path: '/item-ingredient/edit',
                name: "item_ingredient.edit",
                meta: { title: "S-ERP | Item Ingredient" },
                component: ItemIngredientEdit
            },
            {
                path: '/item-variant/edit',
                name: "item-variant.edit",
                meta: { title: "S-ERP | Item Variant" },
                component: ItemVariantEdit
            },
            {
                path: 'suppliers',
                name: "supplier.index",
                meta: { title: "S-ERP | Supppliers" },
                component: Supplier
            },
            {
                path: 'suppliers/create',
                name: "supplier.create",
                meta: { title: "S-ERP | Create Suppplier" },
                component: CreateSupplier
            },
            {
                path: 'suppliers/edit/:id',
                name: "supplier.edit",
                meta: { title: "S-ERP | Edit Suppplier" },
                component: EditSupplier
            },
            {
                path: 'customers',
                name: "customer.index",
                meta: { title: "S-ERP | Customer" },
                component: Customer
            },
            {
                path: 'customers/create',
                name: "customer.create",
                meta: { title: "S-ERP | Create Customer" },
                component: CreateCustomer
            },
            {
                path: 'customers/edit/:id',
                name: "customer.edit",
                meta: { title: "S-ERP | Edit Customer" },
                component: EditCustomer
            },
            {
                path :'employee',
                name: "employee.index",
                meta: { title: "S-ERP | Employee" },
                component: Employee
            },
            {
                path: 'employee/create',
                name: "employee.create",
                meta: { title: "S-ERP | Create Employee" },
                component: CreateEmployee
            },
            {
                path: 'employee/edit/:id',
                name: "employee.edit",
                meta: { title: "S-ERP | Edit Employee" },
                component: EditEmployee
            },
            {
                path: 'company-profile',
                name: "account",
                meta: { title: "S-ERP | Company Profile" },
                component: CompanyProfile
            },
            {
                path: 'account-settings',
                name: "account",
                meta: { title: "S-ERP | Account Settings" },
                component: AccountSetting
            },
            {
                path: 'users',
                name: "users.index",
                meta: { title: "S-ERP | Users" },
                component: User
            },
            {
                path: 'users/form',
                name: "users.form",
                meta: { title: "S-ERP | Users" },
                component: UserForm
            },
            {
                path: '/users/edit',
                name: "users.edit",
                meta: { title: "S-ERP | Users" },
                component: UserEdit
            },
            {
                path: 'branch',
                name: "branch.index",
                meta: { title: "S-ERP | Branch" },
                component: Branch
            },
            {
                path: 'branch/create',
                name: "branch.create",
                meta: { title: "S-ERP | Create Branch" },
                component: CreateBranch
            },
            {
                path: 'branches/edit/:id',
                name: "branch.edit",
                meta: { title: "S-ERP | Edit Branch" },
                component: EditBranch
            },
            {
                path: 'branch/list',
                name: "branch.list",
                meta: { title: "S-ERP | List Branch" },
                component: ListBranch
            },
            {
                path: 'group-table',
                name: "group-table.index",
                meta: { title: "S-ERP | Group Table" },
                component: GroupTable
            },
            {
                path: 'table',
                name: "table.index",
                meta: { title: "S-ERP | Table" },
                component: Table
            },
            {
                path: 'role',
                name: "role.index",
                meta: { title: "S-ERP | Role Permissions" },
                component: Role
            },
            {
                path: 'permission/:id',
                name: 'permission.set',
                meta: { title: "S-ERP | Assign Permissions" },
                component: Permission
            },
            {
                path: 'custom',
                name: "custom.index",
                meta: { title: "S-ERP | Custom" },
                component: Custom
            },
            {
                path: 'voucher',
                name: "voucher.index",
                meta: { title: "S-ERP | Voucher" },
                component: Voucher
            },
            {
                path: 'pph',
                name: "pph.index",
                meta: { title: "S-ERP | PPH" },
                component: Pph
            },
            {
                path: 'unit',
                name: "unit.index",
                meta: { title: "S-ERP | Unit" },
                component: Unit
            },
            {
                path: 'configuration-system',
                name: "configuration.index",
                meta: { title: "S-ERP | Configuration" },
                component: Configuration
            },
            {
                path: 'discount',
                name: "discount.index",
                meta: { title: "S-ERP | Discount" },
                component: Discount
            },

            {
                path: 'discount/create',
                name: "discount.create",
                meta: { title: "S-ERP | Create Discount" },
                component: CreateDiscount
            },

            {
                path: 'discount/edit/:id',
                name: "discount.edit",
                meta: { title: "S-ERP | Edit Discount" },
                component: EditDiscount
            },

            {
                path: 'promo',
                name: "promo.index",
                meta: { title: "S-ERP | Promo" },
                component: Promo
            },

            {
                path: 'promo/create',
                name: "promo.create",
                meta: { title: "S-ERP | Create Promo" },
                component: CreatePromo
            },

            {
                path: 'promo/edit/:id',
                name: "promo.edit",
                meta: { title: "S-ERP | Edit Promo" },
                component: EditPromo
            },

            {
                path: 'variant',
                name: "variant.index",
                meta: { title: "S-ERP | Variant" },
                component: Variant
            },

            {
                path: 'variant/create',
                name: "variant.create",
                meta: { title: "S-ERP | Create Variant" },
                component: CreateVariant
            },

            {
                path: 'variant/edit/:id',
                name: "variant.edit",
                meta: { title: "S-ERP | Edit Variant" },
                component: EditVariant
            },

            {
                path: 'account-petty-cash',
                name: "account-petty-cash.index",
                meta: { title: "S-ERP | Account Petty Cash" },
                component: AccountPatyCash
            },

            {
                path: 'account-petty-cash/create',
                name: "account-petty-cash.create",
                meta: { title: "S-ERP | Create Account Petty Cash" },
                component: AccountPatyCashForm
            },

            {
                path: 'account-petty-cash/edit/:id',
                name: "account-petty-cash.edit",
                meta: { title: "S-ERP | Edit Account Petty Cash" },
                component: AccountPatyCashEdit
            },

            {
                path: 'device',
                name: "device.index",
                meta: { title: "S-ERP | Master Device" },
                component: MasterDevice
            },

            {
                path: 'device/create',
                name: "device.create",
                meta: { title: "S-ERP | Master Device Create" },
                component: MasterDeviceCreate
            },
            {
                path: 'device/edit/:id',
                name: "device.edit",
                meta: { title: "S-ERP | Master Device Edit" },
                component: MasterDeviceEdit
            },
            {
                path: 'service',
                name: "service.index",
                meta: { title: "S-ERP | Master Service" },
                component: MasterService
            },
            {
                path: 'service/create',
                name: "service.create",
                meta: { title: "S-ERP | Master Service Create" },
                component: MasterServiceCreate
            },
            {
                path: 'service/edit/:id',
                name: "service.edit",
                meta: { title: "S-ERP | Master Service Edit" },
                component: MasterServiceEdit
            },
        ]
    }
]