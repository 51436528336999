<template>
    <main-layout>
        <base-header>
            <template #icon> mdi-file</template>
            <template #name>Daily Sales Order</template>
            <template #breadcrumbs>
                <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                    <template #divider>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>
            </template>
        </base-header>
        <v-row class="mx-1" style="margin-bottom:-50px;">
            <v-responsive width="100%" />
            <v-col :xs="12" :sm="12" :md="3" :lg="3">
                <v-switch v-model="is_active" label="Show Filter Widget" class="mt-0"></v-switch>
            </v-col>
        </v-row>
        <v-row class="mx-1" style="margin-bottom:-50px;" v-if="is_active === true">
            <v-responsive width="100%" />
            <v-col :xs="12" :sm="12" :md="3" :lg="3">
                <v-text-field type="date" label="From Date" outlined dense v-model="search.from_date">
                </v-text-field>
            </v-col>
            <v-col :xs="12" :sm="12" :md="3" :lg="3">
                <v-text-field type="date" label="End Date" outlined dense v-model="search.to_date">
                </v-text-field>
            </v-col>
            <v-col :xs="12" :sm="12" :md="3" :lg="3" v-if="(ROLE == 'Owner')">
                <v-select label="Branch" item-text="name" item-value="id" :items="branch" outlined dense
                    v-model="search.branch_id">
                </v-select>
            </v-col>
            <v-col :xs="12" :sm="12" :md="3" :lg="3">
                <v-btn small color="darkblue" class="white--text mx-1" @click.stop="getWidget">Submit <v-icon
                        right>mdi-magnify</v-icon></v-btn>
                <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
                    <v-icon right>mdi-close-circle-multiple </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mx-1">
            <base-column :xs="12" :sm="12" :md="4" :lg="2">
                <v-banner class="mt-3 summary" outlined rounded single-line>
                    <v-row no-gutters class="mb-3 mt-2">
                        <v-col xs="3" sm="3" md="3" class="my-auto mx-auto">
                            <v-avatar color="purple" size="40" tile>
                                <v-icon color="white">mdi-chart-box-outline</v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col xs="9" sm="9" md="9">
                            <h4 class="ml-2 mt-2">Total Transaction</h4>
                            <h4 class="secondary--text ml-2 mt-2">{{ this.total_transaction }}</h4>
                        </v-col>
                    </v-row>
                </v-banner>
            </base-column>
            <base-column :xs="12" :sm="12" :md="4" :lg="2">
                <v-banner class="mt-3 summary" outlined rounded single-line>
                    <v-row no-gutters class="mb-3 mt-2">
                        <v-col xs="3" sm="3" md="3" class="my-auto mx-auto">
                            <v-avatar color="success" size="40" tile>
                                <v-icon color="white">mdi-cash</v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col xs="9" sm="9" md="9">
                            <h4 class="ml-2 mt-2"> Total Amount</h4>
                            <h4 class="secondary--text ml-2 mt-2">Rp {{ this.total_amount | currency("", 0, {
                                symbolOnLeft: false,
                            }) }}</h4>
                        </v-col>
                    </v-row>
                </v-banner>
            </base-column>
            <base-column :xs="12" :sm="12" :md="4" :lg="2">
                <v-banner class="mt-3 summary" outlined rounded single-line>
                    <v-row no-gutters class="mb-3 mt-2">
                        <v-col xs="3" sm="3" md="3" class="my-auto mx-auto">
                            <v-avatar color="warning" size="40" tile>
                                <v-icon color="white">mdi-handshake</v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col xs="9" sm="9" md="9">
                            <h4 class="ml-2 mt-2"> Total Cash</h4>
                            <h4 class="secondary--text ml-2 mt-2">Rp {{ this.total_cash | currency("", 0, {
                                symbolOnLeft:
                                    false,
                            })
                            }}</h4>
                        </v-col>
                    </v-row>
                </v-banner>
            </base-column>
            <base-column :xs="12" :sm="12" :md="4" :lg="2">
                <v-banner class="mt-3 summary" outlined rounded single-line>
                    <v-row no-gutters class="mb-3 mt-2">
                        <v-col xs="3" sm="3" md="3" class="my-auto mx-auto">
                            <v-avatar color="primary" size="40" tile>
                                <v-icon color="white">mdi-card-bulleted</v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col xs="9" sm="9" md="9">
                            <h4 class="ml-2 mt-2"> Total Card</h4>
                            <h4 class="secondary--text ml-2 mt-2">Rp {{ this.total_card | currency("", 0, {
                                symbolOnLeft:
                                    false,
                            })
                            }}</h4>
                        </v-col>
                    </v-row>
                </v-banner>
            </base-column>
            <base-column :xs="12" :sm="12" :md="4" :lg="2">
                <v-banner class="mt-3 summary" outlined rounded single-line>
                    <v-row no-gutters class="mb-3 mt-2">
                        <v-col xs="3" sm="3" md="3" class="my-auto mx-auto">
                            <v-avatar color="secondary" size="40" tile>
                                <v-icon color="white">mdi-forum-minus</v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col xs="9" sm="9" md="9">
                            <h4 class="ml-2 mt-2"> Total Change</h4>
                            <h4 class="secondary--text ml-2 mt-2">Rp {{ this.total_change | currency("", 0, {
                                symbolOnLeft:
                                    false,
                            })
                            }}</h4>
                        </v-col>
                    </v-row>
                </v-banner>
            </base-column>
            <base-column :xs="12" :sm="12" :md="4" :lg="2">
                <v-banner class="mt-3 summary" outlined rounded single-line>
                    <v-row no-gutters class="mb-3 mt-2">
                        <v-col xs="3" sm="3" md="3" class="my-auto mx-auto">
                            <v-avatar color="purple" size="40" tile>
                                <v-icon color="white">mdi-open-in-app</v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col xs="9" sm="9" md="9">
                            <h4 class="ml-2 mt-2"> Total Opening</h4>
                            <h4 class="secondary--text ml-2 mt-2">Rp {{ this.total_opening_amount | currency("", 0, {
                                symbolOnLeft:
                                    false,
                            })
                            }}</h4>
                        </v-col>
                    </v-row>
                </v-banner>
            </base-column>
        </v-row>
        <v-row class="mx-2 mt-2">
            <v-responsive width="100%" />
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-tabs centered fixed-tabs v-model="tab" background-color="primary" dark>
                    <v-tab href="#by-order">By Orders</v-tab>
                    <v-tab-item value="by-order">
                        <ByOrder />
                    </v-tab-item>
                    <v-tab href="#by-item"> By Item</v-tab>
                    <v-tab-item value="by-item">
                        <ByItem />
                    </v-tab-item>
                    <v-tab href="#top-sales"> Top Sales</v-tab>
                    <v-tab-item value="top-sales">
                        <TopSales />
                    </v-tab-item>
                    <v-tab href="#report-payment"> Report Payment</v-tab>
                    <v-tab-item value="report-payment">
                        <ReportPayment />
                    </v-tab-item>
                </v-tabs>
            </v-col>
        </v-row>


    </main-layout>
</template>
<script>
import MainLayout from "@/layouts/MainLayout.vue";
import BaseHeader from "@/components/base/BaseHeader.vue";
import BaseColumn from "@/components/base/BaseColumn.vue";
import ByOrder from "./by_order.vue";
import ByItem from "./by_item.vue";
import TopSales from "./top_sales.vue";
import ReportPayment from "./report_payment.vue";
import { mapMutations,mapGetters } from "vuex";

export default {
    name: "CustomIndex",
    data() {
        return {
            search: {
                from_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
                to_date: new Date().toJSON().slice(0, 10),
                branch_id : null
            },
            tab: "",
            total_transaction: 0,
            total_amount: 0,
            total_cash: 0,
            total_card: 0,
            total_change: 0,
            total_opening_amount: 0,
            is_active: false,

        };
    },

    methods: {
        ...mapMutations("daily_sales_order", ["SET_DAILY_SALES_ORDERS", "SET_DAILY_SALES_ORDER", "SET_CURRENT_PAGE"]),

        async getWidget() {
            let params = {
                from_date: this.search.from_date,
                to_date: this.search.to_date,
                branch_id : this.search.branch_id

            };
            await this.$axios

                .get("/reports/payments", {
                    params: params,
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.total_transaction = data.total_transaction;
                    this.total_amount = data.total_amount;
                    this.total_change = data.total_change;
                    this.total_opening_amount = data.total_opening_amount;
                    this.total_cash = data?.total_payments?.find(item => item.payment_method_id == 1)?.total ?? 0;
                    this.total_card = data?.total_payments?.reduce((a, b) => a + (b.payment_method_id == 2 ? parseInt(b.total) : 0), 0) ?? 0;
                });
        },

        clear() {
            this.page = 1;
            this.search = {
                from_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
                to_date: new Date().toJSON().slice(0, 10),
                branch_id : null
            };
            this.getWidget();
        },
        async getBranch() {
            await this.$axios
                .get("/master/branches", {
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.branch = data.branch.data;
                });
        },
    },
    created() {
        this.getBranch();
        this.getWidget();
        this.breadcrumbs = [
            { text: "Transaction", disabled: false, to: "/dashboard" },
            { text: "Daily Sales Order", disabled: true },
        ];
    },

    components: {
        BaseColumn,
        ByOrder,
        ByItem,
        TopSales,
        ReportPayment,
        MainLayout,
        BaseHeader,
    },

    computed: {
    ...mapGetters("auth", {
      ROLE: "Role",
    }),
  },

};
</script>