const state = () => ({
    account_patty_cashs: {},
    account_patty_cash: {
        id: null,
        code: null,
        name: null,
        branch_id: null,
        description: null,
        is_active: null,
    },
    page: 1,
})

const mutations = {
    SET_ACCOUNT_PATTY_CASHS(state, payload) {
        state.account_patty_cashs = payload
    },

    SET_ACCOUNT_PATTY_CASH(state, payload) {
        state.account_patty_cash = {
            id: payload.id,
            code: payload.code,
            name: payload.name,
            branch_id: payload.branch_id,
            description: payload.description,
            is_active: payload.is_active,
        }
    },

    SET_CURRENT_PAGE(state, payload) {
        state.account_patty_cashs.current_page = payload;
    },

    CLEAR_STATE(state) {
        state.account_patty_cash.id = null;
        state.account_patty_cash.code = null;
        state.account_patty_cash.name = null;
        state.account_patty_cash.branch_id = null;
        state.account_patty_cash.description = null;
        state.account_patty_cash.is_active = null;
    }
}

const getters = {
    AccountPattyCashs: state => state.account_patty_cashs,
    AccountPattyCash: state => state.account_patty_cash,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}