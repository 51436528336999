<template>
  <div>
    <base-title title="Variant" subtitle="List Data Variant" />
    <v-btn to="/master/variant/create" small elevation="1" color="primary" class="ma-3 white--text float-right"
      @click="loader = 'loading3'">
      Create
      <v-icon right dark>mdi-plus-circle </v-icon>
    </v-btn>
    <variant-list />
  </div>
</template>

<script>
import BaseTitle from "@/components/base/BaseTitle.vue";
import VariantList from "@/views/master/variant/List.vue";
export default {
  components: { VariantList, BaseTitle },
  name: "PromoIndex",
  data() {
    return {
      loading: false,

    };
  },
};
</script>