<template>
  <main-layout>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" color="amber" />
    </v-overlay>
    <base-header>
      <template #icon> mdi-file</template>
      <template #name> View Sales Invoice</template>
      <template #breadcrumbs>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </template>
      <template #button>
        <v-btn color="secondary" class="float-end mb-2" small @click="backForm()">Back
          <v-icon right>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn color="secondary" class="float-end mb-2 mr-1" small @click="clear()">Clear
        </v-btn>
        <v-btn color="primary" class="float-end mb-2 mr-1" small @click.stop="update()" :loading="btnLoading">Update to
          Void
        </v-btn>
      </template>
    </base-header>
    <v-card elevation="2" rounded class="mx-2">
      <v-row dense class="mx-2">
      </v-row>
      <v-row dense class="mx-5 mt-4">
        <base-column :lg="2">
          <h4 class="secondary--text">Sales Order No .</h4>
          <h4>{{ SalesOrder.no_so }}</h4>
        </base-column>
        <base-column :lg="2">
          <h4 class="secondary--text">Customer</h4>
          <h4>{{ SalesOrder.customer_name }}</h4>
        </base-column>
        <base-column :lg="2">
          <h4 class="secondary--text">Customer Phone</h4>
          <h4>{{ SalesOrder.customer_phone }}</h4>
        </base-column>
        <base-column :lg="2">
          <h4 class="secondary--text">Address Customer</h4>
          <h4>{{ SalesOrder.customer_address }}</h4>
        </base-column>
        <base-column :lg="2">
          <h4 class="secondary--text">Payment Method 2</h4>
          <h4>{{ SalesOrder.subtotal }}</h4>
        </base-column>
      </v-row>
      <br>
      <v-divider></v-divider>
      <v-simple-table class="mx-2 my-3" fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">#</th>
              <th class="text-left">Item Name</th>
              <th class="text-left">Unit</th>
              <th class="text-left">Price</th>
              <th class="text-left">QTY</th>
              <th class="text-left">Discount</th>
              <th class="text-left">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in SalesOrder.details" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ row.item.name }}</td>
              <td>{{ row.item.unit.name }}</td>
              <td>
                {{
                  row.price | currency("", 2, { symbolOnLeft: false })
                }}
              </td>
              <td> {{
                row.qty | currency("", 2, { symbolOnLeft: false })
              }}</td>
              <td>
                {{
                  row.discount_nominal | currency("", 2, { symbolOnLeft: false })
                }}
              </td>
              <td>
                {{
                  (row.price * row.qty / 100) | currency("", 2, {
                    symbolOnLeft:
                      false
                  })
                }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-simple-table class="mx-2 mb-3" fixed-header>
        <thead>
          <tr>
            <th class="text-left" width="60%"></th>
            <th class="text-left" width="60%"></th>
            <th class="text-left" width="20%"></th>
            <th class="text-left" width="20%"></th>
            <th class="text-left" width="20%"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td class="text-right">
              Sub Total : <br />
              Discount : <br />
              PPh : <br />
              Tax : <br />
              <h3>Grand Total :</h3>
            </td>
            <td>
              <b>
                {{
                  SalesOrder.subtotal | currency("", 2, { symbolOnLeft: false })
                }}
              </b>
              <br />
              <b>
                {{
                  SalesOrder.discount | currency("", 2, { symbolOnLeft: false })
                }}
              </b>
              <br />
              <b>
                {{
                  SalesOrder.pph | currency("", 2, { symbolOnLeft: false })
                }}
              </b>
              <b>
                {{
                  SalesOrder.vat | currency("", 2, { symbolOnLeft: false })
                }}
              </b>
              <br />
              <b>
                {{
                  SalesOrder.grand_total | currency("", 2, { symbolOnLeft: false })
                }}
              </b>
              <br />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>

    <!-- <iframe :src="`${shareableLink}`" width="100%" height="100%" /> -->

  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
import BaseHeader from '@/components/base/BaseHeader.vue';
import BaseColumn from '@/components/base/BaseColumn.vue';
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: "SalesInvoiceView",

  data() {
    return {
      loading: false,
      btnLoading: false,
      breadcrumbs: [
        { text: "Transaction", disabled: false },
        { text: "Sales Invoice", disabled: false },
        { text: "View", disabled: true },

      ],
      // shareableLink: process.env.VUE_APP_API_URL + "/sales_order/view/" + this.$route.params.id,
    };

  },

  created() {
    this.init();
  },

  methods: {
    ...mapMutations("sales_order", ["CLEAR_STATE", "SET_SALES_ORDER"]),

    init() {
      this.loading = true;
      this.$axios
        .get("/transaction/sales-orders/" + this.$route.params.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_SALES_ORDER(data.sales_orders);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    update: async function () {
      this.btnLoading = true;
      await this.$axios
        .put(
          "/transaction/sales-orders/set-to-void/" + this.$route.params.id,
          this.SalesOrder,
          {
            headers: {
              Authorization: this.$store.getters["auth/Token"],
            },
          }
        )
        .then(() => {
          this.btnLoading = false;
          this.$router.push("/sales-invoice");
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },

    clear() {
      this.CLEAR_STATE();
    },

    backForm() {
      this.CLEAR_STATE();
      this.$router.push("/sales-invoice");
    },
  },

  computed: {
    ...mapGetters("sales_order", ["SalesOrder"]),
  },

  components: {
    MainLayout,
    BaseHeader,
    BaseColumn
  },
}
</script>