const state = () => ({
    groups: [],
    page : 1

})

const mutations = {
    SET_GROUPS(state, payload) {
        state.groups = payload;
    },

    SET_CURRENT_PAGE(state, payload){
        state.groups.current_page = payload
    },

    CLEAR_STATE(state) {
        state.groups = [];
    },
}

const actions = {
    clearState({ commit }) {
        commit("CLEAR_STATE");
    },
}

const getters = {
    Groups: state => state.groups,
    Page: state => state.page
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
