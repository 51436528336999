const state = () => ({
    variants: [],
    variant: {
        id: null,
        name: null,
        price: 0,
        use_material: false,
        ingredients: [],
    },
    page: 1
})

const mutations = {
    SET_VARIANTS(state, payload) {
        state.variants = payload
    },
    SET_VARIANT(state, payload) {
        state.variant.id = payload.id
        state.variant.name = payload.name
        state.variant.price = payload.price
        state.variant.use_material = true
        state.variant.ingredients = payload.ingredient_toppings.map(item => {
            return {
                id: item.id,
                name: item.name,
                description: item.description,
                unit: "pcs",
                price: item.price,
                qty:item.pivot.qty
            }
        })
    },

    SET_INGREDIENTS(state, payload) {
        state.variant.ingredients.push({
            id: payload.id,
            name: payload.name,
            description: payload.description,
            unit: payload.unit.name,
            price: payload.price,
            qty: 1
        })

    },

    SET_CURRENT_PAGE(state, payload) {
        state.variants.current_page = payload
    },

    CLEAR_STATE(state) {
        state.variant = {
            id: null,
            name: null,
            price: 0,
            use_material: false,
            ingredients: [],
        }
    },

    CLEAR_INGREDIENTS(state) {
        state.variant.price = 0
        state.variant.ingredients = []
    }
}

const getters = {
    Variants: state => state.variants,
    Variant: state => state.variant,
    Page: state => state.page
}


export default {
    namespaced: true,
    state,
    mutations,
    getters
}