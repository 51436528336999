<template>
    <v-tabs>
        <v-tab href="#product"> Product </v-tab>
        <v-tab-item value="product">
            <Form />
        </v-tab-item>
        <!-- <v-tab href="#productv2"> Product V2 </v-tab>
        <v-tab-item value="productv2">
            <FormV2 />
        </v-tab-item> -->
        <v-tab href="#material"> Material </v-tab>
        <v-tab-item value="material">
            <Material />
        </v-tab-item>
        <v-tab href="#variant"> Variant </v-tab>
        <v-tab-item value="variant">
            <Variant />
        </v-tab-item>
    </v-tabs>
</template>
<script>
import { mapGetters } from "vuex";
import Form from "./Form.vue";
// import FormV2 from "./FormV2.vue";
import Material from "./FormMaterial.vue";
import Variant from "./FormVariant.vue";
export default {
    name: "CustomIndex",
    components: {
        Form,
        // FormV2,
        Material,
        Variant
    },

    computed: {
        ...mapGetters("auth", {
            ACCESS_TOKEN: "AccessToken",
            TOKEN: "Token",
        }),
    },
};
</script>