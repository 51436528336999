const state = () => ({
    users: {},
    user: {
        name: null,
        branch_id: null,
        email: null,
        phone: null,
        address: null,
        username: null,
        password: null,
        password_confirmation: null,
        role_id: null,
        is_active: false,
    },
    page: 1,
})

const mutations = {
    SET_USERS(state, payload) {
        state.users = payload;
    },

    SET_USER(state, payload) {
        state.user.id = payload.id;
        state.user.name = payload.name;
        state.user.branch_id = payload.branch_id;
        state.user.email = payload.email;
        state.user.phone = payload.phone;
        state.user.address = payload.address;
        state.user.username = payload.username;
        state.user.password = payload.password;
        state.user.password_confirmation = payload.password_confirmation;
        state.user.role_id = payload.role_id;
        state.user.is_active = payload.is_active;
    },

    CLEAR_STATE(state) {
        state.user.id = null;
        state.user.name = null;
        state.user.branch_id = null;
        state.user.email = null;
        state.user.phone = null;
        state.user.address = null;
        state.user.username = null;
        state.user.password = null;
        state.user.password_confirmation = null;
        state.user.role_id = null;
        state.user.is_active = false;
    },

    SET_CURRENT_PAGE(state, payload) {
        state.page = payload;
    }

}

const getters = {
    Users: state => state.users,
    User: state => state.user,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}
