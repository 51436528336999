const state = () => ({
    total_sales: {
        amount: 0,
    },

    total_orders: {
        amount: 0,
        percentage: 0
    },

    total_persons: {
        amount: 0,
        percentage: 0
    },

    average_sales: {
        amount: 0,
        percentage: 0
    },

    total_po: {
        amount: 0,
        percentage: 0
    },

    summary_payment: {
        amount: 0,
        percentage: 0
    },

    payment: {},

    branch: {},

    best_selling_product: {},

    page: 1,
})

const mutations = {
    SET_TOTAL_SALES(state, payload) {
        state.total_sales = payload
    },

    SET_TOTAL_ORDERS(state, payload) {
        state.total_orders = payload
    },

    SET_TOTAL_PERSONS(state, payload) {
        state.total_persons = payload
    },

    SET_AVERAGE_SALES(state, payload) {
        state.average_sales = payload
    },

    SET_TOTAL_PO(state, payload) {
        state.total_po = payload
    },

    SET_SUMMARY_PAYMENT(state, payload) {
        state.summary_payment = payload
    },

    SET_PAYMENT(state, payload) {
        state.payment = payload
    },

    SET_BRANCH(state, payload) {
        state.branch = payload
    },

    SET_BEST_SELLING_PRODUCT(state, payload) {
        state.best_selling_product = payload
    },

    SET_CURRENT_PAGE(state, payload) {
        state.best_selling_product.current_page = payload
    }
}

const getters = {
    TotalSales: state => state.total_sales,
    TotalOrders: state => state.total_orders,
    TotalPersons: state => state.total_persons,
    AverageSales: state => state.average_sales,
    TotalPO: state => state.total_po,
    SummaryPayment: state => state.summary_payment,
    Payment: state => state.payment,
    Branch: state => state.branch,
    BestSellingProduct: state => state.best_selling_product,
    CurrentPage: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}