<template>
  <div>
    <base-title title="Customers" subtitle="List Data Customers" />
    <v-btn to="/master/customers/create" small elevation="1" color="primary" class=" white--text float-end mx-4 mt-5"
      @click="loader = 'loading3'">
      Create
      <v-icon right dark>mdi-plus-circle </v-icon>
    </v-btn>
    <customer-list />
  </div>
</template>

<script>
import BaseTitle from "@/components/base/BaseTitle.vue";
import CustomerList from "@/views/master/customer/List.vue";
export default {
  components: { CustomerList, BaseTitle },
  name: "CustomersView",
  data() {
    return {
      loader: "",
      isSelecting: false,
      selectedFile: null,
      loading: false,

    };
  },

  methods: {
    onButtonClick() {
      this.isSelecting = false
      window.addEventListener('focus', () => {
        this.isSelecting = true
      }, { once: true })

      this.$refs.uploader.click()
    },

    importExcell: async function () {
      this.isSelecting = true;
      await this.$axios
        .post(
          "/import/customer", {
          file: this.$refs.uploader.files[0]
        },
          {
            headers: {
              'Content-type': 'multipart/form-data',
              Authorization: this.$store.getters["auth/Token"],
            },
          }
        )
        .then(() => {
          this.isSelecting = false;
          this.$router.go(0);
          this.$router.push({ name: "customer.index" });
        })
        .catch(() => {
          this.isSelecting = false;
        });
    },

    exportExcell: async function () {
      this.loading = true;
      await this.$axios
        .get(
          "/export/customer", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        }
        )
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const a = document.createElement('a');
          a.href = url;
          a.setAttribute('download', 'customer.csv');
          document.body.appendChild(a);
          a.click();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          console.log("error");
        });
    }

  },

  // destroyed() {
  //   this.$store.dispatch("customer/clearCustomer");
  // },
};
</script>