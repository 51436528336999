const state = () => ({
    branches: {},
    branch: {
        post_type_id: null,
        name: null,
        phone: null,
        email: null,
        address: null,
        pic_name: null,
        pic_phone: null,
    },

    page: 1,
})

const mutations = {
    SET_BRANCHES(state, payload) {
        state.branches = payload;
    },

    SET_BRANCH(state, payload) {
        state.branch = {
            post_type_id: payload.post_type_id,
            name: payload.name,
            phone: payload.phone,
            email: payload.email,
            address: payload.address,
            pic_name: payload.pic_name,
            pic_phone: payload.pic_phone,
        }
    },

    SET_CURRENT_PAGE(state, payload) {
        state.branches.current_page = payload
    },

    CLEAR_STATE(state) {
        state.branch = {
            post_type_id: null,
            name: null,
            phone: null,
            email: null,
            address: null,
            pic_name: null,
            pic_phone: null,
        }
    },
}

const getters = {
    Branches: state => state.branches,
    Branch: state => state.branch,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}

