<template>
  <div>
    <br>
    <v-form ref="form_data" v-model="form_valid" lazy-validation>
      <v-row class="mx-1">
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Name" outlined dense v-model="Item.name" :rules="rule_name"></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-select label="Group" outlined dense v-model="Item.group_id" @change="changeItem($event)" :items="group"
            :rules="rule_group" item-text="name" item-value="id"></v-select>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-select label="Sub Group" outlined dense v-model="Item.subgroup_id" :items="subgroup" :rules="rule_subgroup"
            item-text="name" item-value="id"></v-select>
        </base-column>
      </v-row>
      <v-row class="mx-1">
        <base-column :lg="4" :md="4" :sm="12">
          <v-select label="Unit" :items="unit" v-model="Item.unit_id" :rules="rule_unit" dense item-text="name"
            item-value="id" outlined></v-select>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field type="number" label="Price Buy" outlined dense v-model="Item.price_buy"
            :rules="rule_price_buy"></v-text-field>
        </base-column>
        <base-column :lg="2" :md="2" :sm="6">
          <v-switch v-model="Item.use_ingredient" @change="useIngredient()" class="mt-2" label="Use Ingredient">
          </v-switch>
        </base-column>
        <base-column :lg="2" :md="2" :sm="6">
          <v-switch v-model="Item.is_active" @change="isActive()" class="mt-2" label="USE">
          </v-switch>
        </base-column>
      </v-row>
      <v-row class="mx-1">
        <base-column :lg="4" :md="4" :sm="12">
          <v-textarea label="Specification" outlined dense v-model="Item.specification" rows="0"></v-textarea>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-textarea label="Description" outlined dense v-model="Item.description" rows="0"></v-textarea>
        </base-column>
        <base-column :lg="2" :md="2" :sm="6">
          <v-switch v-model="Item.use_variant_size" @change="useVariantSize()" class="mt-2" label="Use Variant">
          </v-switch>
        </base-column>
        <base-column :lg="2" :md="2" :sm="6">
          <v-switch v-model="Item.use_variant_topping" @change="useVariantTopping()" class="mt-2"
            label="Use Addtional Varriant">
          </v-switch>
        </base-column>
      </v-row>
      <v-row class="mx-1">
        <base-column :lg="4" :md="6" :sm="12">
          <v-file-input label="Image" outlined dense v-model="Item.image" id="file" ref="file"
            v-on:change="handleFileUpload()"></v-file-input>
        </base-column>
        <base-column :lg="4" :md="6" :sm="12" v-if="(ROLE == 'Owner')">
          <v-select label="Branch" outlined dense v-model="Item.branch_id" :items="branch" item-text="name" item-value="id"
            ></v-select>
        </base-column>
      </v-row>
      <v-divider class="mx-4"></v-divider>
      <div v-if="Item.use_ingredient == 1">
        <v-row class="mx-1 mt-4">
          <base-column :lg="6" :md="4" :sm="12">
            <p> Item Ingredient Information </p>
          </base-column>
          <base-column :lg="6" :md="4" :sm="12">
            <v-btn color="primary" class="float-end" outlined small @click="openMaterialDialog()"><v-icon
                left>mdi-plus</v-icon> Add
              Material </v-btn>
          </base-column>
        </v-row>
        <v-simple-table class="mx-2 mb-2 mt-4" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left white--text" width="1%" style="background-color:#898F99;">No</th>
                <th class="text-left white--text" width="10%" style="background-color:#898F99;">Material Name</th>
                <th class="text-left white--text" width="15%" style="background-color:#898F99;">Description</th>
                <th class="text-left white--text" width="8%" style="background-color:#898F99;">Unit</th>
                <th class="text-left white--text" width="10%" style="background-color:#898F99;">Price Buy</th>
                <th class="text-left white--text" width="7%" style="background-color:#898F99;">Qty Usage</th>
                <th class="text-left white--text" width="8%" style="background-color:#898F99;">Amount</th>
                <th class="text-left white--text" width="1%" style="background-color:#898F99;">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr height="80" v-for="(row, index) in  Item.details" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ row.item_name ?? row.item.name }}
                </td>
                <td>
                  {{ row?.item_description ?? row?.item?.description }}
                </td>
                <td>{{ row.item_unit ?? row.item?.unit?.name }}</td>
                <td>
                  <v-text-field v-model="row.price" placeholder="Qty" type="number" outlined dense
                    hide-details></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="row.qty_usage" placeholder="Qty Usage" :change="qty()" type="number" outlined
                    dense hide-details></v-text-field>
                </td>
                <td>
                  <b>
                    {{
                      row.amount = (row.price * row.qty_usage) | currency("", 0, { symbolOnLeft: false })
                    }}
                  </b>
                </td>
                <td>
                  <v-icon color="error" @click="removeProduct(index)">mdi-delete
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <v-divider class="mx-4"></v-divider>
      <div v-if="Item.use_variant_size == 1">
        <v-row class="mx-1 mt-4">
          <base-column :lg="6" :md="4" :sm="12">
            <p> Variant Information </p>
          </base-column>
          <base-column :lg="6" :md="4" :sm="12">
            <v-btn color="primary" class="float-end" outlined small @click="openVariantSizeDialog()"><v-icon
                left>mdi-plus</v-icon> Add
              Variant </v-btn>
          </base-column>
        </v-row>
        <v-simple-table class="mx-2 mb-2 mt-4" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left white--text" width="1%" style="background-color:#898F99;">No</th>
                <th class="text-left white--text" width="10%" style="background-color:#898F99;">Variant Name</th>
                <th class="text-left white--text" width="10%" style="background-color:#898F99;">Description</th>
                <th class="text-left white--text" width="10%" style="background-color:#898F99;">Plus Price</th>
                <th class="text-left white--text" width="7%" style="background-color:#898F99;">Plus BOM Qty</th>
                <th class="text-left white--text" width="1%" style="background-color:#898F99;">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr height="80" v-for="(row, index) in  Item.details_variant_size" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ row.name ?? "N/A" }}</td>
                <td>{{ row.description ?? "N/A" }}</td>
                <td>{{ row.price ?? "0" }} </td>
                <td>{{ row.qty ?? "0" }}</td>
                <td>
                  <v-icon color="error" @click="removeVariantSize(index)">mdi-delete
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <v-divider class="mx-4"></v-divider>
      <div v-if="Item.use_variant_topping == 1">
        <v-row class="mx-1 mt-4">
          <base-column :lg="6" :md="4" :sm="12">
            <p>Variant Addtional Information </p>
          </base-column>
          <base-column :lg="6" :md="4" :sm="12">
            <v-btn color="primary" class="float-end" outlined small @click="openVariantToppingDialog()"><v-icon
                left>mdi-plus</v-icon> Add
                Addtional Varriant</v-btn>
          </base-column>
        </v-row>
        <v-simple-table class="mx-2 mb-2 mt-4" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left white--text" width="1%" style="background-color:#898F99;">No</th>
                <th class="text-left white--text" width="10%" style="background-color:#898F99;">Code</th>
                <th class="text-left white--text" width="10%" style="background-color:#898F99;">Name</th>
                <th class="text-left white--text" width="10%" style="background-color:#898F99;">Price</th>
                <th class="text-left white--text" width="1%" style="background-color:#898F99;">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr height="80" v-for="(row, index) in  Item.toppings" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ row.code ?? "N/A" }}</td>
                <td>{{ row.name ?? "N/A" }}</td>
                <td>{{ row.price | currency("", 0, { symbolOnLeft: false, }) }} </td>
                <td>
                  <v-icon color="error" @click="removeVariantTopping(index)">mdi-delete
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <br>
      <v-divider class="mx-4"></v-divider>
      <base-sub-title title="Price Information" />
      <v-row class="mx-1">
        <base-column :lg="3" :md="4" :sm="12">
          <v-text-field type="number" label="Price" outlined dense v-model="Item.price_sell"
            :rules="rule_price_sell"></v-text-field>
        </base-column>
        <base-column :lg="3" :md="4" :sm="12">
          <v-text-field type="number" label="Service" outlined dense v-model="Item.service" :rules="rule_service"
            append-icon="mdi-percent"></v-text-field>
        </base-column>
        <base-column :lg="3" :md="4" :sm="12">
          <v-text-field type="number" label="Ppn" outlined dense v-model="Item.ppn" :rules="rule_ppn"
            append-icon="mdi-percent"></v-text-field>
        </base-column>
        <base-column :lg="1" :md="4" :sm="12">
          <v-switch v-model="Item.use_service" class="mt-0" true-value="1" false-value="0" @input="useService()"  label="Service">
          </v-switch>
        </base-column>
        <base-column :lg="1" :md="4" :sm="12">
          <v-switch v-model="Item.use_auto" class="mt-0" true-value="1" false-value="0" @click="autoService()"  label="Auto Service">
          </v-switch>
        </base-column>
      </v-row>
      <v-row>
        <v-col lg="3">
          <v-simple-table>
            <tbody>
              <tr>
                <td class="text-left">
                  Price <br />
                  Service <br />
                  Ppn <br />
                  Total <br />
                </td>
                <td>
                  <b class="primary--text">{{ price_amount | currency("", 0, { symbolOnLeft: false }) }}</b>
                  <hr />
                  <b class="primary--text">{{ service_amount | currency("", 0, { symbolOnLeft: false }) }}</b>
                  <hr />
                  <b class="primary--text"> {{ ppn_amount | currency("", 0, { symbolOnLeft: false }) }}</b>
                  <hr />
                  <b class="primary--text"> {{ total | currency("", 0, { symbolOnLeft: false }) }}</b>
                  <hr />
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogMaterial" v-if="dialogMaterial" max-width="1200px" persistent><material-modal
          v-on:closeMaterialDialog="closeMaterialDialog" v-on:selectMaterial="selectMaterial" />
      </v-dialog>
      <v-dialog v-model="dialogVariantSize" v-if="dialogVariantSize" max-width="1200px" persistent>
        <add-variant-size-modal v-on:closeVariantSizeDialog="closeVariantSizeDialog" />
      </v-dialog>
      <v-dialog v-model="dialogVariantTopping" v-if="dialogVariantTopping" max-width="1200px" persistent>
        <variant-topping-modal v-on:closeVariantToppingDialog="closeVariantToppingDialog"
          v-on:selectVariantTopping="selectVariantTopping" />
      </v-dialog>
      <v-row class="mx-1">
        <v-btn small color="secondary" class="ma-2" @click="backForm()">
          <v-icon>mdi-arrow-left</v-icon> Back
        </v-btn>
        <v-btn small color="secondary" class="ma-2" @click="clearForm()">
          Clear
          <v-icon>mdi-close-circle-multiple </v-icon>
        </v-btn>
        <v-btn color="primary" small class="ma-2" @click.stop="store" :loading="btnLoading"
          :disabled="!form_valid || btnLoading">Save
          <v-icon right>mdi-content-save</v-icon></v-btn>
      </v-row>
    </v-form>
    <br>
  </div>
</template>

<script>
import MaterialModal from "@/components/modal/MaterialModal.vue";
import AddVariantSizeModal from "@/components/modal/AddVariantSizeModal.vue";
import VariantToppingModal from "@/components/modal/VariantToppingModal.vue";
import BaseColumn from "@/components/base/BaseColumn";
import BaseSubTitle from "@/components/base/BaseSubTitle";
import { mapState, mapMutations, mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "ProductForm",
  data() {
    return {
      btnLoading: false,
      form_valid: true,
      use_resep: 0,
      dialogMaterial: false,
      dialogVariantSize: false,
      dialogVariantTopping: false,
      group: [],
      subgroup: [],
      branch: [],
      unit: [],
      file: '',
    };
  },
  created() {
    this.getGroup();
    this.getSubgroup();
    this.getBranch();
    this.getUnit();
    this.getVat();
  },

  methods: {
    ...mapMutations("item", ["CLEAR_STATE", "CLEAR_DETAILS", "CLEAR_DETAILS_VARIANT_SIZE", "CLEAR_TOPPINGS", "SET_ITEM", "SET_DETAILS"]),

    openMaterialDialog() {
      this.dialogMaterial = true;
    },

    closeMaterialDialog() {
      this.dialogMaterial = false;
    },

    openVariantSizeDialog() {
      this.dialogVariantSize = true;
    },

    closeVariantSizeDialog() {
      this.dialogVariantSize = false;
    },

    openVariantToppingDialog() {
      this.dialogVariantTopping = true;
    },

    closeVariantToppingDialog() {
      this.dialogVariantTopping = false;
    },

    selectMaterial(val) {
      if (this.Item.details.find((item) => item.ingredient_id === val.id)) {
        this.Item.details.find((item) => item.ingredient_id === val.id).qty_usage++;
        return;
      }
      this.$store.commit("item/SET_DETAILS", val);
      this.dialogMaterial = false;
    },

    selectVariantTopping(val) {
      if (this.Item.toppings.find((item) => item.id === val.id)) {
        alert("Topping already exist");
        return;
      }
      this.$store.commit("item/SET_TOPPINGS", val);
      this.dialogVariantTopping = false;
    },

    removeProduct(index) {
      this.Item.details.splice(index, 1);
    },

    removeVariantSize(index) {
      this.Item.details_variant_size.splice(index, 1);
    },

    removeVariantTopping(index) {
      this.Item.toppings.splice(index, 1);
    },

    handleFileUpload() {
      this.file = this.$refs.file.files;
    },

    qty() {
      this.Item.price_buy = _.sumBy(this.Item.details, function (o) { return o.price * o.qty_usage; });
    },
   
    useService() {
      if (this.Item.use_service == 1) {
        this.Item.use_service = 1
        return this.Item.use_service = 1;
      } else {
        this.Item.use_service = 0
        return this.Item.use_service = 0;
      }
    },

    autoService(){
        if(this.Item.use_auto == 1){
          this.Item.service = 2;
          this.Item.ppn = 11;
        }else{
          this.Item.service = 0;
          this.Item.ppn = 0;
        }
    },

    useIngredient() {
      if (this.Item.use_ingredient == 1) {
        this.Item.use_ingredient = 1
        return this.Item.use_ingredient = 1;
      } else {
        this.Item.use_ingredient = 0
        return this.CLEAR_DETAILS();
      }
    },

    useVariantSize() {
      if (this.Item.use_variant_size == 1) {
        this.Item.use_variant_size = 1
        return this.Item.use_variant_size = 1;
      } else {
        this.Item.use_variant_size = 0
        return this.CLEAR_DETAILS_VARIANT_SIZE();
      }
    },

    useVariantTopping() {
      if (this.Item.use_variant_topping == 1) {
        this.Item.use_variant_topping = 1
        return this.Item.use_variant_topping = 1;
      } else {
        this.Item.use_variant_topping = 0
        return this.CLEAR_TOPPINGS();
      }
    },

    isActive() {
      if (this.Item.is_active == 1) {
        this.Item.is_active = 1
        return this.Item.is_active = 1;
      } else {
        this.Item.is_active = 0
        return this.Item.is_active = 0;
      }
    },

    store: async function () {
      if (this.$refs.form_data.validate()) {

        if (this.Item.use_ingredient == 1 && this.Item.details.length == 0) {
          alert("Please add material");
          return;
        }

        if (this.Item.use_variant_size == 1 && this.Item.details_variant_size.length == 0) {
          alert("Please add variant size");
          return;
        }

        if (this.Item.use_variant_topping == 1 && this.Item.toppings.length == 0) {
          alert("Please add variant topping");
          return;
        }

        let toppings = this.Item.toppings.map((item) => {
          return item.id
        })
        this.Item.toppings = toppings
        this.Item.price_amount = parseInt(this.Item.price_sell);
        this.Item.service_amount = parseInt(this.Item.price_sell) * parseInt(this.Item.service) / 100;
        this.Item.ppn_percent = parseInt(this.price_amount) + parseInt(this.service_amount);
        if (this.Item.use_service == 1) {
          this.Item.ppn_amount = this.ppn_percent * this.Item.ppn / 100;
        }
        else {
          this.Item.ppn_amount = this.price_amount * this.Item.ppn / 100;
        }
        if (this.Item.use_service == 1) {
          this.Item.total = parseInt(this.price_amount) + parseInt(this.service_amount) + parseInt(this.ppn_amount);
        }
        else {
          this.Item.total = parseInt(this.price_amount) + parseInt(this.ppn_amount) + parseInt(this.service_amount);
        }

        if (this.Item.ppn != 0 ) {
          this.Item.use_ppn = 1
        }else {
          this.Item.use_ppn = 0
        }

        if (this.Item.use_auto == 1 ) {
          this.Item.use_auto = 1
        }else {
          this.Item.use_auto = 0
        }

        // if (this.Item.service != 0 ) {
        //   this.Item.use_service = 1
        // }else {
        //   this.Item.use_service = 0
        // }

        let formData = new FormData();
        formData.append('file', this.file);
        this.image = this.Item.image;
        this.btnLoading = true;
        await this.$axios
          .post("/master/item", this.Item,
            {
              headers: { 
                Authorization: this.$store.getters["auth/Token"],
                'Content-Type': 'multipart/form-data'
              },
            })
          .then(() => {
            this.CLEAR_STATE();
            this.btnLoading = false;
            this.$router.push({ name: "item.tabs-list" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },

    changeItem(event) {
      this.Item.group_id = event;
      this.getSubgroup();
    },

    async getGroup() {
      await this.$axios("/master/group?active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.group = data.group.data;
      });
    },

    async getSubgroup() {
      await this.$axios("/master/subgroup?active=1&group_id=" + this.Item.group_id, {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.subgroup = data.subgroup.data;
      });
    },

    async getBranch() {
      await this.$axios("/master/branches?active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.branch = data.branch.data;
      });
    },

    async getVat() {
      await this.$axios("/master/vat?active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        if (data.vat.data.length > 0) {
          this.vat = data.vat.data[0].percentage
        }else {
          this.vat = 0
        }
      });
    },

    async getUnit() {
      await this.$axios("/master/unit?active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.unit = data.unit.data;
      });
    },


    clearForm() {
      this.CLEAR_STATE();
    },

    backForm() {
      this.CLEAR_STATE();
      this.$router.push({ name: "item.tabs-list" });
    },

  },

  computed: {
    ...mapState("item", {
      Item: (state) => state.item,
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    Item() {
      return this.$store.getters["item/Item"];
    },

    price_amount() {
      return parseInt(this.Item.price_sell);
    },

    service_amount() {
      return parseInt(this.Item.price_sell) * parseInt(this.Item.service) / 100;
    },

    ppn_percent() {
      return parseInt(this.price_amount) + parseInt(this.service_amount);
    },

    ppn_amount() {
      if (this.Item.use_service == 1) {
        return this.ppn_percent * this.Item.ppn / 100;
      }
      else {
        return this.price_amount * this.Item.ppn / 100;
      }
    },

    total() {
      if (this.Item.use_service == 1) {
        return parseInt(this.price_amount) + parseInt(this.service_amount) + parseInt(this.ppn_amount);
      }
      else {
        return parseInt(this.price_amount) + parseInt(this.ppn_amount) + parseInt(this.service_amount);
      }
    },
  },

  components: {
    BaseColumn,
    BaseSubTitle,
    "material-modal": MaterialModal,
    "add-variant-size-modal": AddVariantSizeModal,
    "variant-topping-modal": VariantToppingModal,
  },
};
</script>

<style lang="scss">
.dropbox {
  outline: 2px dashed grey;
  /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.dropbox1 {
  outline: 1px dashed grey;
  /* the dash box */
  outline-offset: -10px;
  color: dimgray;
  border-radius: 15px;
  padding: 10px 10px;
  position: relative;
  cursor: pointer;
  width: 250px;
  height: 250px;
}

.input-file {
  opacity: 0;
  /* invisible but it's there! */
  width: 10%;
  height: 10px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
  /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}</style>