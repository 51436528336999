const state = () => ({
    variants: {},
    variant: {
        id: "",
        name: "",
        item_id: "",
        branch_id: "",
        description: "",
        image: "",
        price: "",
        stock: "",
        weight: "",
        length: "",
        width: "",
        height: "",
        deleted_at: "",
    },
    page: 1,

})

const mutations = {
    SET_VARIANTS(state, payload) {
        state.variants = payload;
    },

    SET_VARIANT(state, payload) {
        state.variant = {
            id: payload.id,
            name: payload.name,
            item_id: payload.item_id,
            branch_id: payload.branch_id,
            description: payload.description,
            image: payload.image,
            price: payload.price,
            stock: payload.stock,
            weight: payload.weight,
            length: payload.length,
            width: payload.width,
            height: payload.height,
            deleted_at: payload.deleted_at,
        };
    },

    CLEAR_VARIANTS(state) {
        state.variants = {
            id: null,
            name: null,
            item_id: null,
            branch_id: null,
            description: null,
            image: null,
            price: null,
            stock: null,
            weight: null,
            length: null,
            width: null,
            height: null,
            deleted_at: null,
        };
    },

    SET_CURRENT_PAGE(state, payload) {
        state.variants.current_page = payload;
    },

}

const getters = {
    Variants: state => state.variants,
    Variant: state => state.variant,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}




