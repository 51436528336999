<template>
  <div>
    <base-title title="Create Employee" subtitle="Please fill in the employee information in the form below" />
    <!-- Form -->
    <v-form ref="form_data" v-model="form_valid" lazy-validation>
      <v-row class="mx-1 mt-2">
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Name" v-model="form_data.name" :rules="rule_name" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Email" v-model="form_data.email" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Phone Number" v-model="form_data.phone" outlined dense></v-text-field>
        </base-column>
      </v-row>
      <v-row class="mx-1 mt-2">
        <base-column :lg="4" :md="6" :sm="12">
          <v-file-input label="Image" outlined dense v-model="form_data.image" id="file" ref="file"
            v-on:change="handleFileUpload()"></v-file-input>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-textarea label="Address" rows="3" outlined dense v-model="form_data.address"></v-textarea>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-switch dense v-model="form_data.is_active" label="USE"> </v-switch>
        </base-column>
      </v-row>
      <v-row>
        <v-container>
          <back-button />
          <v-btn color="primary" small class="ma-1" @click.stop="store" :loading="btnLoading"
            :disabled="!form_valid || btnLoading">Save <v-icon right>mdi-content-save</v-icon></v-btn>
        </v-container>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BackButton from "@/components/base/BackButton.vue";
import BaseTitle from "@/components/base/BaseTitle.vue";
import BaseColumn from "@/components/base/BaseColumn.vue";
export default {
  components: { BaseTitle, BaseColumn, BackButton },
  name: "ImageCreate",
  data() {
    return {
      btnLoading: false,
      form_valid: true,
      branch: [],

      form_data: {
        name: "",
        image: null,
        phone: "",
        email: "",
        address: "",
        is_active: true,
      },
    };
  },
  created() {
    this.getBranch();
  },

  methods: {
    store: async function () {
      if (this.$refs.form_data.validate()) {
        this.btnLoading = true;
        let formData = new FormData();
        formData.append('file', this.file);
        this.image = this.file;
        if(this.form_data.is_active == true){
          this.form_data.is_active = 1;
        }else {
          this.form_data.is_active = 0;
        }
        await this.$axios
          .post(
            "/master/employees",
            {
              name: this.form_data.name,
              email: this.form_data.email,
              phone: this.form_data.phone,
              address: this.form_data.address,
              image: this.form_data.image ?? null,
              is_active: this.form_data.is_active,
            },
            {
              headers: {
                Authorization: this.$store.getters["auth/Token"],
                'Content-Type': "multipart/form-data",
              },
            }
          )
          .then(() => {
            this.btnLoading = false;
            this.$router.push({ name: "employee.index" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },

    handleFileUpload() {
      this.file = this.$refs.file.files;
    },

    async getBranch() {
      await this.$axios("/master/branches?active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.branch = data.branch.data;
      });
    },
  },

  computed: {
    ...mapGetters("auth", {
      ROLE: "Role",
    }),
  },
};
</script>