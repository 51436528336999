<template>
    <v-card>
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title> Add Variant Size </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="closeVariantSizeDialog" color="error" outlined small>
                <v-icon>mdi-close</v-icon></v-btn>
        </v-app-bar>
        <v-divider class="mx-2"></v-divider>
        <v-form ref="form_data" v-model="form_valid" lazy-validation>
            <v-row class="mx-1 mt-2">
                <v-col sm="12" md="4" lg="4">
                    <v-text-field v-model="form_data.name" label="Variant Name" outlined dense></v-text-field>
                </v-col>
                <v-col sm="12" md="4" lg="4">
                    <v-text-field type="number" v-model="form_data.price" label="Price" outlined dense></v-text-field>
                </v-col>
                <v-col sm="12" md="4" lg="4">
                    <v-text-field type="number" v-model="form_data.qty" label="Qty" outlined dense></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mx-1 mt-2">
                <v-col sm="12" md="4" lg="4">
                    <v-textarea v-model="form_data.description" rows="2" label="Description" outlined dense></v-textarea>
                </v-col>
            </v-row>
            <v-row class="mx-1">
                <v-col sm="12" md="12" lg="12">
                    <v-btn color="primary" small class="float-right mx-2" @click.stop="save" :loading="btnLoading"
                        :disabled="!form_valid || btnLoading">Save
                        <v-icon right>mdi-content-save</v-icon></v-btn>
                    <v-btn class="float-right mb-2 white--text" color="danger" small @click.stop="close()">Cancel</v-btn>
                </v-col>
            </v-row>
        </v-form>

    </v-card>
</template>
  
<script>
// import { mapGetters } from "vuex";
export default {
    // mixins: [datatableMixin],
    data() {
        return {
            btnLoading: false,
            form_valid: true,
            form_data: {
                name: "",
                description: "",
                price: 0,
                qty: 0,
            },
        };
    },
    methods: {
        save: async function () {
            this.$store.commit("item/SET_DETAILS_VARIANT_SIZE", this.form_data);
            this.$emit("closeVariantSizeDialog")
        },

        close() {
            this.$store.commit("item/CLEAR_DETAILS_VARIANT_SIZE", {});
            this.$emit("closeVariantSizeDialog");
        },
        closeVariantSizeDialog() {
            this.$emit("closeVariantSizeDialog");
        },
    },
};
</script>