<template>
    <div>
        <br>
        <v-data-table :loading="loading" :headers="headers" :items="Variants.data" class="elevation-1" hide-default-footer>
            <template v-slot:no-data> No data </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon color="danger" class="mr-2" @click.stop="deleteVariant(item.id)">
                    mdi-trash-can
                </v-icon>
                <v-icon color="warning" class="mr-2" @click.stop="edit(item.id)">
                    mdi-pencil-box-outline
                </v-icon>
            </template>
            <template v-slot:[`item.price`]="{ item }">
                <b>{{ item.price | currency("", 0, { symbolOnLeft: false, }) }}</b>
            </template>
            <template v-slot:top>
                <v-row dense class="mx-2">
                    <base-column :lg="4" :md="4" :sm="12">
                        <v-text-field v-model="search.search" append-icon="mdi-magnify" label="Search" outlined dense
                            hide-details></v-text-field>
                    </base-column>
                    <base-column :lg="8" :md="8" :sm="12">
                        <v-btn small color="darkblue" class="white--text" @click.stop="searching">Submit <v-icon
                                right>mdi-magnify</v-icon></v-btn>
                        <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
                            <v-icon right>mdi-close-circle-multiple </v-icon>
                        </v-btn>
                        <v-btn to="/master/item/tabs" small elevation="1" color="primary"
                            class="white--text float-end mx-2" @click="loader = 'loading3'">
                            Create
                            <v-icon right dark>mdi-plus-circle </v-icon>
                        </v-btn>
                    </base-column>
                </v-row>
                <v-spacer></v-spacer>
            </template>
        </v-data-table>
        <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
    </div>
</template>
  
  
<script>
import BaseColumn from "@/components/base/BaseColumn";
import { mapGetters, mapMutations } from "vuex";
export default {
    name: "ItemList",
    data() {
        return {
            headers: [
                { text: "Code", value: "code" },
                { text: "Name", value: "name" },
                { text: "Price", value: "price" },
                { text: "Action", value: "actions", sortable: false, width: 100 },
            ],
            page: 1,
            disabled: false,
            loading: false,
            status: ["Active", "Inactive"],
            search: {
                status: null,
                search: null,
                branch_id: null,
            },
            branch: [],
        };
    },

    created() {
        this.getVariant();
        this.getBranch();
    },
    methods: {
        ...mapMutations("master_variant", ["CLEAR_STATE", "SET_VARIANTS", "SET_VARIANT", "SET_CURRENT_PAGE"]),
        async getVariant() {
            this.loading = true;
            this.disabled = true;
            let params = {
                page: this.page,
                status: this.search.status,
                search: this.search.search,
                branch_id: this.search.branch_id,
            };

            await this.$axios
                .get("/master/toppings", { 
                    params: params,
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.$store.commit("master_variant/SET_VARIANTS", data.toppings);
                    this.loading = false;
                    this.disabled = false;
                });
        },

        async getBranch() {
            await this.$axios
                .get("/master/branches", {
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.branch = data.branch.data;
                });
        },

        searching() {
            this.page = 1;
            this.getVariant();
        },
        clear() {
            this.page = 1;
            this.search = {
                status: "",
                search: "",
                branch_id: "",
            };
            this.getVariant();
        },

        async deleteVariant(id) {
            await this.$axios
                .delete("/master/toppings/" + id, {
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(() => {
                    this.getVariant();
                    this.$router.go();
                });
        },

        async edit(id) {
            this.loading = true;
            await this.$axios
                .get("/master/toppings/" + id, {
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.SET_VARIANT(data.toppings);
                    this.loading = false;
                    this.$router.push("/item-variant/edit");
                })
                .catch(() => {
                    this.loading = true;
                });
        },
    },
    watch: {
        currentPage(newValue) {
            this.page = newValue;
            this.getVariant();
        },
    },
    computed: {
        ...mapGetters("master_variant", {
            Variants: "Variants",
        }),

        ...mapGetters("auth", {
            ROLE: "Role",
        }),

        currentPage: {
            get() {
                return this.Variants.current_page;
            },
            set(val) {
                return this.$store.commit("master_variant/SET_CURRENT_PAGE", val);
            },
        },
        lastPage: {
            get() {
                return this.Variants.last_page;
            },
        },
    },

    components: {
        BaseColumn,
    },
};
</script>