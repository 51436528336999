<template>
  <div>
    <base-title title="Create Service" subtitle="Please fill in the Service information in the form below" />
    <v-form ref="form_data" v-model="form_valid" lazy-validation>
      <v-row class="mx-1 mt-2">
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Type Name" v-model="Service.type" :rules="rule_type" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field type="number" label="Percentage" v-model="Service.percentage" :rules="rule_disc" outlined dense></v-text-field>
        </base-column>
         <base-column :lg="2" :md="2" :sm="12">
          <v-switch label="Is Active" v-model="Service.is_active" class="ma-1" dense></v-switch>
        </base-column>
        <base-column :lg="2" :md="2" :sm="12">
          <v-switch label="Use Default" v-model="Service.use_default" class="ma-1" dense></v-switch>
        </base-column>
      </v-row>
      <v-row class="mx-1">
        <back-button />
        <v-btn color="primary" small class="ma-1 mt-2" @click.stop="store" :loading="btnLoading"
          :disabled="!form_valid || btnLoading" >Save <v-icon right>mdi-content-save</v-icon></v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import BackButton from "@/components/base/BackButton.vue";
import BaseTitle from "@/components/base/BaseTitle.vue";
import BaseColumn from "@/components/base/BaseColumn.vue";
import { mapGetters } from "vuex";
export default {
  components: { BaseTitle, BaseColumn, BackButton },
  name: "ServiceCreate",
  data() {
    return {
      btnLoading: false,
      form_valid: true,
      branch: [],
    };
  },
  created() {
    this.getBranch();
  },
  methods: {
    store: async function () {
      if (this.$refs.form_data.validate()) {
        this.btnLoading = true;
        await this.$axios
          .post(
            "/master/service",
            {
              headers: {
                Authorization: this.TOKEN,
              },
            }
          )
          .then(() => {
            this.btnLoading = false;
            this.$router.push({ name: "service.index" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },

    async getBranch() {
      await this.$axios("/master/branches?active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.branch = data.branch.data;
      });
    },

  },

  computed: {
    ...mapGetters("service", {
      Service: "Service",
    }),

    ...mapGetters("auth", {
      TOKEN: "Token",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),
  },
};
</script>