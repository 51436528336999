<template>
  <div>
    <br>
    <v-form ref="Item" v-model="form_valid" lazy-validation>
      <v-row class="mx-1">
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Item Name" outlined dense v-model="ItemIngredient.name" :rules="rule_name"></v-text-field>
        </base-column>

        <base-column :lg="4" :md="4" :sm="12">
          <v-select label="Unit" outlined dense v-model="ItemIngredient.unit_id" :items="unit" item-text="name"
            item-value="id" :rules="rule_unit"></v-select>
        </base-column>

        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field type="number" label="Price" outlined dense v-model="ItemIngredient.price"></v-text-field>
        </base-column>
      </v-row>
      <v-row class="mx-1">
        <base-column :lg="4" :md="4" :sm="12">
          <v-textarea rows="2" label="Description" outlined dense v-model="ItemIngredient.description"></v-textarea>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12" v-if="(ROLE == 'Owner')">
          <v-select label="Branch" :items="branch" v-model="ItemIngredient.branch_id" dense item-text="name"
            item-value="id" outlined></v-select>
        </base-column>
      </v-row>
      <v-row class="mx-1">
        <v-btn small color="secondary" class="ma-2" @click="backForm()">
          <v-icon>mdi-arrow-left</v-icon> Back
        </v-btn>
        <v-btn small color="secondary" class="ma-2" @click="clearForm()">
          Clear
          <v-icon>mdi-close-circle-multiple </v-icon>
        </v-btn>
        <v-btn color="primary" small class="ma-2" @click.stop="store" :loading="btnLoading"
          :disabled="!form_valid || btnLoading">Save
          <v-icon right>mdi-content-save</v-icon></v-btn>
      </v-row>
    </v-form>
    <br>
  </div>
</template>

<script>
import BaseColumn from "@/components/base/BaseColumn";
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "ProductForm",
  data() {
    return {
      btnLoading: false,
      form_valid: true,
      item_type: [],
      item: [],
      branch: [],
      unit: [],

    };
  },
  created() {
    this.getItem();
    this.getBranch();
    this.getUnit();
    this.getItemType();
  },

  methods: {
    ...mapMutations("item_ingredient", ["CLEAR_STATE", "SET_ITEM_INGREDIENT"]),
    itemType() {
      if (this.ItemIngredient.item_type_id == "2") {
        this.$router.push("/master/item/form");

      } else {
        this.$router.push("/master/item-ingredient/form");
      }
    },

    store: async function () {
      if (this.$refs.Item.validate()) {
        this.btnLoading = true;
        await this.$axios
          .post("/master/ingredients", this.ItemIngredient,
            {
              headers: { Authorization: this.$store.getters["auth/Token"] },
            })
          .then(() => {
            this.btnLoading = false;
            this.CLEAR_STATE();
            this.$router.push({ name: "item_ingredient.index" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },

    async getItemType() {
      await this.$axios("/master/item-type", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.item_type = data.data.item_type.data;
      });

    },

    async getItem() {
      this.loading = true;
      await this.$axios("/master/item?is_active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.item = data.item.data;
        this.loading = false;
        this.disabled = false;
      });
    },

    async getBranch() {
      await this.$axios("/master/branches?active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.branch = data.branch.data;
      });
    },

    async getUnit() {
      await this.$axios("/master/unit?active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.unit = data.unit.data;
      });
    },


    clearForm() {
      this.CLEAR_STATE();
    },

    backForm() {
      this.CLEAR_STATE();
      this.$router.push({ name: "item_ingredient.index" });
    },

  },

  computed: {
    ...mapState("item_ingredient", {
      ItemIngredient: (state) => state.item_ingredient,
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),

  },

  components: {
    BaseColumn,
  },
};
</script>