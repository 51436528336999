<template>
  <v-card>
    <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-toolbar-title> Purchase Order Invoice List </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="closePurchaseInvoiceDialog" color="error" outlined small>
        <v-icon>mdi-close</v-icon></v-btn>
    </v-app-bar>
    <v-row class="mx-1 mt-2 mb-1">
      <base-column :lg="2" :md="2" :sm="6" :xs="12">
        <v-text-field type="date" label="From date" outlined dense v-model="search.from_date">
        </v-text-field>
      </base-column>
      <base-column :lg="2" :md="2" :sm="6" :xs="12">
        <v-text-field type="date" label="End date" outlined dense v-model="search.end_date">
        </v-text-field>
      </base-column>

      <base-column :lg="2" :md="2" :sm="6" :xs="12">
        <v-select :items="status" outlined dense attach chips label="Status" v-model="search.status"></v-select>
      </base-column>

      <base-column :lg="4" :md="2" :sm="6" :xs="12">
        <v-text-field type="text" label="Search" outlined dense v-model="search.search">
        </v-text-field>
      </base-column>
      <v-btn small color="darkblue" class="white--text float-end mx-1" @click.stop="getPurchaseOrder">Submit
        <v-icon right>mdi-magnify</v-icon></v-btn>
      <v-btn small color="secondary" class="white--text float-end" @click.stop="clear">Clear
        <v-icon right>mdi-close-circle-multiple </v-icon>
      </v-btn>
    </v-row>
    <v-data-table :loading="loading" :headers="headers" :items="PURCHASE_ORDERS.data" class="elevation-1"
      hide-default-footer>
      <template v-slot:[`item.status_invoice`]="{ item }">
        <v-chip color="success" small v-if="item.status_invoice == 'DONE'">
          DONE
        </v-chip>
        <v-chip color="secondary" small v-if="item.status_invoice == 'PARTIAL'">
          PARTIAL
        </v-chip>
        <v-chip color="primary" small v-else-if="item.status_invoice == 'ONPROGRESS'">
          ON PROGRESS
        </v-chip>
        <v-chip color="error" small v-else-if="item.status_invoice == 'CANCEL'">
          CANCEL
        </v-chip>
      </template>
      <template v-slot:[`item.grand_total`]="{ item }">
        <b>{{ item.grand_total | currency("", 0, { symbolOnLeft: false, }) }}</b>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="primary" outlined small @click.stop="selectPurchase(item)">Select <v-icon right> mdi-check
          </v-icon></v-btn>
      </template>
    </v-data-table>
    <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import BaseColumn from "@/components/base/BaseColumn.vue";
export default {
  name: "PurchaseModal",

  created() {
    this.getPurchaseOrder();
  },

  data() {
    return {
      headers: [
        { text: "Purchase Order No", value: "no_po" },
        { text: "Supplier", value: "supplier.name" },
        { text: "Order Date", value: "purchase_date" },
        { text: "Total Amount", value: "grand_total" },
        { text: "Status", value: "status_invoice" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      page: 1,
      disabled: false,
      loading: false,
      status: ["PROCESS", "FINISH", "CANCEL"],
      search: {
        from_date: new Date().toJSON().slice(0, 10),
        end_date: new Date().toJSON().slice(0, 10),
        keyword: null,
      },
    };
  },

  props: {
    statusPurchase: {
      type: String,
      default: "PROCESS",
    }
  },

  methods: {
    async getPurchaseOrder() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        status: this.search.status,
        keyword: this.search.keyword,
        from_date: this.search.from_date,
        end_date: this.search.end_date,
      };

      await this.$axios
        .get("/transaction/purchase-invoice-invoiced", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit(
            "purchase_order/SET_PURCHASE_ORDERS",
            data.purchase_invoice
          );
          this.loading = false;
          this.disabled = false;
        });
    },

    closePurchaseInvoiceDialog() {
      this.$emit("closePurchaseInvoiceDialog");
    },

    selectPurchase(item) {
      this.$emit("selectPurchase", item);
      this.closePurchaseInvoiceDialog();
    },
    searching() {
      this.page = 1;
      this.getPurchaseOrder();
    },
    clear() {
      this.page = 1;
      this.search = {
        status: "",
        search: "",
      };
      this.getPurchaseOrder();
    },
  },

  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.getPurchaseOrder();
    },
  },
  computed: {
    ...mapGetters("purchase_order", {
      PURCHASE_ORDERS: "PurchaseOrders",
    }),

    currentPage: {
      get() {
        return this.PURCHASE_ORDERS.current_page;
      },
      set(val) {
        return this.$store.commit("purchase_order/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.PURCHASE_ORDERS.last_page;
      },
    },
  },

  components: {
    BaseColumn,
  },
};
</script>