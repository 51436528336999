<template>
  <div>
    <v-row class="mt-1 mx-1">
      <base-column :lg="3" :md="6" :sm="12">
        <v-text-field v-model="SalesOrder.no_so" readonly placeholder="Sales Order Number" outlined dense
          :append-icon="'mdi-magnify'" hide-details @click:append="openSalesOrderDialog()"></v-text-field>
      </base-column>
    </v-row>
    <v-divider class="mx-4 mt-5"></v-divider>
    <v-row class="mt-3 mx-1">
      <base-column :lg="3" :md="6" :sm="12">
        <v-card max-width="100%" height="95%">
          <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title>
              <v-icon>mdi-account</v-icon>
              {{ SalesOrder.customer_name ?? "CUSTOMER" }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn outlined small color="primary" @click="openCustomerDialog">
              SEARCH
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-app-bar>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>Email : {{ SalesOrder.customer_email }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Phone : {{ SalesOrder.customer_phone }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Address : {{ SalesOrder.customer_address }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </base-column>
      <base-column :lg="3" :md="6" :sm="12">
        <v-card max-width="100%" height="95%">
          <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title>
              <v-icon>mdi-account</v-icon>
              {{ SalesOrder.name_account ?? "BANK ACCOUNT" }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn outlined small color="primary" @click="openBankAccountDialog">
              SEARCH
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-app-bar>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>Account Number : {{ SalesOrder.number_account }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Bank Name : {{ SalesOrder.bank_name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </base-column>
    </v-row>
    <v-divider class="mx-4 mt-5"></v-divider>
    <v-row class="mt-3 mx-1">
      <base-column :lg="3" :md="6" :sm="12">
        <v-select label="SO Status" outlined dense v-model="SalesOrder.so_status" :items="status"></v-select>
      </base-column>
      <base-column :lg="3" :md="6" :sm="12">
        <v-text-field type="date" label="Order Date" outlined dense v-model="SalesOrder.order_date"
          :rules="rule_order_date"></v-text-field>
      </base-column>
      <base-column :lg="3" :md="6" :sm="12">
        <v-text-field type="date" label="Delivery Date" outlined dense v-model="SalesOrder.delivery_date"
          :rules="rule_delivery_date"></v-text-field>
      </base-column>
      <base-column :lg="3" :md="6" :sm="12">
        <v-select label="Payment Method" outlined dense v-model="SalesOrder.payment_method_id"
          :items="payment_method.data" item-text="name" item-value="id" :rules="rule_payment_method"></v-select>
      </base-column>
    </v-row>
    <v-row class="mt-3 mx-1">
      <base-column :lg="3" :md="6" :sm="12">
        <v-text-field :rules="rule_disc" type="number" outlined dense label="Discount" append-icon="mdi-percent"
          v-model="SalesOrder.discount_percent"></v-text-field>
      </base-column>
      <base-column :lg="3" :md="6" :sm="12">
        <v-select append-icon="mdi-percent" label="PPH" :items="PPHS.data" item-text="percentage" item-value="id"
          v-model="SalesOrder.pph_id" outlined dense @change="changePPH"></v-select>
      </base-column>
      <base-column :lg="1" :md="6" :sm="12">
        <v-switch label="Use VAT" v-model="SalesOrder.use_vat" class="mt-2"></v-switch>
      </base-column>
      <base-column :lg="3" :md="6" :sm="12">
        <v-select :items="branch.data" item-text="name" item-value="id" outlined dense label="Branch"
          v-model="SalesOrder.branch_id" v-if="(ROLE == 'Owner')"></v-select>
      </base-column>
    </v-row>
    <v-divider class="mx-4"></v-divider>
    <v-dialog v-if="dialogCustomer" v-model="dialogCustomer" max-width="1200px" persistent>
      <customer-modal v-on:closeCustomerDialog="closeCustomerDialog" v-on:selectCustomer="selectCustomer" />
    </v-dialog>
    <v-dialog v-if="dialogBankAccount" v-model="dialogBankAccount" max-width="1200px" persistent>
      <bank-account-modal v-on:closeBankAccountDialog="closeBankAccountDialog"
        v-on:selectBankAccount="selectBankAccount" />
    </v-dialog>
    <v-dialog v-if="dialogSalesOrder" v-model="dialogSalesOrder" max-width="1200px" persistent>
      <sales-order-modal v-on:closeSalesOrderDialog="closeSalesOrderDialog" v-on:selectSalesOrder="selectSalesOrder" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import BaseColumn from "@/components/base/BaseColumn.vue";
import CustomerModal from '@/components/modal/CustomerModal.vue';
import BankAccountModal from '@/components/modal/BankAccountModal.vue';
import SalesOrderModal from "@/components/modal/SalesOrderModal.vue";

export default {
  name: "SalesOrderForm",
  data() {
    return {
      dialogCustomer: false,
      dialogBankAccount: false,
      dialogSalesOrder: false,
      payment_method: [],
      branch: [],
      status: ["PROCESS", "COMPLETED", "CANCELED", "INVOICED", "PENDING"],
    };
  },
  created() {
    this.getPph();
    this.getPaymentMethod();
    this.getBranch();
  },
  methods: {
    ...mapActions("pph", ["setPph"]),
    ...mapMutations("sales_order", [
      "SET_SALES_ORDER",
      "SET_CUSTOMER",
      "SET_BANK_ACCOUNT",
    ]),

    changePPH(val) {
      console.log(val);
      this.$axios
        .get("/master/pph/" + val, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.setPph(data.pph);
          this.SalesOrder.pph_percentage = data.pph.percentage;
        });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch;
        });
    },

    async getPph() {
      await this.$axios("/master/pph", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.$store.commit("pph/SET_PPHS", data.pph);
      });
    },

    async getPaymentMethod() {
      await this.$axios("/master/payment-method", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.payment_method = data.payment_method;
      });
    },

    openCustomerDialog() {
      this.dialogCustomer = true;
    },

    closeCustomerDialog() {
      this.dialogCustomer = false;
    },

    openSalesOrderDialog() {
      this.dialogSalesOrder = true;
    },
    closeSalesOrderDialog() {
      this.dialogSalesOrder = false;
    },

    selectSalesOrder(sales_order) {
      this.$axios
        .get("/transaction/sales-orders/" + sales_order.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          console.log(data);
          this.$store.commit("sales_order/CLEAR_STATE");
          this.$store.commit("sales_order/SET_SO", data.sales_orders);

        })
        .catch(() => {
          this.loading = true;
        });
    },

    selectCustomer(val) {
      this.SET_CUSTOMER({
        id: val.id,
        name: val.name,
        phone: val.phone,
        email: val.email,
        address: val.address,
      });
      this.dialogCustomer = false;
    },

    openBankAccountDialog() {
      this.dialogBankAccount = true;
    },

    closeBankAccountDialog() {
      this.dialogBankAccount = false;
    },

    selectBankAccount(val) {
      this.SET_BANK_ACCOUNT({
        id: val.id,
        name_account: val.name_account,
        number_account: val.number_account,
        bank_name: val.bank_name,
      });
      this.dialogBankAccount = false;
    },

  },

  computed: {
    ...mapState("sales_order", {
      SalesOrder: (state) => state.sales_order,
    }),

    ...mapGetters("pph", {
      PPHS: "Pphs",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),

  },
  components: {
    BaseColumn,
    CustomerModal,
    BankAccountModal,
    "sales-order-modal": SalesOrderModal,
  },
};
</script>