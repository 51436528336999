<template>
  <div>
    <v-row class="mx-1 mt-2">
      <v-col :lg="4" :md="4" :sm="12">
        <v-text-field v-model="search.search" append-icon="mdi-magnify" label="Search" outlined dense
          hide-details></v-text-field>
      </v-col>
      <v-col :lg="4" :md="4" :sm="12" v-if="(ROLE == 'Owner')">
        <v-select v-model="search.branch_id" label="Branch" :items="branch" item-text="name" item-value="id" outlined
          dense hide-details></v-select>
      </v-col>
      <v-col :lg="4" :md="4" :sm="12">
        <v-btn small color="darkblue" class="white--text" @click.stop="searching">Submit <v-icon
            right>mdi-magnify</v-icon></v-btn>
        <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
          <v-icon right>mdi-close-circle-multiple </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mx-1 mt-2">
      <v-col lg="12" md="12" sm="12">
        <v-data-table :loading="loading" :headers="headers" :items="CUSTOMERS.data" class="elevation-1"
          hide-default-footer>
          <template v-slot:[`item.is_active`]="{ item }">
            <v-icon v-if="item.is_active == 1" color="success">
              mdi-check-circle
            </v-icon>
            <v-icon v-else color="error"> mdi-close-circle </v-icon>
          </template>
          <template v-slot:[`item.membership`]="{ item }">
            <span v-if="item.membership == 1" color="success">Yes</span>
            <span v-else color="success">No</span>
          </template>
          <template v-slot:no-data> No data </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="warning" class="mr-2" @click.stop="edit(item)">
              mdi-pencil-box-outline
            </v-icon>
          </template>
        </v-data-table>
        <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "CustomerList",
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Branch", value: "branch.name" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Membership", value: "membership" },
        { text: "Status", value: "is_active" },
        { text: "Action", value: "actions", sortable: false },
      ],
      page: 1,
      disabled: false,
      loading: false,
      status: ["Active", "Inactive"],
      branch: [],
      search: {
        search: null,
        branch_id: null,
      },
    };
  },

  created() {
    this.getBranch();
    this.getCustomer();
  },
  methods: {
    ...mapMutations("customer", ["SET_CUSTOMERS", "CLEAR_CUSTOMERS"]),
    async getCustomer() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        search: this.search.search,
        branch_id: this.search.branch_id,
      };

      await this.$axios
        .get("/master/customers", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_CUSTOMERS(data.customers);
          this.loading = false;
          this.disabled = false;
        });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch.data;
        });
    },
    searching() {
      this.page = 1;
      this.getCustomer();
    },
    clear() {
      this.page = 1;
      this.search = {
        search: "",
        branch_id: null,
      };
      this.getCustomer();
    },

    edit(item) {
      this.$router.push("/master/customers/edit/" + item.id);
    },
  },
  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.getCustomer();
    },
  },
  computed: {
    ...mapGetters("customer", {
      CUSTOMERS: "Customers",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    currentPage: {
      get() {
        return this.CUSTOMERS.current_page;
      },
      set(val) {
        return this.$store.commit("customer/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.CUSTOMERS.last_page;
      },
    },
  },

  destroyed() {
    this.CLEAR_CUSTOMERS();
  },
};
</script>
