<template>
  <main-layout>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64" color="amber" />
    </v-overlay>
    <base-header>
      <template v-slot:icon> mdi-view-dashboard </template>
      <template v-slot:name> Dashboard </template>
    </base-header>
    <v-card class="mx-1">
      <v-row class="mx-2">
        <v-col xs="12" sm="12" md="4" lg="3">
          <v-text-field type="date" small outlined dense v-model="search.start_date"></v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="4" lg="3">
          <v-text-field type="date" small outlined dense v-model="search.end_date"></v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="4" lg="3" v-if="(ROLE == 'Owner')">
          <v-select :items="branch.data" v-model="search.branch_id" item-text="name" item-value="id" outlined dense
            label="Branch"></v-select>
        </v-col>
        <v-col xs="12" sm="12" md="4" lg="3">
          <v-btn color="darkblue" class="white--text" small @click.stop="getInit">Submit <v-icon
              right>mdi-magnify</v-icon></v-btn>
          <v-btn color="secondary" class="white--text mx-1" small @click.stop="clear">Clear
            <v-icon right>mdi-close-circle-multiple </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-2 mt-0">
        <v-col xs="12" sm="12" md="6" lg="4">
          <v-card color="white">
            <v-row class="mt-2">
              <v-col xs="12" sm="12" md="6" lg="7">
                <p class="text-left font mx-4">Total Sales</p>
                <p class="text-left font2 mx-4">{{ this.total_sales | currency("", 0, { symbolOnLeft: false, })
                }}</p>
              </v-col>
              <v-col xs="12" sm="12" md="6" lg="5">
                <v-icon color="#146C94" size="100" class="text-center font4 mx-2">mdi-cash</v-icon>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col xs="12" sm="12" md="6" lg="4">
          <v-card color="white">
            <v-row class="mt-2">
              <v-col xs="12" sm="12" md="6" lg="7">
                <p class="text-left font mx-4">Total Orders</p>
                <p class="text-left font2 mx-4">{{ this.total_orders }} Orders
                </p>
              </v-col>
              <v-col xs="12" sm="12" md="6" lg="5">
                <v-icon color="#7DE0E6" size="100" class="text-center font4 mx-2">mdi-cart</v-icon>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col xs="12" sm="12" md="6" lg="4">
          <v-card color="white">
            <v-row class="mt-2">
              <v-col xs="12" sm="12" md="6" lg="7">
                <p class="text-left font mx-4">Total Persons</p>
                <p class="text-left font2 mx-4">{{ this.total_peaople }} Persons</p>
                <!-- <p class="text-left font3 mx-4">{{ this.total_persons_percentage }}%</p> -->
              </v-col>
              <v-col xs="12" sm="12" md="6" lg="5">
                <v-icon color="#0E38B1" size="100" class="text-left font4 mx-2">mdi-account-group</v-icon>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mx-2 mt-2">
        <v-col xs="12" sm="12" md="12" lg="6">
          <br>
          <p class="paragraph">Payment</p>
          <v-card color="white" class="scroll">
            <v-data-table :headers="payment_headers" :items="Payment" :items-per-page="5" class="elevation-1"
              hide-default-footer>
              <template v-slot:[`item.payment_method`]="{ item }">
               <p class="text-left">{{ item?.payment_method?.name ?? '' }} | {{ item?.card_payment_type?.name ?? 'N/A' }}</p>
              </template>
              <template v-slot:[`item.total`]="{ item }">
                Rp. {{ item?.total | currency("", 0, { symbolOnLeft: false, }) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col xs="12" sm="12" md="12" lg="6">
          <br>
          <p class="paragraph">Branch</p>
          <v-card color="white" class="scroll">
            <v-data-table :headers="branch_sales_headers" :items="Branch" :items-per-page="5" class="elevation-1"
              hide-default-footer>
              <template v-slot:[`item.total_sales`]="{ item }">
                Rp. {{ item.total_sales | currency("", 0, { symbolOnLeft: false, }) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mx-2 mt-2">
        <v-col xs="12" sm="12" md="12" lg="12">
          <br>
          <p class="paragraph">Top 10 Best Selling Product</p>
          <v-data-table :headers="best_selling_product_headers" :items="BestSellingProduct" :items-per-page="5"
            class="elevation-1" hide-default-footer>
            <template v-slot:[`item.price`]="{ item }">
              Rp. {{ item.price | currency("", 0, { symbolOnLeft: false, }) }}
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              Rp. {{ item.price * item.total_qty | currency("", 0, { symbolOnLeft: false, }) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <br>
      <v-divider class="mx-2" :thickness="2"></v-divider>
    </v-card>
  </main-layout>
</template>

<script>
import { mapGetters } from "vuex";
import MainLayout from "@/layouts/MainLayout.vue";
import BaseHeader from "@/components/base/BaseHeader.vue";
export default {
  name: "Dashboard",
  data() {
    return {
      branch: [],
      dashboard: [],
      currentPage: 1,
      lastPage: 0,
      total_sales: 0,
      total_orders: 0,
      total_peaople: 0,
      payment: [],
      branch_sales: [],
      best_selling_product: [],

      payment_headers: [
        { text: "Name", value: "payment_method", class: "primary white--text" },
        { text: "Total", value: "total", class: "primary white--text" },
      ],
      branch_sales_headers: [
        { text: "Name", value: "name", class: "primary white--text" },
        { text: "Phone", value: "phone", class: "primary white--text" },
        { text: "Email", value: "email", class: "primary white--text" },
        { text: "Total Sales", value: "total_sales", class: "primary white--text" },
      ],
      best_selling_product_headers: [
        { text: "Item Name", value: "name", width: "20", class: "primary white--text" },
        { text: "Description", value: "description", width: "30", class: "primary white--text" },
        { text: "Price", value: "price", width: "10", class: "primary white--text" },
        { text: "Qty", value: "total_qty", width: "10", class: "primary white--text" },
        { text: "Amount", value: "amount", width: "15", class: "primary white--text" },
      ],
      search: {
        start_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
        end_date: new Date().toJSON().slice(0, 10),
        branch_id: "",
      },
      isLoading: false,
    };
  },

  created() {
    this.getInit();
    this.getBranch();
  },

  methods: {
    async getInit() {
      let params = {
        start_date: this.search.start_date,
        end_date: this.search.end_date,
        branch_id: this.search.branch_id,
      };

      await this.$axios
        .get("/dashboard", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit("dashboard/SET_PAYMENT", data.payment);
          this.$store.commit("dashboard/SET_BRANCH", data.branch_sales);
          this.$store.commit("dashboard/SET_BEST_SELLING_PRODUCT", data.best_selling_products);
          this.total_sales = data.total_sales;
          this.total_orders = data.total_sales_count;
          this.total_peaople = data.total_people;
        });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch;
        });
    },

    searching() {
      this.getInit();
    },
    clear() {
      this.search = {
        start_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
        end_date: new Date().toJSON().slice(0, 10),
      };
    },
  },

  components: {
    MainLayout,
    BaseHeader,
  },

  computed: {
    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    ...mapGetters("dashboard", {
      // TotalSales: "TotalSales",
      // TotalOrders: "TotalOrders",
      // TotalPersons: "TotalPersons",
      // AverageSales: "AverageSales",
      // TotalPO: "TotalPO",
      // SummaryPayment: "SummaryPayment",
      Payment: "Payment",
      Branch: "Branch",
      BestSellingProduct : "BestSellingProduct",
    }),
  },
};
</script>
<style>
.font {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 23px;
  line-height: 17px;
  color: black;
}

.font2 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 17px;
  color: black;
}

.font3 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  color: #00A82F;
}

.font4 {
  size: 50%;
  color: #FF8B8B;
}

.font5 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  color: black;
}

.paragraph {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  padding-left: 0px;
  font-size: 22px;
  line-height: 17px;
  color: black;
}

.tables {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.scroll {
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 9px;
  height: 140px;
}
</style>
