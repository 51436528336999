<template>
  <div>
    <v-data-table :loading="loading" :headers="headers" :items="BRANCHES.data" class="elevation-1" hide-default-footer>
      <template v-slot:[`item.is_active`]="{ item }">
        <v-icon v-if="item.is_active == 1" color="success">
          mdi-check-circle
        </v-icon>
        <v-icon v-else color="error"> mdi-close-circle </v-icon>
      </template>
      <template v-slot:no-data> No data </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="warning" class="mr-2" @click.stop="edit(item)">
          mdi-pencil-box-outline
        </v-icon>
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white">
          <base-column :lg="4" :md="4" :sm="6">
            <v-text-field v-model="search.search" append-icon="mdi-magnify" label="Search" outlined dense
              hide-details></v-text-field>
          </base-column>
          <v-btn small color="darkblue" class="white--text float-end mx-1" @click.stop="searching">Submit <v-icon
              right>mdi-magnify</v-icon></v-btn>
          <v-btn small color="secondary" class="white--text float-end mx-1" @click.stop="clear">Clear
            <v-icon right>mdi-close-circle-multiple </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
    </v-data-table>

    <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
  </div>
</template>


<script>
import BaseColumn from "@/components/base/BaseColumn";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "BranchList",
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Address", value: "address" },
        { text: "Pic Name", value: "pic_name" },
        { text: "Pic Phone", value: "pic_phone" },
        // { text: "Action", value: "actions", sortable: false, width: 100 },
      ],
      page: 1,
      disabled: false,
      loading: false,

      status: ["Active", "Inactive"],
      search: {
        status: null,
        search: null,
      },
      expanded: [],
    };
  },

  created() {
    this.getBranch();
  },
  methods: {
    ...mapMutations("branch", ["SET_BRANCHES", "SET_CURRENT_PAGE"]),
    async getBranch() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        status: this.search.status,
        search: this.search.search,
      };

      await this.$axios
        .get("/master/branches", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_BRANCHES(data.branch);
          this.loading = false;
          this.disabled = false;
        });
    },

    searching() {
      this.page = 1;
      this.getBranch();
    },
    clear() {
      this.page = 1;
      this.search = {
        status: "",
        search: "",
      };
      this.getBranch();
    },

    edit(item) {
      this.$router.push("/master/branches/edit/" + item.id);
    },
  },
  computed: {
    ...mapGetters("branch", {
      BRANCHES: "Branches",
    }),

    currentPage: {
      get() {
        return this.BRANCHES.current_page;
      },
      set(val) {
        return this.SET_CURRENT_PAGE(val);
      },
    },
    lastPage: {
      get() {
        return this.BRANCHES.last_page;
      },
    },
  },

  components: {
    BaseColumn,
  },
};
</script>