<template>
  <v-col xs="12" sm="12" md="12" lg="12">
    <v-responsive width="100%" v-if="$vuetify.breakpoint.xsOnly" />
    <v-card elevation="5" rounded>
      <v-row class="mx-1 mb-1">
        <v-col xs="12" sm="12" md="2" lg="2">
          <v-text-field type="date" label="From date" outlined dense v-model="search.from_date">
          </v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="2" lg="2">
          <v-text-field type="date" label="End date" outlined dense v-model="search.end_date">
          </v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="2" lg="2">
          <v-select :items="status" outlined dense label="Status" v-model="search.status"></v-select>
        </v-col>
        <v-col xs="12" sm="12" md="2" lg="2" v-if="(ROLE == 'Owner')">
          <v-select :items="branch" outlined dense label="Branch" item-text="name" item-value="id"
            v-model="search.branch"></v-select>
        </v-col>
        <v-col xs="12" sm="12" md="2" lg="2">
          <v-text-field label="Search" outlined dense v-model="search.search">
          </v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="2" lg="2">
          <v-btn small color="darkblue" class="white--text mx-1" @click.stop="init">Submit <v-icon
              right>mdi-magnify</v-icon></v-btn>
          <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
            <v-icon right>mdi-close-circle-multiple </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table :loading="loading" :headers="headers" :items="SalesOrders.data" class="elevation-1"
        hide-default-footer>
        <template slot="body.append">
          <tr>
            <th colspan="4"></th>
            <th style="font-size: 15px;">{{ sumField('grand_total') | currency("", 0, { symbolOnLeft: false, }) }}</th>
          </tr>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip color="secondary" small v-if="item.status == 'WAITING'">
            WAITING
          </v-chip>
          <v-chip color="primary" small v-else-if="item.status == 'ONPROGRESS'">
            ON PROGRESS
          </v-chip>
          <v-chip color="success" small v-else-if="item.status == 'DONE'">
            DONE
          </v-chip>
          <v-chip color="danger" small v-else-if="item.status == 'CANCEL'">
            CANCEL
          </v-chip>
          <v-chip color="warning" small v-else-if="item.status == 'VOID'">
            VOID
          </v-chip>
        </template>
        <template v-slot:[`item.is_paid`]="{ item }">
          <v-chip color="success" small v-if="item.is_paid == true">
            Yes
          </v-chip>
          <v-chip color="primary" small v-else-if="item.is_paid == false">
            No
          </v-chip>
        </template>
        <template v-slot:[`item.grand_total`]="{ item }">
          <b>{{ item.grand_total | currency("", 0, { symbolOnLeft: false, }) }}</b>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip color="dark" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" color="primary" v-bind="attrs" icon small @click.stop="view(item.id)" v-on="on">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View Quotation</span>
          </v-tooltip>
          <v-tooltip color="dark" bottom>S
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" color="warning" v-bind="attrs" icon small @click.stop="edit(item.id)" v-on="on">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit Quotation</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "SalesOrderList",
  data() {
    return {
      headers: [
        { text: "No.Quotation", value: "no_so" },
        { text: "Branch", value: "branch.name" },
        { text: "Customer", value: "customer.name" },
        { text: "Quotation Date", value: "order_date" },
        { text: "Grand Total", value: "grand_total" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      branch: [],
      selected: [],
      disabled: false,
      loading: false,
      page: 1,
      loading_download: false,
      grand_total: 0,
      flag: null,
      status: [
        { text: "ALL", value: "" },
        { text: "ON PROGRESS", value: "ONPROGRESS" },
        { text: "DONE", value: "DONE" },
        { text: "CANCEL", value: "CANCEL" },
      ],

      search: {
        from_date: new Date().toJSON().slice(0, 10),
        end_date: new Date().toJSON().slice(0, 10),
        search: null,
        status: "",
        branch_id: null,
      },
    };
  },
  methods: {
    ...mapMutations("sales_order", ["CLEAR_STATE", "SET_SALES_ORDERS", "SET_SALES_ORDER", "SET_CURRENT_PAGE"]),
    sumField(key) {
      return this.SalesOrders.data.reduce((a, b) => a + (b[key] || 0), 0)
    },
    async init() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        from_date: this.search.from_date,
        end_date: this.search.end_date,
        branch_id: this.search.branch_id,
        status: this.search.status,
        search: this.search.search,
      };

      await this.$axios
        .get("/transaction/sales-orders?for_page=b2b", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit("sales_order/SET_SALES_ORDERS", data.sales_orders);
          this.grand_total = this.SalesOrders.data ? this.SalesOrders.data.reduce((a, b) => a + b.grand_total, 0) : 0;
          this.loading = false;
          this.disabled = false;
        });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch.data;
        });
    },

    async edit(id) {
      this.loading = true;
      await this.$axios
        .get("/transaction/sales-orders/" + id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_SALES_ORDER(data.sales_orders);
          this.loading = false;
          this.$router.push("/sales-order/edit");
        })
        .catch(() => {
          this.loading = true;
        });
    },

    exportExcell: async function () {
      await this.$axios
        .get(
          "/transaction/sales-orders/export",
          {
            headers: {
              Authorization: this.$store.getters["auth/Token"],
            },
          }
        ).then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const a = document.createElement('a');
          a.href = url;
          a.setAttribute('download', 'sales-order.xlsx');
          document.body.appendChild(a);
          a.click();
        })
        .catch(() => {
        });
    },

    view(id) {
      this.$router.push("/sales-order/view/" + id);
    },

    searching() {
      this.page = 1;
      this.init();
    },
    clear() {
      this.search = {
        status: "",
        search: "",
        branch_id: "",
        from_date: new Date().toJSON().slice(0, 10),
        end_date: new Date().toJSON().slice(0, 10),
      };
      this.init();
    },
  },

  created() {
    this.init();
    this.getBranch();
  },

  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.init();
    },
  },
  computed: {
    ...mapGetters("sales_order", {
      SalesOrders: "SalesOrders",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    currentPage: {
      get() {
        return this.SalesOrders.current_page;
      },
      set(val) {
        return this.$store.commit("sales_order/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.SalesOrders.last_page;
      },
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  background-color: #ebebeb59;
}

::v-deep th {
  color: #000 !important;
}
</style>