<template>
    <v-col xs="12" sm="12" md="12" lg="12">
        <v-responsive width="100%" v-if="$vuetify.breakpoint.xsOnly" />
        <br>
        <v-card elevation="5" rounded>
            <v-row class="mx-1 mb-1">
                <v-col xs="12" sm="12" md="2" lg="2">
                    <v-text-field type="date" label="From date" outlined dense v-model="search.start_date">
                    </v-text-field>
                </v-col>
                <v-col xs="12" sm="12" md="2" lg="2">
                    <v-text-field type="date" label="End date" outlined dense v-model="search.end_date">
                    </v-text-field>
                </v-col>
                <v-col xs="12" sm="12" md="2" lg="2">
                    <v-btn small color="darkblue" class="white--text mx-1" @click.stop="init">Submit <v-icon
                            right>mdi-magnify</v-icon></v-btn>
                    <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
                        <v-icon right>mdi-close-circle-multiple </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="mx-1 mb-1">
                <v-col xs="12" sm="12" md="12" lg="12">
                    <v-card elevation="4">
                        <template>
                            <v-breadcrumbs :items="items">
                                <template v-slot:item="{ item }">
                                    <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                        {{ item.text.toUpperCase() }}
                                    </v-breadcrumbs-item>
                                </template>
                            </v-breadcrumbs>
                        </template>
                        <v-divider></v-divider>
                        <v-row class="mx-2 mt-2">
                            <v-col xs="12" sm="12" md="6" lg="3">
                                <v-img src="https://picsum.photos/350/165?random" height="125"
                                    class="bg-grey-lighten-2"></v-img>
                            </v-col>
                            <v-col xs="12" sm="12" md="6" lg="3">
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="1">
                                        <v-icon color="primary">mdi-silverware-fork-knife</v-icon>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="11"><span class="font-weight-bold">Bakso
                                            Colenak</span></v-col>
                                </v-row>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12"><span>1x Rp.24.563</span></v-col>
                                </v-row>
                            </v-col>
                            <v-col xs="12" sm="12" md="6" lg="3">
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="1">
                                        <v-icon color="primary">mdi-map-marker</v-icon>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="11"><span
                                            class="font-weight-bold">Address</span></v-col>
                                </v-row>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12">
                                        <p>Ruko Inkopal, Jl. Boulevard Bar. Raya No.2, RT.2/RW.9, Klp.
                                            Gading Bar., Kec. Klp. Gading, Jkt Utara, Daerah Khusus Ibukota Jakarta 14240
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col xs="12" sm="12" md="6" lg="3">
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="1">
                                        <v-icon color="primary">mdi-cash</v-icon>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="11"><span
                                            class="font-weight-bold">Price</span></v-col>
                                </v-row>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12">
                                        <h3>Rp. 63.326</h3>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="6">
                                        <span>Reject Order</span>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="6">
                                        <v-btn color="primary" small>Accept Order</v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mx-1 mb-1">
                <v-col xs="12" sm="12" md="12" lg="12">
                    <v-card elevation="4">
                        <template>
                            <v-breadcrumbs :items="items">
                                <template v-slot:item="{ item }">
                                    <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                        {{ item.text.toUpperCase() }}
                                    </v-breadcrumbs-item>
                                </template>
                            </v-breadcrumbs>
                        </template>
                        <v-divider></v-divider>
                        <v-row class="mx-2 mt-2">
                            <v-col xs="12" sm="12" md="6" lg="3">
                                <v-img src="https://cdn.vuetifyjs.com/images/cards/cooking.png" height="125"
                                    class="bg-grey-lighten-2"></v-img>
                            </v-col>
                            <v-col xs="12" sm="12" md="6" lg="3">
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="1">
                                        <v-icon color="primary">mdi-silverware-fork-knife</v-icon>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="11"><span class="font-weight-bold">Bakso
                                            Colmex</span></v-col>
                                </v-row>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12"><span>1x Rp.24.563</span></v-col>
                                </v-row>
                            </v-col>
                            <v-col xs="12" sm="12" md="6" lg="3">
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="1">
                                        <v-icon color="primary">mdi-map-marker</v-icon>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="11"><span
                                            class="font-weight-bold">Address</span></v-col>
                                </v-row>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12">
                                        <p>Ruko Inkopal, Jl. Boulevard Bar. Raya No.2, RT.2/RW.9, Klp.
                                            Gading Bar., Kec. Klp. Gading, Jkt Utara, Daerah Khusus Ibukota Jakarta 14240
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col xs="12" sm="12" md="6" lg="3">
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="1">
                                        <v-icon color="primary">mdi-cash</v-icon>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="11"><span
                                            class="font-weight-bold">Price</span></v-col>
                                </v-row>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12">
                                        <h3>Rp. 63.326</h3>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="6">
                                        <span>Reject Order</span>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="6">
                                        <v-btn color="primary" small>Accept Order</v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-col>
</template>
  
<script>
export default {
    name: "OrderShipped",
    data() {
        return {
            items: [
                {
                    text: 'Order Shipped',
                    disabled: false,
                },
                {
                    text: 'INV/20230312/IX/12',
                    disabled: false,
                },
                {
                    text: 'KUSNANTO',
                    disabled: false,
                },
                {
                    text: '18 July 2023; 13:14 WIB',
                    disabled: false,
                },
            ],
            search: {
                start_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
                end_date: new Date().toJSON().slice(0, 10),
            },
        };
    },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
    background-color: #ebebeb59;
}

::v-deep th {
    color: #000 !important;
}
</style>