<template>
    <v-card>
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title> Bank Account List </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="closeBankAccountDialog" color="error" outlined small>
                <v-icon>mdi-close</v-icon></v-btn>
        </v-app-bar>

        <v-data-table :loading="loading" :headers="headers" :items="BANK_ACCOUNTS.data" class="elevation-1"
            hide-default-footer>
            <template v-slot:no-data> No data </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-btn color="primary" outlined small @click.stop="selectBankAccount(item)">Select <v-icon right> mdi-check
                    </v-icon></v-btn>
            </template>

            <template v-slot:[`item.is_active`]="{ item }">
                <v-icon v-if="item.is_active == 1" color="success">
                    mdi-check-circle
                </v-icon>
                <v-icon v-else color="error"> mdi-close-circle </v-icon>
            </template>
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <base-column :lg="6" :md="6" :sm="12">
                        <v-text-field append-icon="mdi-magnify" v-model="search.search" label="Search" outlined dense
                            hide-details></v-text-field></base-column>

                    <v-btn small color="darkblue" class="white--text float-end mx-1" @click.stop="getBankAccount">Submit
                        <v-icon right>mdi-magnify</v-icon></v-btn>
                    <v-btn small color="secondary" class="white--text float-end mx-1" @click.stop="clear">Clear
                        <v-icon right>mdi-close-circle-multiple </v-icon>
                    </v-btn>
                </v-toolbar>
            </template>
            <template>
                <v-icon color="warning" class="mr-2"> mdi-pencil-box-outline </v-icon>
            </template>
        </v-data-table>
        <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
    </v-card>
</template>
  
<script>
import BaseColumn from "@/components/base/BaseColumn";
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "BankAccountModal",
    created() {
        this.getBankAccount();
    },
    data() {
        return {
            headers: [
                { text: "Bank Name", value: "bank_name" },
                { text: "Account Number", value: "number_account" },
                { text: "Account Name", value: "name_account" },
                { text: "Actions", value: "actions", sortable: false },
            ],
            disabled: false,
            loading: false,
            search: {
                search: null,
            },
        };
    },

    methods: {
        ...mapMutations("bank_account", ["SET_BANK_ACCOUNTS", "CLEAR_BANK_ACCOUNTS"]),
        async getBankAccount() {
            this.loading = true;
            this.disabled = true;
            let params = {
                page: this.page,
                search: this.search.search,
            };
            await this.$axios
                .get("/master/bank-account", {
                    params: params,
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.SET_BANK_ACCOUNTS(data.bank_account);
                    this.loading = false;
                    this.disabled = false;
                });
        },
        closeBankAccountDialog() {
            this.$emit("closeBankAccountDialog");
        },

        selectBankAccount(item) {
            this.$emit("selectBankAccount", item);
            this.closeBankAccountDialog();
        },

        searching() {
            this.page = 1;
            this.getBankAccount();
        },
        clear() {
            this.page = 1;
            this.search = {
                search: "",
            };
            this.getBankAccount();
        },
    },

    watch: {
        currentPage(newValue) {
            this.page = newValue;
            this.getBankAccount();
        },
    },

    computed: {
        ...mapGetters("bank_account", {
            BANK_ACCOUNTS: "BankAccounts",
        }),
        currentPage: {
            get() {
                return this.BANK_ACCOUNTS.current_page;
            },
            set(val) {
                return this.$store.commit("bank_account/SET_CURRENT_PAGE", val);
            },
        },
        lastPage: {
            get() {
                return this.BANK_ACCOUNTS.last_page;
            },
        },
    },

    destroyed() {
        this.CLEAR_BANK_ACCOUNTS();
    },
    components: {
        BaseColumn,
    },
};
</script>