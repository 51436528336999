<template>
    <v-card>
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title> Variant By Size List </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="closeVariantDialog" color="error" outlined small>
                <v-icon>mdi-close</v-icon></v-btn>
        </v-app-bar>
        <v-divider class="mx-2"></v-divider>
        <v-row class="mx-2 mt-2">
            <v-col sm="12" md="12" lg="4">
                <v-text-field v-model="search.search" append-icon="mdi-magnify" label="Search" outlined
                    dense></v-text-field>
            </v-col>
            <v-col sm="12" md="12" lg="4">
                <v-btn small color="darkblue" class="white--text" @click.stop="searching">Submit <v-icon
                        right>mdi-magnify</v-icon></v-btn>
                <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
                    <v-icon right>mdi-close-circle-multiple </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mx-1">
            <v-col sm="12" md="12" lg="12">
                <v-simple-table class="mx-2" fixed-header>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left white--text"  style="background-color:#898F99;">Actions</th>
                                <th class="text-left white--text"  style="background-color:#898F99;">No</th>
                                <th class="text-left white--text"  style="background-color:#898F99;">Variant Size</th>
                                <th class="text-left white--text"  style="background-color:#898F99;">Plus Price
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr height="80" v-for="(row, index) in  DetailsVariant.sizes" :key="index">
                                <td>
                                    <v-radio-group v-model="selectedVariantSize" name="rowSelector"
                                        @click="selectedVariantSize = row.id">
                                        <v-radio :value="row" />
                                    </v-radio-group>
                                </td>
                                <td>{{ index + 1 }}</td>
                                <td>
                                    {{ row.name }}
                                </td>
                                <td>
                                    {{ row.price | currency("", 0, {
                                        symbolOnLeft: false
                                    }) }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-divider class="mx-2"></v-divider>
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title> Variant Topping List </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-app-bar>
        <v-row class="mx-2">
            <v-col sm="12" md="12" lg="4">
                <v-text-field append-icon="mdi-magnify" label="Search" outlined dense></v-text-field>
            </v-col>
            <v-col sm="12" md="12" lg="4">
                <v-btn small color="darkblue" class="white--text" @click.stop="searching">Submit <v-icon
                        right>mdi-magnify</v-icon></v-btn>
                <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
                    <v-icon right>mdi-close-circle-multiple </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mx-1">
            <v-col sm="12" md="12" lg="12">
                <v-simple-table class="mx-2" fixed-header>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left white--text"  style="background-color:#898F99;"></th>
                                <th class="text-left white--text" style="background-color:#898F99;">No</th>
                                <th class="text-left white--text" style="background-color:#898F99;">Topping Name</th>
                                <th class="text-left white--text" style="background-color:#898F99;">Plus Price
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr height="80" v-for="(row, index) in  DetailsVariant.toppings" :key="index">
                                <td>
                                   <v-checkbox color="primary" v-model="selectedTopping[index]" :value="row"></v-checkbox>
                                </td>
                                <td>{{ index + 1 }}</td>
                                <td>
                                    {{ row.topping.name }}
                                </td>
                                <td>
                                    {{ row.topping.price | currency("", 0, {
                                        symbolOnLeft: false
                                    }) }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-divider class="mx-2 mb-2"></v-divider>
        <v-row class="mx-1 mb-2">
            <v-col sm="12" md="12" lg="12">
                <v-btn color="primary" small class="float-right mx-2"
                    :disabled="selectedVariantSize.length == 0 && selectedTopping.length == 0"
                    @click.stop="checkedVariant">Add
                    <v-icon right>mdi-plus</v-icon></v-btn>
                <v-btn class="float-right mb-2 white--text" color="danger" small @click.stop="close()">Cancel</v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>
  
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
    name: "VariantModal",
    data() {
        return {
            page: 1,
            search: {
                search: null,
            },
            selectedVariantSize: [],
            selectedTopping: [],
            singleSelect: false,
            disabled: false,
            row : null,
            currentPage: 1,
            lastPage: 1,
            loading: false,

        };


    },

    props: {
        itemId: {
            type: Number,
            default: null,
        },
    },

    created() {
        this.getVariant();
    },

    methods: {
        ...mapMutations("sales_order", ["SET_DETAILS_VARIANT", "SET_CURRENT_PAGE"]),
        async getVariant() {
            let params = {
                page: this.page,
                search: this.search.search,
            };

            await this.$axios
                .get("/master/variant-by-item/" + this.itemId, {
                    params: params,
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.SET_DETAILS_VARIANT(data.item);
                    this.loading = false;
                    this.disabled = false;
                });
        },

        selectedToppings(index) {
            console.log(index);
            
        },
        closeVariantDialog() {
            this.$emit("closeVariantDialog");
        },

        checkedVariant() {
            this.$emit("checkedVariant", this.selectedVariantSize ?? {} , this.selectedTopping ?? {});
            this.$emit("closeVariantDialog");
        },

        close() {
            this.$store.commit("sales_order/CLEAR_DETAILS_VARIANT", {});
            this.$emit("closeVariantDialog");
        }
    },

    computed: {
        ...mapGetters("sales_order", {
            SalesOrder: "SalesOrder",
            DetailsVariant: "DetailsVariant",
        }),
    },
};
</script>