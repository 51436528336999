<template>
  <div>
    <v-row class="mt-1 mx-1">
      <base-column :lg="3" :md="4" :sm="12">
        <v-text-field v-model="PurchaseInvoice.no_po" readonly placeholder="Purchase Invoice" outlined dense
          :append-icon="'mdi-magnify'" hide-details @click:append="openPurchaseInvoiceDialog()"></v-text-field>
      </base-column>
      <base-column :lg="3" :md="4" :sm="12">
        <v-text-field v-model="PurchaseInvoice.supplier_id" readonly placeholder="Supplier" outlined dense
          :append-icon="'mdi-magnify'" hide-details @click:append="openSupplierDialog()"></v-text-field>
      </base-column>
    </v-row>
    <br>
    <v-row dense class="mx-3 mt-4">
      <base-column :lg="4" :md="6" :sm="12">
        <v-card max-width="100%">
          <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title>
              <v-icon>mdi-account</v-icon>
              Supplier
              {{ PurchaseInvoice.supplier_name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-app-bar>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>Email : {{ PurchaseInvoice.supplier_email }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Phone : {{ PurchaseInvoice.supplier_phone }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Address : {{ PurchaseInvoice.supplier_address }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </base-column>
      <base-column :lg="3" :md="6" :sm="12">
        <v-card max-width="100%" height="95%">
          <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title>
              <v-icon>mdi-account</v-icon>
              Bank Account
              {{ PurchaseInvoice.name_account }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn outlined small color="primary" @click="openBankAccountDialog">
              SEARCH
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-app-bar>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>Account Number : {{ PurchaseInvoice.number_account }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Bank Name : {{ PurchaseInvoice.bank_name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </base-column>
      <v-row dense class="mx-3 mt-1">
        <base-column :lg="6" :md="6" :sm="12">
          <v-text-field type="date" label="Date Payment" outlined dense v-model="PurchaseInvoice.date"
            :rules="rule_invoice_date"></v-text-field>
          <v-textarea label="Remark" rows="2" outlined dense v-model="PurchaseInvoice.remark"></v-textarea>
        </base-column>
        <base-column :lg="6" :md="6" :sm="12">
          <v-select label="Payment Method" item-value="id" item-text="name" outlined dense
            v-model="PurchaseInvoice.payment_method_id" :items="payment_method" :rules="rule_payment_method"
            readonly></v-select>
        </base-column>
      </v-row>
    </v-row>
    <br>
    <v-divider class="mx-3"></v-divider>
    <v-dialog v-if="dialogSupplier" v-model="dialogSupplier" max-width="1200px" persistent>
      <supplier-modal v-on:closeSupplierDialog="closeSupplierDialog" v-on:selectSupplier="selectSupplier" />
    </v-dialog>
    <v-dialog v-if="dialogBankAccount" v-model="dialogBankAccount" max-width="1200px" persistent>
      <bank-account-modal v-on:closeBankAccountDialog="closeBankAccountDialog"
        v-on:selectBankAccount="selectBankAccount" />
    </v-dialog>
    <v-dialog v-if="dialogPurchaseInvoice" v-model="dialogPurchaseInvoice" max-width="1200px" persistent>
      <purchase-invoice-modal v-on:closePurchaseInvoiceDialog="closePurchaseInvoiceDialog"
        v-on:selectPurchaseInvoice="selectPurchaseInvoice" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import BaseColumn from "@/components/base/BaseColumn.vue";
import PurchaseInvoiceModal from "@/components/modal/PurchaseOrderInvoiceModal.vue";
import BankAccountModal from '@/components/modal/BankAccountModal.vue';
import SupplierModal from '@/components/modal/SupplierModal.vue';

export default {
  name: "PurchaseInvoiceForm",

  data() {
    return {
      supplier: [],
      dialogPurchaseInvoice: false,
      dialogSupplier: false,
      dialogBankAccount: false,
      status_invoice: "PARTIAL",
      payment_method: [],
      branch: [],
    };
  },
  created() {
    this.getPaymentMethod();
    this.getBranch();
  },

  methods: {
    ...mapMutations("purchase_invoice", [
      "SET_PURCHASE_INVOICE",
    ]),

    openPurchaseInvoiceDialog() {
      this.dialogPurchaseInvoice = true;
    },
    closePurchaseInvoiceDialog() {
      this.dialogPurchaseInvoice = false;
    },

    selectPurchaseInvoice(purchase_order) {
      this.$axios
        .get("/transaction/purchases/" + purchase_order.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit("purchase_invoice/CLEAR_STATE");
          this.$store.commit("purchase_invoice/SET_INV_FROM_PO", data.purchase_orders);
        })
        .catch(() => {
          this.loading = true;
        });
    },

    openSupplierDialog() {
      this.dialogSupplier = true;
    },

    closeSupplierDialog() {
      this.dialogSupplier = false;
    },

    selectSupplier(supplier) {
      this.$axios
        .get("/transaction/sales-orders/" + supplier.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit("purchase_order/CLEAR_STATE");
          this.$store.commit("purchase_order/SET_SO", data.supplier);

        })
        .catch(() => {
          this.loading = true;
        });
    },

    openBankAccountDialog() {
      this.dialogBankAccount = true;
    },

    closeBankAccountDialog() {
      this.dialogBankAccount = false;
    },

    selectBankAccount(val) {
      this.SET_BANK_ACCOUNT({
        id: val.id,
        name_account: val.name_account,
        number_account: val.number_account,
        bank_name: val.bank_name,
      });
      this.dialogBankAccount = false;
    },


    async getPaymentMethod() {
      await this.$axios("/master/payment-method", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.payment_method = data.payment_method.data;
      });
    },

    async getBranch() {
      await this.$axios("/master/branches", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.branch = data.branch.data;
      });
    },

  },

  computed: {
    ...mapState("purchase_invoice", {
      PurchaseInvoice: (state) => state.purchase_invoice,
    }),

    ...mapState("purchase_order", {
      PurchaseOrder: (state) => state.purchase_order,
    }),
    ...mapGetters("auth", {
      ROLE: "Role",
    }),
  },
  components: {
    BaseColumn,
    BankAccountModal,
    "supplier-modal": SupplierModal,
    "purchase-invoice-modal": PurchaseInvoiceModal,
  },
};
</script>