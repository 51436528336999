<template>
    <main-layout>
        <base-header>
            <template #icon> mdi-file</template>
            <template #name> Edit Ingoing</template>
            <template #breadcrumbs>
                <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                    <template #divider>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>
            </template>
        </base-header>

        <v-card class="mx-1">
            <!-- App Bar -->
            <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
                <v-toolbar-title> Reference No</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn small color="secondary" class="ma-2" to="/inventory-in">
                    <v-icon>mdi-arrow-left</v-icon> Back
                </v-btn>
                <v-btn small color="secondary" class="ma-2" to="/inventory-in/create">
                    Clear
                    <v-icon>mdi-close-circle-multiple </v-icon>
                </v-btn>
                <v-btn small class="ma-2" color="primary" @click.stop="update" :loading="btnLoading"
                    :disabled="!form_valid || btnLoading">
                    Update
                    <v-icon right>mdi-content-save-all</v-icon>
                </v-btn>
            </v-app-bar>

            <v-form ref="Ingoing" v-model="form_valid" lazy-validation>
                <form-ingoing />

                <form-item />
            </v-form>
        </v-card>
    </main-layout>
</template>

<script>
import Form from "./Form.vue";
import FormItem from "./FormItem.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import BaseHeader from "@/components/base/BaseHeader.vue";

export default {
    name: "IngoingEdit",

    data() {
        return {
            btnLoading: false,
            form_valid: true,
        };
    },
    created() {
        this.breadcrumbs = [
            { text: "Transaction", disabled: false },
            { text: "Ingoing", disabled: true },
            { text: "Edit", disabled: true },
        ];
    },

    methods: {
        update: async function () {
            if (this.$refs.Ingoing.validate()) {
                this.btnLoading = true;
                await this.$axios
                    .put("/transaction/ingoings/" + this.Ingoing.id, this.Ingoing,
                        {
                            headers: { Authorization: this.$store.getters["auth/Token"] },
                        })
                    .then(() => {
                        this.btnLoading = false;
                        this.$router.push({ name: "inventory-in.index" });
                    })
                    .catch(() => {
                        this.btnLoading = false;
                    });
            }
        },
        clear() {
            this.$store.commit("ingoing/CLEAR_STATE");
        },

    },

    computed: {
        Ingoing() {
            return this.$store.getters["ingoing/Ingoing"];
        },
    },

    destroyed() {
        this.$store.commit("ingoing/CLEAR_STATE");
    },

    components: {
        MainLayout,
        BaseHeader,
        "form-ingoing": Form,
        "form-item": FormItem,
    },
};
</script>
