import QuotationIndex from '@/views/transaction/quotation/Index.vue'
import QuotationCreate from '@/views/transaction/quotation/Create.vue'
import QuotationEdit from '@/views/transaction/quotation/Edit.vue'
import QuotationView from '@/views/transaction/quotation/View.vue'
import SalesOrderIndex from '@/views/transaction/sales_order/Index.vue'
import SalesOrderCreate from '@/views/transaction/sales_order/Create.vue'
import SalesOrderEdit from '@/views/transaction/sales_order/Edit.vue'
import SalesOrderView from '@/views/transaction/sales_order/View.vue'

import TabDailySales from '@/views/transaction/tab_daily_sales/tabs.vue'
import DailySalesOrder from '@/views/transaction/daily_sales_order/Index.vue'
import DailySalesOrderDetail from '@/views/transaction/daily_sales_order/View.vue'


import SalesOrderTabs from '@/views/transaction/branch_sales/tabs.vue'
import BranchSalesOrderIndex from '@/views/transaction/branch_sales/Index.vue'
import BranchSalesOrderView from '@/views/transaction/branch_sales/View.vue'

import SalesInvoiceIndex from '@/views/transaction/sales_invoice/Index.vue'
import SalesInvoiceCreate from '@/views/transaction/sales_invoice/Create.vue'
import SalesInvoiceEdit from '@/views/transaction/sales_invoice/Edit.vue'
import SalesInvoiceView from '@/views/transaction/sales_invoice/View.vue'

import SalesInvoiceAdjustmentIndex from '@/views/transaction/sales_invoice_adjustment/Index.vue'
import SalesInvoiceAdjustmentCreate from '@/views/transaction/sales_invoice_adjustment/Create.vue'
import SalesInvoiceAdjustmentEdit from '@/views/transaction/sales_invoice_adjustment/Edit.vue'
import SalesInvoiceAdjustmentView from '@/views/transaction/sales_invoice_adjustment/View.vue'

import PettyCash from '@/views/transaction/petty_cash/Index.vue'
import PettyCashCreate from '@/views/transaction/petty_cash/Create.vue'
import PettyCashEdit from '@/views/transaction/petty_cash/Edit.vue'

export default [
    {
        path: '/quotation',
        name: 'quotation.index',
        meta: { title: 'S-ERP | Quotation' },
        component: QuotationIndex
    },
    {
        path: '/quotation/create',
        name: 'quotation.create',
        meta: { title: 'S-ERP | Quotation Create' },
        component: QuotationCreate
    },
    {
        path: '/quotation/edit',
        name: 'quotation.edit',
        meta: { title: "S-ERP | Edit Quotation" },
        component: QuotationEdit
    },
    {
        path: '/quotation/view/:id',
        name: 'quotation.view',
        meta: { title: "S-ERP | Details Quotation" },
        component: QuotationView
    },
    {
        path: '/sales-order',
        name: 'sales-order.index',
        meta: { title: 'S-ERP | Sales Order' },
        component: SalesOrderIndex
    },

    {
        path: '/sales-order/create',
        name: 'sales-order.create',
        meta: { title: 'S-ERP | Sales Order Create' },
        component: SalesOrderCreate
    },

    {
        path: '/sales-order/edit',
        name: 'sales-order.edit',
        meta: { title: "S-ERP | Edit Sales Order" },
        component: SalesOrderEdit
    },

    {
        path: '/sales-order/view/:id',
        name: 'sales-order.view',
        meta: { title: "S-ERP | Details Sales Order" },
        component: SalesOrderView
    },

    {
        path: '/sales-invoice',
        name: 'sales-invoice.index',
        meta: { title: 'S-ERP | Sales Invoice' },
        component: SalesInvoiceIndex
    },

    {
        path: '/sales-invoice/create',
        name: 'sales-invoice.create',
        meta: { title: 'S-ERP | Sales Invoice Create' },
        component: SalesInvoiceCreate
    },

    {
        path: '/sales-invoice/edit',
        name: 'sales-invoice.edit',
        meta: { title: "S-ERP | Edit Sales Invoice" },
        component: SalesInvoiceEdit
    },

    {
        path: '/sales-invoice/view/:id',
        name: 'sales-invoice.view',
        meta: { title: "S-ERP | Details Sales Invoice" },
        component: SalesInvoiceView
    },

    {
        path: '/sales-invoice-adjustment',
        name: 'sales-invoice-adjustment.index',
        meta: { title: 'S-ERP | Sales Invoice Adjustment' },
        component: SalesInvoiceAdjustmentIndex
    },

    {
        path: '/sales-invoice-adjustment/create',
        name: 'sales-invoice-adjustment.create',
        meta: { title: 'S-ERP | Sales Invoice Adjustment Create' },
        component: SalesInvoiceAdjustmentCreate
    },

    {
        path: '/sales-invoice-adjustment/edit',
        name: 'sales-invoice-adjustment.edit',
        meta: { title: "S-ERP | Edit Sales Invoice Adjustment" },
        component: SalesInvoiceAdjustmentEdit
    },

    {
        path: '/sales-invoice-adjustment/view/:id',
        name: 'sales-invoice-adjustment.view',
        meta: { title: "S-ERP | Details Sales Invoice Adjustment" },
        component: SalesInvoiceAdjustmentView
    },

    {
        path: '/daily-sales-order',
        name: 'daily-sales-order.index',
        meta: { title: 'S-ERP | Daily Sales Order' },
        component: DailySalesOrder
    },

    {
        path: '/daily-sales-order/view/:id',
        name: 'daily-sales-order.detail',
        meta: { title: 'S-ERP | Daily Sales Order Detail' },
        component: DailySalesOrderDetail
    },

    {
        path: '/branch-sales-order',
        name: 'branch-sales-order.index',
        meta: { title: 'S-ERP | Branch Sales Order' },
        component: BranchSalesOrderIndex
    },

    {
        path: '/branch-sales-order-tabs',
        name: 'branch-sales-order-tabs',
        meta: { title: 'S-ERP | Branch Sales Order Tabs' },
        component: SalesOrderTabs
    },

    {
        path: '/branch-sales-order/view/:id',
        name: 'branch-sales-order.view',
        meta: { title: "S-ERP | Details Branch Sales Order" },
        component: BranchSalesOrderView

    },

    {
        path: '/petty-cash',
        name: 'petty-cash.index',
        meta: { title: 'S-ERP | Petty Cash' },
        component: PettyCash
    },

    {
        path: '/petty-cash/create',
        name: 'petty-cash.create',
        meta: { title: 'S-ERP | Petty Cash Create' },
        component: PettyCashCreate
    },

    {
        path: '/petty-cash/edit',
        name: 'petty-cash.edit',
        meta: { title: "S-ERP | Edit Petty Cash" },
        component: PettyCashEdit
    },

    {
        path: '/tab-daily-sales',
        name: 'tab-daily-sales.index',
        meta: { title: 'S-ERP | Tab Daily Sales Order' },
        component: TabDailySales
    },
]