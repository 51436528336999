<template>
    <div>
        <base-title title="Group Table" subtitle="List Data Group Table" />
        <v-data-table :headers="headers" :items="GroupTables.data" hide-default-footer>
            <template v-slot:[`item.is_active`]="{ item }">
                <v-chip x-small :color="getColor(item.is_active)" dark>
                    {{ item.is_active ? "Active" : "Not Active" }}
                </v-chip></template>
            <template v-slot:no-data> No data </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon color="warning" class="mr-2" @click.stop="editItem(item)">
                    mdi-pencil-box-outline
                </v-icon>
            </template>
            <template v-slot:top>
                <v-toolbar flat color="white">
                        <v-text-field v-model="search.search" append-icon="mdi-magnify" label="Search" outlined dense
                            hide-details></v-text-field>
                        <v-btn small color="darkblue" class="white--text mx-1" @click.stop="searching">Submit <v-icon
                                right>mdi-magnify</v-icon></v-btn>
                        <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
                            <v-icon right>mdi-close-circle-multiple </v-icon>
                        </v-btn>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialogForm" max-width="450px" persistent>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" small elevation="4" color="primary" @click="loader = 'loading3'">
                                Create
                                <v-icon right dark>mdi-plus-circle </v-icon>
                            </v-btn>
                        </template>
                        <v-form ref="form_data" v-model="form_valid" lazy-validation>
                            <v-card class="rounded-lg">
                                <v-card-title class="mb-3">
                                    <span class="headline">{{ form_title }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <base-column :lg="4" :md="4" :sm="12" v-if="(ROLE == 'Owner')">
                                        <v-select label="Branch" :items="branch" v-model="form_data.branch_id" dense
                                            item-text="name" item-value="id" outlined></v-select>
                                    </base-column>
                                    <v-text-field v-model="form_data.name" label="No table" outlined dense
                                        :rules="rule_name">
                                    </v-text-field>
                                    <v-textarea v-model="form_data.description" label="Description" outlined
                                        dense></v-textarea>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn small color="error darken-1" @click.stop="closedialogForm">Cancel
                                        <v-icon right dark>mdi-close </v-icon>
                                    </v-btn>
                                    <v-btn small color="primary" class="ma-2 white--text" @click.stop="save"
                                        :loading="btnLoading" :disabled="!form_valid || btnLoading">
                                        Save
                                        <v-icon right dark>mdi-content-save </v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                </v-toolbar>
            </template>
        </v-data-table>
        <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
    </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import { datatableMixin } from "@/mixins/datatable.js";
import BaseTitle from "@/components/base/BaseTitle.vue";
export default {
    name: "GroupTable",
    components: { BaseTitle },
    mixins: [datatableMixin],
    data: () => ({
        headers: [
            { text: "Name", value: "name" },
            { text: "Description", value: "description" },
            { text: "Actions", value: "actions", sortable: false, width: 100 },
        ],
        group_table: [],
        branch: [],
        page: 1,
        disabled: false,
        search: {
            search: '',
        },
        form_data: {
            name: "",
            branch_id: "",
            description: "",
        },
        form_default: {
            name: "",
            branch_id: "",
            description: "",
        },
        editedIndex: -1,
    }),

    created() {
        this.initialize();
        this.getBranch();
    },

    methods: {
        initialize: async function () {
            this.datatableLoading = true;
            let params = {
                search: this.search.search,
                page: this.page,
            }
            await this.$axios
                .get("/master/group-tables", {
                    params: params,
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.datatable = data.group_tables.data;
                    this.$store.commit("group_table/SET_GROUP_TABLES", data.group_tables);
                    this.datatableLoading = false;
                })
                .catch(() => {
                    this.datatableLoading = false;
                });
        },

        save: async function () {
            if (this.$refs.form_data.validate()) {
                this.btnLoading = true;
                if (this.editedIndex > -1) {
                    await this.$axios
                        .post(
                            "/master/group-tables/" + this.form_data.id,
                            {
                                _method: "PUT",
                                branch_id: this.form_data.branch_id,
                                name: this.form_data.name,
                                description: this.form_data.description,
                            },
                            {
                                headers: {
                                    Authorization: this.$store.getters["auth/Token"],
                                },
                            }
                        )
                        .then(({ data }) => {
                            this.initialize();
                            this.datatable.push(data.group_tables.data);
                            this.closedialogForm();
                            this.btnLoading = false;
                        })
                        .catch(() => {
                            this.btnLoading = false;
                        });
                } else {
                    await this.$axios
                        .post(
                            "/master/group-tables",
                            {
                                branch_id: this.form_data.branch_id,
                                name: this.form_data.name,
                                description: this.form_data.description,
                            },
                            {
                                headers: {
                                    Authorization: this.$store.getters["auth/Token"],
                                },
                            }
                        )
                        .then(({ data }) => {
                            this.initialize();
                            this.datatable.push(data.group_tables.data);
                            this.closedialogForm();
                            this.btnLoading = false;
                        })
                        .catch(() => {
                            this.btnLoading = false;
                        });
                }
            }
        },

        async getBranch() {
            await this.$axios("/master/branches?active=1", {
                headers: {
                    Authorization: this.$store.getters["auth/Token"],
                },
            }).then(({ data }) => {
                this.branch = data.branch.data;
            });
        },

        searching() {
            this.page = 1;
            this.initialize();
        },
        clear() {
            this.page = 1;
            this.search = {
                search: "",
            };
            this.initialize();
        },

    },
    watch: {
        currentPage(newValue) {
            this.page = newValue;
            this.initialize();
        },
    },
    computed: {
        form_title() {
            return this.editedIndex === -1 ? "Create Group Table" : "Edit Group Table";
        },

        ...mapGetters("auth", {
            ROLE: "Role",
        }),

        ...mapGetters("group_table", {
            GroupTables: "GroupTables",
        }),

        currentPage: {
            get() {
                return this.GroupTables.current_page;
            },
            set(val) {
                return this.$store.commit("group_table/SET_CURRENT_PAGE", val);
            },
        },
        lastPage: {
            get() {
                return this.GroupTables.last_page;
            },
        },
    },
};
</script>
  
   
  