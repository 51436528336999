<template>
    <div>
        <base-title title="Table" subtitle="List Data Table" />
        <v-data-table :headers="headers" :items="datatable" hide-default-footer>
            <template v-slot:[`item.is_active`]="{ item }">
                <v-chip x-small :color="getColor(item.is_active)" dark>
                    {{ item.is_active ? "Active" : "Not Active" }}
                </v-chip></template>
            <template v-slot:no-data> No data </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon color="warning" class="mr-2" @click.stop="editItem(item)">
                    mdi-pencil-box-outline
                </v-icon>
            </template>

            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-text-field v-model="search.search" append-icon="mdi-magnify" label="Search" outlined dense
                        hide-details></v-text-field>
                    <v-btn color="darkblue" class="white--text mx-1 mb-2" small @click.stop="searching">Submit <v-icon
                            right>mdi-magnify</v-icon></v-btn>
                    <v-btn small color="secondary" class="white--text mx-1 mb-2" @click.stop="clear">Clear
                        <v-icon right>mdi-close-circle-multiple </v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialogForm" max-width="450px" persistent>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" small elevation="4" color="primary" @click="loader = 'loading3'">
                                Create
                                <v-icon right dark>mdi-plus-circle </v-icon>
                            </v-btn>
                        </template>
                        <v-form ref="form_data" v-model="form_valid" lazy-validation>
                            <v-card class="rounded-lg">
                                <v-card-title class="mb-3">
                                    <span class="headline">{{ form_title }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <base-column :lg="4" :md="4" :sm="12" v-if="(ROLE == 'Owner')">
                                        <v-select label="Branch" :items="branch" v-model="form_data.branch_id" dense
                                            item-text="name" item-value="id" outlined></v-select>
                                    </base-column>
                                    <v-text-field type="text" v-model="form_data.no_table" label="No table" outlined dense
                                        :rules="rule_no_table">
                                    </v-text-field>
                                    <v-text-field type="number" v-model="form_data.capacity" label="Capacity" outlined dense
                                        :rules="rule_capacity">
                                    </v-text-field>
                                    <v-select v-model="form_data.group_table_id" :items="group_table" item-text="name"
                                        item-value="id" label="Group Table" outlined dense :rules="rule_group_table_id">
                                    </v-select>
                                    <v-select label="Category Table" v-model="form_data.category_table"
                                        :items="category_table" item-text="name" item-value="id" outlined dense></v-select>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn small color="error darken-1" @click.stop="closedialogForm">Cancel
                                        <v-icon right dark>mdi-close </v-icon>
                                    </v-btn>
                                    <v-btn small color="primary" class="ma-2 white--text" @click.stop="save"
                                        :loading="btnLoading" :disabled="!form_valid || btnLoading">
                                        Save
                                        <v-icon right dark>mdi-content-save </v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                </v-toolbar>
            </template>
        </v-data-table>
        <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
    </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import { datatableMixin } from "@/mixins/datatable.js";
import BaseTitle from "@/components/base/BaseTitle.vue";
export default {
    name: "Table",
    components: { BaseTitle },
    mixins: [datatableMixin],
    data: () => ({
        headers: [
            { text: "No Table", value: "no_table" },
            { text: "Capacity", value: "capacity" },
            { text: "Table", value: "group_table.name" },
            { text: "Actions", value: "actions", sortable: false, width: 100 },
        ],
        group_table: [],
        branch: [],
        page: 1,
        disabled: false,
        search: {
            search: "",
        },
        category_table: [
            { id: 1, name: "Mart" },
            { id: 2, name: "Restaurant" },
            { id: 3, name: "Entertain" },
        ],
        form_data: {
            branch_id: "",
            no_table: "",
            capacity: "",
            group_table_id: "",
        },
        form_default: {
            branch_id: "",
            no_table: "",
            capacity: "",
            group_table_id: "",
        },
        editedIndex: -1,
    }),

    created() {
        this.initialize();
        this.getGroupTable();
        this.getBranch();
        this.getPosType();
    },

    methods: {
        initialize: async function () {
            this.datatableLoading = true;
            let params = {
                search: this.search.search,
                page: this.page
            }
            await this.$axios
                .get("/master/table", {
                    params: params,
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.datatable = data.table.data;
                    this.$store.commit("table/SET_TABLES", data.table);
                    this.datatableLoading = false;
                })
                .catch(() => {
                    this.datatableLoading = false;
                });
        },

        async getPosType() {
            await this.$axios
                .get("/master/pos-types", {
                    headers: {
                        Authorization: this.TOKEN,
                    },
                })
                .then(({ data }) => {
                    this.pos_type = data.pos_type
                });
        },

        searching() {
            this.page = 1;
            this.initialize();
        },

        clear() {
            this.page = 1;
            this.search = {
                search: "",
            };
            this.initialize();
        },


        save: async function () {
            if (this.$refs.form_data.validate()) {
                this.btnLoading = true;
                if (this.editedIndex > -1) {
                    await this.$axios
                        .post(
                            "/master/table/" + this.form_data.id,
                            {
                                _method: "PUT",
                                branch_id: this.form_data.branch_id,
                                no_table: this.form_data.no_table,
                                capacity: this.form_data.capacity,
                                group_table_id: this.form_data.group_table_id,
                            },
                            {
                                headers: {
                                    Authorization: this.$store.getters["auth/Token"],
                                },
                            }
                        )
                        .then(({ data }) => {
                            Object.assign(this.datatable[this.editedIndex], data.table.data);
                            this.initialize();
                            this.closedialogForm();
                            this.btnLoading = false;
                        })
                        .catch(() => {
                            this.btnLoading = false;
                        });
                } else {
                    await this.$axios
                        .post(
                            "/master/table",
                            {
                                branch_id: this.form_data.branch_id,
                                no_table: this.form_data.no_table,
                                capacity: this.form_data.capacity,
                                group_table_id: this.form_data.group_table_id,
                            },
                            {
                                headers: {
                                    Authorization: this.$store.getters["auth/Token"],
                                },
                            }
                        )
                        .then(({ data }) => {
                            this.datatable.push(data.table.data);
                            this.initialize();
                            this.closedialogForm();
                            this.btnLoading = false;
                        })
                        .catch(() => {
                            this.btnLoading = false;
                        });
                }
            }
        },

        async getBranch() {
            await this.$axios("/master/branches?active=1", {
                headers: {
                    Authorization: this.$store.getters["auth/Token"],
                },
            }).then(({ data }) => {
                this.branch = data.branch.data;
            });
        },


        async getGroupTable() {
            await this.$axios
                .get("/master/group-tables", {
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.group_table = data.group_tables.data;
                })
                .catch(() => {
                    this.datatableLoading = false;
                });
        },
    },

    watch: {
        currentPage(newValue) {
            this.page = newValue;
            this.initialize();
        },
    },
    computed: {
        form_title() {
            return this.editedIndex === -1 ? "Create Table" : "Edit Table";
        },

        ...mapGetters("auth", {
            ROLE: "Role",
        }),

        ...mapGetters("table", {
            Tables: "Tables",
        }),

        currentPage: {
            get() {
                return this.Tables.current_page;
            },
            set(val) {
                return this.$store.commit("table/SET_CURRENT_PAGE", val);
            },
        },
        lastPage: {
            get() {
                return this.Tables.last_page;
            },
        },
    },
};
</script>
  
   
  