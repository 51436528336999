<template>
  <div>
    <v-divider class="mx-4 mt-2"></v-divider>
    <v-row class="mt-3 mx-1">
      <base-column :lg="3" :md="4" :sm="12">
        <v-select label="Type" :items="type" outlined dense v-model="RequestOrder.type"></v-select>
      </base-column>
      <base-column :lg="3" :md="4" :sm="12">
        <v-text-field type="date" label="Request Date" outlined dense v-model="RequestOrder.purchase_date"
          :rules="rule_purchase_date"></v-text-field>
      </base-column>
      <base-column :lg="3" :md="4" :sm="12">
        <v-text-field :rules="rule_disc" type="number" outlined dense label="Discount" append-icon="mdi-percent"
          v-model="RequestOrder.discount_percent"></v-text-field>
      </base-column>
      <base-column :lg="3" :md="4" :sm="12">
        <v-select append-icon="mdi-percent" label="PPH" :items="PPHS.data" item-text="percentage" item-value="id"
          v-model="RequestOrder.pph_id" outlined dense @change="changePPH"></v-select>
      </base-column>
      <base-column :lg="3" :md="4" :sm="12">
        <v-switch label="Use VAT" v-model="RequestOrder.use_vat" class="mt-2"></v-switch>
      </base-column>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import BaseColumn from "@/components/base/BaseColumn.vue";
export default {
  name: "RequestOrderForm",

  data() {
    return {
      branch: [],
      type: ["Material"],
    };
  },
  created() {
    this.getBranch();
    this.getPph();
  },

  methods: {
    ...mapActions("pph", ["setPph"]),
    async getBranch() {
      await this.$axios("/master/branches", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.branch = data.branch.data;
      });
    },

    changePPH(val) {
      this.$axios
        .get("/master/pph/" + val, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.setPph(data.pph);
          this.RequestOrder.pph_percentage = data.pph.percentage;
        });
    },

    async getPph() {
      await this.$axios("/master/pph", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.$store.commit("pph/SET_PPHS", data.pph);
      });
    },

  },

  computed: {
    ...mapState("request_order", {
      RequestOrder: (state) => state.request_order,
    }),

    ...mapGetters("pph", {
      PPHS: "Pphs",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),
  },
  components: {
    BaseColumn,
  },
};
</script>