<template>
  <main-layout>
    <base-header>
      <template #icon> mdi-file</template>
      <template #name> Create Ingoing</template>
      <template #breadcrumbs>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </template>
    </base-header>

    <v-card class="mx-1">
      <!-- App Bar -->
      <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
        <v-toolbar-title>Ingoing Information</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small color="secondary" class="ma-2" @click="backForm()">
          <v-icon>mdi-arrow-left</v-icon> Back
        </v-btn>
        <v-btn small color="secondary" class="ma-2" @click="clearForm()">
          Clear
          <v-icon>mdi-close-circle-multiple </v-icon>
        </v-btn>
        <v-btn small class="ma-2" color="primary" @click.stop="store" :loading="btnLoading"
          :disabled="!form_valid || btnLoading">
          Save
          <v-icon right>mdi-content-save-all</v-icon>
        </v-btn>
      </v-app-bar>
      <v-form ref="Ingoing" v-model="form_valid" lazy-validation>
        <form-ingoing />
        <form-item />
      </v-form>
    </v-card>
  </main-layout>
</template>

<script>
import Form from "./Form.vue";
import FormItem from "./FormItem.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import BaseHeader from "@/components/base/BaseHeader.vue";
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "IngoingCreate",

  data() {
    return {
      btnLoading: false,
      form_valid: true,
    };
  },
  created() {
    this.breadcrumbs = [
      { text: "Transaction", disabled: false },
      { text: "Ingoing", disabled: true },
      { text: "Create", disabled: true },
    ];
  },

  methods: {
    ...mapMutations("ingoing", ["CLEAR_STATE"]),
    store: async function () {
      if (this.$refs.Ingoing.validate()) {
        this.Ingoing.branch_id = this.BRANCH;
        this.date = this.Ingoing.ingoing_date;
        this.qty_in = this.Ingoing.details.map((item) => parseInt(item.qty_in));
        this.btnLoading = true;
        await this.$axios
          .post("/transaction/ingoings", this.Ingoing,
            {
              headers: { Authorization: this.$store.getters["auth/Token"] },
            })
          .then(() => {
            this.btnLoading = false;
            this.CLEAR_STATE();
            this.$router.push({ name: "inventory-in.index" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },
    backForm() {
      this.CLEAR_STATE();
      this.$router.push({ name: "inventory-in.index" });
    },

    clearForm() {
      this.CLEAR_STATE();
    },
  },
  computed: {
    Ingoing() {
      return this.$store.getters["ingoing/Ingoing"];
    },

    ...mapGetters("auth", {
      BRANCH: "Branch",
    }),
  },

  components: {
    MainLayout,
    BaseHeader,
    "form-ingoing": Form,
    "form-item": FormItem,
  },
};
</script>
