<template>
    <div>
        <base-title title="Unit" subtitle="List Data Unit" />
        <v-data-table :headers="headers" :items="datatable" class="elevation-1" :loading="datatableLoading"
            loading-text="Please wait..." hide-default-footer>
            <template v-slot:[`item.is_active`]="{ item }">
                <v-chip x-small :color="getColor(item.is_active)" dark>
                    {{ item.is_active ? "Active" : "Not Active" }}
                </v-chip></template>
            <template v-slot:no-data> No data </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="text-center">
                    <v-col cols="12"> <strong>ID:</strong>{{ item.id }} </v-col>
                </td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon color="warning" class="mr-2" @click.stop="editItem(item)">
                    mdi-pencil-box-outline
                </v-icon>
            </template>

            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-text-field v-model="search.search" append-icon="mdi-magnify" label="Search" outlined dense
                        hide-details></v-text-field>
                    <v-btn color="darkblue" class="white--text mx-1 mb-2" small @click.stop="searching">Submit <v-icon
                            right>mdi-magnify</v-icon></v-btn>
                    <v-btn small color="secondary" class="white--text mx-1 mb-2" @click.stop="clear">Clear
                        <v-icon right>mdi-close-circle-multiple </v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialogForm" max-width="450px" persistent>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" small elevation="1" color="primary" class="ma-2 white--text"
                                @click="loader = 'loading3'">
                                Create
                                <v-icon right dark>mdi-plus-circle </v-icon>
                            </v-btn>
                        </template>
                        <v-form ref="form_data" v-model="form_valid" lazy-validation>
                            <v-card class="rounded-lg">
                                <v-card-title class="mb-3">
                                    <span class="headline">{{ form_title }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-text-field v-model="form_data.name" label="Unit Name" outlined dense
                                        :rules="rule_name">
                                    </v-text-field>
                                    <base-column :lg="4" :md="4" :sm="12" v-if="(ROLE == 'Owner')">
                                        <v-select label="Branch" :items="branch" v-model="form_data.branch_id" dense
                                            item-text="name" item-value="id" outlined></v-select>
                                    </base-column>
                                    <v-textarea v-model="form_data.description" label="Description" outlined dense rows="2">
                                    </v-textarea>
                                    <v-switch v-model="form_data.is_active" label="USE">
                                    </v-switch>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn small color="error darken-1" @click.stop="closedialogForm">Cancel
                                        <v-icon right dark>mdi-close </v-icon>
                                    </v-btn>
                                    <v-btn small color="primary" class="ma-2 white--text" @click.stop="save"
                                        :loading="btnLoading" :disabled="!form_valid || btnLoading">
                                        Save
                                        <v-icon right dark>mdi-content-save </v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                </v-toolbar>
            </template>
        </v-data-table>
        <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { datatableMixin } from "@/mixins/datatable.js";
import BaseTitle from "@/components/base/BaseTitle.vue";

export default {
    components: { BaseTitle },
    name: "Unit",
    mixins: [datatableMixin],
    data: () => ({
        headers: [
            { text: "Unit Name", value: "name" },
            { text: "Description", value: "description" },
            { text: "Status", value: "is_active" },
            { text: "Actions", value: "actions", sortable: false, width: 100 },
        ],
        branch: [],
        page: 1,

        form_data: {
            name: "",
            branch_id: "",
            description: "",
            is_active: true,
        },
        form_default: {
            name: "",
            branch_id: "",
            description: "",
            is_active: true,
        },
        disabled: false,

        search: {
            search: "",
        },
    }),

    created() {
        this.initialize();
        this.getBranch();
    },

    methods: {
        initialize: async function () {
            this.datatableLoading = true;
            let params = {
                page: this.page,
                search: this.search.search,
            }
            await this.$axios
                .get("/master/unit", {
                    params: params,
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.datatable = data.unit.data;
                    this.$store.commit("unit/SET_UNITS", data.unit);

                    this.datatableLoading = false;
                })
                .catch(() => {
                    this.datatableLoading = false;
                });
        },

        save: async function () {
            if (this.$refs.form_data.validate()) {
                this.btnLoading = true;
                if (this.editedIndex > -1) {
                    await this.$axios
                        .post(
                            "/master/unit/" + this.form_data.id,
                            {
                                _method: "PUT",
                                name: this.form_data.name,
                                branch_id: this.form_data.branch_id,
                                description: this.form_data.description,
                                is_active: this.form_data.is_active,
                            },
                            {
                                headers: {
                                    Authorization: this.$store.getters["auth/Token"],
                                },
                            }
                        )
                        .then(() => {
                            this.initialize();
                            this.closedialogForm();
                            this.btnLoading = false;
                        })
                        .catch(() => {
                            this.btnLoading = false;
                        });
                } else {
                    await this.$axios
                        .post(
                            "/master/unit",
                            {
                                name: this.form_data.name,
                                branch_id: this.form_data.branch_id,
                                description: this.form_data.description,
                                is_active: this.form_data.is_active,
                            },
                            {
                                headers: {
                                    Authorization: this.$store.getters["auth/Token"],
                                },
                            }
                        )
                        .then(() => {
                            this.initialize();
                            this.closedialogForm();
                            this.btnLoading = false;
                        })
                        .catch(() => {
                            this.btnLoading = false;
                        });
                }
            }
        },

        async getBranch() {
            await this.$axios("/master/branches?active=1", {
                headers: {
                    Authorization: this.$store.getters["auth/Token"],
                },
            }).then(({ data }) => {
                this.branch = data.branch.data;
            });
        },

        searching() {
            this.page = 1;
            this.initialize();
        },

        clear() {
            this.page = 1;
            this.search = {
                search: "",
            };
            this.initialize();
        },
    },

    watch: {
        currentPage(newValue) {
            this.page = newValue;
            this.initialize();
        },
    },
    computed: {
        form_title() {
            return this.editedIndex === -1 ? "Create Unit" : "Edit Unit";
        },

        ...mapGetters("auth", {
            ROLE: "Role",
        }),

        ...mapGetters("unit", {
            Units: "Units",
        }),

        currentPage: {
            get() {
                return this.Units.current_page;
            },
            set(val) {
                return this.$store.commit("unit/SET_CURRENT_PAGE", val);
            },
        },
        lastPage: {
            get() {
                return this.Units.last_page;
            },
        },
    },
};
</script>