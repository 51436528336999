import InventoryInIndex from '@/views/transaction/ingoing/Index.vue'
import InventoryInCreate from '@/views/transaction/ingoing/Create.vue'
import InventoryInEdit from '@/views/transaction/ingoing/Edit.vue'
import InventoryInView from '@/views/transaction/ingoing/View.vue'

import InventoryOutIndex from '@/views/transaction/outgoing/Index.vue'
import InventoryOutCreate from '@/views/transaction/outgoing/Create.vue'
import InventoryOutEdit from '@/views/transaction/outgoing/Edit.vue'
import InventoryOutView from '@/views/transaction/outgoing/View.vue'

import StockCardTabs from '@/views/transaction/stock_card/tabs.vue'
import StockOpnameTabs from '@/views/transaction/stock_opname/tabs.vue'

export default [
    {
        path: '/inventory-in',
        name: "inventory-in.index",
        meta: { title: "S-ERP | Inventory In" },
        component: InventoryInIndex
    },

    {
        path: '/inventory-in/create',
        name: "inventory-in.create",
        meta: { title: "S-ERP | Inventory In Create" },
        component: InventoryInCreate
    },

    {
        path: '/inventory-in/edit',
        name: "inventory-in.edit",
        meta: { title: "S-ERP | Inventory In Edit" },
        component: InventoryInEdit
    },

    {
        path: '/inventory-in/view/:id',
        name: "inventory-in.view",
        meta: { title: "S-ERP | Inventory In View" },
        component: InventoryInView
    },

    {
        path: '/inventory-out',
        name: "inventory-out.index",
        meta: { title: "S-ERP | Inventory Out" },
        component: InventoryOutIndex
    },

    {
        path: '/inventory-out/create',
        name: "inventory-out.create",
        meta: { title: "S-ERP | Inventory Out Create" },
        component: InventoryOutCreate
    },

    {
        path: '/inventory-out/edit',
        name: "inventory-out.edit",
        meta: { title: "S-ERP | Inventory Out Edit" },
        component: InventoryOutEdit
    },

    {
        path: '/inventory-out/view/:id',
        name: "inventory-out.view",
        meta: { title: "S-ERP | Inventory Out View" },
        component: InventoryOutView
    },
    {
        path: '/stock-opname-tabs',
        name: "stock-opname-tabs",
        meta: { title: "S-ERP | Stock Opname" },
        component: StockOpnameTabs
    },
    {
        path: '/stock-card-tabs',
        name: "stock-card-tabs",
        meta: { title: "S-ERP | Stock Card" },
        component: StockCardTabs
    },

]