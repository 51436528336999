<template>
    <v-col xs="12" sm="12" md="12" lg="12">
        <v-responsive width="100%" v-if="$vuetify.breakpoint.xsOnly" />
        <br>
        <v-card elevation="5" rounded>
            <v-row class="mx-1 mb-1">
                <v-col xs="12" sm="12" md="2" lg="2">
                    <v-text-field type="date" label="From date" outlined dense v-model="search.start_date">
                    </v-text-field>
                </v-col>
                <v-col xs="12" sm="12" md="2" lg="2">
                    <v-text-field type="date" label="End date" outlined dense v-model="search.end_date">
                    </v-text-field>
                </v-col>
                <v-col xs="12" sm="12" md="2" lg="2" v-if="(ROLE == 'Owner')">
                    <v-select :items="branch.data" item-text="name" item-value="id" outlined dense label="Branch"
                        v-model="search.branch_id"></v-select>
                </v-col>
                <v-col xs="12" sm="12" md="2" lg="2">
                    <v-text-field label="Search" outlined dense v-model="search.search">
                    </v-text-field>
                </v-col>
                <v-col xs="12" sm="12" md="2" lg="2">
                    <v-btn small color="darkblue" class="white--text mx-1" @click.stop="init">Submit <v-icon
                            right>mdi-magnify</v-icon></v-btn>
                    <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
                        <v-icon right>mdi-close-circle-multiple </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table :loading="loading" :headers="headers" :items="data" class="elevation-1" hide-default-footer>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip color="dark" bottom>S
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mr-2" color="warning" v-bind="attrs" icon small @click.stop="edit(item.id)"
                                v-on="on">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Sales Order</span>
                    </v-tooltip>
                </template>
            </v-data-table>
            <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled">
            </v-pagination>
        </v-card>
    </v-col>
</template>
  
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
    name: "StockOpnameListProduct",
    data() {
        return {
            headers: [
                { text: "Item Name", value: "item_name" },
                { text: "Begin", value: "begin" },
                { text: "In", value: "in" },
                { text: "Out", value: "out" },
                { text: "End", value: "end" },
                { text: "Actions", value: "actions", sortable: false }
            ],
            disabled: false,
            loading: false,
            branch: [],
            data: [],
            page: 1,
            loading_download: false,
            flag: null,
            search: {
                start_date: new Date().toJSON().slice(0, 10),
                end_date: new Date().toJSON().slice(0, 10),
                search: null,
                branch_id: null,
            },
        };
    },
    methods: {
        ...mapMutations("stock_opname", ["SET_STOCK_OPNAME_MATERIAL", "SET_CURRENT_PAGE_MATERIAL"]),
        async init() {
            this.loading = true;
            this.disabled = true;
            let params = {
                page: this.page,
                status: this.search.status,
                search: this.search.search,
                from_date: this.search.from_date,
                end_date: this.search.end_date,
            };

            await this.$axios
                .get("http://localhost:3000/stock_opname", {
                    params: params,
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.data = data;
                    this.loading = false;
                    this.disabled = false;
                });
        },


        async getBranch() {
            await this.$axios
                .get("/master/branches", {
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.branch = data.branch;
                });
        },

        view(id) {
            this.$router.push("/branch-sales-order/view/" + id);
        },

        searching() {
            this.page = 1;
            this.init();
        },
        clear() {
            this.search = {
                search: "",
                branch_id: "",
                start_date: new Date().toJSON().slice(0, 10),
                end_date: new Date().toJSON().slice(0, 10),
            };
            this.init();
        },
    },

    created() {
        this.init();
        this.getBranch();
    },

    watch: {
        currentPage(newValue) {
            this.page = newValue;
            this.init();
        },
    },
    computed: {
        ...mapGetters("stock_opname", {
            StockOpnameProduct: "StockOpnameProduct",
        }),

        ...mapGetters("auth", {
            ROLE: "Role",
        }),


        currentPage: {
            get() {
                return this.StockOpnameProduct.current_page;
            },
            set(val) {
                return this.$store.commit("stock_opname/SET_CURRENT_PAGE_MATERIAL", val);
            },
        },
        lastPage: {
            get() {
                return this.StockOpnameProduct.last_page;
            },
        },
    },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
    background-color: #ebebeb59;
}

::v-deep th {
    color: #000 !important;
}
</style>