<template>
  <div>
    <v-data-table :loading="loading" :headers="headers" :items="Services.data" class="elevation-1" hide-default-footer>
      <template v-slot:[`item.use_default`]="{ item }">
        <v-icon v-if="item.use_default == 1" color="success">
          mdi-check-circle
        </v-icon>
        <v-icon v-else color="error"> mdi-close-circle </v-icon>
      </template>
      <template v-slot:[`item.is_active`]="{ item }">
        <v-icon v-if="item.is_active == 1" color="success">
          mdi-check-circle
        </v-icon>
        <v-icon v-else color="error"> mdi-close-circle </v-icon>
      </template>
      <template v-slot:no-data> No data </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="danger" class="mr-2" @click.stop="deleteService(item)">
          mdi-trash-can
        </v-icon>
        <v-icon color="warning" class="mr-2" @click.stop="edit(item)">
          mdi-pencil-box-outline
        </v-icon>
        
      </template>

      <template v-slot:top>
        <v-row dense class="mx-2">
          <base-column :lg="4" :md="4" :sm="6">
            <v-text-field v-model="search.search" append-icon="mdi-magnify" label="Search" outlined dense
              hide-details></v-text-field>
          </base-column>
          <base-column :lg="3" :md="4" :sm="6" v-if="(ROLE == 'Owner')">
            <v-select v-model="search.branch_id" :items="branch" item-text="name" item-value="id" label="Branch" outlined
              dense></v-select>
          </base-column>
          <base-column :lg="3" :md="4" :sm="6">
            <v-btn small color="darkblue" class="white--text" @click.stop="searching">Submit <v-icon
                right>mdi-magnify</v-icon></v-btn>
            <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
              <v-icon right>mdi-close-circle-multiple </v-icon>
            </v-btn>
          </base-column>
        </v-row>
      </template>
    </v-data-table>

    <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
  </div>
</template>

<script>
import BaseColumn from "@/components/base/BaseColumn";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "ServiceList",
  data() {
    return {
      headers: [
        { text: "Service Type Name", value: "" },
        { text: "Percentage", value: "" },
        { text: "Status", value: "" },
        { text: "Use Default", value: "" },
        { text: "Action", value: "", sortable: false, width: 100 },
      ],
      page: 1,
      branch: [],
      disabled: false,
      loading: false,
      status: ["Active", "Inactive"],
      search: {
        search: null,
      },
    };
  },

  created() {
    this.getService();
    this.getBranch();
  },
  methods: {
    ...mapMutations("service", ["SET_SERVICES","SET_CURRENT_PAGE"]),

    async getService() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        search: this.search.search,
      };

      await this.$axios
        .get("/master/devices", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_SERVICES(data.devices);
          this.loading = false;
          this.disabled = false;
        });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch.data;
        });
    },

    async deleteService(item) {
      await this.$axios
        .delete("/master/device-connection-settings/" + item.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(() => {
          this.$router.go();
        });
    },

    searching() {
      this.page = 1;
      this.getService();
    },
    clear() {
      this.page = 1;
      this.search = {
        search: "",
      };
      this.getService();
    },

    edit(item) {
      this.$router.push("/master/device/edit/" + item.id);
    },
  },

  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.getService();
    },
  },

  computed: {
    ...mapGetters("service", {
      Services: "Services",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    currentPage: {
      get() {
        return this.Services.current_page;
      },
      set(val) {
        return this.$store.commit("service/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.Services.last_page;
      },
    },
  },

  components: {
    BaseColumn,
  },
};
</script>