<template>
  <div>
    <v-row dense class="mx-3 mt-2">
      <base-column :lg="3" :md="6" :sm="12">
        <v-text-field readonly placeholder="Sales Invoice Number" v-model="SalesInvoice.no_invoice" outlined dense
          :append-icon="'mdi-magnify'" @click:append="openSalesInvoiceDialog()"></v-text-field>
      </base-column>
      <base-column :lg="3" :md="6" :sm="12">
        <v-text-field readonly placeholder="Sales Order Number" v-model="SalesInvoice.no_so" outlined dense
          :append-icon="'mdi-magnify'" @click:append="openSalesOrderDialog()"></v-text-field>
      </base-column>
    </v-row>
    <v-divider class="mx-3"></v-divider>
    <v-row dense class="mx-3 mt-2">
      <base-column :lg="3" :md="6" :sm="12">
        <v-card max-width="100%" height="95%">
          <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title>
              <v-icon>mdi-account</v-icon>
              {{ SalesInvoice.customer_name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn outlined small color="primary" @click="openCustomerDialog">
              SEARCH
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-app-bar>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>Email : {{ SalesInvoice.customer_email }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Phone : {{ SalesInvoice.customer_phone }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Address : {{ SalesInvoice.customer_address }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </base-column>
      <base-column :lg="3" :md="6" :sm="12">
        <v-card max-width="100%" height="95%">
          <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title>
              <v-icon>mdi-account</v-icon>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn outlined small color="primary" @click="openBankAccountDialog">
              SEARCH
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-app-bar>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>Account Name : {{ SalesInvoice.name_account }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Account Number : {{ SalesInvoice.number_account }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Bank Name : {{ SalesInvoice.bank_name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </base-column>
      <v-row class="mt-1 mx-1">
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field type="date" label="Order Date" outlined dense v-model="SalesInvoice.date"
            :rules="rule_order_date"></v-text-field>
          <v-text-field readonly outlined dense label="Discount Nominal" v-model="SalesInvoice.discount"
            disabled></v-text-field>
          <v-switch label="Use VAT" v-model="SalesInvoice.use_vat"></v-switch>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field type="date" label="Delivery Date" outlined dense v-model="SalesInvoice.delivery_date"
            :rules="rule_delivery_date"></v-text-field>
          <v-text-field :rules="rule_disc" type="number" outlined dense label="Discount" append-icon="mdi-percent"
            v-model="SalesInvoice.discount_percent"></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-select label="Payment Method" outlined dense v-model="SalesInvoice.payment_method_id"
            :items="payment_method.data" item-text="name" item-value="id"></v-select>
          <v-select append-icon="mdi-percent" label="PPH" :items="PPHS.data" item-text="percentage" item-value="id"
            v-model="SalesInvoice.pph_id" outlined dense @change="changePPH"></v-select>
        </base-column>
      </v-row>
    </v-row>
    <br>
    <v-divider class="mx-3"></v-divider>
    <v-dialog v-if="dialogCustomer" v-model="dialogCustomer" max-width="1200px" persistent>
      <customer-modal v-on:closeCustomerDialog="closeCustomerDialog" v-on:selectCustomer="selectCustomer" />
    </v-dialog>
    <v-dialog v-if="dialogBankAccount" v-model="dialogBankAccount" max-width="1200px" persistent>
      <bank-account-modal v-on:closeBankAccountDialog="closeBankAccountDialog"
        v-on:selectBankAccount="selectBankAccount" />
    </v-dialog>
    <v-dialog v-if="dialogSalesOrder" v-model="dialogSalesOrder" max-width="1200px" persistent>
      <sales-order-modal v-on:closeSalesOrderDialog="closeSalesOrderDialog" v-on:selectSalesOrder="selectSalesOrder" />
    </v-dialog>
    <v-dialog v-if="dialogSalesInvoice" v-model="dialogSalesInvoice" max-width="1200px" persistent>
      <sales-invoice-modal v-on:closeSalesInvoiceDialog="closeSalesInvoiceDialog"
        v-on:selectSalesInvoice="selectSalesInvoice" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import BaseColumn from "@/components/base/BaseColumn.vue";
import CustomerModal from '@/components/modal/CustomerModal.vue';
import BankAccountModal from '@/components/modal/BankAccountModal.vue';
import SalesOrderModal from "@/components/modal/SalesOrderModal.vue";
import SalesInvoiceModal from "@/components/modal/SalesInvoiceModal.vue";
export default {
  name: "SalesOrderForm",

  data() {
    return {
      dialogCustomer: false,
      dialogBankAccount: false,
      dialogSalesOrder: false,
      dialogSalesInvoice: false,

      payment_method: [],
    };
  },
  created() {
    this.getPph();
    this.getPaymentMethod();
  },
  methods: {
    ...mapActions("pph", ["setPph"]),
    ...mapMutations("sales_invoice", [
      "SET_SALES_INVOICE",
      "SET_CUSTOMER",
      "SET_BANK_ACCOUNT",
    ]),

    changePPH(val) {
      console.log(val);
      this.$axios
        .get("/master/pph/" + val, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.setPph(data.pph);
          this.SalesOrder.pph_percentage = data.pph.percentage;
        });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch;
        });
    },

    async getPph() {
      await this.$axios("/master/pph", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.$store.commit("pph/SET_PPHS", data.pph);
      });
    },

    async getPaymentMethod() {
      await this.$axios("/master/payment-method", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.payment_method = data.payment_method;
      });
    },

    openCustomerDialog() {
      this.dialogCustomer = true;
    },

    closeCustomerDialog() {
      this.dialogCustomer = false;
    },

    openSalesOrderDialog() {
      this.dialogSalesOrder = true;
    },
    closeSalesOrderDialog() {
      this.dialogSalesOrder = false;
    },

    selectSalesOrder(sales_order) {
      this.$axios
        .get("/transaction/sales-orders/" + sales_order.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          console.log(data);
          this.$store.commit("sales_invoice/CLEAR_STATE");
          this.$store.commit("sales_invoice/SET_SO", data.sales_orders);

        })
        .catch(() => {
          this.loading = true;
        });
    },

    openSalesInvoiceDialog() {
      this.dialogSalesInvoice = true;
    },
    closeSalesInvoiceDialog() {
      this.dialogSalesInvoice = false;
    },

    selectSalesInvoice(sales_invoice) {
      this.$axios
        .get("/transaction/sales-invoice/" + sales_invoice.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          console.log(data);
          this.$store.commit("sales_invoice/CLEAR_STATE");
          this.$store.commit("sales_invoice/SET_INV", data.sales_invoices);

        })
        .catch(() => {
          this.loading = true;
        });
    },

    selectCustomer(val) {
      this.SET_CUSTOMER({
        id: val.id,
        name: val.name,
        phone: val.phone,
        email: val.email,
        address: val.address,
      });
      this.dialogCustomer = false;
    },

    openBankAccountDialog() {
      this.dialogBankAccount = true;
    },

    closeBankAccountDialog() {
      this.dialogBankAccount = false;
    },

    selectBankAccount(val) {
      this.SET_BANK_ACCOUNT({
        id: val.id,
        name_account: val.name_account,
        number_account: val.number_account,
        bank_name: val.bank_name,
      });
      this.dialogBankAccount = false;
    },

  },

  computed: {
    ...mapState("sales_invoice", {
      SalesInvoice: (state) => state.sales_invoice,
    }),

    ...mapGetters("pph", {
      PPHS: "Pphs",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),

  },
  components: {
    BaseColumn,
    CustomerModal,
    BankAccountModal,
    "sales-order-modal": SalesOrderModal,
    "sales-invoice-modal": SalesInvoiceModal,
  },
};
</script>