<template>
  <v-col xs="12" sm="12" md="12" lg="12">
    <v-responsive width="100%" v-if="$vuetify.breakpoint.xsOnly" />
    <br>
    <v-card elevation="5" rounded>
      <v-row class="mx-1 mb-1">
        <v-col xs="12" sm="12" md="4" lg="2">
          <v-select label="Sort" items="short" outlined dense>
          </v-select>
        </v-col>
        <v-col xs="12" sm="12" md="4" lg="3">
          <v-text-field type="text" label="Search" outlined dense v-model="search.search">
          </v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="4" lg="2">
          <v-btn small color="darkblue" class="white--text mx-1" @click.stop="init">Submit <v-icon
              right>mdi-magnify</v-icon></v-btn>
          <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
            <v-icon right>mdi-close-circle-multiple </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-1 mb-1">
        <v-col xs="12" sm="12" md="12" lg="12">
          <v-card elevation="4">
            <template>
              <v-breadcrumbs :items="items">
                <template v-slot:item="{ item }">
                  <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                    {{ item.text.toUpperCase() }}
                  </v-breadcrumbs-item>
                </template>
              </v-breadcrumbs>
            </template>
            <v-divider></v-divider>
            <v-divider></v-divider>
            <v-simple-table fixed-header height="300px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>
                      Product Info
                    </th>
                    <th>
                      Product Name
                    </th>
                    <th>
                      Price
                    </th>
                    <th>
                      Stock
                    </th>
                    <th>
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img src="https://cdn.vuetifyjs.com/images/lists/1.jpg" alt="product" width="100px">
                    </td>
                    <td>
                      Product Name
                    </td>
                    <td>
                      1.000
                    </td>
                    <td>
                      100
                    </td>
                    <td>
                      <v-switch>
                        <template v-slot:label>
                          <span class="font-weight-bold">Active</span>
                        </template>
                      </v-switch>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "AllOrder",
  data() {
    return {
      short: ["Newest", "Oldest", "Name", "Price", "Stock"],
      search: {
        start_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
        end_date: new Date().toJSON().slice(0, 10),
      },
    };
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  background-color: #ebebeb59;
}

::v-deep th {
  color: #000 !important;
}
</style>