<template>
  <div>
    <base-title title="Users" subtitle="List Data Users" />
    <v-btn to="/master/users/form" small elevation="1" color="primary" class="white--text float-right mx-2"
      @click="loader = 'loading3'">
      Create
      <v-icon right dark>mdi-plus-circle </v-icon>
    </v-btn>
    <user-list />
  </div>
</template>

<script>
import UserList from "@/views/master/user/List";
import BaseTitle from "@/components/base/BaseTitle";
export default {
  name: "ItemIndex",

  data() {
    return {
      form_valid: true,
      loader: "",
      expanded: [],
      search: {
        search: "",
        status: null,
      },
      loading: false,
    };
  },

  components: {
    BaseTitle,
    "user-list": UserList,
  },
};
</script>