<template>
  <div>
    <base-title title="Device" subtitle="List Data Device" />
    <v-btn to="/master/device/create" small elevation="1" color="primary" class=" white--text float-right mx-2"
      @click="loader = 'loading3'">
      Create
      <v-icon right dark>mdi-plus-circle </v-icon>
    </v-btn>
    <device-list />
  </div>
</template>

<script>
import BaseTitle from "@/components/base/BaseTitle.vue";
import DeviceList from "@/views/master/master_device/List.vue";
export default {
  components: { BaseTitle, DeviceList },
  name: "DeviceIndex",
};
</script>