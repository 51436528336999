const state = () => ({
    group_tables: {},
    page : 0
})

const mutations = {
    SET_GROUP_TABLES(state, payload) {
        state.group_tables = payload
    },
    SET_CURRENT_PAGE(state, payload) {
        state.group_tables.current_page = payload
    }
}

const getters = {
    GroupTables: state => state.group_tables,
    Page: state => state.page
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}