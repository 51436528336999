<template>
  <div>
    <v-row class="mt-2 mx-1" >
      <v-responsive width="100%" />
      <v-col :xs="12" :sm="12" :md="3" :lg="2">
        <v-text-field type="date" label="From Date" outlined dense v-model="search.from_date">
        </v-text-field>
      </v-col>
      <v-col :xs="12" :sm="12" :md="3" :lg="2">
        <v-text-field type="date" label="End Date" outlined dense v-model="search.to_date">
        </v-text-field>
      </v-col>
      <v-col :xs="12" :sm="12" :md="3" :lg="3" v-if="(ROLE == 'Owner')">
        <v-select label="Branch" item-text="name" item-value="id" :items="branch" outlined dense
          v-model="search.branch_id">
        </v-select>
      </v-col>
      <v-col :xs="12" :sm="12" :md="6" :lg="5">
        <v-btn small color="darkblue" class="white--text mx-1" @click.stop="init">Submit <v-icon
            right>mdi-magnify</v-icon></v-btn>
        <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
          <v-icon right>mdi-close-circle-multiple </v-icon>
        </v-btn>
        <v-menu open-on-hover top offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small color="success" v-bind="attrs" v-on="on" @click.stop="exportExcellByOrder()">
              Excell<v-icon right> mdi-file-excel</v-icon>
            </v-btn>
          </template>
        </v-menu>
        <v-menu open-on-hover top offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small color="secondary" class="mx-1" v-bind="attrs" v-on="on"
              @click.stop="downloadPdfSales()">
              PDF<v-icon right> mdi-file-pdf-box</v-icon>
            </v-btn>
          </template>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="mt-0 mx-1">
      <v-col xs="12" sm="12" md="12" lg="12">
        <v-data-table :loading="loading" loading-text="Loading... Please wait" :headers="headers"
          :items="DailySalesOrders.data" class="elevation-3" hide-default-footer>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip color="primary" class="white--text" small v-if="item.status == 'ONPROGRESS'">
              ON PROGRESS
            </v-chip>
            <v-chip color="secondary" small v-else-if="item.status == 'VOID'">
              VOID
            </v-chip>
            <v-chip color="info" small v-else-if="item.status == 'HOLD'">
              HOLD
            </v-chip>
            <v-chip color="success" small v-else-if="item.status == 'DONE'">
              DONE
            </v-chip>
          </template>
          <template v-slot:[`item.no`]="{ item }">
            {{ DailySalesOrders.data.indexOf(item) + 1 }}
          </template>
          <template v-slot:[`item.service`]="{ item }">
            {{ item.service ?? 0 }}
          </template>
          <template v-slot:[`item.order_date`]="{ item }">
            {{
              new Date(item.order_date).toLocaleDateString("id-ID", { timeZone: "Asia/Jakarta" })
            }}
          </template>
          <template v-slot:[`item.order_time`]="{ item }">
            {{
              new Date(item.order_date).toLocaleTimeString("id-ID", { timeZone: "Asia/Jakarta" })
            }}
          </template>
          <template v-slot:[`item.grand_total`]="{ item }">
            <p>{{ item.grand_total | currency("", 0, { symbolOnLeft: false, }) }}</p>
          </template>
          <template v-slot:[`item.subtotal`]="{ item }">
            <p>{{ item.subtotal | currency("", 0, { symbolOnLeft: false, }) }}</p>
          </template>
          <template v-slot:[`item.total_discount_item`]="{ item }">
            <p class="mt-2">{{ item.total_discount_item | currency("", 0, { symbolOnLeft: false, }) }}</p>
          </template>
          <template v-slot:[`item.discount_amount`]="{ item }">
            <p class="mt-2">{{ item.discount_amount | currency("", 0, { symbolOnLeft: false, }) }}</p>
          </template>
          <template v-slot:[`item.total_price_item`]="{ item }">
            <p class="mt-2">{{ item.total_price_item | currency("", 0, { symbolOnLeft: false, }) }}</p>
          </template>
          <template v-slot:[`item.change_amount`]="{ item }">
            <p class="mt-2">{{ item.change_amount | currency("", 0, { symbolOnLeft: false, }) }}</p>
          </template>
          <template v-slot:[`item.vat`]="{ item }">
            <p>{{ item.vat | currency("", 0, { symbolOnLeft: false, }) }}</p>
          </template>
          <template v-slot:[`item.payments_type`]="{ item }">
            <ul class="list-unstyled">
              <li class="mt-2" v-for="payment in item.payments" :key="payment.id">
                {{ payment?.payment_method?.name ?? 'N/A' }} <span color="primary"
                  v-if="payment?.payment_method_id == 2">{{
                    payment?.card_payment_type?.name ?? 'N/A' }}</span>
              </li>
            </ul>
          </template>
          <template v-slot:[`item.payment_total`]="{ item }">
            <ul class="list-unstyled">
              <li v-for="payment in item.payments" :key="payment.id">
                {{ payment.amount | currency("", 0, { symbolOnLeft: false, }) }}
              </li>
            </ul>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip color="dark" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-2" color="primary" v-bind="attrs" icon small @click.stop="view(item.id)" v-on="on">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>View Sales Order</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "DailySalesOrder",
  data() {
    return {
      headers: [
        { text: "No", value: "no", width: "70px",class: "primary white--text" },
        { text: "Order Date", value: "order_date", width: "150px" ,class: "primary white--text"},
        { text: "Order Time", value: "order_time", width: "150px" ,class: "primary white--text"},
        { text: "No Order", value: "no_order", width: "100px" ,class: "primary white--text"},
        { text: "Type Order", value: "type", width: "150px" ,class: "primary white--text"},
        { text: "Table", value: "table.no_table", width: "100px" ,class: "primary white--text"},
        { text: "Person", value: "person", width: "100px" ,class: "primary white--text"},
        { text: "Customer", value: "customer.name", width: "150px" ,class: "primary white--text"},
        { text: "Order Amount", value: "total_price_item", width: "150px" ,class: "primary white--text"},
        { text: "Disc", value: "total_discount_item", width: "100px" ,class: "primary white--text"},
        { text: "Service", value: "service", width: "100px" ,class: "primary white--text"},
        { text: "Vat", value: "vat", width: "100px" ,class: "primary white--text"},
        { text: "Sub total", value: "subtotal", width: "100px" ,class: "primary white--text"},
        { text: "Disc Global", value: "discount_amount", width: "150px" ,class: "primary white--text"},
        { text: "Total Amount", value: "grand_total", width: "140px" ,class: "primary white--text"},
        { text: "Payment Method", value: "payments_type", width: "150px" ,class: "primary white--text"},
        { text: "Total Payment", value: "payment_total", width: "150px" ,class: "primary white--text"},
        { text: "Change", value: "change_amount", width: "100px" ,class: "primary white--text"},
        { text: "Status", value: "status", width: "100px" ,class: "primary white--text"},
        { text: "Actions", value: "actions", width: "100px" ,class: "primary white--text"},
      ],
      disabled: false,
      loading: false,
      expanded: [],
      loading_download: false,
      status: ["ONPROGRESS", "DONE", "VOID", "HOLD", "CANCEL"],
      search: {
        from_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
        to_date: new Date().toJSON().slice(0, 10),
        branch_id: null,
      },
      branch: [],
      total_transaction: 0,
      total_amount: 0,
      total_cash: 0,
      total_card: 0,

    };
  },

  methods: {
    ...mapMutations("daily_sales_order", ["SET_DAILY_SALES_ORDERS", "SET_DAILY_SALES_ORDER", "SET_CURRENT_PAGE"]),
    sumField(key) {
      return this.DailySalesOrders.data.reduce((a, b) => a + (b[key] || 0), 0)
    },
    async init() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        from_date: this.search.from_date,
        to_date: this.search.to_date,
        branch_id: this.search.branch_id,
      };

      await this.$axios
        .get("/reports/sales?for_page=mobile", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_DAILY_SALES_ORDERS(data.sales_orders);
          this.loading = false;
          this.disabled = false;
        });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch.data;
        });
    },

    view(id) {
      this.$router.push("/daily-sales-order/view/" + id);
    },

    exportExcellByOrder: async function () {
      this.loading = true;
      let params = {
        from_date: this.search.from_date,
        to_date: this.search.to_date,
        branch_id: this.search.branch_id,
      };
      await this.$axios
        .get(
          "/reports/sales/export/",
          {
            params: params,
            headers: {
              Authorization: this.$store.getters["auth/Token"],
            },
          }
        ).then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const a = document.createElement('a');
          a.href = url;
          a.setAttribute('download', 'daily-sales-order.csv');
          document.body.appendChild(a);
          a.click();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async downloadPdfSales() {
      let params = {
        from_date: this.search.from_date,
        to_date: this.search.to_date,
        branch_id: this.search.branch_id,
      };
      await this.$axios
        .get(`/reports/sales/export/pdf-download`, {
          method: "GET",
          responseType: "arraybuffer",
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then((response) => {
          this.loading = false;
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `sales-order-report.pdf`;
          link.click();
          window.close();
        });
    },
    clear() {
      this.page = 1;
      this.search = {
        from_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
        to_date: new Date().toJSON().slice(0, 10),
        branch_id: null,
        search: null,
      };
      this.init();
    },
  },

  created() {
    this.init();
    this.getBranch();
  },

  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.init();
    },
  },
  computed: {
    ...mapGetters("daily_sales_order", {
      DailySalesOrders: "DailySalesOrders",
    }),
    ...mapGetters("auth", {
      CAN: "Can",
    }),
    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    currentPage: {
      get() {
        return this.DailySalesOrders.current_page;
      },
      set(val) {
        return this.$store.commit("daily_sales_order/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.DailySalesOrders.last_page;
      },
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  background-color: #ebebeb59;
}

::v-deep th {
  color: #000 !important;
}
</style>