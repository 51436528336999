<template>
    <main-layout>
        <base-header>
            <template #icon> mdi-file</template>
            <template #name>Order List </template>
            <template #breadcrumbs>
                <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                    <template #divider>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>
            </template>
        </base-header>
        <v-row dense class="mx-1">
            <v-responsive width="100%" />
            <v-tabs centered fixed-tabs v-model="tab">
                <v-tab href="#all-order">All Orders (2)</v-tab>
                <v-tab-item value="all-order">
                    <AllOrder />
                </v-tab-item>
                <v-tab href="#new-order"> New Orders (2)</v-tab>
                <v-tab-item value="new-order">
                    <NewOrder />
                </v-tab-item>
                <v-tab href="#in-process"> In Process (0)</v-tab>
                <v-tab-item value="in-process">
                    <InProcess />
                </v-tab-item>
                <v-tab href="#order-shipped"> Order Shipped (0)</v-tab>
                <v-tab-item value="order-shipped">
                    <OrderShipped />
                </v-tab-item>
                <v-tab href="#order-completed"> Order Completed (2)</v-tab>
                <v-tab-item value="order-completed">
                    <OrderCompleted />
                </v-tab-item>
                <v-tab href="#order-cancel"> Order Cancel (4)</v-tab>
                <v-tab-item value="order-cancel">
                    <OrderCancel />
                </v-tab-item>
            </v-tabs>
        </v-row>
    </main-layout>
</template>
<script>
import MainLayout from "@/layouts/MainLayout.vue";
import BaseHeader from "@/components/base/BaseHeader.vue";
import AllOrder from "./all_order.vue";
import NewOrder from "./new_order.vue";
import InProcess from "./in_process.vue";
import OrderShipped from "./order_shipped.vue";
import OrderCompleted from "./order_completed.vue";
import OrderCancel from "./order_cancel.vue";

export default {
    name: "CustomIndex",

    created() {
        this.breadcrumbs = [
            { text: "Transaction", disabled: false, to: "/dashboard" },
            { text: "Order", disabled: true },
        ];
    },

    components: {
        AllOrder,
        NewOrder,
        InProcess,
        OrderShipped,
        OrderCompleted,
        OrderCancel,
        MainLayout,
        BaseHeader,
    },

};
</script>