<template>
  <div>
    <v-row class="mt-2">
      <v-responsive width="100%" />
      <v-col :xs="12" :sm="12" :md="4" :lg="3">
        <v-text-field v-model="search.search" append-icon="mdi-magnify" label="Search" outlined dense
          hide-details></v-text-field>
      </v-col>
      <v-col :xs="12" :sm="12" :md="3" :lg="3" v-if="(ROLE == 'Owner')">
        <v-select label="Branch" item-text="name" item-value="id" :items="branch" outlined dense
          v-model="search.branch_id">
        </v-select>
      </v-col>
      <v-col :xs="12" :sm="12" :md="8" :lg="6">
        <v-btn small color="darkblue" class="white--text mx-1" @click.stop="init">Submit <v-icon
            right>mdi-magnify</v-icon></v-btn>
        <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
          <v-icon right>mdi-close-circle-multiple </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="my-0">
      <v-col xs="12" sm="12" md="12" lg="12">
        <v-responsive width="100%" v-if="$vuetify.breakpoint.xsOnly" />
        <v-data-table :loading="loading" :headers="headers" :items="TopSales.data" class="elevation-3"
          hide-default-footer>
          <template v-slot:[`item.no`]="{ item }">
            {{ TopSales.data.indexOf(item) + 1 }}
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <p class="mt-3">{{ item.price | currency("", 0, { symbolOnLeft: false, }) }}</p>
          </template>
        </v-data-table>
        <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>
  
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "TopSales",
  data() {
    return {
      headers: [
        { text: "No", value: "no" ,class: "primary white--text"},
        { text: "Sub Group", value: "item.subgroup.name" ,class: "primary white--text"},
        { text: "Item Name", value: "item.name" ,class: "primary white--text"},
        { text: "Unit Price", value: "price" ,class: "primary white--text"},
        { text: "Qty", value: "qty" ,class: "primary white--text"},
      ],
      disabled: false,
      loading: false,
      expanded: [],
      loading_download: false,
      search: {
        search: null,
        branch_id: null
      },
      branch: [],
      total_transaction: 0,
      total_amount: 0,
      total_cash: 0,
      total_card: 0,

    };
  },

  methods: {
    ...mapMutations("daily_sales_order", ["SET_TOP_SALES", "SET_CURRENT_PAGE"]),
    sumField(key) {
      return this.ByItem.data.reduce((a, b) => a + (b[key] || 0), 0)
    },
    async init() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        search: this.search.search,
        branch_id : this.search.branch_id
      };

      await this.$axios
        .get("/reports/items", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_TOP_SALES(data.items);
          this.loading = false;
          this.disabled = false;
        });
    },

    exportExcellBySales: async function () {
      this.loading = true;
      let params = {
        search: this.search.search,
        branch_id : this.search.branch_id
      };
      await this.$axios
        .get(
          "/reports/sales/export/",
          {
            params: params,
            headers: {
              Authorization: this.$store.getters["auth/Token"],
            },
          }
        ).then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const a = document.createElement('a');
          a.href = url;
          a.setAttribute('download', 'daily-sales-order.csv');
          document.body.appendChild(a);
          a.click();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async downloadPdfSales() {
      let params = {
        search: this.search.search,
      };
      await this.$axios
        .get(`/reports/sales/export/pdf-download`, {
          method: "GET",
          responseType: "arraybuffer",
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then((response) => {
          this.loading = false;
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `sales-order-report.pdf`;
          link.click();
          window.close();
        });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch.data;
        });
    },
    clear() {
      this.page = 1;
      this.search = {
        search: "",

      };
      this.init();
    },
  },

  created() {
    this.init();
    this.getBranch();
  },

  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.init();
    },
  },
  computed: {
    ...mapGetters("daily_sales_order", {
      TopSales: "TopSales",
    }),
    ...mapGetters("auth", {
      CAN: "Can",
    }),
    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    currentPage: {
      get() {
        return this.TopSales.current_page;
      },
      set(val) {
        return this.$store.commit("daily_sales_order/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.TopSales.last_page;
      },
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  background-color: #ebebeb59;
}

::v-deep th {
  color: #000 !important;
}
</style>