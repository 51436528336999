import PurchaseOrderIndex from '@/views/transaction/purchase/Index.vue'
import PurchaseOrderCreate from '@/views/transaction/purchase/Create.vue'
import PurchaseOrderEdit from '@/views/transaction/purchase/Edit.vue'
import PurchaseOrderView from '@/views/transaction/purchase/View.vue'

import RequestOrderIndex from '@/views/transaction/request_order/Index.vue'
import RequestOrderCreate from '@/views/transaction/request_order/Create.vue'
import RequestOrderEdit from '@/views/transaction/request_order/Edit.vue'
import RequestOrderView from '@/views/transaction/request_order/View.vue'

import PurchaseInvoiceIndex from '@/views/transaction/purchase_invoice/Index.vue'
import PurchaseInvoiceCreate from '@/views/transaction/purchase_invoice/Create.vue'
import PurchaseInvoiceEdit from '@/views/transaction/purchase_invoice/Edit.vue'
import PurchaseInvoiceView from '@/views/transaction/purchase_invoice/View.vue'

import PurchaseAdjustmentIndex from '@/views/transaction/purchase_adjustment/Index.vue'
import PurchaseAdjustmentCreate from '@/views/transaction/purchase_adjustment/Create.vue'
import PurchaseAdjustmentEdit from '@/views/transaction/purchase_adjustment/Edit.vue'
import PurchaseAdjustmentView from '@/views/transaction/purchase_adjustment/View.vue'

export default [
    //purchase order
    {
        path: '/purchase-order',
        name: "purchase-order.index",
        meta: { title: "S-ERP | Purchase order" },
        component: PurchaseOrderIndex
    },
    {
        path: '/purchase-order/create',
        name: "purchase-order.create",
        meta: { title: "S-ERP | Create Purchase order" },
        component: PurchaseOrderCreate
    },

    {
        path: '/purchase-order/edit',
        name: "purchase-order.edit",
        meta: { title: "S-ERP | Edit Purchase order" },
        component: PurchaseOrderEdit
    },

    {
        path: '/purchase-order/view/:id',
        name: "purchase-order.view",
        meta: { title: "S-ERP | Detail Purchase order" },
        component: PurchaseOrderView
    },

    {
        path: '/request-order',
        name: "request-order.index",
        meta: { title: "S-ERP | Request order" },
        component: RequestOrderIndex
    },

    {
        path: '/request-order/create',
        name: "request-order.create",
        meta: { title: "S-ERP | Create Request order" },
        component: RequestOrderCreate
    },

    {
        path: '/request-order/edit',
        name: "request-order.edit",
        meta: { title: "S-ERP | Edit Request order" },
        component: RequestOrderEdit
    },

    {
        path: '/request-order/view/:id',
        name: "request-order.view",
        meta: { title: "S-ERP | Detail Request order" },
        component: RequestOrderView
    },

    {
        path: '/purchase-invoice',
        name: "purchase-invoice.index",
        meta: { title: "S-ERP | Purchase invoice" },
        component: PurchaseInvoiceIndex
    },

    {
        path: '/purchase-invoice/create',
        name: "purchase-invoice.create",
        meta: { title: "S-ERP | Create Purchase invoice" },
        component: PurchaseInvoiceCreate
    },

    {
        path: '/purchase-invoice/view/:id',
        name: "purchase-invoice.view",
        meta: { title: "S-ERP | Detail Purchase invoice" },
        component: PurchaseInvoiceView
    },

    {
        path: '/purchase-invoice/edit',
        name: "purchase-invoice.edit",
        meta: { title: "S-ERP | Edit Purchase invoice" },
        component: PurchaseInvoiceEdit
    },

    {
        path: '/purchase-adjustment',
        name: "purchase-adjustment.index",
        meta: { title: "S-ERP | Purchase adjustment" },
        component: PurchaseAdjustmentIndex
    },

    {
        path: '/purchase-adjustment/create',
        name: "purchase-adjustment.create",
        meta: { title: "S-ERP | Create Purchase adjustment" },
        component: PurchaseAdjustmentCreate
    },

    {
        path: '/purchase-adjustment/view/:id',
        name: "purchase-adjustment.view",
        meta: { title: "S-ERP | Detail Purchase adjustment" },
        component: PurchaseAdjustmentView
    },

    {
        path: '/purchase-adjustment/edit',
        name: "purchase-adjustment.edit",
        meta: { title: "S-ERP | Edit Purchase adjustment" },
        component: PurchaseAdjustmentEdit
    },


]