<template>
  <div>
    <base-title title="Edit Branch" subtitle="Please fill in the Branch information in the form below" />
    <!-- Form -->
    <v-form ref="form_data" v-model="form_valid" lazy-validation>
      <v-row class="mx-1 mt-2">
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Name" v-model="form_data.name" :rules="rule_name" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-select label="Pos Type" v-model="form_data.pos_type_id" :items="pos_type" item-text="name" item-value="id"
            :rules="rule_pos_type" outlined dense></v-select>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Phone" v-model="form_data.phone" :rules="rule_phone" outlined dense></v-text-field>
        </base-column>
      </v-row>

      <v-row class="mx-1 mt-2">
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Email" v-model="form_data.email" :rules="rule_email" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Pic Name" v-model="form_data.pic_name" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Pic Phone" v-model="form_data.pic_phone" outlined dense></v-text-field>
        </base-column>
      </v-row>

      <v-row class="mx-1 mt-2">
        <base-column :lg="4" :md="4" :sm="12">
          <v-textarea label="Address" rows="3" v-model="form_data.address" outlined dense></v-textarea>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
        <v-switch v-model="form_data.mode" label="Otonom Mode"></v-switch>
        </base-column>
      </v-row>

      <v-row class="mx-1">
        <back-button />
        <v-btn color="primary" small class="ma-1" @click.stop="store" :loading="btnLoading"
          :disabled="!form_valid || btnLoading">Save <v-icon right>mdi-content-save</v-icon></v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import BackButton from "@/components/base/BackButton.vue";
import BaseTitle from "@/components/base/BaseTitle.vue";
import BaseColumn from "@/components/base/BaseColumn.vue";
import { mapGetters } from "vuex";
export default {
  components: { BaseTitle, BaseColumn, BackButton },
  name: "BranchEdit",
  data() {
    return {
      btnLoading: false,
      form_valid: true,
      pos_type: [],
      form_data: {
        name: "",
        pos_type_id: "",
        email: "",
        phone: "",
        pic_name: "",
        pic_phone: "",
        address: "",
        mode: false
      }
    };
  },
  created() {
    this.getPosType();
    this.findOne();
  },

  methods: {
    update: async function () {
      if (this.$refs.form_data.validate()) {
        this.btnLoading = true;
        await this.$axios
          .post(
            "/master/branches/" + this.$route.params.id,
            {
              _method: "PUT",
              name: this.form_data.name,
              pos_type_id: this.form_data.pos_type_id,
              email: this.form_data.email,
              phone: this.form_data.phone,
              pic_name: this.form_data.pic_name,
              pic_phone: this.form_data.pic_phone,
              address: this.form_data.address,
              mode: this.form_data.mode
            },
            {
              headers: {
                Authorization: this.TOKEN,
              },
            }
          )
          .then(() => {
            this.btnLoading = false;
            this.$router.push({ name: "branch.index" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },

    async getPosType() {
      await this.$axios
        .get("/master/pos-types", {
          headers: {
            Authorization: this.TOKEN,
          },
        })
        .then(({ data }) => {
          this.pos_type = data.pos_type
        });
    },

    findOne() {
      this.$axios
        .get("/master/branches/" + this.$route.params.id, {
          headers: {
            Authorization: this.TOKEN,
          },
        })
        .then(({ data }) => {
          this.form_data.name = data.branch.name;
          this.form_data.pos_type_id = data.branch.pos_type_id;
          this.form_data.email = data.branch.email;
          this.form_data.phone = data.branch.phone;
          this.form_data.pic_name = data.branch.pic_name;
          this.form_data.pic_phone = data.branch.pic_phone;
          this.form_data.address = data.branch.address;
          this.form_data.mode = data.branch.mode;
        })
        .catch(() => {
          this.$router.push({ name: "branch.index" });
        });
    },
  },

  computed: {
    ...mapGetters("auth", {
      TOKEN: "Token",
    }),
  },
};
</script>