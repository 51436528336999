<template>
  <main-layout>
    <base-header>
      <template #icon> mdi-file</template>
      <template #name> Product </template>
      <template #breadcrumbs>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </template>
    </base-header>
    <v-row dense class="mx-1">
      <v-responsive width="100%" v-if="$vuetify.breakpoint.xsOnly" />
      <product-list />
    </v-row>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import BaseHeader from "@/components/base/BaseHeader.vue";
import ProductList from "./List.vue";
export default {
  name: "ProductIndex",

  created() {
    this.breadcrumbs = [
      { text: "Transaction", disabled: false },
      { text: "Product", disabled: true },
    ];
  },

  components: {
    MainLayout,
    BaseHeader,
    "product-list": ProductList,
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  background-color: #ebebeb59;
}

::v-deep th {
  color: #000 !important;
}
</style>