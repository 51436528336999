<template>
  <div>
    <base-title title="Branch" subtitle="List Data Branch" />
    <v-btn to="/master/branch/create" small elevation="1" color="primary" class="ma-3 white--text float-right">
      Create
      <v-icon right dark>mdi-plus-circle </v-icon>
    </v-btn>
    <supplier-list />
  </div>
</template>

<script>
import BaseTitle from "@/components/base/BaseTitle.vue";
import SupplierList from "@/views/master/branch/List.vue";
import { mapMutations, mapGetters } from "vuex";
export default {
  components: { BaseTitle, SupplierList },
  name: "BranchIndex",

  data() {
    return {
      loader: null,
      branchLimit: 0,
    };
  },

  created() {
    this.getBranch();
  },

  methods: {
    ...mapMutations("branch", ["SET_BRANCHES", "SET_CURRENT_PAGE"]),

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branchLimit = data.branch.data.length;
          console.log(this.branchLimit);
          this.loading = false;
          this.disabled = false;
        });
    },
  },

  computed: {
    ...mapGetters("branch", {
      BRANCHES: "Branches",
    }),

  },

};
</script>