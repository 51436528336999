<template>
  <div>
    <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-toolbar-title> Item List</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn outlined small class="ma-1" color="primary" @click="openProductDialog()" v-if="!Ingoing.no_po">
        Add Item
        <v-icon right>mdi-plus</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-simple-table class="mx-2 mb-3" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" width="1%">#</th>
            <th class="text-left" width="12%">No. Invoice</th>
            <th class="text-left" width="10%">Invoice Amount</th>
            <th class="text-left" width="10%">Payment</th>
            <th class="text-left" width="10%">Already Payment</th>
            <th class="text-left" width="10%">Balance</th>
            <th class="text-left" width="10%">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr height="80" v-for="(row, index) in PurchaseInvoice.details" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              {{ row.no_invoice }}
            </td>
            <td>
              {{ row.invoice_amount | currency("", 0, { symbolOnLeft: false }) }}
            </td>
            <td>
              <v-text-field type="number" v-model="row.payment" placeholder="Payment" outlined dense
                hide-details></v-text-field>
            </td>
            <td>
              {{ row.already_payment | currency("", 0, { symbolOnLeft: false }) }}
            </td>
            <td>
              {{ row.balance | currency("", 0, { symbolOnLeft: false }) }}
            </td>
            <td>
              <v-btn icon small color="error" @click="removeProduct(index)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table class="mx-2 mb-3" fixed-header>
      <thead>
        <tr>
          <th class="text-left" width="45%"></th>
          <th class="text-left" width="10%"></th>
          <th class="text-left" width="10%"></th>
          <th class="text-left" width="20%"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
          </td>
          <td></td>
          <td class="text-right">
            Invoice Amount : <br />
            Payment : <br />
            Already Payment : <br />
            Balance : <br />
          </td>
          <td>
            <b>{{ invoice_amount | currency("", 0, { symbolOnLeft: false }) }} 0
            </b>
            <hr />
            <b>{{ payment | currency("", 0, { symbolOnLeft: false }) }} 0</b>
            <hr />
            <b>{{ already_payment | currency("", 0, { symbolOnLeft: false }) }}0</b>
            <hr />
            <b>{{ balance | currency("", 0, { symbolOnLeft: false }) }}0</b>
            <hr />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-model="dialogProduct" v-if="dialogProduct" max-width="1200px" persistent><products-modal
        v-on:closeProductDialog="closeProductDialog" v-on:selectProduct="selectProduct" />
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import ProductsModal from "@/components/modal/ProductsModal.vue";

export default {
  name: "PurchaseInvoiceFormItem",
  data() {
    return {
      dialogProduct: false,
    };
  },
  methods: {
    preventOverflow() {
      this.PurchaseInvoice.details.forEach((item) => {
        if (item.qty_in > item.qty) {
          item.qty_in = item.qty;
        }
      });
    },
    openProductDialog() {
      this.dialogProduct = true;
    },
    closeProductDialog() {
      this.dialogProduct = false;
    },
    selectProduct(val) {
      if (this.PurchaseInvoice.details.find((item) => item.item_id === val.id)) {
        this.PurchaseInvoice.details.find((item) => item.item_id === val.id).qty++;
        return;
      }
      this.$store.commit("purchase_invoice/SET_DETAILS", val);
      this.dialogProduct = false;
    },

    removeProduct(index) {
      this.PurchaseInvoice.details.splice(index, 1);
    },
  },

  computed: {
    ...mapState("purchase_invoice", {
      PurchaseInvoice: (state) => state.purchase_invoice,
    }),

    sub_total() {
      return _.sumBy(this.PurchaseInvoice.details, function (t) {
        return (t.price * t.qty_in)
      });
    },

    discount() {
      if (this.PurchaseInvoice.discount_percent > 100 || this.PurchaseInvoice.discount_percent < 0) {
        return "Discount not valid value";
      }
      return parseFloat(this.sub_total) * parseFloat(this.PurchaseInvoice.discount_percent) / 100;
    },

    afterDiscount() {
      let discount = parseFloat(this.sub_total - this.discount);
      if (discount > 0) {
        return discount;
      } else {
        return 0;
      }
    },

    vat() {
      if (this.PurchaseInvoice.use_vat) {
        return parseFloat((this.afterDiscount * 11)) / 100;
      }
      else {
        return 0;
      }
    },

    grand_total() {
      return parseFloat(this.afterDiscount) + parseFloat(this.vat);
    },
  },

  components: {
    "products-modal": ProductsModal,
  },
};
</script>
