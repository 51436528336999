export default {
    data() {
        return {
            rule_name: [(v) => !!v || "Name please fill in !!!"],
            rule_device_name: [(v) => !!v || "Device Name please fill in !!!"],
            rule_ip_address: [(v) => !!v || "IP Address please fill in !!!"],
            rule_branch: [(v) => !!v || "Branch please fill in !!!"],
            rule_code: [(v) => !!v || "Code please fill in !!!"],
            rule_sku: [(v) => !!v || "SKU please fill in !!!"],
            rule_percentage: [(v) => !!v || "Percentage please fill in !!!"],
            rule_account_number: [(v) => !!v || "Account Number please fill in !!!"],
            rule_name_account: [(v) => !!v || "Name Account please fill in !!!"],
            rule_payment_method: [(v) => !!v || "Payment Method please fill in !!!"],
            rule_stock: [(v) => !!v || "Stock please fill in !!!"],
            rule_minimum_qty: [(v) => v >= 0 || "Minimum Qty cannot be below 0 or minus value",
            (v) => !!v || "Minimum Qty please fill in !!!"],
            rule_minimum_price: [(v) => v >= 0 || "Minimum Price cannot be below 0 or minus value",
            (v) => !!v || "Minimum Price please fill in !!!"],
            rule_price: [(v) => v >= 0 || "Price cannot be below 0 or minus value",
            (v) => !!v || "Price please fill in !!!"],
            rule_started_date: [(v) => !!v || "Started Date please fill in !!!"],
            rule_expired_date: [(v) => !!v || "Expired Date please fill in !!!"],
            rule_barcode: [(v) => !!v || "Barcode please fill in !!!"],
            rule_email: [
                (v) => !!v || "Email address please fill in !!!",
                (v) => /.+@.+\..+/.test(v) || "Email address not valid",],
            rule_phone: [
                (v) => !!v || "Phone number please fill in !!!",
                // (v) => /^[0-9]+$-/.test(v) || "Phone number can only be numbers",
            ],
            rule_discount: [(v) => !!v || "Discount please fill in !!!"],
            rule_voucher: [(v) => !!v || "Voucher please fill in !!!"],
            rule_promo: [(v) => !!v || "Promo please fill in !!!"],

            rule_description: [
                (value) => !!value || "Description please fill in",
            ],
            rule_address: [
                (v) => !!v || "Address please fill in !!!",
            ],
            rule_flag : [
                (v) => !!v || "Flag please fill in !!!",
            ],
            rule_unit: [(v) => !!v || "Unit please fill in !!!"],
            rule_group: [(v) => !!v || "Group please fill in !!!"],
            rule_subgroup: [(v) => !!v || "Sub Group please fill in !!!"],
            rule_start_date: [(v) => !!v || "Start Date please fill in !!!"],
            rule_end_date: [(v) => !!v || "End Date please fill in !!!"],
            rule_start_time: [(v) => !!v || "Start Time please fill in !!!"],
            rule_expired_time: [(v) => !!v || "Expired Time please fill in !!!"],
            rule_qtys: [(v) => v >= 0 || "Quantity cannot be below 0",
            (v) => !!v || "Group please fill in !!!"
            ],
            rule_disc: [
                (v) => v >= 0 || "Discount cannot be below 0",
                (v) => v <= 100 || "Discount must not be above 100",
                v => /^[\w\s]+$/.test(v) || 'Name must only contain alphabets, numeric and space'
            ],
            rule_service: [
                (v) => v >= 0 || "Service cannot be below 0",
                (v) => v <= 100 || "Service must not be above 100",
            ],
            rule_ppn: [
                (v) => v >= 0 || "PPN cannot be below 0",
                (v) => v <= 100 || "PPN must not be above 100",
            ],

            rule_item: [(v) => !!v || "Item please fill in !!!"],

            rule_rate: [(v) => v >= 0 || "Rate cannot be below 0"],
            rule_order_date: [(v) => !!v || " Order Date is required"],
            rule_request_date: [(v) => !!v || " Request Date is required"],
            rule_type: [(v) => !!v || "Type  is required"],
            rule_status: [(v) => !!v || "Status  is required"],
            rule_pph: [(v) => !!v || "PPh is required"],
            rule_bank_name: [(v) => !!v || "Bank Name is required"],
            rule_account_number_idr: [(v) => !!v || "Account Number IDR is required"],
            rule_account_number_usd: [(v) => !!v || "Account Number USD is required"],
            rule_account_name: [(v) => !!v || "Bank Account Name is required"],
            rule_date: [(v) => !!v || "Date is required"],
            rule_weight: [(v) => !!v || "Weight is required"],
            rule_height: [(v) => !!v || "Height is required"],
            rule_width: [(v) => !!v || "Width is required"],
            rule_length: [(v) => !!v || "Length is required"],
            rule_owner: [(v) => !!v || "Owner is required"],
            rule_type_in: [(v) => !!v || "Type In is required"],
            rule_type_out: [(v) => !!v || "Type Out is required"],
            rule_purchase_date: [(v) => !!v || "Purchase Date is required"],
            rule_shipping_address: [(v) => !!v || "Shipping Address is required"],
            rule_ingoing_date: [(v) => !!v || "Ingoing Date is required"],
            rule_delivery_date: [(v) => !!v || "Delivery Date is required"],
            rule_pos_type: [(v) => !!v || "POS Type is required"],
            rule_no_table: [(v) => !!v || "No Table is required"],
            rule_capacity: [(v) => !!v || "Capacity is required"],
            rule_group_table_id: [(v) => !!v || "Group Table is required"],
            rule_username: [(v) => !!v || "Username is required"],
            rule_role: [(v) => !!v || "Role is required"],
            rule_password: [(v) => !!v || "Password is required"],
            rule_retype_password: [(v) => !!v || "Retype Password is required"],
            rule_item_type: [(v) => !!v || "Item Type is required"],
            rule_petty_cash_category: [(v) => !!v || "Petty Cash Category is required"],
            rule_customer: [(v) => !!v || "Customer is required"],
            rule_outgoing_date: [(v) => !!v || "Outgoing Date is required"],
            rule_invoice_date: [(v) => !!v || "Invoice Date is required"],
            rule_item_name: [(v) => !!v || "Item Name is required"],
            rule_group_patty_cash: [(v) => !!v || "Group Petty Cash is required"],
            rule_price_buy: [
                v => /^[\w\s]+$/.test(v) || 'Name must only contain alphabets, numeric and space'
            ],
            rule_price_sell: [
                v => /^[\w\s]+$/.test(v) || 'Name must only contain alphabets, numeric and space'
            ],
            rule_qty: [
                v => /^[\w\s]+$/.test(v) || 'Name must only contain alphabets, numeric and space'
            ],
            rule_disc_nominal: [
                v => /^[\w\s]+$/.test(v) || 'Name must only contain alphabets, numeric and space'
            ],
        }
    },
};