<template>
  <div>
    <base-title title="Account Petty Cash" subtitle="List Data Account Petty Cash" />
    <v-btn to="/master/account-petty-cash/create" small elevation="1" color="primary"
      class=" white--text float-right mx-2" @click="loader = 'loading3'">
      Create
      <v-icon right dark>mdi-plus-circle </v-icon>
    </v-btn>
    <account-patty-cash />
  </div>
</template>

<script>
import BaseTitle from "@/components/base/BaseTitle.vue";
import AccountPattyCash from "@/views/master/account_paty_cash/List.vue";
export default {
  components: { AccountPattyCash, BaseTitle },
  name: "PettyCash",
};
</script>