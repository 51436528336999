const state = () => ({
    items: {},
    item: {
        id: null,
        item_type: null,
        code: null,
        name: null,
        branch_id: null,
        description: null,
        unit_id: null,
        group_id: null,
        subgroup_id: null,
        discount_id: null,
        promo_id: null,
        voucher_id: null,
        barcode: null,
        image: null,
        specification: null,
        price: 0,
        price_buy: 0,
        price_sell: 0,
        expired_date: null,
        stock: 0,
        use_resep: 0,
        use_service: 0,
        use_auto: 0,
        use_ppn: 0,
        service: 0,
        ppn: 0,
        price_amount: 0,
        service_amount: 0,
        ppn_amount: 0,
        total: 0,
        use_ingredient: 0,
        use_variant_size: 0,
        use_variant_topping: 0,
        use_stock: 0,
        is_active: 1,
        details: [],
        details_variant_size: [],
        toppings: [],
    },
    page: 1,
})

const mutations = {
    SET_ITEMS(state, payload) {
        state.items = payload;
    },

    SET_ITEM(state, payload) {
        state.item.id = payload.id;
        state.item.item_type = payload.item_type;
        state.item.name = payload.name;
        state.item.branch_id = payload.branch_id;
        state.item.code = payload.code;
        state.item.description = payload.description;
        state.item.unit_id = payload.unit_id;
        state.item.group_id = payload.group_id;
        state.item.subgroup_id = payload.subgroup_id;
        state.item.discount_id = payload.discount_id;
        state.item.promo_id = payload.promo_id;
        state.item.voucher_id = payload.voucher_id;
        state.item.barcode = payload.barcode;
        state.item.specification = payload.specification;
        state.item.price = payload.price;
        state.item.price_buy = payload.price_buy;
        state.item.use_ppn = payload.use_ppn;
        state.item.price_sell = payload.price_sell;
        state.item.expired_date = payload.expired_date;
        state.item.stock = payload.stock;
        state.item.use_service = payload.use_service;
        state.item.use_auto = payload.use_auto;
        state.item.service = payload.service;
        state.item.ppn = payload.ppn;
        state.item.price_amount = payload.price_amount;
        state.item.service_amount = payload.service_amount;
        state.item.ppn_amount = payload.ppn_amount;
        state.item.total = payload.total;
        state.item.use_stock = payload.use_stock;
        state.item.use_ingredient = payload.use_ingredient;
        state.item.use_variant_size = payload.use_variant_size;
        state.item.use_variant_topping = payload.use_variant_topping;
        state.item.use_resep = payload.use_resep;
        state.item.is_active = payload.is_active;
        if (state.item.use_ingredient == 1) {
            state.item.details = payload.details.map(item => {
                return {
                    ingredient_id: item.ingredient_id,
                    item_name: item.ingredient.name,
                    item_description: item.ingredient.description,
                    item_unit: item.ingredient.unit.name,
                    qty_usage: item.qty_usage,
                    price: item.ingredient.price,
                    total: item.ingredient.price * item.qty_usage,
                }
            })
        } else {
            state.item.details = [];
        }
        state.item.details_variant_size = payload.sizes;
        state.item.toppings = payload.item_toppings.map(item => {
            return {
                id : item.topping.id,
                code: item.topping.code,
                name: item.topping.name,
                price: item.topping.price,
            }
        })
    },

    SET_DETAILS(state, payload) {
        state.item.details.push({
            ingredient_id: payload.id,
            item_name: payload.name,
            item_description: payload.description,
            item_unit: payload.unit.name,
            qty_usage: 1,
            price: payload.price,
            total: payload.price,
        })
    },

    SET_DETAILS_VARIANT_SIZE(state, payload) {
        state.item.details_variant_size.push({
            name: payload.name,
            description: payload.description,
            price: payload.price,
            qty: payload.qty,
        })
    },

    SET_TOPPINGS(state, payload) {
        state.item.toppings.push({
            id: payload.id,
            name: payload.name,
            code: payload.code,
            price: payload.price,
        })
    },


    CLEAR_STATE(state) {
        state.item.id = null;
        state.item.item_type = null;
        state.item.code = null;
        state.item.name = null;
        state.item.branch_id = null;
        state.item.description = null;
        state.item.unit_id = null;
        state.item.group_id = null;
        state.item.subgroup_id = null;
        state.item.discount_id = null;
        state.item.promo_id = null;
        state.item.voucher_id = null;
        state.item.barcode = null;
        state.item.specification = null;
        state.item.price = 0;
        state.item.price_buy = 0;
        state.item.price_sell = 0;
        state.item.image = null;
        state.item.expired_date = null;
        state.item.stock = 0;
        state.item.use_service = 0;
        state.item.use_auto = 0;
        state.item.use_ppn = 0;
        state.item.service = 0;
        state.item.ppn = 0;
        state.item.price_amount = 0;
        state.item.service_amount = 0;
        state.item.ppn_amount = 0;
        state.item.total = 0;
        state.item.use_ingredient = 0;
        state.item.use_variant_size = 0;
        state.item.use_variant_topping = 0;
        state.item.use_stock = 0;
        state.item.is_active = 1;
        state.item.details = [];
        state.item.details_variant_size = [];
        state.item.toppings = [];
    },

    CLEAR_DETAILS(state) {
        state.item.details = [];
    },

    CLEAR_DETAILS_VARIANT_SIZE(state) {
        state.item.details_variant_size = [];
    },

    CLEAR_TOPPINGS(state) {
        state.item.toppings = [];
    },

    SET_CURRENT_PAGE(state, payload) {
        state.items.current_page = payload;
    },
}

const getters = {
    Items: state => state.items,
    Item: state => state.item,
    page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}