const state = () => ({
    item_ingredients: [],
    item_ingredient: {
        id: null,
        name: null,
        unit_id: null,
        branch_id: null,
        price: 0,
        description: null,
    },

    page: 1,

})

const mutations = {
    SET_ITEM_INGREDIENTS(state, payload) {
        state.item_ingredients = payload;
    },

    SET_ITEM_INGREDIENT(state, payload) {
        state.item_ingredient = {
            id: payload.id,
            name: payload.name,
            unit_id: 1,
            branch_id: payload.branch_id,
            price: payload.price,
            description: payload.description,
        };
    },

    SET_CURRENT_PAGE(state, payload) {
        state.item_ingredients.current_page = payload;
    },

    CLEAR_STATE(state) {
        state.item_ingredient = {
            id: null,
            name: null,
            unit_id: null,
            branch_id: null,
            price: 0,
            description: null,
        };
    }
}

const getters = {
    ItemIngredients: state => state.item_ingredients,
    ItemIngredient: state => state.item_ingredient,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}