const state = () => ({
    bank_accounts: {},
    page : 1,
    bank_account: {
        id: null,
        bank_name: null,
        account_number: null,
        account_name: null,
        details: []
    }

})

const mutations = {
    SET_BANK_ACCOUNTS(state, payload) {
        state.bank_accounts = payload;
    },

    SET_BANK_ACCOUNT(state, payload) {
        state.bank_account = payload;
    },

    SET_FORM(state, payload) {
        state.bank_account.id = payload.id;
        state.bank_account.bank_name = payload.bank_name;
        state.bank_account.account_number = payload.account_number;
        state.bank_account.account_name = payload.account_name;
    },

    SET_BANK_ACCOUNT_LIST(state, payload) {
        state.bank_account.details.push({
            id: payload.id,
            bank_name: payload.bank_name,
            account_number: payload.account_number,
            account_name: payload.account_name,
        })
    },

    SET_CURRENT_PAGE(state, payload) {
        state.bank_accounts.current_page = payload;
    },

    CLEAR_FORM(state) {
        state.bank_account.id = null;
        state.bank_account.bank_name = null;
        state.bank_account.account_number = null;
        state.bank_account.account_name = null;
    },

    CLEAR_BANK_ACCOUNTS(state) {
        state.bank_accounts = {};
    }
}

const getters = {
    BankAccounts: state => state.bank_accounts,
    BankAccount: state => state.bank_account,
    Page : state => state.page
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}
