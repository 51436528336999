<template>
    <div>
        <br>
        <v-form ref="Variant" v-model="form_valid" lazy-validation>
            <v-row class="mx-1">
                <base-column :lg="4" :md="6" :sm="12">
                    <v-text-field label="Name" outlined dense v-model="Variant.name" :rules="rule_name"></v-text-field>
                </base-column>
                <base-column :lg="4" :md="6" :sm="12">
                    <v-text-field type="text" label="Price" outlined dense v-model="Variant.price" :disabled="Variant.use_material"></v-text-field>
                </base-column>
                <base-column :lg="4" :md="6" :sm="12">
                    <v-switch color="primary" label="Use Material" class="mt-0" v-model="Variant.use_material" @change="changeMaterial()"></v-switch>
                </base-column>
            </v-row>
            <div v-if="Variant.use_material">
                <v-divider class="mx-4 mt-2"></v-divider>
                <v-row class="mx-1 mt-4">
                    <base-column :lg="6" :md="4" :sm="12">
                        <p> Item Ingredient Information </p>
                    </base-column>
                    <base-column :lg="6" :md="4" :sm="12">
                        <v-btn color="primary" class="float-end" outlined small @click="openMaterialDialog()"><v-icon
                                left>mdi-plus</v-icon> Add
                            Material </v-btn>
                    </base-column>
                </v-row>
                <v-simple-table class="mx-2 mb-2 mt-4" fixed-header>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left white--text" width="1%" style="background-color:#898F99;">No</th>
                                <th class="text-left white--text" width="10%" style="background-color:#898F99;">Material
                                    Name</th>
                                <th class="text-left white--text" width="15%" style="background-color:#898F99;">Description
                                </th>
                                <th class="text-left white--text" width="8%" style="background-color:#898F99;">Unit</th>
                                <th class="text-left white--text" width="10%" style="background-color:#898F99;">Price Sell
                                </th>
                                <th class="text-left white--text" width="7%" style="background-color:#898F99;">Qty
                                </th>
                                <th class="text-left white--text" width="8%" style="background-color:#898F99;">Amount</th>
                                <th class="text-left white--text" width="1%" style="background-color:#898F99;">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr height="80" v-for="(row, index) in  Variant.ingredients" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td>
                                    {{ row.name ?? "N/A" }}
                                </td>
                                <td>
                                    {{ row?.description ?? "N/A" }}
                                </td>
                                <td>{{ row.unit ?? "N/A" }}</td>
                                <td>
                                    {{ row.price ?? 0 }}
                                </td>
                                <td>
                                    <v-text-field v-model="row.qty" placeholder="Qty Usage" type="number" :change="qty()"
                                        outlined dense hide-details></v-text-field>
                                </td>
                                <td>
                                    <b>
                                        {{
                                            row.amount = (row.price * row.qty) | currency("", 0, { symbolOnLeft: false })
                                        }}
                                    </b>
                                </td>
                                <td>
                                    <v-icon color="error" @click="removeMaterial(index)">mdi-delete
                                    </v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-dialog v-model="dialogMaterial" v-if="dialogMaterial" max-width="1200px" persistent><material-modal
                        v-on:closeMaterialDialog="closeMaterialDialog" v-on:selectMaterial="selectMaterial" />
                </v-dialog>
            </div>
            <v-divider class="mx-4"></v-divider>
            <v-row class="mx-1 mt-2">
                <v-btn small color="secondary" class="ma-2" @click="backForm()">
                    <v-icon>mdi-arrow-left</v-icon> Back
                </v-btn>
                <v-btn small color="secondary" class="ma-2" @click="clearForm()">
                    Clear
                    <v-icon>mdi-close-circle-multiple </v-icon>
                </v-btn>
                <v-btn color="primary" small class="ma-2" @click.stop="store" :loading="btnLoading"
                    :disabled="!form_valid || btnLoading">Save
                    <v-icon right>mdi-content-save</v-icon></v-btn>
            </v-row>
        </v-form>
        <br>
    </div>
</template>
  
<script>
import BaseColumn from "@/components/base/BaseColumn";
import MaterialModal from "@/components/modal/MaterialModal.vue";
import { mapState, mapMutations, mapGetters } from "vuex";
import _ from "lodash";
export default {
    name: "ProductForm",
    data() {
        return {
            btnLoading: false,
            form_valid: true,
            dialogMaterial: false,

        };
    },
    created() {
        this.getBranch();
    },

    methods: {
        ...mapMutations("master_variant", ["CLEAR_STATE", "SET_VARIANT"]),

        store: async function () {
            if (this.$refs.Variant.validate()) {
                if(this.Variant.use_material == true && this.Variant.ingredients.length == 0){
                    alert("Please add material");
                    return false
                }
                this.btnLoading = true;
                await this.$axios
                    .post("/master/toppings", this.Variant,
                        {
                            headers: { Authorization: this.$store.getters["auth/Token"] },
                        })
                    .then(() => {
                        this.btnLoading = false;
                        this.CLEAR_STATE();
                        this.$router.push({ name: "item.tabs-list" });
                    })
                    .catch(() => {
                        this.btnLoading = false;
                    });
            }
        },

        openMaterialDialog() {
            this.dialogMaterial = true;
        },

        closeMaterialDialog() {
            this.dialogMaterial = false;
        },

        selectMaterial(val) {
            if (this.Variant.ingredients.find((item) => item.id === val.id)) {
                this.Variant.ingredients.find((item) => item.id === val.id).qty++;
                return;
            }
            this.$store.commit("master_variant/SET_INGREDIENTS", val);
            this.dialogMaterial = false;
        },

        removeMaterial(index) {
            this.Variant.ingredients.splice(index, 1);
        },

        qty() {
            if(this.Variant.use_material){
            this.Variant.price = _.sumBy(this.Variant.ingredients, function (o) { return o.price * o.qty; });
            }else{
                this.Variant.price = 0
            }
        },
        changeMaterial() {
            if (this.Variant.use_material == false) {
                this.$store.commit("master_variant/CLEAR_INGREDIENTS");
            }else{
                this.Variant.price = 0
            }
        },

        async getBranch() {
            await this.$axios("/master/branches?active=1", {
                headers: {
                    Authorization: this.$store.getters["auth/Token"],
                },
            }).then(({ data }) => {
                this.branch = data.branch.data;
            });
        },

        clearForm() {
            this.CLEAR_STATE();
        },

        backForm() {
            this.CLEAR_STATE();
            this.$router.push({ name: "item.tabs-list" });
        },

    },

    computed: {
        ...mapState("master_variant", {
            Variant: (state) => state.variant,
        }),

        ...mapGetters("auth", {
            ROLE: "Role",
        }),

    },

    components: {
        BaseColumn,
        "material-modal": MaterialModal,
    },
};
</script>