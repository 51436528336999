<template>
  <div>
    <v-app-bar app elevation="0" color="#fff">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title> </v-toolbar-title>
      <v-spacer></v-spacer>
      <notification />
      <v-menu :close-on-content-click="true" origin="center center" transition="scale-transition" :offset-y="true" bottom
        left ml-1>
      </v-menu>
      <v-menu :close-on-content-click="true" origin="center center" transition="scale-transition" :offset-y="true" bottom
        left ml-1>
        <template v-slot:activator="{ on }">
          <v-avatar size="30" class="on_cursor mx-3">
            <img src="@/assets/user_default.png" lazy-src="@/assets/user_default.png" v-on="on" />
          </v-avatar>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img src="@/assets/user_default.png" lazy-src="@/assets/user_default.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="title">
                {{ USER.user.name ? USER.user.name : "N/A" }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <span color="secondary">
                  {{ USER.user.roles[0].name ? USER.user.roles[0].name : "N/A" }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item @click.prevent="logout">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-power</v-icon>
            </v-list-item-icon>
            <v-list-item-title>LOGOUT</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-app-bar>
    <v-navigation-drawer width="240" dark color="primary" app v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            <img src="@/assets/yubilogo.png" class="mx-5" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item-group color="#898F99" v-model="group">
        <v-list-item-title class="ml-4 mb-2" style="font-size: 14px;color:white;">Dashboard</v-list-item-title>
        <v-list-item to="/dashboard">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title style="font-size: 14px;color:white;">Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-title class="ml-4 mb-2" style="font-size: 14px;color:white;">Master</v-list-item-title>
        <v-list-item to="/master/account-settings">
          <v-list-item-icon>
            <v-icon>mdi-wrench</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title style="font-size: 14px;color:white;">Master Management</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-title class="ml-4 my-2" style="font-size: 14px;color:white;">Management</v-list-item-title>
        <v-list-group :value="false" prepend-icon="mdi-cart-outline">
          <template v-slot:activator>
            <v-list-item-title style="font-size: 14px;color:white;">Sales Management</v-list-item-title>
          </template>
          <v-list-item class="ml-3" to="/tab-daily-sales">
            <v-list-item-icon>
              <v-icon>mdi-folder</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="font-size: 14px;color:white;">Daily Sales Order</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- <v-list-group :value="false" prepend-icon="mdi-storefront">
          <template v-slot:activator>
            <v-list-item-title style="font-size: 14px;color:white;">Online Store Management</v-list-item-title>
          </template>
          <v-list-item class="ml-3" to="/store-management/tabs">
            <v-list-item-icon>
              <v-icon>mdi-folder</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="font-size: 14px;color:white;">Order</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->
        <!-- <v-list-group :value="false" prepend-icon="mdi-source-branch-sync" v-if="(ROLE == 'Owner')">
          <template v-slot:activator>
            <v-list-item-title style="font-size: 14px;color:white;">Branch Management</v-list-item-title>
          </template>
          <v-list-item class="ml-3" to="/branch-sales-order-tabs">
            <v-list-item-icon>
              <v-icon>mdi-folder</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="font-size: 14px;color:white;">Branch Sales</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->
        <!-- <v-list-group :value="false" prepend-icon="mdi-dolly">
          <template v-slot:activator>
            <v-list-item-title style="font-size: 14px;color:white;">Purchase Management</v-list-item-title>
          </template>
          <v-list-item class="ml-3" to="/purchase-order">
            <v-list-item-icon>
              <v-icon>mdi-folder</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="font-size: 14px;color:white;">Purchase Order</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="ml-3" to="/request-order">
            <v-list-item-icon>
              <v-icon>mdi-folder</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="font-size: 14px;color:white;">Request Order</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="ml-3" to="/purchase-invoice">
            <v-list-item-icon>
              <v-icon>mdi-folder</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="font-size: 14px;color:white;">Purchase Invoice</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="ml-3" to="/purchase-adjustment">
            <v-list-item-icon>
              <v-icon>mdi-folder</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="font-size: 14px;color:white;">Purchase Adjustment</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->

        <!-- <v-list-group :value="false" prepend-icon="mdi-sitemap">
          <template v-slot:activator>
            <v-list-item-title style="font-size: 14px;color:white;">Inventory Management</v-list-item-title>
          </template>
          <v-list-item class="ml-3" to="/inventory-in">
            <v-list-item-icon>
              <v-icon>mdi-folder</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="font-size: 14px;color:white;">Inventory In</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="ml-3" to="/inventory-out">
            <v-list-item-icon>
              <v-icon>mdi-folder</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="font-size: 14px;color:white;">Inventory Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->
        <!-- <v-list-item to="/petty-cash">
          <v-list-item-icon>
            <v-icon>mdi-cash-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title style="font-size: 14px;color:white;">Patty Cash</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list-item-group>
    </v-navigation-drawer>
    <v-main class="mx-1 mr-4 mb-4">
      <slot />
    </v-main>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Notification from "@/components/notification/Notification.vue";

export default {
  name: "MainLayout",
  data: () => ({
    drawer: true,
    group: null,
    loginTime: 0,
    dialogPurchaseOrders: false,
  }),
  methods: {
    logout() {
      this.loginTime = 0;
      this.$store.dispatch("auth/logout");
      localStorage.removeItem("S-ERP-Application");
      this.$router.push("/");
    },
    openAccounting() {
      window.open("http://localhost:8080", "_blank");
    },
    toggleThemeDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },

    openPurchaseDialog() {
      this.dialogPurchaseOrders = true;
    },
    closePurchaseDialog() {
      this.dialogPurchaseOrders = false;
    },

    selectPurchase(purchase_order) {
      this.$axios
        .get("/transaction/purchases/" + purchase_order.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit("purchase_order/CLEAR_STATE");
          this.$store.commit("purchase_order/SET_PO", data.purchase_orders);

        })
        .catch(() => {
          this.loading = true;
        });
    },
  },
  computed: {
    ...mapGetters("auth", {
      AUTHENTICATED: "Authenticated",
      USER: "User",
      CAN: "Can",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    APP_NAME() {
      return process.env.VUE_APP_NAME;
    },
  },
  watch: {
    loginTime: {
      handler(value) {
        if (value >= 0) {
          setTimeout(() => {
            this.loginTime =
              this.AUTHENTICATED == true ? this.loginTime + 1 : -1;
          }, 1000);
        } else {
          this.$store.dispatch("auth/logout");
          this.$router.replace("/login");
        }
      },
      immediate: true,
    },
    group() {
      this.drawer = true;
    },
  },
  components: {
    Notification,
  },
};
</script>

<style scoped>
.profile {
  position: fixed !important;
  bottom: 0 !important;
  width: 100%;
}

.role {
  font-size: 12px;
}
</style>