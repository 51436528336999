<template>
  <main-layout>
    <base-header>
      <template #icon> mdi-file</template>
      <template #name> Create Petty Cash</template>
      <template #breadcrumbs>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </template>
    </base-header>

    <v-card class="mx-1">
      <!-- App Bar -->
      <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
        <v-spacer></v-spacer>
        <v-btn small color="secondary" class="ma-2" @click="backForm()">
          <v-icon>mdi-arrow-left</v-icon> Back
        </v-btn>
        <v-btn small color="secondary" class="ma-2" @click="clearForm()">
          Clear
          <v-icon>mdi-close-circle-multiple </v-icon>
        </v-btn>
        <v-btn small class="ma-2" color="primary" @click.stop="store" :loading="btnLoading"
          :disabled="!form_valid || btnLoading">
          Save
          <v-icon right>mdi-content-save-all</v-icon>
        </v-btn>
      </v-app-bar>
      <v-form ref="PettyCash" v-model="form_valid" lazy-validation>
        <form-petty-cash />
      </v-form>
    </v-card>
  </main-layout>
</template>

<script>
import Form from "./Form.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import BaseHeader from "@/components/base/BaseHeader.vue";
import { mapMutations } from "vuex";
export default {
  name: "PettyCashCreate",

  data() {
    return {
      btnLoading: false,
      form_valid: true,
      breadcrumbs: [],

    };
  },
  created() {
    this.breadcrumbs = [
      { text: "Transaction", disabled: false },
      { text: "Petty Cash", disabled: true },
      { text: "Create", disabled: true },
    ];
  },

  methods: {
    ...mapMutations("petty_cash", ["CLEAR_STATE"]),
    store: async function () {
      if (this.$refs.PettyCash.validate()) {
        this.btnLoading = true;
        this.PettyCash.amount;
        await this.$axios
          .post("/transaction/petty-cash", this.PettyCash,
            {
              headers: { Authorization: this.$store.getters["auth/Token"] },
            })
          .then(() => {
            this.btnLoading = false;
            this.CLEAR_STATE();
            this.$router.push({ name: "petty-cash.index" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },
    backForm() {
      this.CLEAR_STATE();
      this.$router.push({ name: "petty-cash.index" });
    },

    clearForm() {
      this.CLEAR_STATE();
    },
  },
  computed: {
    PettyCash() {
      return this.$store.getters["petty_cash/PettyCash"];
    },
  },

  components: {
    MainLayout,
    BaseHeader,
    "form-petty-cash": Form,
  },
};
</script>
