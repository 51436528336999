<template>
  <div>
    <base-title title="Sub Group" subtitle="List Data Sub Group" />
    <v-data-table :headers="headers" :items="SubGroups.data" 
      class="elevation-1" :loading="datatableLoading" loading-text="Loading... Please wait" hide-default-footer>
      <template v-slot:[`item.is_active`]="{ item }">
        <v-chip x-small :color="getColor(item.is_active)" dark>
          {{ item.is_active ? "Active" : "Not Active" }}
        </v-chip></template>
      <template v-slot:no-data> No data </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-col cols="12">
            <strong>Group Name:</strong>{{ item.group.name }}
          </v-col>
        </td>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <img :src="item.image" width="50" height="50" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="warning" class="mr-2" @click.stop="editItem(item)">
          mdi-pencil-box-outline
        </v-icon>
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field v-model="search.search" append-icon="mdi-magnify" label="Search" outlined dense
            hide-details></v-text-field>
          <v-btn color="darkblue" class="white--text mx-1 mb-2" small @click.stop="searching">Submit <v-icon
              right>mdi-magnify</v-icon></v-btn>
          <v-btn small color="secondary" class="white--text mx-1 mb-2" @click.stop="clear">Clear
            <v-icon right>mdi-close-circle-multiple </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogForm" max-width="450px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small elevation="1" color="primary" class="ma-2 white--text" @click="loader = 'loading3'">
                Create
                <v-icon right dark>mdi-plus-circle </v-icon>
              </v-btn>
            </template>
            <v-form ref="form_data" v-model="form_valid" lazy-validation>
              <v-card class="rounded-lg">
                <v-card-title class="mb-3">
                  <span class="headline">{{ form_title }}</span>
                </v-card-title>
                <v-card-text>
                  <v-select v-model="form_data.group" label="Group Name" :items="list_group" item-text="name"
                    item-value="id" return-object dense outlined :rules="rule_group">
                  </v-select>
                  <v-text-field v-model="form_data.name" label="Sub Group Name" outlined dense :rules="rule_name">
                  </v-text-field>
                  <v-select label="Branch" :items="branch" v-if="(ROLE == 'Owner')" v-model="form_data.branch_id" dense
                    item-text="name" item-value="id" outlined></v-select>
                  <v-textarea v-model="form_data.description" label="Description" outlined dense rows="3">
                  </v-textarea>
                  <v-file-input label="Image" outlined dense v-model="form_data.image" id="file" ref="file"
                    v-on:change="handleFileUpload()"></v-file-input>
                  <v-switch dense v-model="form_data.is_active" label="USE">
                  </v-switch>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn small color="error darken-1" @click.stop="closedialogForm">Cancel
                    <v-icon right dark>mdi-close </v-icon>
                  </v-btn>
                  <v-btn small color="primary" class="ma-2 white--text" @click.stop="save" :loading="btnLoading"
                    :disabled="!form_valid || btnLoading">
                    Save
                    <v-icon right dark>mdi-content-save </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
    <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { datatableMixin } from "@/mixins/datatable.js";
import BaseTitle from "@/components/base/BaseTitle.vue";
export default {
  components: { BaseTitle },
  name: "GroupView",

  mixins: [datatableMixin],

  data: () => ({
    headers: [
      { text: "Group", value: "group.name" },
      { text: "Sub Group", value: "name" },
      { text: "Description", value: "description" },
      { text: "Image", value: "image" },
      { text: "Status", value: "is_active" },
      { text: "Actions", value: "actions", sortable: false, width: 100 },
    ],

    list_group: [],
    branch: [],
    disabled: false,
    file: '',
    page: 1,
    form_data: {
      name: "",
      branch_id: "",
      group: "",
      description: "",
      is_active: true,
    },
    form_default: {
      name: "",
      branch_id: "",
      group: "",
      description: "",
      is_active: true,
    },

    search: {
            search: null,
        },
  }),

  created() {
    this.getBranch();
    this.initialize();
    this.getGroup();
  },

  methods: {
    ...mapMutations("sub_group", ["SET_CURRENT_PAGE"]),
    initialize: async function () {
      this.datatableLoading = true;
      let params = {
        page: this.page,
        search: this.search.search,
      }
      await this.$axios
        .get("/master/subgroup", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.datatable = data.subgroup.data;
          this.$store.commit("sub_group/SET_SUBGROUPS", data.subgroup);
          this.datatableLoading = false;
        })
        .catch(() => {
          this.datatableLoading = false;
        });
    },

    searching() {
      this.page = 1;
      this.initialize();
    },

    getGroup() {
      this.$axios
        .get("/master/group", {
          headers: { Authorization: this.$store.getters["auth/Token"] },
        })
        .then(({ data }) => {
          this.list_group = data.group.data;
        });
      this.dialogfrm = true;
    },

    handleFileUpload() {
      this.file = this.$refs.file.files;
    },

    save: async function () {
      if (this.$refs.form_data.validate()) {
        this.btnLoading = true;
        let formData = new FormData();
        formData.append('file', this.file);
        this.image = this.file;
        if (this.editedIndex > -1) {
          await this.$axios
            .post(
              "/master/subgroup/" + this.form_data.id,
              {
                _method: "POST",
                name: this.form_data.name,
                branch_id: this.form_data.branch_id,
                group_id: this.form_data.group.id,
                description: this.form_data.description,
                image: this.form_data.image ?? null,
                is_active: this.form_data.is_active,
              },
              {
                headers: {
                  Authorization: this.$store.getters["auth/Token"],
                  'Content-Type': 'multipart/form-data'
                },
              }
            )
            .then(() => {
              this.initialize();
              this.closedialogForm();
              this.btnLoading = false;
            })
            .catch(() => {
              this.btnLoading = false;
            });
        } else {
          await this.$axios
            .post(
              "/master/subgroup",
              {
                name: this.form_data.name,
                branch_id: this.form_data.branch_id,
                group_id: this.form_data.group.id,
                description: this.form_data.description,
                image: this.form_data.image ?? null,
                is_active: this.form_data.is_active,
              },
              {
                headers: {
                  Authorization: this.$store.getters["auth/Token"],
                  'Content-Type': 'multipart/form-data'
                },
              }
            )
            .then(() => {
              this.closedialogForm();
              this.btnLoading = false;
              this.initialize();
            })
            .catch(() => {
              this.btnLoading = false;
            });
        }
      }
    },
    async getBranch() {
      await this.$axios("/master/branches?active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.branch = data.branch.data;
      });
    },

    editItem(item) {
      this.id = item.id;
      this.editedIndex = this.datatable.indexOf(item);
      item.image = null
      this.form_data = Object.assign({}, item);
      this.$axios
        .get("/master/subgroup", {
          headers: { Authorization: this.$store.getters["auth/Token"] },
        })
        .then(({ data }) => {
          this.list_subgroup = data.subgroup.data;
        });

      this.dialogForm = true;
    },

    clear() {
            this.page = 1;
            this.search = {
                search: "",
            };
            this.initialize();
        },
  },

  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.initialize();
    },
  },
  computed: {
    form_title() {
      return this.editedIndex === -1 ? "Create Sub Group" : "Edit Sub Group";
    },

    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    ...mapGetters("sub_group", {
      SubGroups: "SubGroups",
    }),

    currentPage: {
      get() {
        return this.SubGroups.current_page;
      },
      set(val) {
        return this.$store.commit("sub_group/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.SubGroups.last_page;
      },
    },

  },
};
</script>