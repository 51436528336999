const state = () => ({
    payment_cards: {},
    page: 1
})

const mutations = {
    SET_PAYMENT_CARDS(state, payment_card) {
        state.payment_cards = payment_card
    },
    SET_CURRENT_PAGE(state, payload) {
        state.payment_cards.current_page = payload
    }
}

const getters = {
    PaymentCards: state => state.payment_cards,
    Page: state => state.page
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}