<template>
  <div>
    <v-data-table :loading="loading" :headers="headers" :items="USERS.data" class="elevation-1" hide-default-footer>
      <template v-slot:[`item.is_active`]="{ item }">
        <v-icon v-if="item.is_active == 1" color="success">
          mdi-check-circle
        </v-icon>
        <v-icon v-else color="error"> mdi-close-circle </v-icon>
      </template>
      <template v-slot:no-data> No data </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="warning" class="mr-2" @click.stop="edit(item.id)">
          mdi-pencil-box-outline
        </v-icon>
      </template>

      <template v-slot:top>
        <v-row dense class="mx-2">
          <base-column :lg="4" :md="4" :sm="12">
            <v-text-field v-model="search.search" append-icon="mdi-magnify" label="Search" outlined dense
              hide-details></v-text-field>
          </base-column>
          <base-column :lg="3" :md="4" :sm="12" v-if="(ROLE == 'Owner')">
            <v-select v-model="search.branch_id" :items="branch" item-text="name" item-value="id" label="Branch" outlined
              dense></v-select>
          </base-column>
          <base-column :lg="3" :md="4" :sm="12">
            <v-btn small color="darkblue" class="white--text" @click.stop="searching">Submit <v-icon
                right>mdi-magnify</v-icon></v-btn>
            <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
              <v-icon right>mdi-close-circle-multiple </v-icon>
            </v-btn>
          </base-column>
        </v-row>
        <v-spacer></v-spacer>
      </template>
    </v-data-table>

    <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
  </div>
</template>


<script>
import BaseColumn from "@/components/base/BaseColumn";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "ItemList",
  data() {
    return {
      headers: [
        { text: "Username", value: "username" },
        { text: "Name", value: "name" },
        { text: "E-Mail", value: "email" },
        { text: "Phone Number", value: "phone" },
        { text: "Role", value: "roles[0].name" },
        { text: "Status", value: "is_active" },
        { text: "Actions", value: "actions", sortable: false, width: 100 },
      ],
      page: 1,
      branch: [],
      disabled: false,
      loading: false,
      status: ["Active", "Inactive"],
      search: {
        branch_id: null,
        status: null,
        search: null,
      },
      expanded: [],
    };
  },

  created() {
    this.getBranch();
    this.getUser();
  },
  methods: {
    ...mapMutations("user", ["CLEAR_STATE", "SET_USERS", "SET_USER", "SET_CURRENT_PAGE"]),
    async getUser() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        branch_id: this.search.branch_id,
        status: this.search.status,
        search: this.search.search,
      };

      await this.$axios
        .get("/master/user", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit("user/SET_USERS", data.user);
          this.loading = false;
          this.disabled = false;
        });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch.data;
        });
    },

    searching() {
      this.page = 1;
      this.getUser();
    },
    clear() {
      this.page = 1;
      this.search = {
        branch_id: "",
        status: "",
        search: "",
      };
      this.getUser();
    },

    async edit(id) {
      this.loading = true;
      await this.$axios
        .get("/master/user/" + id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_USER(data.user);
          this.loading = false;
          this.$router.push("/users/edit");
        })
        .catch(() => {
          this.loading = true;
        });
    },
  },
  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.getUser();
    },
  },
  computed: {
    ...mapGetters("user", {
      USERS: "Users",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    currentPage: {
      get() {
        return this.USERS.current_page;
      },
      set(val) {
        return this.$store.commit("item/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.USERS.last_page;
      },
    },
  },

  components: {
    BaseColumn,
  },
};
</script>