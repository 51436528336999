<template>
    <v-card>
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title> Outgoing List </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="closeOutgoingDialog" color="error" outlined small>
                <v-icon>mdi-close</v-icon></v-btn>
        </v-app-bar>
        <v-row class="mx-1 mb-1 mt-2">
            <v-col xs="12" sm="6" md="2" lg="2">
                <v-text-field type="date" label="From date" outlined dense v-model="search.from_date">
                </v-text-field>
            </v-col>
            <v-col xs="12" sm="6" md="2" lg="2">
                <v-text-field type="date" label="End date" outlined dense v-model="search.end_date">
                </v-text-field>
            </v-col>

            <v-col xs="12" sm="6" md="3" lg="3">
                <v-text-field type="text" label="Search" outlined dense v-model="search.keyword">
                </v-text-field>
            </v-col>
            <v-btn small color="darkblue" class="white--text float-end mx-1 mt-3" @click.stop="init">Submit <v-icon
                    right>mdi-magnify</v-icon></v-btn>
            <v-btn small color="secondary" class="white--text float-end mx-1 mt-3" @click.stop="clear">Clear
                <v-icon right>mdi-close-circle-multiple </v-icon>
            </v-btn>
        </v-row>
        <v-data-table :loading="loading" :headers="headers" :items="Outgoings.data" class="elevation-1" hide-default-footer>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn color="primary" outlined small @click.stop="selectOutgoing(item)">Select<v-icon right> mdi-check
                    </v-icon></v-btn>
            </template>
        </v-data-table>
        <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
    </v-card>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
export default {
    name: "IngoingModal",
    data() {
        return {
            headers: [
                { text: "Outgoing No.", value: "no_outgoing" },
                { text: "Outgoing Date", value: "date" },
                { text: "Actions", value: "actions", sortable: false },
            ],
            disabled: false,
            loading: false,
            search: {
                from_date: new Date().toJSON().slice(0, 10),
                end_date: new Date().toJSON().slice(0, 10),
                keyword: null,
            },
            page: 1,
        };
    },

    created() {
        this.init();
    },

    methods: {
        ...mapMutations("outgoing", ["CLEAR_STATE", "SET_OUTGOINGS", "SET_OUTGOING"]),

        async init() {
            this.loading = true;
            this.disabled = true;
            let params = {
                page: this.page,
                keyword: this.search.keyword,
                from_date: this.search.from_date,
                end_date: this.search.end_date,
            };
            await this.$axios
                .get("/transaction/outgoings", {
                    params: params,
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    this.SET_OUTGOINGS(data.out_goings);
                    this.loading = false;
                    this.disabled = false;
                });
        },

        searching() {
            this.page = 1;
            this.init();
        },
        clear() {
            this.page = 1;
            this.search = {
                search: "",
                from_date: new Date().toJSON().slice(0, 10),
                end_date: new Date().toJSON().slice(0, 10),
            };
            this.init();
        },
        closeOutgoingDialog() {
            this.$emit("closeOutgoingDialog");
        },
        selectOutgoing(item) {
            this.$emit("selectOutgoing", item);
            this.closeOutgoingDialog();
        },
    },

    watch: {
        currentPage(newValue) {
            this.page = newValue;
            this.init();
        },
    },

    computed: {
        ...mapGetters("outgoing", ["Outgoings"]),
        currentPage: {
            get() {
                return this.Outgoings.current_page;
            },
            set(val) {
                return this.$store.commit("outgoing/SET_CURRENT_PAGE", val);
            },
        },
        lastPage: {
            get() {
                return this.Outgoings.last_page;
            },
        },
    },
    destroyed() {
        this.CLEAR_STATE();
    },
};
</script>
  