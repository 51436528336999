const state = () => ({
    sales_orders: {},
    sales_order: {
        id: null,
        no_so: null,
        customer_id: null,
        customer_name: null,
        customer_address: null,
        customer_phone: null,
        customer_email: null,
        bank_account_id: null,
        name_account: null,
        number_account: null,
        bank_name: null,
        so_status: "PROCESS",
        branch_id: 1,
        table_id : null,
        payment_method_id: null,
        use_vat: false,
        order_date: new Date().toJSON().slice(0, 10),
        delivery_date: new Date().toJSON().slice(0, 10),
        pph_id: null,
        use_pph : false,
        subtotal: 0,
        type : "BACKOFFICE",
        discount_nominal: 0,
        discount_percent: 0,
        afterDiscount: 0,
        discount_amount: 0,
        change_money: 0,
        vat: 0,
        pph: 0,
        pph_percentage: 0,
        grand_total: 0,
        details: [],

    },
    details_variant: [],
    page: 1,

})

const mutations = {
    SET_SALES_ORDERS(state, payload) {
        state.sales_orders = payload
    },

    SET_SALES_ORDER(state, payload) {
        state.sales_order.id = payload.id
        state.sales_order.no_so = payload.no_so
        state.sales_order.customer_id = payload.customer_id
        state.sales_order.customer_name = payload.customer.name ?? payload.customer_name
        state.sales_order.customer_address = payload.customer.address
        state.sales_order.customer_phone = payload.customer.phone
        state.sales_order.customer_email = payload.customer.email
        state.sales_order.bank_account_id = payload.bank_account_id
        state.sales_order.name_account = payload.bank_account.name_account
        state.sales_order.number_account = payload.bank_account.number_account
        state.sales_order.bank_name = payload.bank_account.bank_name
        state.sales_order.branch_id = 1
        state.sales_order.table_id = null
        state.sales_order.payment_method_id = payload.payment_method_id
        state.sales_order.use_vat = payload.use_vat
        state.sales_order.order_date = payload.order_date
        state.sales_order.delivery_date = payload.delivery_date
        state.sales_order.pph_id = payload.pph_id
        state.sales_order.discount_nominal = payload.discount_nominal
        state.sales_order.discount_percent = payload.discount_percent
        state.sales_order.discount_amount = payload.discount_amount
        state.sales_order.afterDiscount = payload.discount_nominal
        state.sales_order.subtotal = payload.subtotal
        state.sales_order.vat = payload.vat
        state.sales_order.pph = payload.pph
        state.sales_order.pph_percentage = payload.pph_percentage
        state.sales_order.grand_total = payload.grand_total
        state.sales_order.details = payload.details

    },

    SET_SO(state, payload) {
        state.sales_order.id = payload.id;
        state.sales_order.no_so = payload.no_so;
        state.sales_order.customer_id = payload.customer_id
        state.sales_order.customer_name = payload.customer.name ?? payload.customer_name
        state.sales_order.customer_address = payload.customer.address
        state.sales_order.customer_phone = payload.customer.phone
        state.sales_order.customer_email = payload.customer.email
        state.sales_order.bank_account_id = payload.bank_account_id
        state.sales_order.name_account = payload.bank_account.name_account
        state.sales_order.number_account = payload.bank_account.number_account
        state.sales_order.bank_name = payload.bank_account.bank_name
        state.sales_order.branch_id = payload.branch_id
        state.sales_order.payment_method_id = payload.payments[0].id
        state.sales_order.use_vat = payload.use_vat
        state.sales_order.order_date = payload.order_date
        state.sales_order.delivery_date = payload.delivery_date
        state.sales_order.type = "BACKOFFICE"
        state.sales_order.pph_id = payload.pph_id
        state.sales_order.use_pph = payload.use_pph
        state.sales_order.change_money = payload.change_money
        state.sales_order.discount_nominal = payload.discount_nominal
        state.sales_order.discount_percent = payload.discount_percent
        state.sales_order.discount_amount = payload.discount_amount
        state.sales_order.afterDiscount = payload.discount_nominal
        state.sales_order.subtotal = payload.subtotal
        state.sales_order.vat = payload.vat
        state.sales_order.pph = payload.pph
        state.sales_order.pph_percentage = payload.pph_percentage
        state.sales_order.grand_total = payload.grand_total
        for (let i = 0; i < payload.details.length; i++) {
            state.sales_order.details.push({
                item_id: payload.details[i].item_id,
                item_name: payload.details[i].item.name,
                item_description: payload.details[i].item.description,
                item_unit: payload.details[i].item.unit.name,
                qty: payload.details[i].qty,
                price: payload.details[i].price,
            });
        }
    },

    SET_DETAILS(state, payload) {
        state.sales_order.details.push({
            item_id: payload.id,
            item_name: payload.name,
            item_description: payload.description,
            item_unit: payload.unit.name,
            price: payload.total,
            qty: 1,
            total: payload.total,
            variant_size_id : null,
            variants:[],
            toppings: []
        })
    },

    SET_DETAILS_VARIANTS(state, payload) {
        const sales_details = state.sales_order.details.find(d => d.item_id == payload.item_id)

        let checkVariant = false;
        let checkTopping = false;
        let priceToppings = 0;
        let priceVariants = 0;
        let qtyVariants = 0;
        let indexVariant = -1;
        let indexVariantTopping = -1;
        let checkSizeAndTopping = false
    
        sales_details.variants.forEach((v,i) => {
            if(v?.size?.id == payload?.size?.id){
                checkVariant = true
                indexVariant = i
                return
            }
        })


        // sales_details.variants.forEach((v,i) => {
        //     v.topping.forEach(t => {
        //         if(t?.size?.id == payload?.size?.id){
        //             checkSizeAndTopping = true
        //             indexVariant = i
        //             return
        //         }
        //     })
        // })

        console.log({
            "checkTopping": checkTopping,
            "checkVariant": checkVariant,
            "indexVariant": indexVariant,
            "indexVariantTopping": indexVariantTopping
        })

        // if(!checkTopping && !checkVariant){
        //     sales_details.variants.push(payload)
        // }

        // if(!checkTopping && checkVariant){
        //     // sales_details.variants[indexVariant] = payload
        //     sales_details.variants.push(payload)
        // }

        // if(checkTopping && !checkVariant){
        //     // sales_details.variants[indexVariant] = payload
        //     sales_details.variants.push(payload)
        // }

        // if(indexVariant == -1 && indexVariantTopping == -1){
        //     sales_details.variants.push(payload)
        // }

        // if(indexVariant != indexVariantTopping){
        //     sales_details.variants.push(payload)
        // }

        if(!checkSizeAndTopping){
            sales_details.variants.push(payload)
        }

        sales_details?.variants?.forEach(v => {
            priceVariants += parseInt(v?.size?.price ?? 0)
        })

        sales_details?.variants?.forEach(v => {
            v?.topping?.forEach(t => {
                priceToppings += parseInt(t?.topping?.price ?? 0)
            })
        })

        sales_details.variants.forEach(v => {
            qtyVariants += v?.qty ?? 1
        })
    
        console.log("index variant", indexVariant)

        sales_details.price = (priceToppings + priceVariants) * (qtyVariants + 1)
        sales_details.qty = qtyVariants
        if(indexVariant != -1){
            sales_details.variants[indexVariant].qty = sales_details.variants[indexVariant].qty + 1
        }
    },
    SET_DETAILS_VARIANT(state, payload) {
        state.details_variant = payload
    },

    SET_CURRENT_PAGE(state, payload) {
        state.page = payload
    },

    SET_CUSTOMER(state, payload) {
        state.sales_order.customer_id = payload.id
        state.sales_order.customer_name = payload.name
        state.sales_order.customer_address = payload.address
        state.sales_order.customer_phone = payload.phone
        state.sales_order.customer_email = payload.email
    },

    SET_BANK_ACCOUNT(state, payload) {
        state.sales_order.bank_account_id = payload.id
        state.sales_order.name_account = payload.name_account
        state.sales_order.number_account = payload.number_account
        state.sales_order.bank_name = payload.bank_name
    },

    SET_SUBTOTAL(state, payload) {
        state.sales_order.subtotal = payload
    },

    SET_DISCOUNT(state, payload) {
        state.sales_order.discount_amount = payload
    },

    SET_VAT(state, payload) {
        state.sales_order.vat = payload
    },

    SET_PPH(state, payload) {
        state.sales_order.pph = payload
    },

    SET_GRAND_TOTAL(state, payload) {
        state.sales_order.grand_total = payload
    },

    CLEAR_DETAILS_VARIANT(state) {
        state.details_variant = []
    },

    CLEAR_STATE(state) {
        state.sales_order = {
            id: null,
            no_so: null,
            customer_id: null,
            customer_name: null,
            customer_address: null,
            customer_phone: null,
            customer_email: null,
            branch_id: 1,
            so_status: "PROCESS",
            payment_method_id: null,
            use_vat: false,
            pph_id: null,
            type : "BACKOFFICE",
            table_id : null,
            order_date: new Date().toJSON().slice(0, 10),
            delivery_date: new Date().toJSON().slice(0, 10),
            use_pph : false,
            change_money: 0,
            subtotal: 0,
            afterDiscount: 0,
            discount_nominal: 0,
            discount_percent: 0,
            discount_amount: 0,
            vat: 0,
            pph: 0,
            pph_percentage: 0,
            grand_total: 0,
            details: [],
        }
    }
}

const getters = {
    SalesOrders: state => state.sales_orders,
    SalesOrder: state => state.sales_order,
    DetailsVariant: state => state.details_variant,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}