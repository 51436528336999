const state = () => ({
    vouchers: {},
    page: 1,
})

const mutations = {
    SET_VOUCHERS(state, vouchers) {
        state.vouchers = vouchers
    },

    SET_CURRENT_PAGE(state, page) {
        state.vouchers.current_page = page
    },
}

const getters = {
    Vouchers: state => state.vouchers,
    page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}

