<template>
  <v-col xs="12" sm="12" md="12" lg="12">
    <v-responsive width="100%" v-if="$vuetify.breakpoint.xsOnly" />
    <v-card elevation="5" rounded>
      <v-row class="mx-1 mb-2">
        <base-column :xs="12" :sm="12" :md="4" :lg="2">
          <v-banner class="mt-3 summary" outlined rounded single-line>
            <v-row no-gutters class="mb-3 mt-2">
              <v-col xs="3" sm="3" md="3" class="my-auto mx-auto">
                <v-avatar color="primary" size="40">
                  <v-icon color="white">mdi-cash</v-icon>
                </v-avatar>
              </v-col>
              <v-col xs="9" sm="9" md="9">
                <h4 class="secondary--text ml-2">{{ this.total_transaction }}</h4>
                <h4 class="ml-2 mt-3"> Total Transaction</h4>
              </v-col>
            </v-row>
          </v-banner>
        </base-column>
        <base-column :xs="12" :sm="12" :md="4" :lg="2">
          <v-banner class="mt-3 summary" outlined rounded single-line>
            <v-row no-gutters class="mb-3 mt-2">
              <v-col xs="3" sm="3" md="3" class="my-auto mx-auto">
                <v-avatar color="primary" size="40">
                  <v-icon color="white">mdi-cash</v-icon>
                </v-avatar>
              </v-col>
              <v-col xs="9" sm="9" md="9">
                <h4 class="secondary--text ml-2">{{ this.total_amount | currency("", 0, { symbolOnLeft: false, }) }}</h4>
                <h4 class="ml-2 mt-3"> Total Amount</h4>
              </v-col>
            </v-row>
          </v-banner>
        </base-column>
        <base-column :xs="12" :sm="12" :md="4" :lg="2">
          <v-banner class="mt-3 summary" outlined rounded single-line>
            <v-row no-gutters class="mb-3 mt-2">
              <v-col xs="3" sm="3" md="3" class="my-auto mx-auto">
                <v-avatar color="primary" size="40">
                  <v-icon color="white">mdi-cash</v-icon>
                </v-avatar>
              </v-col>
              <v-col xs="9" sm="9" md="9">
                <h4 class="secondary--text ml-2">{{ this.total_cash | currency("", 0, { symbolOnLeft: false, }) }}</h4>
                <h4 class="ml-2 mt-3"> Total Cash</h4>
              </v-col>
            </v-row>
          </v-banner>
        </base-column>
        <base-column :xs="12" :sm="12" :md="4" :lg="2">
          <v-banner class="mt-3 summary" outlined rounded single-line>
            <v-row no-gutters class="mb-3 mt-2">
              <v-col xs="3" sm="3" md="3" class="my-auto mx-auto">
                <v-avatar color="primary" size="40">
                  <v-icon color="white">mdi-cash</v-icon>
                </v-avatar>
              </v-col>
              <v-col xs="9" sm="9" md="9">
                <h4 class="secondary--text ml-2">{{ this.total_card | currency("", 0, { symbolOnLeft: false, }) }}</h4>
                <h4 class="ml-2 mt-3"> Total Card</h4>
              </v-col>
            </v-row>
          </v-banner>
        </base-column>
      </v-row>
      <v-divider class="mx-4 mb-4"></v-divider>
      <v-row class="mx-1 mb-2">
        <v-col :xs="12" :sm="12" :md="4" :lg="2">
          <v-text-field type="date" label="From Date" outlined dense v-model="search.from_date">
          </v-text-field>
        </v-col>
        <v-col :xs="12" :sm="12" :md="4" :lg="2">
          <v-text-field type="date" label="End Date" outlined dense v-model="search.to_date">
          </v-text-field>
        </v-col>
        <v-col :xs="12" :sm="12" :md="4" :lg="2">
          <v-select :items="status" outlined dense label="Status" v-model="search.status"></v-select>
        </v-col>
        <v-col :xs="12" :sm="12" :md="4" :lg="2">
          <v-text-field type="text" label="Search" outlined dense v-model="search.search">
          </v-text-field>
        </v-col>
        <v-col :xs="12" :sm="12" :md="8" :lg="4">
          <v-btn small color="darkblue" class="white--text mx-1" @click.stop="init">Submit <v-icon
              right>mdi-magnify</v-icon></v-btn>
          <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
            <v-icon right>mdi-close-circle-multiple </v-icon>
          </v-btn>
          <v-btn small color="success" class="white--text float-end" @click.stop="exportExcell">Excell
            <v-icon right>mdi-file-excel </v-icon>
          </v-btn>
          <v-btn small color="lightgray" class="white--text mx-1 float-end" @click.stop="exportPdf">Pdf
            <v-icon right>mdi-file-pdf-box </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table :loading="loading" :headers="headers" :items="DailySalesOrders.data" class="elevation-3"
        hide-default-footer>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip color="primary" class="white--text" small v-if="item.status == 'ONPROGRESS'">
            ON PROGRESS
          </v-chip>
          <v-chip color="secondary" small v-else-if="item.status == 'VOID'">
            VOID
          </v-chip>
          <v-chip color="info" small v-else-if="item.status == 'HOLD'">
            HOLD
          </v-chip>
          <v-chip color="success" small v-else-if="item.status == 'DONE'">
            DONE
          </v-chip>
          <v-chip color="danger" small v-else-if="item.status == 'CANCEL'">
            CANCEL
          </v-chip>
        </template>
        <template v-slot:[`item.no`]="{ item }">
          {{ DailySalesOrders.data.indexOf(item) + 1 }}
        </template>
        <template v-slot:[`item.service`]="{ item }">
          {{ item.service ?? 0 }}
        </template>
        <template v-slot:[`item.order_date`]="{ item }">
          {{
            new Date(item.order_date).toLocaleString("id-ID", { timeZone: "Asia/Jakarta" })
          }}
        </template>
        <template v-slot:[`item.grand_total`]="{ item }">
          <p>{{ item.grand_total | currency("", 0, { symbolOnLeft: false, }) }}</p>
        </template>
        <template v-slot:[`item.subtotal`]="{ item }">
          <p>{{ item.subtotal | currency("", 0, { symbolOnLeft: false, }) }}</p>
        </template>
        <template v-slot:[`item.discount_amount`]="{ item }">
          <p>{{ item.discount_amount | currency("", 0, { symbolOnLeft: false, }) }}</p>
        </template>
        <template v-slot:[`item.vat`]="{ item }">
          <p>{{ item.vat | currency("", 0, { symbolOnLeft: false, }) }}</p>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip color="dark" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" color="primary" v-bind="attrs" icon small @click.stop="view(item.id)" v-on="on">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View Sales Order</span>
          </v-tooltip>
        </template>
        <template slot="body.append">
          <tr>
            <th colspan="13"></th>
            <th style="font-size: 15px;">{{ sumField('grand_total') | currency("", 0, { symbolOnLeft: false, }) }}</th>
            <th></th>
          </tr>
        </template>
      </v-data-table>
      <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
    </v-card>
  </v-col>
</template>

<script>
import BaseColumn from "@/components/base/BaseColumn.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "DailySalesOrder",
  data() {
    return {
      headers: [
        { text: "No", value: "no" },
        { text: "Order Date", value: "order_date" },
        { text: "Order No", value: "no_so" },
        { text: "Order Type", value: "type" },
        { text: "Table", value: "table.no_table" },
        { text: "Person", value: "person" },
        { text: "Customer", value: "customer.name" },
        { text: "Order Amount", value: "amount" },
        { text: "Disc", value: "discount_amount" },
        { text: "Service", value: "service" },
        { text: "Vat", value: "vat" },
        { text: "Subtotal", value: "subtotal" },
        { text: "Disc Global", value: "discount_amount" },
        { text: "Total Amount", value: "grand_total" },
        { text: "Payment Type", value: "payments[0].payment_method.name" },
        { text: "Payment Total", value: "payments[0].amount" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ],
      disabled: false,
      loading: false,
      expanded: [],
      loading_download: false,
      status: ["ONPROGRESS", "DONE", "VOID", "HOLD", "CANCEL"],
      search: {
        from_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
        to_date: new Date().toJSON().slice(0, 10),
        search: null,
        status: null,
      },
      branch: [],
      total_transaction: 0,
      total_amount: 0,
      total_cash: 0,
      total_card: 0,

    };
  },

  methods: {
    ...mapMutations("daily_sales_order", ["SET_DAILY_SALES_ORDERS" ,"SET_CURRENT_PAGE"]),
    sumField(key) {
      return this.DailySalesOrders.data.reduce((a, b) => a + (b[key] || 0), 0)
    },
    async init() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        status: this.search.status,
        search: this.search.search,
        from_date: this.search.from_date,
        to_date: this.search.to_date,
      };

      await this.$axios
        .get("/reports/sales?for_page=mobile", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_DAILY_SALES_ORDERS(data.sales_orders);
          this.getWidget();
          this.loading = false;
          this.disabled = false;
        });
    },
    async getWidget() {
      let params = {
                from_date: this.search.from_date,
                to_date: this.search.to_date,

            };
      await this.$axios

        .get("/reports/payments", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.total_transaction = data.total_transaction;
          this.total_amount = data.total_amount;
          this.total_cash = data?.total_payments?.find(item => item.payment_method_id == 1)?.total ?? 0;
          this.total_card = data?.total_payments?.find(item => item.payment_method_id == 2)?.total ?? 0;
        });
    },

    dataTableRowClicked(item) {
      if (item === this.expanded[0]) {
        this.expanded = [];
      } else {
        this.expanded = [item];
      }
    },

    async edit(id) {
      this.loading = true;
      await this.$axios
        .get("/transaction/sales-orders/" + id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_DAILY_SALES_ORDER(data.sales_orders);
          this.loading = false;
          this.$router.push("/sales_order/edit");
        })
        .catch(() => {
          this.loading = true;
        });
    },
    view(id) {
      this.$router.push("/daily-sales-order/view/" + id);
      this.$router.go();
    },

    exportExcell: async function () {
      this.loading = true;
      await this.$axios
        .get(
          "/reports/sales/export/",
          {
            headers: {
              Authorization: this.$store.getters["auth/Token"],
            },
          }
        ).then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const a = document.createElement('a');
          a.href = url;
          a.setAttribute('download', 'daily-sales-order.xlsx');
          document.body.appendChild(a);
          a.click();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    exportPdf: async function () {
      this.loading = true;
      await this.$axios
        .get(
          "/reports/sales/export/pdf",
          {
            headers: {
              Authorization: this.$store.getters["auth/Token"],
              'Content-Type': 'multipart/form-data',
              responseType: 'blob',
            },
          }

        ).then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const a = document.createElement('a');
          a.href = url;
          a.setAttribute('download', 'daily-sales-order.pdf');
          document.body.appendChild(a);
          a.click();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    searching() {
      this.page = 1;
      this.init();
      this.getWidget();
    },
    clear() {
      this.page = 1;
      this.search = {
        status: "",
        search: "",
        from_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
        to_date: new Date().toJSON().slice(0, 10),
      };
      this.init();
    },
    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch.data;
        });
    },
  },

  created() {
    this.init();
    this.getBranch();
    this.getWidget();
  },

  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.init();
    },
  },
  computed: {
    ...mapGetters("daily_sales_order", {
      DailySalesOrders: "DailySalesOrders",
    }),
    ...mapGetters("auth", {
      CAN: "Can",
    }),
    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    currentPage: {
      get() {
        return this.DailySalesOrders.current_page;
      },
      set(val) {
        return this.$store.commit("daily_sales_order/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.DailySalesOrders.last_page;
      },
    },
  },
  components: {
    BaseColumn,
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  background-color: #ebebeb59;
}

::v-deep th {
  color: #000 !important;
}
</style>