<template>
  <div>
    <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-toolbar-title> Product List</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outlined small class="ma-1" color="primary" @click="openProductDialog()">
        Add Product
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </v-app-bar>
    <v-data-table :headers="headers" :items="SalesOrder.details" class="elevation-3" hide-default-footer
      :expanded.sync="expanded" show-expand item-key="name.last">
      <template v-slot:[`item.item_name`]="{ item }">
        <td>{{ item.item_name }}</td>
      </template>
      <template v-slot:[`item.item_description`]="{ item }">
        <td>{{ item.item_description }}</td>
      </template>
      <template v-slot:[`item.item_unit`]="{ item }">
        <td>{{ item.item_unit }}</td>
      </template>
      <template v-slot:[`item.variant`]="{ item }">
        <td> <v-btn outlined small class="ma-1" color="primary" @click="openVariantDialog(item.item_id)">
            Choose Variant
            <v-icon right>mdi-plus</v-icon>
          </v-btn></td>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        <v-text-field v-model="item.price" placeholder="Price Sell"  small outlined dense
          hide-details></v-text-field>
      </template>
      <template v-slot:[`item.qty`]="{ item }">
        <v-text-field v-model="item.qty" placeholder="Qty" type="number" small outlined dense hide-details></v-text-field>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <td>{{ item.price * item.qty | currency("", 0, {
          symbolOnLeft:
            false,
        }) }}</td>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <td>
          <v-icon color="danger" @click="removeProduct(item)">mdi-delete</v-icon>
        </td>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="10" class="mt-1">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" width="30%" style="background-color: #f5f5f5">
                        Variant By Size
                      </th>
                      <th class="text-left" width="30%" style="background-color: #f5f5f5">
                        Variant By Topping
                      </th>
                      <th class="text-left" width="10%" style="background-color: #f5f5f5">
                        Qty
                      </th>
                      <th class="text-left" width="10%" style="background-color: #f5f5f5">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, indexVariant) in item.variants" :key="indexVariant">
                      <td>
                        {{ row?.size?.name ?? "N/A" }} <v-chip small class="ml-2" color="primary">{{ row?.size?.price ?? 0 |
                          currency("", 0, {
                            symbolOnLeft:
                              false,
                          }) }}</v-chip>
                      </td>
                      <td>
                        <ul class="pl-0">
                          <template v-if="row?.topping.length > 0">
                              <li v-for="(t, indexTopping) in row?.topping" :key="indexTopping"> 
                              {{ t?.topping?.name ?? 'N/A' }} <v-chip small class="ml-2" color="primary">{{ t?.topping?.price ?? 0 | 
                                currency("", 0, {
                                  symbolOnLeft:
                                    false,
                                })
                              }}</v-chip>
                              </li>
                            </template>
                            <li v-else>N/A <v-chip small class="ml-2" color="primary">0</v-chip></li>
                          </ul>
                      </td>
                      
                      <td>
                        <v-text-field v-model="row.qty" placeholder="Qty" type="number"
                          @input="calculateQty(row.item_id, indexVariant)" outlined dense class="mt-3"></v-text-field>
                      </td>
                      <td>
                        <v-icon color="danger" @click="removeVariant(row.item_id, indexVariant)">mdi-delete</v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>
    <v-simple-table fixed-header>
      <thead>
        <tr>
          <th class="text-left" width="45%"></th>
          <th class="text-left" width="10%"></th>
          <th class="text-left" width="10%"></th>
          <th class="text-left" width="20%"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
          </td>
          <td></td>
          <td class="text-right">
            Sub Total : <br />
            Discount : <br />
            Vat : <br />
            Pph : <br />
            <h3>Total :</h3>
          </td>
          <td>
            <b>{{ subtotal | currency("", 0, { symbolOnLeft: false }) }}
            </b>
            <hr />
            <b>{{
              discount_amount | currency("", 0, { symbolOnLeft: false }) }}
              |
              <v-icon color="success" small>mdi-arrow-bottom-right-thin
              </v-icon>
              <b class="success--text">{{ afterDiscount | currency("", 0, { symbolOnLeft: false }) }}</b>
            </b>
            <hr />
            <b>{{ vat | currency("", 0, { symbolOnLeft: false }) }}</b>
            <hr />
            <b>{{ pph | currency("", 2, { symbolOnLeft: false }) }}</b>
            <hr />
            <b class="primary--text"> {{ grand_total | currency("", 0, { symbolOnLeft: false }) }}</b>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-dialog v-model="dialogProduct" v-if="dialogProduct" max-width="1200px" persistent><products-modal
        v-on:closeProductDialog="closeProductDialog" v-on:selectProduct="selectProduct" />
    </v-dialog>
    <v-dialog v-model="dialogVariant" v-if="dialogVariant" max-width="1000px" persistent><variant-modal
        v-on:closeVariantDialog="closeVariantDialog" :itemId="itemId" v-on:checkedVariant="checkedVariant" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ProductsModal from "@/components/modal/ProductsModal.vue";
import VariantModal from "@/components/modal/VariantModal.vue";
import _ from "lodash";

export default {
  name: "SalesOrderForm",
  data() {
    return {
      dialogProduct: false,
      dialogVariant: false,
      itemId: null,
      expanded: [],
      headers: [
        { text: "Product Name", value: "item_name", width: 200 },
        { text: "Description", value: "item_description", width: 200 },
        { text: "Unit", value: "item_unit", width: 100 },
        { text: "Variant", value: "variant", width: 100 },
        { text: "Price Sell", value: "price", width: 100 },
        { text: "Qty", value: "qty", width: 100 },
        { text: "Amount", value: "amount", width: 100 },
        { text: "Action", value: "action", sortable: false, width: 100 },
      ],
    }
  },
  methods: {
    openProductDialog() {
      this.dialogProduct = true;
    },
    closeProductDialog() {
      this.dialogProduct = false;
    },

    openVariantDialog(id) {
      this.itemId = id
      this.dialogVariant = true;
    },

    closeVariantDialog() {
      this.dialogVariant = false;
    },

    selectProduct(val) {
      if (this.SalesOrder.details.find((item) => item.item_id === val.id)) {
        this.SalesOrder.details.find((item) => item.item_id === val.id).qty++;
        return;
      }
      this.$store.commit("sales_order/SET_DETAILS", val);
      this.dialogProduct = false;
    },

    checkedVariant(selected, selectedTopping) {
      let variants = {
        item_id: this.itemId,
        size:selected,
        topping: selectedTopping,
        qty: 1
      }
      this.$store.commit("sales_order/SET_DETAILS_VARIANTS", variants);
    },



    removeProduct(index) {
      this.SalesOrder.details.splice(index, 1);
    },

    removeVariant(item_id, indexVariant) {
      this.SalesOrder.details.find((item) => item.item_id === item_id).qty -= this.SalesOrder.details.find((item) => item.item_id === item_id).variants[indexVariant].qty
      this.SalesOrder.details.find((item) => item.item_id === item_id).variants.splice(indexVariant, 1);
      if (this.SalesOrder.details.find((item) => item.item_id === item_id).qty == 0) {
        this.SalesOrder.details.find((item) => item.item_id === item_id).qty = 1
      }
    },
    calculateQty(item_id) {
      this.SalesOrder.details.find((item) => item.item_id === item_id).qty =
        this.SalesOrder.details.find((item) => item.item_id === item_id).variants.reduce((a, b) => parseInt(a) + parseInt(b.qty), 0)
    },
  },
  computed: {
    ...mapState("sales_order", {
      SalesOrder: (state) => state.sales_order,
    }),
    ...mapGetters("pph", {
      PPH: "Pph",
    }),



    subtotal() {
      return _.sumBy(this.SalesOrder.details, function (t) {
        return (t.price * t.qty)
      });
    },

    discount() {
      return this.SalesOrder.discount_percent;
    },

    discount_amount() {
      if (this.SalesOrder.discount_percent > 100 || this.SalesOrder.discount_percent < 0) {
        return "Discount not valid value";
      }
      return parseFloat(this.subtotal) * parseFloat(this.SalesOrder.discount_percent) / 100;
    },

    afterDiscount() {
      let discount = parseFloat(this.subtotal - this.discount_amount);
      if (discount > 0) {
        return discount;
      } else {
        return 0;
      }
    },

    vat() {
      if (this.SalesOrder.use_vat) {
        return parseFloat((this.afterDiscount * 11)) / 100;
      }
      else {
        return 0;
      }
    },

    pph() {
      let pph = this.PPH.percentage ?? 0;
      return (
        (this.afterDiscount * pph ?? 0) / 100
      );
    },

    grand_total() {
      return parseFloat(this.afterDiscount) + parseFloat(this.vat) - parseFloat(this.pph);
    },
  },
  destroyed() {
    this.$store.commit("pph/CLEAR_PPH");
  },

  components: {
    "products-modal": ProductsModal,
    "variant-modal": VariantModal,
  },
};
</script>
