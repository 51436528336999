const state = () => ({
    petty_cashs: {},
    petty_cash: {
        id: null,
        name: null,
        petty_cash_category_id: null,
        account_patty_cash_name: null,
        account_patty_cash_description: null,
        branch_id: null,
        customer_id: null,
        date: new Date().toJSON().slice(0, 10),
        amount: 0,
        remark: null,
    },
    page: 1,
})

const mutations = {
    SET_PETTY_CASHS(state, payload) {
        state.petty_cashs = payload
    },

    SET_PETTY_CASH(state, payload) {
        state.petty_cash.id = payload.id
        state.petty_cash.name = payload.name
        state.petty_cash.petty_cash_category_id = payload.petty_cash_category_id ?? null
        state.petty_cash.account_patty_cash_name = payload?.petty_cash_category?.name
        state.petty_cash.account_patty_cash_description = payload?.petty_cash_category?.description
        state.petty_cash.branch_id = payload.branch_id
        state.petty_cash.customer_id = payload.customer_id
        state.petty_cash.date = payload.date
        state.petty_cash.amount = payload.amount
        state.petty_cash.remark = payload.remark
    },

    SET_ACCOUNT_PATTY_CASH(state, payload) {
        state.petty_cash.petty_cash_category_id = payload.id;
        state.petty_cash.account_patty_cash_name = payload.name;
        state.petty_cash.account_patty_cash_description = payload.description;
    },

    SET_CURRENT_PAGE(state, payload) {
        state.petty_cashs.current_page = payload
    },

    CLEAR_STATE(state) {
        state.petty_cash = {
            id: null,
            name: null,
            petty_cash_category_id: null,
            account_patty_cash_name: null,
            account_patty_cash_description: null,
            branch_id: null,
            customer_id: null,
            date: new Date().toJSON().slice(0, 10),
            amount: 0,
            remark: null,
        }
        state.page = 1
    }

}

const getters = {
    PettyCash: state => state.petty_cash,
    PettyCashs: state => state.petty_cashs,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}
