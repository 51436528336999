<template>
  <div>
    <v-row class="mt-2 mx-1">
      <base-column :lg="3" :md="6" :sm="12">
        <v-text-field v-model="Ingoing.no_po" readonly placeholder="Purchase Order Number" outlined dense
          :append-icon="'mdi-magnify'" @click:append="openPurchaseDialog()"></v-text-field>
      </base-column>
    </v-row>
    <v-divider class="mx-3"></v-divider>
    <v-row class="mt-2 mx-1">
      <base-column :lg="3" :md="6" :sm="12">
        <v-card max-width="100%">
          <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title>
              <v-icon>mdi-account</v-icon>
              {{ Ingoing.supplier_name ?? "Supplier" }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-app-bar>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>Email : {{ Ingoing.supplier_email }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Phone : {{ Ingoing.supplier_phone }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Address : {{ Ingoing.supplier_address }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </base-column>
      <v-row class="mt-2 mx-1">
        <base-column :lg="4" :md="6" :sm="12">
          <v-text-field type="date" label="Ingoing Date" outlined dense v-model="Ingoing.ingoing_date"
            :rules="rule_ingoing_date"></v-text-field>
          <v-textarea label="Remark" outlined dense v-model="Ingoing.remark" rows="2"></v-textarea>
        </base-column>
        <base-column :lg="4" :md="6" :sm="12">
          <v-text-field label="Type" outlined dense v-model="Ingoing.type" disabled></v-text-field>
          <v-select :items="branch" outlined dense label="Branch" item-text="name" item-value="id" v-model="branch_id"
            v-if="(ROLE == 'Owner')"></v-select>
        </base-column>
        <base-column :lg="4" :md="6" :sm="12">
          <v-select label="Type In" item-value="id" item-text="name" outlined dense v-model="Ingoing.type_in_out_id"
            :items="type_in_out" :rules="rule_type_in"></v-select>
        </base-column>

      </v-row>
    </v-row>
    <br>
    <v-divider class="mx-3"></v-divider>
    <v-dialog v-if="dialogSupplier" v-model="dialogSupplier" max-width="1200px" persistent>
      <supplier-modal v-on:closeSupplierDialog="closeSupplierDialog" v-on:selectSupplier="selectSupplier" />
    </v-dialog>
    <v-dialog v-if="dialogPurchaseOrders" v-model="dialogPurchaseOrders" max-width="1200px" persistent>
      <purchase-order-modal v-on:closePurchaseDialog="closePurchaseDialog" v-on:selectPurchase="selectPurchase" />
    </v-dialog>
    <v-dialog v-if="dialogIngoing" v-model="dialogIngoing" max-width="1200px" persistent>
      <ingoing-modal v-on:closeIngoingDialog="closeIngoingDialog" v-on:selectIngoing="selectIngoing" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import BaseColumn from "@/components/base/BaseColumn.vue";
import SupplierModal from '@/components/modal/SupplierModal.vue';
import PurchaseOrderModal from "@/components/modal/PurchaseOrderModal.vue";
import IngoingModal from "@/components/modal/IngoingModal.vue";
export default {
  name: "IngoingForm",

  data() {
    return {
      supplier: [],
      dialogSupplier: false,
      dialogPurchaseOrders: false,
      dialogIngoing: false,
      type_in_out: [],
      branch: [],
    };
  },
  created() {
    this.getTypeInOut();
    this.getBranch();
  },

  methods: {
    ...mapMutations("ingoing", [
      "SET_INGOING",
      "SET_SUPPLIER",
    ]),

    openSupplierDialog() {
      this.dialogSupplier = true;
    },

    closeSupplierDialog() {
      this.dialogSupplier = false;
    },

    selectSupplier(val) {
      this.SET_SUPPLIER({
        id: val.id,
        name: val.name,
        phone: val.phone,
        email: val.email,
        address: val.address,
      });
      this.dialogSupplier = false;
    },

    openPurchaseDialog() {
      this.dialogPurchaseOrders = true;
    },
    closePurchaseDialog() {
      this.dialogPurchaseOrders = false;
    },

    selectPurchase(purchase_order) {
      this.$axios
        .get("/transaction/purchases/" + purchase_order.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit("ingoing/CLEAR_STATE");
          this.$store.commit("ingoing/SET_IN_FORM_PO", data.purchase_orders);

        })
        .catch(() => {
          this.loading = true;
        });
    },

    openIngoingDialog() {
      this.dialogIngoing = true;
    },
    closeIngoingDialog() {
      this.dialogIngoing = false;
    },

    selectIngoing(ingoing) {
      this.$axios
        .get("/transaction/ingoings/" + ingoing.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit("ingoing/CLEAR_STATE");
          this.$store.commit("ingoing/SET_IN_FROM_IN", data.in_goings);

        })
        .catch(() => {
          this.loading = true;
        });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch.data;
        });
    },


    async getTypeInOut() {
      await this.$axios("/master/type-in-outs?falg=IN", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.type_in_out = data.type_in_outs.data;
      });
    },
  },

  computed: {
    ...mapState("ingoing", {
      Ingoing: (state) => state.ingoing,
    }),
    ...mapGetters("auth", {
      ROLE: "Role",
    }),
  },
  components: {
    BaseColumn,
    SupplierModal,
    "purchase-order-modal": PurchaseOrderModal,
    "ingoing-modal": IngoingModal,
  },
};
</script>