<template>
  <main-layout>
    <base-header>
      <template #icon> mdi-file</template>
      <template #name> Quotation </template>
      <template #breadcrumbs>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </template>
      <template #button>
        <v-btn color="primary" class="float-end mb-2" small to="/quotation/create"
          v-if="(!ROLE == 'Owner') || (ROLE == 'Manager') || (ROLE == 'Cashier')">Create
          New
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </template>
    </base-header>
    <v-row dense class="mx-1">
      <v-responsive width="100%" v-if="$vuetify.breakpoint.xsOnly" />
      <quotation-list />
    </v-row>
  </main-layout>
</template>

<script>
import { mapGetters } from "vuex";
import MainLayout from "@/layouts/MainLayout.vue";
import BaseHeader from "@/components/base/BaseHeader.vue";
import QuotationList from "./List.vue";
export default {
  name: "QuotationIndex",

  created() {
    this.breadcrumbs = [
      { text: "Transaction", disabled: false, to: "/dashboard" },
      { text: "Quotation", disabled: true },
      { text: "List", disabled: true },
    ];
  },

  computed: {
    ...mapGetters("auth", {
      CAN: "Can",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),
  },

  components: {
    MainLayout,
    BaseHeader,
    "quotation-list": QuotationList,
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  background-color: #ebebeb59;
}

::v-deep th {
  color: #000 !important;
}
</style>