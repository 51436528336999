const state = () => ({
    request_orders: {},
    request_order: {
        id: null,
        req_no: null,
        type: "MATERIAL",
        branch_id: null,
        request_date: new Date().toJSON().slice(0, 10),
        remark: null,
        pph_id: null,
        use_vat : false,
        sub_total: 0,
        discount_nominal: 0,
        discount_percent: 0,
        discount: 0,
        pph_percentage: 0,
        vat: 0,
        pph: 0,
        grand_total: 0,
        details: [],
    },
    page: 1,

})

const mutations = {
    SET_REQUEST_ORDERS(state, payload) {
        state.request_orders = payload
    },
    SET_REQUEST_ORDER(state, payload) {
        state.request_order.id = payload.id
        state.request_order.req_no = payload.req_no
        state.request_order.type = payload.type
        state.request_order.branch_id = payload.branch_id
        state.request_order.request_date = payload.request_date
        state.request_order.pph_id = payload.pph_id
        state.request_order.use_vat = payload.use_vat
        state.request_order.sub_total = payload.sub_total
        state.request_order.discount_nominal = payload.discount_nominal
        state.request_order.discount_percent = payload.discount_percent
        state.request_order.discount = payload.discount
        state.request_order.vat = payload.vat
        state.request_order.pph = payload.pph
        state.request_order.grand_total = payload.grand_total
        state.request_order.remark = payload.remark
        state.request_order.details = payload.details


    },
    SET_DETAILS(state, payload) {
        state.request_order.details.push({
            item_id: payload.id,
            item_name: payload.name,
            item_description: payload.description,
            item_unit: payload.unit.name,
            price: payload.price,
            qty: 1,
            discount: 0,
            total: payload.price,
        })
    },

   
    CLEAR_STATE(state) {
        state.request_order = {
            id: null,
            req_no: null,
            type: "MATERIAL",
            branch_id: null,
            request_date: new Date().toJSON().slice(0, 10),
            pph_id: null,
            use_vat : false,
            sub_total: 0,
            discount_nominal: 0,
            discount_percent: 0,
            discount: 0,
            pph_percentage: 0,
            vat: 0,
            pph: 0,
            grand_total: 0,
            remark: null,
            details: [],
        }
    }
}

const getters = {
    RequestOrders: state => state.request_orders,
    RequestOrder: state => state.request_order,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}