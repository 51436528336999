<template>
    <v-card>
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title> Account Patty Cash </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="closeAccountPattyCashDialog" color="error" outlined small>
                <v-icon>mdi-close</v-icon></v-btn>
        </v-app-bar>

        <v-data-table :loading="loading" :headers="headers" :items="AccountPattyCashs.data" class="elevation-1"
            hide-default-footer>
            <template v-slot:no-data> No data </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-btn color="primary" outlined small @click.stop="selectAccountPattyCash(item)">Select <v-icon right>
                        mdi-check
                    </v-icon></v-btn>
            </template>

            <template v-slot:[`item.is_active`]="{ item }">
                <v-icon v-if="item.is_active == 1" color="success">
                    mdi-check-circle
                </v-icon>
                <v-icon v-else color="error"> mdi-close-circle </v-icon>
            </template>
            <template v-slot:[`item.printing`]="{ item }">
                <v-icon v-if="item.printing == 1" color="success">
                    mdi-check-circle
                </v-icon>
                <v-icon v-else color="error"> mdi-close-circle </v-icon>
            </template>
            <template v-slot:[`item.perforation`]="{ item }">
                <v-icon v-if="item.perforation == 1" color="success" class="text-center">
                    mdi-check-circle
                </v-icon>
                <v-icon v-else color="error"> mdi-close-circle </v-icon>
            </template>
            <template v-slot:[`item.price`]="{ item }">
                <b>{{ item.price | currency("", 0, { symbolOnLeft: false, }) }}</b>
            </template>

            <template v-slot:top>
                <v-toolbar flat color="white">
                    <base-column :lg="4" :md="4" :sm="6">
                        <v-text-field v-model="search.search" append-icon="mdi-magnify" label="Search" outlined dense
                            hide-details></v-text-field>
                    </base-column>

                    <v-btn small color="darkblue" class="white--text float-end mx-1" @click.stop="searching">Submit <v-icon
                            right>mdi-magnify</v-icon></v-btn>
                    <v-btn small color="secondary" class="white--text float-end mx-1" @click.stop="clear">Clear
                        <v-icon right>mdi-close-circle-multiple </v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>
            <template>
                <v-icon color="warning" class="mr-2"> mdi-pencil-box-outline </v-icon>
            </template>
        </v-data-table>

        <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
    </v-card>
</template>
  
<script>
import { mapGetters, mapMutations } from "vuex";
import BaseColumn from "@/components/base/BaseColumn.vue";
export default {
    name: "AccountPattyCashModal",

    created() {
        this.getAccountPattyCash();
    },

    data() {
        return {
            headers: [
                { text: "Group Patty Cash", value: "group_petty_cash.name" },
                { text: "Name", value: "name" },
                { text: "Description", value: "description" },
                { text: "Use", value: "is_active" },
                { text: "Action", value: "actions", sortable: false, width: 100 },
            ],
            page: 1,
            expanded: [],
            loading: false,
            disabled: false,
            search: {
                search: null,
                status: "Active",
            },
        };
    },
    watch: {
        currentPage(newValue) {
            this.page = newValue;
            this.getAccountPattyCash();
        },
    },
    methods: {
        ...mapMutations("account_patty_cash", ["CLEAR_STATE", "SET_ACCOUNT_PATTY_CASHS", "SET_CURRENT_PAGE"]),
        async getAccountPattyCash() {
            this.loading = true;
            let params = {
                page: this.page,
                search: this.search.search,
                status: this.search.status,
            };
            await this.$axios("/transaction/petty-cash-categories", {
                params: params,
                headers: {
                    Authorization: this.$store.getters["auth/Token"],
                },
            }).then(({ data }) => {
                this.$store.commit("account_patty_cash/SET_ACCOUNT_PATTY_CASHS", data.resource);
                this.loading = false;
                this.disabled = false;
            });
        },
        searching() {
            this.page = 1;
            this.getAccountPattyCash();
        },
        clear() {
            this.page = 1;
            this.search = {
                status: "",
                search: "",
            };
            this.getAccountPattyCash();
        },
        closeAccountPattyCashDialog() {
            this.$emit("closeAccountPattyCashDialog");
        },

        selectAccountPattyCash(item) {
            this.$emit("selectAccountPattyCash", item);
            this.closeAccountPattyCashDialog();
        },

        removeMaterial(item) {
            this.$emit("removeMaterial", item);
        },
    },

    destroyed() {
        this.CLEAR_STATE();
    },

    computed: {
        ...mapGetters("account_patty_cash", {
            AccountPattyCashs: "AccountPattyCashs",
        }),
        currentPage: {
            get() {
                return this.AccountPattyCashs.current_page;
            },
            set(val) {
                return this.$store.commit("account_patty_cash/SET_CURRENT_PAGE", val);
            },
        },
        lastPage: {
            get() {
                return this.AccountPattyCashs.last_page;
            },
        },

    },

    components: {
        BaseColumn,
    },
};
</script>