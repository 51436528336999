const state = () => ({
    outgoings: {},
    outgoing: {
        id: null,
        no_outgoing: null,
        sales_order_id: null,
        no_so: null,
        date: null,
        payment_method_id: null,
        type_in_out_id: null,
        type: null,
        remark: null,
        branch_id: null,
        branch_name: null,
        branch_phone: null,
        branch_email: null,
        branch_address: null,
        customer_id: null,
        customer_name: null,
        customer_address: null,
        customer_phone: null,
        customer_email: null,
        bank_account_id: null,
        name_account: null,
        number_account: null,
        bank_name: null,
        details: [],
    },
    page: 1,
})

const mutations = {
    SET_OUTGOINGS(state, payload) {
        state.outgoings = payload
    },

    SET_OUTGOING(state, payload) {
        state.outgoing.id = payload.id
        state.outgoing.no_outgoing = payload.no_outgoing
        state.outgoing.no_so = payload.sales_order.no_so
        state.outgoing.sales_order_id = payload.sales_order_id
        state.outgoing.type_in_out_id = payload.type_in_out_id
        state.outgoing.type = payload.type
        state.outgoing.date = payload.date
        state.outgoing.payment_method_id = payload.payment_method_id
        state.outgoing.branch_id = payload.branch_id
        state.outgoing.branch_name = payload.branch.name
        state.outgoing.branch_phone = payload.branch.phone
        state.outgoing.branch_email = payload.branch.email
        state.outgoing.branch_address = payload.branch.address
        state.outgoing.customer_id = payload.customer_id
        state.outgoing.customer_name = payload.customer.name
        state.outgoing.customer_address = payload.customer.address
        state.outgoing.customer_phone = payload.customer.phone
        state.outgoing.customer_email = payload.customer.email
        state.outgoing.bank_account_id = payload.bank_account_id
        state.outgoing.name_account = payload.sales_order.bank_account.name_account
        state.outgoing.number_account = payload.sales_order.bank_account.number_account
        state.outgoing.bank_name = payload.sales_order.bank_account.bank_name
        state.outgoing.details = payload.details
    },

    SET_DETAILS(state, payload) {
        state.outgoing.details.push({
            item_id: payload.id,
            item_name: payload.name,
            item_description: payload.description,
            item_unit: payload.unit.name,
            qty: 0,
            qty_in: 0,
            qty_out: 0,
            qty_balance: 0,
            price: payload.total,
        })
    },

    SET_OUT_FROM_OUT(state, payload) {
        state.outgoing.id = payload.id
        state.outgoing.no_outgoing = payload.no_outgoing
        state.outgoing.no_so = payload.sales_order.no_so
        state.outgoing.customer_id = payload.customer_id
        state.outgoing.customer_name = payload.customer.name
        state.outgoing.customer_address = payload.customer.address
        state.outgoing.customer_phone = payload.customer.phone
        state.outgoing.customer_email = payload.customer.email
        // state.outgoing.bank_account_id = payload.bank_account_id
        // state.outgoing.name_account = payload.bank_account.name_account
        // state.outgoing.number_account = payload.bank_account.number_account
        // state.outgoing.bank_name = payload.bank_account.bank_name
        for (let i = 0; i < payload.details.length; i++) {
            state.outgoing.details.push({
                item_id: payload.details[i].item_id,
                item_name: payload.details[i].item.name,
                item_description: payload.details[i].item.description,
                item_unit: payload.details[i].item.unit.name,
                price: payload.details[i].price,
                qty: payload.details[i].qty ?? 0,
                qty_in: payload.details[i].qty_in ?? 0,
                qty_out: payload.details[i].qty_out ?? 0,
                qty_balance: 0,
            })
        }
    },

    SET_SO_FROM_OUT(state, payload) {
        state.outgoing.sales_order_id = payload.id
        state.outgoing.no_so = payload.no_so
        state.outgoing.payment_method_id = payload.payment_method_id
        state.outgoing.type_in_out_id = 2
        state.outgoing.customer_id = payload.customer_id
        state.outgoing.customer_name = payload.customer.name
        state.outgoing.customer_address = payload.customer.address
        state.outgoing.customer_phone = payload.customer.phone
        state.outgoing.customer_email = payload.customer.email
        state.outgoing.bank_account_id = payload.bank_account_id
        state.outgoing.name_account = payload.bank_account.name_account
        state.outgoing.number_account = payload.bank_account.number_account
        state.outgoing.bank_name = payload.bank_account.bank_name
        for (let i = 0; i < payload.details.length; i++) {
            state.outgoing.details.push({
                item_id: payload.details[i].item_id,
                item_name: payload.details[i].item.name,
                item_description: payload.details[i].item.description,
                item_unit: payload.details[i].item.unit.name,
                price: payload.details[i].price,
                qty: payload.details[i].qty ?? 0,
                qty_in: payload.details[i].qty_in ?? 0,
                qty_out: payload.details[i].qty - payload.details[i].qty_out ?? 0,
                qty_balance: 0,
            })
        }
    },

    SET_CURRENT_PAGE(state, payload) {
        state.outgoings.current_page = payload
    },


    CLEAR_STATE(state) {
        state.outgoing.id = null
        state.outgoing.no_outgoing = null
        state.outgoing.sales_order_id = null
        state.outgoing.no_so = null
        state.outgoing.type_in_out_id = null
        state.outgoing.type = "SALES ORDER"
        state.outgoing.payment_method_id = null
        state.outgoing.remark = null
        state.outgoing.branch_id = null
        state.outgoing.branch_name = null
        state.outgoing.branch_phone = null
        state.outgoing.branch_email = null
        state.outgoing.branch_address = null
        state.outgoing.customer_id = null
        state.outgoing.customer_name = null
        state.outgoing.customer_address = null
        state.outgoing.customer_phone = null
        state.outgoing.customer_email = null
        state.outgoing.bank_account_id = null
        state.outgoing.name_account = null
        state.outgoing.number_account = null
        state.outgoing.bank_name = null
        state.outgoing.details = []
    },
}

const getters = {
    Outgoings: state => state.outgoings,
    Outgoing: state => state.outgoing,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}