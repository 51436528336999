<template>
  <div>
    <base-title title="Create Supplier" subtitle="Please fill in the Supplier information in the form below" />
    <!-- Form -->
    <v-form ref="form_data" v-model="form_valid" lazy-validation>
      <v-row class="mx-1 mt-2">
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Name" v-model="form_data.name" :rules="rule_name" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12" v-if="(ROLE == 'Owner')">
          <v-select label="Branch" :items="branch" v-model="form_data.branch_id" dense item-text="name" item-value="id"
            outlined></v-select>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Owner" v-model="form_data.owner" :rules="rule_owner" outlined dense></v-text-field>
        </base-column>
      </v-row>

      <v-row class="mx-1 mt-2">
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Email" v-model="form_data.email" outlined dense></v-text-field>
          <v-switch dense v-model="form_data.is_active" label="USE"> </v-switch>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Phone Number" v-model="form_data.phone" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-textarea label="Address" rows="3" v-model="form_data.address" outlined dense></v-textarea>
        </base-column>
      </v-row>

      <v-row class="mx-1">
        <back-button />
        <v-btn color="primary" small class="ma-1" @click.stop="store" :loading="btnLoading"
          :disabled="!form_valid || btnLoading">Save <v-icon right>mdi-content-save</v-icon></v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import BackButton from "@/components/base/BackButton.vue";
import BaseTitle from "@/components/base/BaseTitle.vue";
import BaseColumn from "@/components/base/BaseColumn.vue";
import { mapGetters } from "vuex";
export default {
  components: { BaseTitle, BaseColumn, BackButton },
  name: "SupplierCreate",
  data() {
    return {
      btnLoading: false,
      form_valid: true,
      branch: [],
      form_data: {
        name: "",
        branch_id: "",
        owner: "",
        phone: "",
        email: "",
        address: "",
        is_active: true,
      },
    };
  },
  created() {
    this.getBranch();
  },
  methods: {
    store: async function () {
      if (this.$refs.form_data.validate()) {
        this.btnLoading = true;
        await this.$axios
          .post(
            "/master/supplier",
            {
              name: this.form_data.name,
              branch_id: this.form_data.branch_id,
              owner: this.form_data.owner,
              email: this.form_data.email,
              phone: this.form_data.phone,
              address: this.form_data.address,
              is_active: this.form_data.is_active,
            },
            {
              headers: {
                Authorization: this.TOKEN,
              },
            }
          )
          .then(() => {
            this.btnLoading = false;
            this.$router.push({ name: "supplier.index" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },

    async getBranch() {
      await this.$axios("/master/branches?active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.branch = data.branch.data;
      });
    },

  },

  computed: {
    ...mapGetters("auth", {
      TOKEN: "Token",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),
  },
};
</script>