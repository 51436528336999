<template>
  <div>
    <v-divider class="mx-4"></v-divider>
    <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-toolbar-title> Add Material</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outlined small class="ma-1" color="primary" @click="openMaterialDialog()">
        Add Material
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </v-app-bar>
    <v-simple-table class="mx-2 mb-3" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" width="1%">No</th>
            <th class="text-left" width="10%">Material Name</th>
            <th class="text-left" width="15%">Description</th>
            <th class="text-left" width="8%">Unit</th>
            <th class="text-left" width="10%">Price</th>
            <th class="text-left" width="7%">Qty</th>
            <th class="text-left" width="8%">Amount</th>
            <th class="text-left" width="1%">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr height="80" v-for="(row, index) in  RequestOrder.details" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              {{ row.item_name ?? row.item.name }}
            </td>
            <td>
              {{ row?.item_description ?? row?.item?.description ?? "N/A" }}
            </td>
            <td>{{ row.item_unit ?? row?.item?.unit?.name }}</td>
            <td>
              <v-text-field v-model="row.price" placeholder="Price Buy" type="number" outlined dense hide-details
                :rules="rule_price_buy"></v-text-field>
            </td>
            <td>
              <v-text-field v-model="row.qty" placeholder="Qty" type="number" outlined dense hide-details
                :rules="rule_qty"> </v-text-field>
            </td>
            <td>
              <b>
                {{
                  row.total = (row.price * row.qty) | currency("", 0, { symbolOnLeft: false })
                }}
              </b>
            </td>
            <td>
              <v-icon color="error" @click="removeProduct(index)">mdi-delete
              </v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table class="mx-2 mb-3" fixed-header>
      <thead>
        <tr>
          <th class="text-left" width="45%"></th>
          <th class="text-left" width="10%"></th>
          <th class="text-left" width="10%"></th>
          <th class="text-left" width="20%"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
          </td>
          <td></td>
          <td class="text-right">
            Sub Total : <br />
            Discount : <br />
            Vat : <br />
            Pph : <br />
            <h3>Total :</h3>
          </td>
          <td>
            <b>{{ subtotal | currency("", 0, { symbolOnLeft: false }) }}
            </b>
            <hr />
            <b>{{
              discount | currency("", 0, { symbolOnLeft: false }) }}
              |
              <v-icon color="success" small>mdi-arrow-bottom-right-thin
              </v-icon>
              <b class="success--text">{{ afterDiscount | currency("", 0, { symbolOnLeft: false }) }}</b>
            </b>
            <hr />
            <b>{{ vat | currency("", 0, { symbolOnLeft: false }) }}</b>
            <hr />
            <b>{{ pph | currency("", 0, { symbolOnLeft: false }) }}</b>
            <hr />
            <b class="primary--text"> {{ grand_total | currency("", 0, { symbolOnLeft: false }) }}</b>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-model="dialogMaterial" v-if="dialogMaterial" max-width="1200px" persistent><materials-modal
        v-on:closeMaterialDialog="closeMaterialDialog" v-on:selectMaterial="selectMaterial" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import MaterialsModal from "@/components/modal/MaterialModal.vue";
import _ from "lodash";

export default {
  name: "RequestOrderFormItem",
  data() {
    return {
      dialogMaterial: false,
    };
  },
  methods: {
    openMaterialDialog() {
      this.dialogMaterial = true;
    },
    closeMaterialDialog() {
      this.dialogMaterial = false;
    },
    selectMaterial(val) {
      for (let i = 0; i < this.RequestOrder.details.length; i++) {
        if (this.RequestOrder.details[i].item_id === val.id) {
          this.RequestOrder.details[i].qty++;
          break;
        }
      }
      this.$store.commit("request_order/SET_DETAILS", val);
      this.dialogMaterial = false;
    },

    removeMaterial(index) {
      this.RequestOrder.details.splice(index, 1);
    },
  },

  computed: {
    ...mapState("request_order", {
      RequestOrder: (state) => state.request_order,
    }),

    ...mapGetters("pph", {
      PPH: "Pph",
    }),


    subtotal() {
      return _.sumBy(this.RequestOrder.details, function (t) {
        return (t.price * t.qty)
      });
    },

    discount() {
      return this.RequestOrder.discount_percent;
    },

    discount_amount() {
      if (this.RequestOrder.discount_percent > 100 || this.RequestOrder.discount_percent < 0) {
        return "Discount not valid value";
      }
      return parseFloat(this.subtotal) * parseFloat(this.RequestOrder.discount_percent) / 100;
    },

    afterDiscount() {
      let discount = parseFloat(this.subtotal - this.discount_amount);
      if (discount > 0) {
        return discount;
      } else {
        return 0;
      }
    },

    vat() {
      if (this.RequestOrder.use_vat) {
        return parseFloat((this.afterDiscount * 11)) / 100;
      }
      else {
        return 0;
      }
    },

    pph() {
      let pph = this.PPH.percentage ?? 0;
      return (
        (this.afterDiscount * pph ?? 0) / 100
      );
    },

    grand_total() {
      return parseFloat(this.afterDiscount) + parseFloat(this.vat) - parseFloat(this.pph);
    },
  },

  destroyed() {
    this.$store.commit("pph/CLEAR_PPH");
  },

  components: {
    "materials-modal": MaterialsModal,
  },
};
</script>
