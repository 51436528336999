const state = () => ({
    stock_opname_product: {},
    stock_opname_material: {},
    page_product: 1,
    page_material: 1,

})

const mutations = {
    SET_STOCK_OPNAME_PRODUCT(state, payload) {
        state.stock_opname_product = payload
    },

    SET_STOCK_OPNAME_MATERIAL(state, payload) {
        state.stock_opname_material = payload
    },

    SET_CURRENT_PAGE_PRODUCT(state, payload) {
        state.stock_opname_product.current_page = payload
    },

    SET_CURRENT_PAGE_MATERIAL(state, payload) {
        state.stock_opname_material.current_page = payload
    }
}

const getters = {
    StockOpnameProduct: state => state.stock_opname_product,
    StockOpnameMaterial: state => state.stock_opname_material,
    PageProduct: state => state.page_product,
    PageMaterial: state => state.page_material,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}