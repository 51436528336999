const state = () => ({
    sales_invoices: {},
    sales_invoice: {
        id: null,
        no_invoice: null,
        no_so: null,
        customer_id: null,
        customer_name: null,
        customer_address: null,
        customer_phone: null,
        customer_email: null,
        bank_account_id: null,
        name_account: null,
        number_account: null,
        bank_name: null,
        date: null,
        delivery_date: null,
        payment_method_id: null,
        discount_nominal: 0,
        discount_percent: 0,
        discount_amount: 0,
        pph_id: null,
        use_vat: false,
        details: [],

    },
    page: 1,

})

const mutations = {
    SET_SALES_INVOICES(state, payload) {
        state.sales_invoices = payload
    },

    SET_SALES_INVOICE(state, payload) {
        state.sales_invoice.id = payload.id
        state.sales_invoice.no_invoice = payload.no_invoice
        state.sales_invoice.no_so = payload.no_so
        state.sales_invoice.customer_id = payload.customer_id
        state.sales_invoice.customer_name = payload.customer.name
        state.sales_invoice.customer_address = payload.customer.address
        state.sales_invoice.customer_phone = payload.customer.phone
        state.sales_invoice.customer_email = payload.customer.email
        state.sales_invoice.bank_account_id = payload.bank_account_id
        state.sales_invoice.name_account = payload.bank_account.name_account
        state.sales_invoice.number_account = payload.bank_account.number_account
        state.sales_invoice.bank_name = payload.bank_account.bank_name
        state.sales_invoice.date = payload.date
        state.sales_invoice.delivery_date = payload.delivery_date
        state.sales_invoice.payment_method_id = payload.payment_method_id
        state.sales_invoice.discount_nominal = payload.discount_nominal
        state.sales_invoice.discount_percent = payload.discount_percent
        state.sales_invoice.discount_amount = payload.discount_amount
        state.sales_invoice.pph_id = payload.pph_id
        state.sales_invoice.use_vat = payload.use_vat
        state.sales_invoice.details = payload.details
    },

    SET_SO(state, payload) {
        state.sales_invoice.no_so = payload.no_so
        state.sales_invoice.customer_id = payload.customer_id
        state.sales_invoice.customer_name = payload.customer.name
        state.sales_invoice.customer_address = payload.customer.address
        state.sales_invoice.customer_phone = payload.customer.phone
        state.sales_invoice.customer_email = payload.customer.email
        state.sales_invoice.bank_account_id = payload.bank_account_id
        state.sales_invoice.name_account = payload?.bank_account?.name_account
        state.sales_invoice.number_account = payload?.bank_account?.number_account
        state.sales_invoice.bank_name = payload?.bank_account?.bank_name
        state.sales_invoice.date = payload.date
        state.sales_invoice.delivery_date = payload.delivery_date
        state.sales_invoice.payment_method_id = payload.payments[0].payment_method_id
        state.sales_invoice.use_vat = payload.use_vat
        state.sales_invoice.pph_id = payload.pph_id
        for (let i = 0; i < payload?.details.length; i++) {
            state.sales_invoice?.details.push({
                item_id: payload?.details[i]?.item_id,
                item_name: payload?.details[i]?.item?.name,
                item_description: payload?.details[i]?.item?.description,
                item_unit: payload?.details[i]?.item?.unit?.name,
                qty: payload?.details[i]?.qty,
                price: payload?.details[i]?.price,
                discount: 0,
            })
        }
    },

    SET_DETAILS(state, payload) {
        state.sales_invoice.details.push({
            item_id: payload.id,
            item_name: payload.name,
            item_description: payload.description,
            item_unit: payload.unit.name,
            price: payload.total,
            qty: 1,
            discount: 0,
            total: payload.total,
        })
    },


    SET_CURRENT_PAGE(state, payload) {
        state.sales_invoices.current_page = payload
    },

    CLEAR_STATE(state) {
        state.sales_invoice.id = null
        state.sales_invoice.no_invoice = null
        state.sales_invoice.no_so = null
        state.sales_invoice.customer_id = null
        state.sales_invoice.customer_name = null
        state.sales_invoice.customer_address = null
        state.sales_invoice.customer_phone = null
        state.sales_invoice.customer_email = null
        state.sales_invoice.bank_account_id = null
        state.sales_invoice.name_account = null
        state.sales_invoice.number_account = null
        state.sales_invoice.bank_name = null
        state.sales_invoice.date = null
        state.sales_invoice.delivery_date = null
        state.sales_invoice.payment_method_id = null
        state.sales_invoice.discount_nominal = 0
        state.sales_invoice.discount_percent = 0
        state.sales_invoice.discount_amount = 0
        state.sales_invoice.pph_id = null
        state.sales_invoice.use_vat = false
        state.sales_invoice.details = []
    },
}

const getters = {
    SalesInvoices: state => state.sales_invoices,
    SalesInvoice: state => state.sales_invoice,
    Page: state => state.page,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}
