<template>
  <div>
    <base-title title="Edit Customer" subtitle="Please fill in the customer information in the form below" />
    <v-form ref="form_data" v-model="form_valid" lazy-validation>
      <v-row class="mx-1 mt-2">
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Name" v-model="form_data.name" :rules="rule_name" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Email" v-model="form_data.email" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12" v-if="(ROLE == 'Owner')">
          <v-select label="Branch" :items="branch" v-model="form_data.branch_id" dense item-text="name" item-value="id"
            outlined></v-select>
        </base-column>
      </v-row>
      <v-row class="mx-1 mt-2">
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Phone Number" v-model="form_data.phone" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-textarea label="Address" rows="3" outlined dense v-model="form_data.address"></v-textarea>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-switch dense v-model="form_data.is_active" label="USE"> </v-switch>
        </base-column>
      </v-row>

      <v-row>
        <v-container>
          <back-button />
          <v-btn color="primary" small class="ma-1" @click.stop="update" :loading="btnLoading"
            :disabled="!form_valid || btnLoading">Update <v-icon right>mdi-content-save</v-icon></v-btn>
        </v-container>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BackButton from "@/components/base/BackButton.vue";
import BaseTitle from "@/components/base/BaseTitle.vue";
import BaseColumn from "@/components/base/BaseColumn.vue";
export default {
  components: { BaseTitle, BaseColumn, BackButton },
  name: "CustomersEdit",
  data() {
    return {
      btnLoading: false,
      form_valid: true,
      branch: [],
      form_data: {
        name: "",
        branch_id: "",
        phone: "",
        email: "",
        address: "",
        is_active: true,
      },
    };
  },

  created() {
    this.findOne();
    this.getBranch();
  },

  methods: {
    update: async function () {
      if (this.$refs.form_data.validate()) {
        this.btnLoading = true;
        await this.$axios
          .post(
            "/master/customers/" + this.$route.params.id,
            {
              _method: "PUT",
              name: this.form_data.name,
              branch_id: this.form_data.branch_id,
              email: this.form_data.email,
              phone: this.form_data.phone,
              address: this.form_data.address,
              is_active: this.form_data.is_active,
            },
            {
              headers: {
                Authorization: this.$store.getters["auth/Token"],
              },
            }
          )
          .then(() => {
            this.btnLoading = false;
            this.$router.push({ name: "customer.index" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },

    async getBranch() {
      await this.$axios("/master/branches?active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.branch = data.branch.data;
      });
    },


    findOne() {
      this.$axios
        .get("/master/customers/" + this.$route.params.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.form_data.name = data.customers.name;
          this.form_data.branch_id = data.customers.branch_id;
          this.form_data.email = data.customers.email;
          this.form_data.phone = data.customers.phone;
          this.form_data.address = data.customers.address;
          this.form_data.is_active = data.customers.is_active;
        })
        .catch(() => {
          this.$router.push({ name: "customer.index" });
        });
    },
  },

  computed: {
    ...mapGetters("auth", {
      ROLE: "Role",
    }),
  },
};
</script>