<template>
  <div>
    <base-title title="Create Discount" subtitle="Please fill in the discount information in the form below" />
    <!-- Form -->
    <v-form ref="Discount" v-model="form_valid" lazy-validation>
      <v-row class="mx-1 mt-2">
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field label="Discount Name" v-model="Discount.name" :rules="rule_name" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field v-model="this.countItem" readonly placeholder="Menu" outlined dense :append-icon="'mdi-magnify'"
            hide-details @click:append="openProductDialog()"></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field type="number" label="Percentage" v-model="Discount.percentage" :rules="rule_disc" outlined
            dense></v-text-field>
        </base-column>
      </v-row>
      <v-row class="mx-1">
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field type="date" label="From Date" v-model="Discount.from_date" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="4" :md="4" :sm="12">
          <v-text-field type="date" label="End Date" v-model="Discount.end_date" outlined dense></v-text-field>
        </base-column>
      </v-row>
      <v-row class="mx-1">
        <base-column :lg="12" :md="12" :sm="12">
          <v-data-table :headers="headers" :items="Discount.details" class="elevation-3" hide-default-footer>
            <template v-slot:[`item.product_name`]="{ item }">
              <td>{{ item.product_name }}</td>
            </template>
            <template v-slot:[`item.product_description`]="{ item }">
              <td>{{ item.product_description }}</td>
            </template>
            <template v-slot:[`item.product_price`]="{ item }">
              <td>{{ item.product_price | currency("", 0, { symbolOnLeft: false, }) }}</td>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <td>
                <v-icon color="danger" @click="removeProduct(item)">mdi-delete</v-icon>
              </td>
            </template>
          </v-data-table>
          <v-dialog v-model="dialogProduct" v-if="dialogProduct" max-width="1200px" persistent><products-modal
              v-on:closeProductDialog="closeProductDialog" v-on:selectProduct="selectProduct" />
          </v-dialog>
        </base-column>
      </v-row>
      <v-row class="mx-2">
        <base-column :lg="12" :md="12" :sm="12" :xs="12">
          <v-btn color="primary" small class="ma-1 mt-2 float-end" @click.stop="store" :loading="btnLoading"
            :disabled="!form_valid || btnLoading">Save <v-icon right>mdi-content-save</v-icon></v-btn>
          <v-btn small color="secondary" class="ma-2 float-end" @click="clearForm()">
            Clear
            <v-icon>mdi-close-circle-multiple </v-icon>
          </v-btn>
          <v-btn small class="ma-2 float-end" color="darkblue" outlined @click="$router.go(-1)" title="Back">
            <v-icon left>mdi-arrow-left </v-icon>
            Back</v-btn>

        </base-column>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import BaseTitle from "@/components/base/BaseTitle.vue";
import BaseColumn from "@/components/base/BaseColumn.vue";
import ProductsModal from "@/components/modal/ProductsModal.vue";
export default {
  components: { BaseTitle, BaseColumn, "products-modal": ProductsModal, },
  name: "CustomersCreate",
  data() {
    return {
      btnLoading: false,
      form_valid: true,
      branch: [],
      dialogProduct: false,
      countItem: 0,

      headers: [
        { text: "Product Name", value: "product_name", width: 200, class: "secondary white--text" },
        { text: "Description", value: "product_description", width: 200, class: "secondary white--text" },
        { text: "Unit", value: "product_unit", width: 100, class: "secondary white--text" },
        { text: "Price", value: "product_price", width: 100, class: "secondary white--text" },
        { text: "Action", value: "action", sortable: false, width: 100, class: "secondary white--text" },
      ],
    };
  },
  created() {
    this.getBranch();
    this.countItem = this.Discount.details.length + " Items"
  },

  methods: {

    ...mapMutations("discount", ["CLEAR_STATE"]),

    store: async function () {
      if (this.$refs.Discount.validate()) {
        if (this.Discount.details.length == 0) {
          alert("Please add product");
          return;
        }

        this.btnLoading = true;
        await this.$axios
          .post("/transaction/sales-orders", this.SalesOrder,
            {
              headers: { Authorization: this.$store.getters["auth/Token"] },
            })
          .then(() => {
            this.CLEAR_STATE();
            this.btnLoading = false;
            this.$router.push({ name: "sales-order.index" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },

    openProductDialog() {
      this.dialogProduct = true;
    },
    closeProductDialog() {
      this.dialogProduct = false;
    },

    selectProduct(val) {
      if (this.Discount.details.find((item) => item.product_id === val.id)) {
        alert("Product already exist");
        return;
      }
      this.countItem = this.Discount.details.length + 1 + " Items"
      this.$store.commit("discount/SET_DETAILS", val);
      this.dialogProduct = false;
    },

    removeProduct(index) {
      this.Discount.details.splice(index, 1);
      this.countItem = this.Discount.details.length + " Items"
    },

    clearForm() {
      this.countItem = 0 + " Items"
      this.CLEAR_STATE();
    },

    async getBranch() {
      await this.$axios("/master/branches?active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.branch = data.branch.data;
      });
    },
  },
  computed: {
    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    ...mapState("discount", {
      Discount: (state) => state.discount,
    }),
  },
};
</script>