<template>
  <v-col xs="12" sm="12" md="12" lg="12">
    <v-responsive width="100%" v-if="$vuetify.breakpoint.xsOnly" />
    <v-card elevation="5" rounded>
      <v-row class="mx-1 mb-1">
        <v-col xs="12" sm="12" md="4" lg="2">
          <v-text-field type="date" label="From date" outlined dense v-model="search.from_date">
          </v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="4" lg="2">
          <v-text-field type="date" label="End date" outlined dense v-model="search.end_date">
          </v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="4" lg="2">
          <v-select :items="status" outlined dense label="Status" v-model="search.status"></v-select>
        </v-col>
        <v-col xs="12" sm="12" md="4" lg="3">
          <v-text-field label="Search" outlined dense v-model="search.search">
          </v-text-field>
        </v-col>
        <v-col xs="12" sm="12" md="4" lg="2">
          <v-btn small color="darkblue" class="white--text mx-1" @click.stop="init">Submit <v-icon
              right>mdi-magnify</v-icon></v-btn>
          <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
            <v-icon right>mdi-close-circle-multiple </v-icon>
          </v-btn>
        </v-col>
      </v-row>
       <v-row class="mx-1 mb-1">
        <v-col xs="12" sm="12" md="12" lg="12">
          <v-data-table :loading="loading" :headers="headers" :items="RequestOrders.data" class="elevation-1"
            hide-default-footer>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip color="success" small v-if="item.status == 'ONPROGRESS'">
                ON PROGRESS
              </v-chip>
              <v-chip color="info" small v-else-if="item.status == 'DONE'">
                DONE
              </v-chip>
              <v-chip color="danger" small v-else-if="item.status == 'CANCEL'">
                CANCEL
              </v-chip>
            </template>
            <template v-slot:[`item.grand_total`]="{ item }">
              <b>{{ item.grand_total | currency("", 0, { symbolOnLeft: false, }) }}</b>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip color="dark" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-2" color="primary" v-bind="attrs" icon small @click.stop="view(item.id)" v-on="on">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View Purchase Order</span>
              </v-tooltip>
              <v-tooltip color="dark" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-2" color="warning" v-bind="attrs" icon small @click.stop="edit(item.id)" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Purchase Order</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "RequestOrderList",
  data() {
    return {
      headers: [
        { text: "Request Code", value: "", class: "primary white--text" },
        { text: "Branch", value: "branch.name", class: "primary white--text" },
        { text: "Order Date", value: "", class: "primary white--text" },
        { text: "Type", value: "", class: "primary white--text" },
        { text: "Grand Total", value: "grand_total", class: "primary white--text" },
        { text: "Status", value: "status", class: "primary white--text" },
        { text: "Actions", value: "actions", sortable: false, class: "primary white--text" },
      ],
      disabled: false,
      loading: false,
      loading_download: false,
      branch: [],
      status: [
        { text: "ALL", value: "" },
        { text: "ON PROGRESS", value: "ONPROGRESS" },
        { text: "DONE", value: "DONE" },
        { text: "CANCEL", value: "CANCEL" },
      ],
      search: {
        from_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
        end_date: new Date().toJSON().slice(0, 10),
        search: null,
        status: "",
      },
    };
  },

  methods: {
    ...mapMutations("request_order", ["CLEAR_STATE", "SET_REQUEST_ORDERS", "SET_REQUEST_ORDER", "SET_CURRENT_PAGE"]),
    async init() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        status: this.search.status,
        search: this.search.search,
        from_date: this.search.from_date,
        end_date: this.search.end_date,
      };

      await this.$axios
        .get("/transaction/purchases", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_REQUEST_ORDERS(data.purchase_orders);
          this.loading = false;
          this.disabled = false;
        });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch.data;
        });
    },

    async edit(id) {
      this.loading = true;
      await this.$axios
        .get("/transaction/purchases/" + id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_REQUEST_ORDER(data.request_orders);
          this.loading = false;
          this.$router.push("/request-order/edit");
        })
        .catch(() => {
          this.loading = true;
        });
    },

    view(id) {
      this.$router.push("/request-order/view/" + id);
    },

    searching() {
      this.page = 1;
      this.init();
    },
    clear() {
      this.page = 1;
      this.search = {
        status: "",
        search: "",
        branch_id: "",
        from_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
        end_date: new Date().toJSON().slice(0, 10),
      };
      this.init();
    },
  },

  created() {
    this.init();
    this.getBranch();
  },

  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.init();
    },
  },
  computed: {
    ...mapGetters("request_order", {
      RequestOrders: "RequestOrders",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    currentPage: {
      get() {
        return this.RequestOrders.current_page;
      },
      set(val) {
        return this.$store.commit("request_order/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.RequestOrders.last_page;
      },
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  background-color: #ebebeb59;
}

::v-deep th {
  color: #000 !important;
}
</style>