<template>
  <div>
    <v-divider class="mx-4"></v-divider>
    <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-toolbar-title v-if="(this.PurchaseOrder.type == 'PRODUCT')"> Add Product</v-toolbar-title>
      <v-toolbar-title v-else-if="this.PurchaseOrder.type == 'MATERIAL'"> Add Material</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outlined small class="ma-1" color="primary" @click="openProductDialog()"
        v-if="(this.PurchaseOrder.type == 'PRODUCT')" :disabled="this.PurchaseOrder.no_so">
        Add Product
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
      <v-btn outlined small class="ma-1" color="primary" @click="openMaterialDialog()"
        v-else-if="this.PurchaseOrder.type == 'MATERIAL'">
        Add Material
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </v-app-bar>
    <v-simple-table class="mx-2 mb-3" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" width="1%">No</th>
            <th class="text-left" width="10%">Product Name</th>
            <th class="text-left" width="15%">Description</th>
            <th class="text-left" width="8%">Unit</th>
            <th class="text-left" width="10%">Price Buy</th>
            <th class="text-left" width="7%">Qty</th>
            <th class="text-left" width="8%">Amount</th>
            <th class="text-left" width="1%">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr height="80" v-for="(row, index) in  PurchaseOrder.details" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              {{ row.item_name ?? row.item.name }}
            </td>
            <td>
              {{ row?.item_description ?? row?.item?.description }}
            </td>
            <td>{{ row.item_unit ?? row?.item?.unit?.name }}</td>
            <td>
              <v-text-field v-model="row.price" placeholder="Price Buy" type="number" outlined dense hide-details
                :rules="rule_price_buy"></v-text-field>
            </td>
            <td>
              <v-text-field v-model="row.qty" placeholder="Qty" type="number" outlined dense hide-details
                :rules="rule_qty"> </v-text-field>
            </td>
            <td>
              <b>
                {{
                  row.total = (row.price * row.qty) | currency("", 0, { symbolOnLeft: false })
                }}
              </b>
            </td>
            <td>
              <v-icon color="error" @click="removeProduct(index)">mdi-delete
              </v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table class="mx-2 mb-3" fixed-header>
      <thead>
        <tr>
          <th class="text-left" width="45%"></th>
          <th class="text-left" width="10%"></th>
          <th class="text-left" width="10%"></th>
          <th class="text-left" width="20%"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
          </td>
          <td></td>
          <td class="text-right">
            Sub Total : <br />
            Discount : <br />
            Vat : <br />
            <h3>Total :</h3>
          </td>
          <td>
            <b>{{ sub_total | currency("", 0, { symbolOnLeft: false }) }}
            </b>
            <hr />
            <b>{{
              discount | currency("", 0, { symbolOnLeft: false }) }}
              |
              <v-icon color="success" small>mdi-arrow-bottom-right-thin
              </v-icon>
              <b class="success--text">{{ afterDiscount | currency("", 0, { symbolOnLeft: false }) }}</b>
            </b>
            <hr />
            <b>{{ vat | currency("", 0, { symbolOnLeft: false }) }}</b>
            <hr />
            <b class="primary--text"> {{ grand_total | currency("", 0, { symbolOnLeft: false }) }}</b>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-model="dialogProduct" v-if="dialogProduct" max-width="1200px" persistent><products-modal
        v-on:closeProductDialog="closeProductDialog" v-on:selectProduct="selectProduct" />
    </v-dialog>
    <v-dialog v-model="dialogMaterial" v-if="dialogMaterial" max-width="1200px" persistent><materials-modal
        v-on:closeMaterialDialog="closeMaterialDialog" v-on:selectMaterial="selectMaterial" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProductsModal from "@/components/modal/ProductsModal.vue";
import MaterialsModal from "@/components/modal/MaterialModal.vue";
import _ from "lodash";

export default {
  name: "PurchaseOrderFormItem",
  data() {
    return {
      dialogProduct: false,
      dialogMaterial: false,
      type: ["Product", "Material"],
    };
  },
  methods: {
    openProductDialog() {
      this.dialogProduct = true;
    },
    closeProductDialog() {
      this.dialogProduct = false;
    },
    selectProduct(val) {
      if (this.PurchaseOrder.details.find((item) => item.item_id === val.id)) {
        this.PurchaseOrder.details.find((item) => item.item_id === val.id).qty++;
        return;
      }
      this.$store.commit("purchase_order/SET_DETAILS", val);
      this.dialogProduct = false;
    },

    removeProduct(index) {
      this.PurchaseOrder.details.splice(index, 1);
    },

    openMaterialDialog() {
      this.dialogMaterial = true;
    },
    closeMaterialDialog() {
      this.dialogMaterial = false;
    },
    selectMaterial(val) {
      for (let i = 0; i < this.PurchaseOrder.details.length; i++) {
        if (this.PurchaseOrder.details[i].item_id === val.id) {
          this.PurchaseOrder.details[i].qty++;
          break;
        }
      }
      this.$store.commit("purchase_order/SET_MATERIAL", val);
      this.dialogMaterial = false;
    },

    removeMaterial(index) {
      this.PurchaseOrder.details.splice(index, 1);
    },
  },

  computed: {
    ...mapState("purchase_order", {
      PurchaseOrder: (state) => state.purchase_order,
    }),


    sub_total() {
      return _.sumBy(this.PurchaseOrder.details, function (t) {
        return (t.price * t.qty)
      });
    },

    discount() {
      if (this.PurchaseOrder.discount_percent > 100 || this.PurchaseOrder.discount_percent < 0) {
        return "Discount not valid value";
      }
      return parseFloat(this.sub_total) * parseFloat(this.PurchaseOrder.discount_percent) / 100;
    },

    afterDiscount() {
      let discount = parseFloat(this.sub_total - this.discount);
      if (discount > 0) {
        return discount;
      } else {
        return 0;
      }
    },

    vat() {
      if (this.PurchaseOrder.use_vat) {
        return parseFloat((this.afterDiscount * 11)) / 100;
      }
      else {
        return 0;
      }
    },

    grand_total() {
      return parseFloat(this.afterDiscount) + parseFloat(this.vat);
    },
  },

  components: {
    "products-modal": ProductsModal,
    "materials-modal": MaterialsModal,
  },
};
</script>
