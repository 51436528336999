<template>
  <div>
    <v-data-table :loading="loading" :headers="headers" :items="PROMOS.data" class="elevation-1" hide-default-footer>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <base-column :lg="4" :md="4" :sm="4">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" outlined dense
              hide-details></v-text-field>
          </base-column>
          <v-btn small color="darkblue" class="white--text float-end mx-1" @click.stop="searching">Submit <v-icon
              right>mdi-magnify</v-icon></v-btn>
          <v-btn small color="secondary" class="white--text float-end mx-1" @click.stop="clear">Clear
            <v-icon right>mdi-close-circle-multiple </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
    </v-data-table>
    <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
  </div>
</template>

<script>
import BaseColumn from "@/components/base/BaseColumn";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "PromoList",
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Start Date", value: "start_date" },
        { text: "End Date", value: "end_date" },
        { text: "Start Time", value: "start_time" },
        { text: "Expired Time", value: "expired_time" },
        { text: "Item", value: "item" },
        { text: "Size", value: "size" },
        { text: "Buy Qty", value: "buy_qty" },
        { text: "Free Item", value: "free_item" },
        { text: "Free Size", value: "free_size" },
        { text: "Free Qty", value: "free_qty" },
        { text: "Action", value: "actions", sortable: false, width: 100 },
      ],
      page: 1,
      disabled: false,
      loading: false,
    };
  },

  created() {
    this.getPromo();
    this.getBranch();
  },
  methods: {
    ...mapMutations("promo", ["SET_PROMOS", "CLEAR_STATE"]),
    async getPromo() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        search: this.search,
      };

      await this.$axios
        .get("/master/promo", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_PROMOS(data.promo);
          this.loading = false;
          this.disabled = false;
        });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch.data;
        });
    },

    searching() {
      this.page = 1;
      this.getPromo();
    },
    clear() {
      this.page = 1;
      this.search = {
        search: "",
      };
      this.getPromo();
    },

    dataTableRowClicked(item) {
      if (item === this.expanded[0]) {
        this.expanded = [];
      } else {
        this.expanded = [item];
      }
    },

    edit(item) {
      this.$router.push("/master/promo/edit/" + item.id);
    },
  },
  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.getPromo();
    },
  },
  computed: {
    ...mapGetters("promo", {
      PROMOS: "Promos",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    currentPage: {
      get() {
        return this.PROMOS.current_page;
      },
      set(val) {
        return this.$store.commit("promo/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.PROMOS.last_page;
      },
    },
  },

  components: {
    BaseColumn,
  },
};
</script>
