<template>
  <v-card>
    <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-toolbar-title> Suppliers List </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="closeSupplierDialog" color="error" outlined small>
        <v-icon>mdi-close</v-icon></v-btn>
    </v-app-bar>

    <v-data-table :loading="loading" :headers="headers" :items="SUPPLIERS.data" class="elevation-1" hide-default-footer>
      <template v-slot:no-data> No data </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="primary" outlined small @click.stop="selectSupplier(item)">Select <v-icon right> mdi-check
          </v-icon></v-btn>
      </template>

      <template v-slot:[`item.is_active`]="{ item }">
        <v-icon v-if="item.is_active == 1" color="success">
          mdi-check-circle
        </v-icon>
        <v-icon v-else color="error"> mdi-close-circle </v-icon>
      </template>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <base-column :lg="6" :md="6" :sm="12">
            <v-text-field v-model="search.keyword" append-icon="mdi-magnify" label="Search" outlined dense
              hide-details></v-text-field></base-column>

          <v-btn small color="darkblue" class="white--text float-end mx-1" @click.stop="getCustomer">Submit <v-icon
              right>mdi-magnify</v-icon></v-btn>
          <v-btn small color="secondary" class="white--text float-end mx-1" @click.stop="clear">Clear
            <v-icon right>mdi-close-circle-multiple </v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template>
        <v-icon color="warning" class="mr-2"> mdi-pencil-box-outline </v-icon>
      </template>
    </v-data-table>

    <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
  </v-card>
</template>

<script>
import BaseColumn from "@/components/base/BaseColumn";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SupplierModal",

  created() {
    this.getSupplier();
  },

  data() {
    return {
      headers: [
        { text: "Suppier Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Address", value: "address" },
        { text: "Status", value: "is_active" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      page: 1,
      disabled: false,
      loading: false,
      status: ["Active", "Inactive"],
      search: {
        status: "Active",
        keyword: null,
      },
    };
  },

  methods: {
    ...mapMutations("suppliers", ["SET_SUPPLIERS", "SET_CURRENT_PAGE"]),

    async getSupplier() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        status: this.search.status,
        keyword: this.search.keyword,
      };

      await this.$axios
        .get("/master/supplier", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_SUPPLIERS(data.suppliers);
          this.loading = false;
          this.disabled = false;
        });
    },

    searching() {
      this.page = 1;
      this.getSupplier();
    },
    clear() {
      this.page = 1;
      this.search = {
        status: "Active",
        search: "",
      };
      this.getSupplier();
    },
    closeSupplierDialog() {
      this.$emit("closeSupplierDialog");
    },

    selectSupplier(item) {
      this.$emit("selectSupplier", item);
      this.closeSupplierDialog();
    },
  },

  computed: {
    ...mapGetters("suppliers", {
      SUPPLIERS: "Suppliers",
    }),

    currentPage: {
      get() {
        return this.SUPPLIERS.current_page;
      },
      set(val) {
        return this.SET_CURRENT_PAGE(val);
      },
    },
    lastPage: {
      get() {
        return this.SUPPLIERS.last_page;
      },
    },
  },
  components: {
    BaseColumn,
  },
};
</script>