const state = () => ({
    widget: [],
    daily_sales_orders: {},
    by_item: {},
    top_sales: {},
    daily_sales_order: {
        id: null,
        no_so: "SKDKJFJK",
        bill_no: null,
        order_date: null,
        delivery_date: null,
        opening_date: null,
        type : null,
        customer_id: null,
        customer_name: null,
        customer_address: null,
        customer_phone: null,
        customer_email: null,
        branch_id: null,
        branch_name: null,
        branch_email: null,
        branch_phone: null,
        branch_address: null,
        status: null,
        subtotal: 0,
        discount_amount: 0,
        vat: 0,
        grand_total: 0,
        details: [],
    },
    page: 1,

})

const mutations = {
    SET_WIDGET(state, payload) {
        state.widget = payload
    },

    SET_DAILY_SALES_ORDERS(state, daily_sales_orders) {
        state.daily_sales_orders = daily_sales_orders
    },

    SET_BY_ITEM(state, by_item) {
        state.by_item = by_item
    },

    SET_TOP_SALES(state, top_sales) {
        state.top_sales = top_sales
    },

    SET_DAILY_SALES_ORDER(state, payload) {
        state.daily_sales_order.id = payload.id
        state.daily_sales_order.no_so = payload.no_so
        state.daily_sales_order.bill_no = payload.bill_no
        state.daily_sales_order.order_date = payload.order_date
        state.daily_sales_order.delivery_date = payload.delivery_date
        state.daily_sales_order.opening_date = payload.opening_date
        state.daily_sales_order.type = payload.type
        state.daily_sales_order.customer_id = payload.customer_id
        state.daily_sales_order.customer_name = payload.customer.name
        state.daily_sales_order.customer_address = payload.customer.address
        state.daily_sales_order.customer_phone = payload.customer.phone
        state.daily_sales_order.customer_email = payload.customer.email
        state.daily_sales_order.branch_id = payload.branch_id
        state.daily_sales_order.branch_name = payload.branch.name
        state.daily_sales_order.branch_email = payload.branch.email
        state.daily_sales_order.branch_phone = payload.branch.phone
        state.daily_sales_order.branch_address = payload.branch.address
        state.daily_sales_order.status = payload.status
        state.daily_sales_order.subtotal = payload.subtotal
        state.daily_sales_order.discount_amount = payload.discount_amount
        state.daily_sales_order.vat = payload.vat
        state.daily_sales_order.pph = payload.pph
        state.daily_sales_order.grand_total = payload.grand_total
        state.daily_sales_order.details = payload.details
    },

    CLEAR_STATE(state) {
        state.daily_sales_order = {
            id: null,
            no_so: null,
            bill_no: null,
            order_date: null,
            delivery_date: null,
            opening_date: null,
            customer_id: null,
            customer_name: null,
            customer_address: null,
            customer_phone: null,
            customer_email: null,
            branch_id: null,
            branch_name: null,
            branch_email: null,
            branch_phone: null,
            branch_address: null,
            status: null,
            subtotal: 0,
            discount_amount: 0,
            vat: 0,
            pph: 0,
            grand_total: 0,
        }
    },

    SET_CURRENT_PAGE(state, payload) {
        state.daily_sales_orders.current_page = payload;
    }
}

const getters = {
    Widget: state => state.widget,
    DailySalesOrders: state => state.daily_sales_orders,
    ByItem: state => state.by_item,
    TopSales: state => state.top_sales,
    DailySalesOrder: state => state.daily_sales_order,
    Page: state => state.page
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}

