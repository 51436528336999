<template>
  <div>
    <v-row dense class="mx-3 mt-4">
      <base-column :lg="4" :md="6" :sm="12">
        <v-card max-width="100%">
          <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
            <v-toolbar-title>
              <v-icon>mdi-account</v-icon>
              Account Patty Cash
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn outlined small color="primary" @click="openAccountPattyCashDialog">
              Choose
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-app-bar>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>Name : {{ PettyCash.account_patty_cash_name }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>Description : {{ PettyCash.account_patty_cash_description }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </base-column>
      <v-row dense class="mx-3 mt-1">
        <base-column :lg="4" :md="3" :sm="12">
          <v-text-field v-model="PettyCash.name" outlined dense label="Name" :rules="rule_name"></v-text-field>
          <v-text-field type="number" v-model="PettyCash.amount" outlined dense label="Amount"></v-text-field>
        </base-column>
        <base-column :lg="4" :md="3" :sm="12">
          <v-text-field type="date" v-model="PettyCash.date" outlined dense label="Date"
            :rules="rule_date"></v-text-field>
          <v-textarea v-model="PettyCash.remark" rows="2" outlined dense label="Remak"></v-textarea>
        </base-column>
        <base-column :lg="4" :md="3" :sm="12">
          <v-select :items="customer.data" v-model="PettyCash.customer_id" item-text="name" item-value="id" outlined dense
            label="Customer"></v-select>
          <v-select :items="branch.data" item-text="name" item-value="id" v-if="(ROLE == 'Owner')"
            v-model="PettyCash.branch_id" outlined dense label="Branch" :rules="rule_branch"></v-select>
        </base-column>
      </v-row>
    </v-row>
    <v-dialog v-if="dialogAccountPattyCash" v-model="dialogAccountPattyCash" max-width="1200px" persistent>
      <account-patty-cash-modal v-on:closeAccountPattyCashDialog="closeAccountPattyCashDialog"
        v-on:selectAccountPattyCash="selectAccountPattyCash" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import AccountPattyCashModal from "@/components/modal/AccountPattyCashModal.vue";
import BaseColumn from "@/components/base/BaseColumn.vue";

export default {
  name: "PettyCashForm",

  data() {
    return {
      dialogAccountPattyCash: false,
      branch: [],
      petty_cash_category: [],
      customer: [],
    };
  },
  created() {
    this.getPettyCashCategory();
    this.getCustomer();
    this.getBranch();
  },
  methods: {
    ...mapMutations("petty_cash", [
      "SET_PETTY_CASH", "SET_ACCOUNT_PATTY_CASH"
    ]),

    async getPettyCashCategory() {
      await this.$axios
        .get("/transaction/petty-cash-categories", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.petty_cash_category = data.resource;
        });
    },

    async getCustomer() {
      await this.$axios
        .get("/master/customers?is_active=1", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.customer = data.customers;
        });
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch;
        });
    },

    openAccountPattyCashDialog() {
      this.dialogAccountPattyCash = true;
    },

    closeAccountPattyCashDialog() {
      this.dialogAccountPattyCash = false;
    },

    selectAccountPattyCash(val) {
      this.SET_ACCOUNT_PATTY_CASH({
        id: val.id,
        name: val.name,
        description: val.description,
      });
      this.dialogAccountPattyCash = false;
    },
  },

  computed: {
    ...mapState("petty_cash", {
      PettyCash: (state) => state.petty_cash,
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),

  },
  components: {
    "account-patty-cash-modal": AccountPattyCashModal,
    BaseColumn,
  },
};
</script>