<template>
  <div>
    <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-toolbar-title> Product List</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-simple-table class="mx-2 mb-3" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" width="1%">#</th>
            <th class="text-left" width="20%">Product Name</th>
            <th class="text-left" width="20%">Description</th>
            <th class="text-left" width="10%">Unit</th>
            <th class="text-left" width="10%">Price</th>
            <th class="text-left" width="10%">Qty So</th>
            <th class="text-left" width="10%">Qty Remaining</th>
            <th class="text-left" width="10%">Qty</th>
            <th class="text-left" width="10%">Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr>
             <td>1</td>
             <td>
               {{ "Product 1" }}
             </td>
             <td>
               {{ "Description 1" }}
             </td>
             <td>{{ "PCS" }}</td>
             <td>
               <b> {{ "1.20.000" | currency ("", 0, { symbolOnLeft: false }) }} </b>
            </td>
            <td>
              <v-text-field placeholder="Qty So" type="number" outlined dense hide-details></v-text-field>
            </td>
            <td>
              <v-text-field placeholder="Qty Remaining" type="number" outlined dense hide-details></v-text-field>
            </td>
            <td>
              <v-text-field placeholder="Qty" type="number" outlined dense hide-details></v-text-field>
            </td>
            <td>
              <b>
                {{
                  "1.20.000"
                }}
              </b>
            </td>
           </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table class="mx-2 mb-3" fixed-header>
      <thead>
        <tr>
          <th class="text-left" width="45%"></th>
          <th class="text-left" width="10%"></th>
          <th class="text-left" width="10%"></th>
          <th class="text-left" width="20%"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
          </td>
          <td></td>
          <td class="text-right">
            <h3>Total :</h3>
          </td>
          <td>
            <b class="primary--text"> 0</b>
            <hr />
          </td>
        </tr>
      </tbody>

    </v-simple-table>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "OutgoingFormItem",
  data() {
    return {
      dialogProduct: false,
    };
  },
  methods: {
    preventOverflow() {
      this.Outgoing.details.forEach((item) => {
        if (item.qty_out > item.qty) {
          item.qty_out = item.qty;
        }
      });
    },

    removeProduct(index) {
      this.Outgoing.details.splice(index, 1);
    },
  },

  computed: {
    ...mapState("outgoing", {
      Outgoing: (state) => state.outgoing,
    }),
  },
};
</script>
