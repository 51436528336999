<template>
    <div>
        <v-row class="mt-2">
            <v-responsive width="100%" />
            <v-col :xs="12" :sm="12" :md="3" :lg="3">
                <v-text-field type="date" label="From Date" outlined dense v-model="search.from_date">
                </v-text-field>
            </v-col>
            <v-col :xs="12" :sm="12" :md="3" :lg="3">
                <v-text-field type="date" label="End Date" outlined dense v-model="search.to_date">
                </v-text-field>
            </v-col>
            <v-col :xs="12" :sm="12" :md="6" :lg="6">
                <v-btn small color="darkblue" class="white--text mx-1" @click.stop="init">Submit <v-icon
                        right>mdi-magnify</v-icon></v-btn>
                <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
                    <v-icon right>mdi-close-circle-multiple </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="my-0">
            <base-column :xs="12" :sm="12" :md="4" :lg="3">
                <v-banner class="mt-3 summary" outlined rounded single-line>
                    <v-row no-gutters class="mb-3 mt-2">
                        <v-col xs="3" sm="3" md="3" class="my-auto mx-auto">
                            <v-avatar color="primary" size="55" tile>
                                <v-icon color="white" large>mdi-handshake</v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col xs="9" sm="9" md="9">
                            <h4 class="ml-2 mt-2">Cash Total Minus Change</h4>
                            <h4 class="secondary--text ml-2 mt-2">Rp {{ this.total_change | currency("", 0, {
                                symbolOnLeft:
                                    false,
                            }) }}</h4>
                        </v-col>
                    </v-row>
                </v-banner>
            </base-column>
            <base-column :xs="12" :sm="12" :md="4" :lg="3">
                <v-banner class="mt-3 summary" outlined rounded single-line>
                    <v-row no-gutters class="mb-3 mt-2">
                        <v-col xs="3" sm="3" md="3" class="my-auto mx-auto">
                            <v-avatar color="primary" size="55" tile>
                                <v-icon color="white" large>mdi-handshake</v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col xs="9" sm="9" md="9">
                            <h4 class="ml-2 mt-2">Cash Total With Opening</h4>
                            <h4 class="secondary--text ml-2 mt-2">Rp {{ this.total_opening | currency("", 0, {
                                symbolOnLeft:
                                    false,
                            }) }}</h4>
                        </v-col>
                    </v-row>
                </v-banner>
            </base-column>
            <base-column :xs="12" :sm="12" :md="4" :lg="3">
                <v-banner class="mt-3 summary" outlined rounded single-line>
                    <v-row no-gutters class="mb-3 mt-2">
                        <v-col xs="3" sm="3" md="3" class="my-auto mx-auto">
                            <v-avatar color="primary" size="55" tile>
                                <v-icon color="white" large>mdi-handshake</v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col xs="9" sm="9" md="9">
                            <h4 class="ml-2 mt-2">Total Change</h4>
                            <h4 class="secondary--text ml-2 mt-2">Rp {{ this.total_change_amount | currency("", 0, {
                                symbolOnLeft:
                                    false,
                            }) }}</h4>
                        </v-col>
                    </v-row>
                </v-banner>
            </base-column>
            <base-column :xs="12" :sm="12" :md="4" :lg="3">
                <v-banner class="mt-3 summary" outlined rounded single-line>
                    <v-row no-gutters class="mb-3 mt-2">
                        <v-col xs="3" sm="3" md="3" class="my-auto mx-auto">
                            <v-avatar color="primary" size="55" tile>
                                <v-icon color="white" large>mdi-handshake</v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col xs="9" sm="9" md="9">
                            <h4 class="ml-2 mt-2">Total Opening Amount</h4>
                            <h4 class="secondary--text ml-2 mt-2">Rp {{ this.total_opening_amount | currency("", 0, {
                                symbolOnLeft:
                                    false,
                            }) }}</h4>
                        </v-col>
                    </v-row>
                </v-banner>
            </base-column>
        </v-row>
        <v-row class="my-0">
            <base-column :xs="12" :sm="12" :md="4" :lg="3" v-for="row in total_payments" :key="row.id">
                <v-banner class="mt-3 summary" outlined rounded single-line>
                    <v-row no-gutters class="mb-3 mt-2">
                        <v-col xs="3" sm="3" md="3" class="my-auto mx-auto">
                            <v-avatar color="secondary" size="40" tile>
                                <v-icon color="white">mdi-cash</v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col xs="9" sm="9" md="9">
                            <h4 class="ml-2 mt-2">{{ row.payment_method?.name }} | <span color="primary"
                                    v-if="row?.payment_method_id == 2">{{
                                        row?.card_payment_type?.name ?? '' }}</span></h4>
                            <h4 class="secondary--text ml-2 mt-2">Rp {{ row.total | currency("", 0, {
                                symbolOnLeft:
                                    false,
                            }) }}</h4>
                        </v-col>
                    </v-row>
                </v-banner>
            </base-column>
        </v-row>
    </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import BaseColumn from "@/components/base/BaseColumn.vue";
export default {
    name: "ReportPayment",
    data() {
        return {
            search: {
                from_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
                to_date: new Date().toJSON().slice(0, 10),
            },
            total_change: 0,
            total_opening: 0,
            total_change_amount: 0,
            total_opening_amount: 0,
            total_payments: [],


        };
    },

    methods: {
        async init() {
            let params = {
                from_date: this.search.from_date,
                to_date: this.search.to_date,
            }
            await this.$axios
                .get("/reports/payments", {
                    params: params,
                    headers: {
                        Authorization: this.$store.getters["auth/Token"],
                    },
                })
                .then(({ data }) => {
                    if (data.total_payments.length == 0) {
                        this.total_change = 0;
                        this.total_change_amount = 0;
                        this.total_opening_amount = 0;
                        this.total_opening = 0;

                    } else {
                        this.total_change = data?.total_payments[0].total_minus_change;
                        this.total_change_amount = data?.total_change;
                        this.total_opening_amount = data?.total_opening_amount;
                        this.total_opening = data?.total_payments[0].total_with_opening_amount;
                    }
                    this.total_payments = data.total_payments;
                    this.loading = false;
                    this.disabled = false;
                });
        },

        clear() {
            this.search = {
                from_date: `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-01`,
                to_date: new Date().toJSON().slice(0, 10),
            };
            this.init();

        }
    },

    created() {
        this.init();
    },

    components: {
        BaseColumn,
    },
    computed: {
        ...mapGetters("auth", {
            CAN: "Can",
        }),
        ...mapGetters("auth", {
            ROLE: "Role",
        }),
    },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
    background-color: #ebebeb59;
}

::v-deep th {
    color: #000 !important;
}
</style>