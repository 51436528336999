<template>
  <div class="mt-4">
    <v-data-table :loading="loading" :headers="headers" :items="ITEMS.data" class="elevation-1" show-expand
      :expanded.sync="expanded" :single-expand="true" hide-default-footer>
      <template v-slot:[`item.is_active`]="{ item }">
        <v-icon v-if="item.is_active == true" color="success">
          mdi-check-circle
        </v-icon>
        <v-icon v-else color="error"> mdi-close-circle </v-icon>
      </template>
      <template v-slot:[`item.use_service`]="{ item }">
        <v-icon v-if="item.use_service == 1" color="success">
          mdi-check-circle
        </v-icon>
        <v-icon v-else color="error"> mdi-close-circle </v-icon>
      </template>
      <template v-slot:[`item.use_ingredient`]="{ item }">
        <v-icon v-if="item.use_ingredient == 1" color="success">
          mdi-check-circle
        </v-icon>
        <v-icon v-else color="error"> mdi-close-circle </v-icon>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <img :src="item.image" style="width: 90px" />
      </template>
      <template v-slot:[`item.service`]="{ item }">
        <b>{{ item.service }}%</b>
      </template>
      <template v-slot:[`item.ppn`]="{ item }">
        <b>{{ item.ppn }}%</b>
      </template>
      <template v-slot:no-data> No data </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="warning" class="mr-2" @click.stop="edit(item.id)">
          mdi-pencil-box-outline
        </v-icon>
      </template>
      <template v-slot:[`item.price_buy`]="{ item }">
        <b>{{ item.price_buy | currency("", 0, { symbolOnLeft: false, }) }}</b>
      </template>

      <template v-slot:[`item.price_sell`]="{ item }">
        <b>{{ item.price_sell | currency("", 0, { symbolOnLeft: false, }) }}</b>
      </template>

      <template v-slot:[`item.total`]="{ item }">
        <b>{{ item.total | currency("", 0, { symbolOnLeft: false, }) }}</b>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="12" class="mt-5">
              <h4 class="mb-1">
                Description : {{ item.description ? item.description : "N/A" }}
              </h4>
              <h4 class="mb-1">
                Specification : {{ item.specification ? item.specification : "N/A" }}
              </h4>
            </v-col>
          </v-row>
        </td>
      </template>

      <template v-slot:top>
        <v-row dense class="mx-2">
          <base-column :lg="4" :md="4" :sm="12">
            <v-text-field v-model="search.search" append-icon="mdi-magnify" label="Search" outlined dense
              hide-details></v-text-field>
          </base-column>
          <base-column :lg="8" :md="8" :sm="12">
            <v-btn small color="darkblue" class="white--text" @click.stop="searching">Submit <v-icon
                right>mdi-magnify</v-icon></v-btn>
            <v-btn small color="secondary" class="white--text mx-1" @click.stop="clear">Clear
              <v-icon right>mdi-close-circle-multiple </v-icon>
            </v-btn>
            <v-btn to="/master/item/tabs" small elevation="1" color="primary" class="white--text float-end"
              @click="create()">
              Create
              <v-icon right dark>mdi-plus-circle </v-icon>
            </v-btn>
          </base-column>
        </v-row>
        <v-spacer></v-spacer>
      </template>
    </v-data-table>
    <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
  </div>
</template>


<script>
import BaseColumn from "@/components/base/BaseColumn";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "ItemList",
  data() {
    return {
      headers: [
        { text: "Item Code", value: "code", width: 150 },
        { text: "Item Name", value: "name", width: 250 },
        { text: "Branch", value: "branch.name", width: 100 },
        { text: "Subgroup", value: "subgroup.name", width: 150 },
        // { text: "Stock Qty", value: "stock", width: 150 },
        { text: "Price Buy", value: "price_buy", width: 150 },
        { text: "Price Sell", value: "price_sell", width: 150 },
        { text: "Service", value: "service", width: 150 },
        { text: "Ppn", value: "ppn", width: 150 },
        { text: "Total", value: "total", width: 150 },
        { text: "Use Service", value: "use_service", width: 150 },
        { text: "Use Ingredient", value: "use_ingredient", width: 150 },
        { text: "Status", value: "is_active", width: 150 },
        { text: "Image", value: "image", width: 150 },
        { text: "Action", value: "actions", sortable: false, width: 150 },
      ],
      page: 1,
      disabled: false,
      loading: false,
      status: ["Active", "Inactive"],
      search: {
        status: null,
        search: null,
        use_ingredient: 0,
        branch_id: null,
      },
      expanded: [],
      branch: [],
    };
  },

  created() {
    this.getItem();
    this.getBranch();
  },
  methods: {
    ...mapMutations("item", ["CLEAR_STATE", "SET_ITEMS", "SET_ITEM", "SET_CURRENT_PAGE"]),
    async getItem() {
      this.loading = true;
      this.disabled = true;
      let params = {
        page: this.page,
        status: this.search.status,
        search: this.search.search,
        branch_id: this.search.branch_id,
        use_ingredient: this.search.use_ingredient,
      };

      await this.$axios
        .get("/master/item", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit("item/SET_ITEMS", data.item);
          this.CLEAR_STATE();
          this.loading = false;
          this.disabled = false;
        });
    },

    use_ingredient() {
      if (this.search.use_ingredient == true) {
        this.search.use_ingredient = 1;
      } else {
        this.search.use_ingredient = 0;
      }
    },

    async getBranch() {
      await this.$axios
        .get("/master/branches", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.branch = data.branch.data;
        });
    },

    searching() {
      this.page = 1;
      this.getItem();
    },
    clear() {
      this.page = 1;
      this.search = {
        status: "",
        search: "",
        branch_id: "",
        use_ingredient: 0,
      };
      this.getItem();
    },

    dataTableRowClicked(item) {
      if (item === this.expanded[0]) {
        this.expanded = [];
      } else {
        this.expanded = [item];
      }
    },

    async edit(id) {
      this.loading = true;
      await this.$axios
        .get("/master/item/" + id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit("item/SET_ITEM", data.item);
          this.loading = false;
          this.$router.push("/item/edit");
        })
        .catch(() => {
          this.loading = true;
        });
    },
  },
  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.getItem();
    },
  },
  computed: {
    ...mapGetters("item", {
      ITEMS: "Items",
    }),

    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    currentPage: {
      get() {
        return this.ITEMS.current_page;
      },
      set(val) {
        return this.$store.commit("item/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.ITEMS.last_page;
      },
    },
  },

  components: {
    BaseColumn,
  },
};
</script>