<template>
  <div>
    <base-title title="Company Profile" subtitle="You can update information using form bellow" />
    <v-spacer></v-spacer>
    <v-form lazy-validation ref="form_data" >
      <v-responsive width="100%" v-if="$vuetify.breakpoint.xsOnly" />
      <v-row class="mx-1">
        <base-column :lg="3" :md="6" :sm="12">
          <div @dragover.prevent @dragenter.prevent @dragstart.prevent
            @drop.prevent="handleFileChange($event.dataTransfer)">
            <img v-bind:src="preview" class="dropbox1" v-if="preview"/>
            <img :src="form_data.logo" class="dropbox1" v-else />
            <input type="file"  id="file-input"  label="Image" accept="image/png, image/jpeg" outlined dense  ref="file" v-on:change="handleFileChange($event.target)">
          </div>
        </base-column>
        <base-column :lg="3" :md="6" :sm="12" class="mt-2">
          <v-text-field v-model="form_data.name" label="Company Name" outlined dense></v-text-field>
          <v-text-field v-model="form_data.email" label="Company Email" outlined dense></v-text-field>
          <v-text-field v-model="form_data.zip_code" label="Zip Code" outlined dense></v-text-field>
        </base-column>
        <base-column :lg="3" :md="6" :sm="12" class="mt-2">
          <v-text-field v-model="form_data.owner_name" label="Owner Name" outlined dense></v-text-field>
          <v-text-field v-model="form_data.city" label="Company City" outlined dense></v-text-field>
          <v-textarea v-model="form_data.address" label="Address" outlined dense rows="2"></v-textarea>
        </base-column>
        <base-column :lg="3" :md="6" :sm="12" class="mt-2">
          <v-text-field v-model="form_data.phone" label="Company Phone" outlined dense></v-text-field>
          <v-text-field v-model="form_data.province" label="Province" outlined dense></v-text-field>
        </base-column>
      </v-row>
      <v-row class="mx-1 mb-2">
        <base-column :lg="12" :md="12" :sm="12">
         <v-btn color="primary" @click.stop="updateCompany()"  class="mb-2 float-end"><v-icon>mdi-content-save</v-icon>Save Change</v-btn>
        </base-column>
      </v-row>
    </v-form>
   
  </div>
</template>

<script>
import BaseColumn from "@/components/base/BaseColumn.vue";
import BaseTitle from "@/components/base/BaseTitle.vue";
import { mapGetters, mapMutations } from "vuex";
export default {

  name: "CompanyProfile",
  components: { BaseColumn, BaseTitle },

  data() {
    return {
      fileName: "",
      preview: null,
      formData: null,
      file : null,
      success: "",

      form_data :{
        company_name: "DDD",
      },
    };
  },



  created() {
    this.form_data = this.$store.getters["auth/User"];
    this.getCompanyProfile();
  },

  methods: {
    ...mapMutations("company", ["SET_COMPANY"]),
    getCompanyProfile() {
      this.$axios
        .get("/my-company", {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.$store.commit("company/SET_COMPANY", data.companies);
          this.form_data = data.companies;
        })
        .catch(() => {
          alert("Error");
        });
    },

    handleFileChange: function (event) {
      this.file = event.files[0];
      this.fileName = this.file.name;
      this.formData = new FormData();
      this.formData.append("upload_preset", this.preset);

      let reader = new FileReader();
      reader.readAsDataURL(this.file);

      reader.onload = (e) => {
        console.log(e);
        this.preview = e.target.result;
        this.formData.append("file", this.preview);
      };
    },

    updateCompany: async function () {
      if (this.$refs.form_data.validate()) {
        this.btnLoading = true;
        let formData = new FormData();
        formData.append("logo", this.file);
        this.logo = this.file;
        if (this.form_data.is_active == true) {
          this.form_data.is_active = 1;
        } else {
          this.form_data.is_active = 0;
          
        }
        await this.$axios
          .post(
            "/my-company/update",
            {
              _method: "PUT",
              name: this.form_data.name,
              owner_name: this.form_data.owner_name,
              city : this.form_data.city,
              province : this.form_data.province,
              zip_code : this.form_data.zip_code,
              email: this.form_data.email,
              phone: this.form_data.phone,
              address: this.form_data.address,
              website : this.form_data.website,
              is_active : this.form_data.is_active,
              logo : this.logo,
              billing_plan :"TEST",
            },
            {
              headers: {
                Authorization: this.$store.getters["auth/Token"],
                'Content-Type': 'multipart/form-data'
              },
            }
          )
          .then(() => {
            this.btnLoading = false;
            this.$router.path('/company-profile');
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },

    update: async function () {
      if (this.$refs.form_data.validate()) {
        this.btnLoading = true;
        await this.$axios
          .post(
            "/auth/update-profile/",
            {
              _method: "PUT",
              username: this.USER.user.username,
              name: this.USER.user.name,
              owner_name: this.USER.user.owner_name,
              city: this.USER.user.city,
              province: this.USER.user.province,
              zip_code: this.USER.user.zip_code,
              email: this.USER.user.email,
              phone: this.USER.user.phone,
              address: this.USER.user.address,
            },
            {
              headers: {
                Authorization: this.$store.getters["auth/Token"],
              },
            }
          )
          .then(() => {
            this.btnLoading = false;
            this.$router.push({ name: "account" });
          })
          .catch(() => {
            this.btnLoading = false;
          });
      }
    },
  },

  computed: {
    ...mapGetters("auth", {
      AUTHENTICATED: "Authenticated",
      USER: "User",
      CAN: "Can",
    }),

    ...mapGetters("company", {
      COMPANY: "Company",
     }),
    
    APP_NAME() {
      return process.env.VUE_APP_NAME;
    },
  },
};
</script>

<style lang="scss">
  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }
  .dropbox1 {
    outline: 1px dashed grey; /* the dash box */
    outline-offset: -10px;
    color: dimgray;
    border-radius: 15px;
    padding: 10px 10px;
    position: relative;
    cursor: pointer;
    width: 250px;
    height: 250px;
  }
  
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 10%;
    height: 10px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }
  
  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
</style>
