<template>
  <main-layout>
    <base-header>
      <template #icon> mdi-file</template>
      <template #name>Order </template>
      <template #breadcrumbs>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </template>
    </base-header>
    <v-row dense class="mx-1">
      <v-responsive width="100%" v-if="$vuetify.breakpoint.xsOnly" />
      <order-list />
    </v-row>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import BaseHeader from "@/components/base/BaseHeader.vue";
import OrderList from "./List.vue";
export default {
  name: "OrderIndex",

  created() {
    this.breadcrumbs = [
      { text: "Transaction", disabled: false, to: "/dashboard" },
      { text: "Order", disabled: true },
      { text: "List", disabled: true },
    ];
  },
  components: {
    MainLayout,
    BaseHeader,
    "order-list": OrderList,
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  background-color: #ebebeb59;
}

::v-deep th {
  color: #000 !important;
}
</style>