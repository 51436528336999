<template>
  <div>
    <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-toolbar-title> Product List</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn outlined small class="ma-1" color="primary" @click="openProductDialog()">
        Add Product
        <v-icon right>mdi-plus</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-simple-table class="mx-2 mb-3" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" width="1%">No</th>
            <th class="text-left" width="15%">Product Name</th>
            <th class="text-left" width="20%">Description</th>
            <th class="text-left" width="10%">Unit</th>
            <th class="text-left" width="10%">Price Sell</th>
            <th class="text-left" width="8%">Qty</th>
            <th class="text-left" width="10%">Discount</th>
            <th class="text-left" width="10%">Amount</th>
            <th class="text-left" width="1%">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr height="80" v-for="(row, index) in SalesInvoice.details" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              {{ row.item_name ?? row.item.name }}
            </td>
            <td>
              {{ row?.item_description ?? row?.item?.description }}
            </td>
            <td>{{ row.item_unit ?? row.item?.unit?.name }}</td>
            <td>
              <v-text-field v-model="row.price" placeholder="Price Sell" type="number" outlined dense
                hide-details></v-text-field>
            </td>
            <td>
              <v-text-field v-model="row.qty" placeholder="Qty" type="number" outlined dense hide-details></v-text-field>
            </td>
            <td>
              <v-text-field v-model="row.discount" placeholder="Discount" type="number" outlined dense
                hide-details></v-text-field>
            </td>
            <td>
              <b>
                {{
                  row.amount = (row.price * row.qty - row.discount) | currency("", 0, { symbolOnLeft: false })
                }}
              </b>
            </td>
            <td>
              <v-icon color="error" @click="removeProduct(index)">mdi-delete
              </v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-simple-table class="mx-2 mb-3" fixed-header>
      <thead>
        <tr>
          <th class="text-left" width="45%"></th>
          <th class="text-left" width="10%"></th>
          <th class="text-left" width="10%"></th>
          <th class="text-left" width="20%"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
          </td>
          <td></td>
          <td class="text-right">
            Sub Total : <br />
            Discount : <br />
            Vat : <br />
            Pph : <br />
            <h3>Total :</h3>
          </td>
          <td>
            <b>{{ subtotal | currency("", 0, { symbolOnLeft: false }) }}
            </b>
            <hr />
            <b>{{
              discount_amount | currency("", 0, { symbolOnLeft: false }) }}
              |
              <v-icon color="success" small>mdi-arrow-bottom-right-thin
              </v-icon>
              <b class="success--text">{{ afterDiscount | currency("", 0, { symbolOnLeft: false }) }}</b>
            </b>
            <hr />
            <b>{{ vat | currency("", 0, { symbolOnLeft: false }) }}</b>
            <hr />
            <b>{{ pph | currency("", 2, { symbolOnLeft: false }) }}</b>
            <hr />
            <b class="primary--text"> {{ grand_total | currency("", 0, { symbolOnLeft: false }) }}</b>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-dialog v-model="dialogProduct" v-if="dialogProduct" max-width="1200px" persistent><products-modal
        v-on:closeProductDialog="closeProductDialog" v-on:selectProduct="selectProduct" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ProductsModal from "@/components/modal/ProductsModal.vue";
import _ from "lodash";

export default {
  name: "SalesInvoiceForm",
  data() {
    return {
      dialogProduct: false,
    };
  },
  methods: {
    openProductDialog() {
      this.dialogProduct = true;
    },
    closeProductDialog() {
      this.dialogProduct = false;
    },

    selectProduct(val) {
      if (this.SalesInvoice.details.find((item) => item.item_id === val.id)) {
        this.SalesInvoice.details.find((item) => item.item_id === val.id).qty++;
        return;
      }
      this.$store.commit("sales_invoice/SET_DETAILS", val);
      this.dialogProduct = false;
    },

    removeProduct(index) {
      this.SalesInvoice.details.splice(index, 1);
    },
  },
  computed: {
    ...mapState("sales_invoice", {
      SalesInvoice: (state) => state.sales_invoice,
    }),
    ...mapGetters("pph", {
      PPH: "Pph",
    }),

    subtotal() {
      return _.sumBy(this.SalesInvoice.details, function (t) {
        return (t.price * t.qty - t.discount)
      });
    },

    discount() {
      return this.SalesInvoice.discount_percent;
    },

    discount_amount() {
      if (this.SalesInvoice.discount_percent > 100 || this.SalesInvoice.discount_percent < 0) {
        return "Discount not valid value";
      }
      return parseFloat(this.subtotal) * parseFloat(this.SalesInvoice.discount_percent) / 100;
    },

    afterDiscount() {
      let discount = parseFloat(this.subtotal - this.discount_amount);
      if (discount > 0) {
        return discount;
      } else {
        return 0;
      }
    },

    vat() {
      if (this.SalesInvoice.use_vat) {
        return parseFloat((this.afterDiscount * 11)) / 100;
      }
      else {
        return 0;
      }
    },

    pph() {
      let pph = this.PPH.percentage ?? 0;
      return (
        (this.afterDiscount * pph ?? 0) / 100
      );
    },

    grand_total() {
      return parseFloat(this.afterDiscount) + parseFloat(this.vat) - parseFloat(this.pph);
    },
  },
  destroyed() {
    this.$store.commit("pph/CLEAR_PPH");
  },

  components: {
    "products-modal": ProductsModal,
  },
};
</script>
