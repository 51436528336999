<template>
  <div>
    <base-title title="Group" subtitle="List Data Group" />
    <v-data-table :headers="headers" :items="Groups.data" class="elevation-1" :loading="datatableLoading"
      loading-text="Please wait..." hide-default-footer>
      <template v-slot:[`item.is_active`]="{ item }">
        <v-chip x-small :color="getColor(item.is_active)" dark>
          {{ item.is_active ? "Active" : "Not Active" }}
        </v-chip></template>
      <template v-slot:no-data> No data </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="text-center">
          <v-col cols="12"> <strong>ID:</strong>{{ item.id }} </v-col>
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="warning" class="mr-2" @click.stop="editItem(item)">
          mdi-pencil-box-outline
        </v-icon>
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field v-model="search.search" append-icon="mdi-magnify" label="Search" outlined dense
            hide-details></v-text-field>
          <v-btn color="darkblue" class="white--text mx-1 mb-2" small @click.stop="searching">Submit <v-icon
              right>mdi-magnify</v-icon></v-btn>
          <v-btn small color="secondary" class="white--text mx-1 mb-2" @click.stop="clear">Clear
            <v-icon right>mdi-close-circle-multiple </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogForm" max-width="450px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small elevation="1" color="primary" class="ma-2 white--text" @click="loader = 'loading3'">
                Create
                <v-icon right dark>mdi-plus-circle </v-icon>
              </v-btn>
            </template>
            <v-form ref="form_data" v-model="form_valid" lazy-validation enctype="multipart/form-data">
              <v-card class="rounded-lg">
                <v-card-title class="mb-3">
                  <span class="headline">{{ form_title }}</span>
                </v-card-title>
                <v-card-text>
                  <v-text-field v-model="form_data.name" label="Group Name" outlined dense :rules="rule_name">
                  </v-text-field>
                  <v-select label="Branch" :items="branch" v-if="(ROLE == 'Owner')" v-model="form_data.branch_id" dense
                    item-text="name" item-value="id" outlined></v-select>
                  <v-textarea v-model="form_data.description" label="Description" outlined dense rows="3">
                  </v-textarea>
                  <v-switch dense v-model="form_data.is_active" label="USE">
                  </v-switch>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn small color="error darken-1" @click.stop="closedialogForm">Cancel
                    <v-icon right dark>mdi-close </v-icon>
                  </v-btn>
                  <v-btn small color="primary" class="ma-2 white--text" @click.stop="save" :loading="btnLoading"
                    :disabled="!form_valid || btnLoading">
                    Save
                    <v-icon right dark>mdi-content-save </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
    <v-pagination v-model="currentPage" :length="lastPage" :total-visible="10" :disabled="disabled"></v-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { datatableMixin } from "@/mixins/datatable.js";
import BaseTitle from "@/components/base/BaseTitle.vue";

export default {
  components: { BaseTitle },
  name: "GroupView",
  mixins: [datatableMixin],
  data() {
    return {
      previewImage: null,
      headers: [
        { text: "Group Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Status", value: "is_active" },
        { text: "Action", value: "actions", sortable: false, width: 100 },
      ],
      branch: [],

      search: {
        search: "",
      },
      disabled: false,
      page: 1,

      form_data: {
        name: "",
        branch_id: "",
        description: "",
        is_active: true,
      },

      form_default: {
        name: "",
        branch_id: "",
        description: "",
        is_active: true,
      },

    }
  },

  created() {
    this.getBranch();
  },

  methods: {

    initialize: async function () {
      this.datatableLoading = true;
      let params = {
        search: this.search.search,
        page: this.page,
      }
      await this.$axios
        .get("/master/group", {
          params: params,
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.datatable = data.group.data;
          this.$store.commit("group/SET_GROUPS", data.group);
          this.datatableLoading = false;
        })
        .catch(() => {
          this.datatableLoading = false;
        });
    },

    save: async function () {
      if (this.$refs.form_data.validate()) {
        this.btnLoading = true;
        let formData = new FormData();
        formData.append("image", this.file);
        if (this.editedIndex > -1) {
          await this.$axios
            .post(
              "/master/group/" + this.form_data.id,
              {
                _method: "PUT",
                name: this.form_data.name,
                branch_id: this.form_data.branch_id,
                description: this.form_data.description,
                is_active: this.form_data.is_active,
              },
              {
                headers: {
                  Authorization: this.$store.getters["auth/Token"],
                },
              }
            )
            .then(() => {
              this.initialize();
              this.closedialogForm();
              this.btnLoading = false;
            })
            .catch(() => {
              this.btnLoading = false;
            });
        } else {
          await this.$axios
            .post(
              "/master/group",
              {
                name: this.form_data.name,
                branch_id: this.form_data.branch_id,
                description: this.form_data.description,
                is_active: this.form_data.is_active,
              },
              {
                headers: {
                  Authorization: this.$store.getters["auth/Token"],
                  headers: { 'content-type': 'multipart/form-data', }
                },
              }
            )
            .then(() => {
              this.initialize();
              this.closedialogForm();
              this.btnLoading = false;
            })
            .catch(() => {
              this.btnLoading = false;
            });
        }
      }
    },

    editItem(item) {
      this.id = item.id;
      this.editedIndex = this.datatable.indexOf(item);
      item.image = null
      this.form_data = Object.assign({}, item);
      this.$axios
        .get("/master/group", {
          headers: { Authorization: this.$store.getters["auth/Token"] },
        })
        .then(({ data }) => {
          this.list_payment = data.group.data;
        });

      this.dialogForm = true;
    },

    searching() {
      this.page = 1;
      this.initialize();
    },

    clear() {
      this.page = 1;
      this.search = {
        search: "",
      };
      this.initialize();
    },

    async getBranch() {
      await this.$axios("/master/branches?active=1", {
        headers: {
          Authorization: this.$store.getters["auth/Token"],
        },
      }).then(({ data }) => {
        this.branch = data.branch.data;
      });
    },
  },

  watch: {
    currentPage(newValue) {
      this.page = newValue;
      this.initialize();
    },
  },

  computed: {
    form_title() {
      return this.editedIndex === -1 ? "Create Group" : "Edit Group";
    },

    ...mapGetters("auth", {
      ROLE: "Role",
    }),

    ...mapGetters("group", {
      Groups: "Groups",
    }),

    currentPage: {
      get() {
        return this.Groups.current_page;
      },
      set(val) {
        return this.$store.commit("group/SET_CURRENT_PAGE", val);
      },
    },
    lastPage: {
      get() {
        return this.Groups.last_page;
      },
    },
  },
};
</script>