<template>
  <main-layout>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" color="amber" />
    </v-overlay>
    <base-header>
      <template #icon> mdi-file</template>
      <template #name> View Sales Order</template>
      <template #breadcrumbs>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </template>
      <template #button>
        <v-btn color="secondary" class="float-end mb-2" small @click="backForm()">Back
          <v-icon right>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn color="primary" class="float-end mb-2 mr-1" v-if="SalesOrder.is_paid == false" small @click.stop="update()"
          :loading="btnLoading">Update to
          Void
        </v-btn>
      </template>
    </base-header>
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
    <v-card elevation="2" rounded class="mx-2">
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default invoice" id="invoice">
            <div class="panel-body">
              <!-- <div class="invoice-ribbon">
                <div class="ribbon-inner">{{ SalesOrder.status }}</div>
              </div> -->
              <v-row class="mx-2">
                <v-col sm="12" md="6" lg="6">
                  <h3 class="marginright">{{ SalesOrder.no_so }}</h3>
                  <span class="marginright">{{ SalesOrder.order_date }}</span>
                </v-col>

                <v-col sm="12" md="6" lg="6" class="top-right">
                  <h3 class="marginright">{{ SalesOrder.status }}</h3>
                </v-col>
              </v-row>
              <v-divider class="mx-4"></v-divider>
              <br>
              <v-row class="mx-2">
                <v-col sm="12" md="4" lg="4" class="from">
                  <h3 class="lead marginbottom">From : {{ SalesOrder.branch_name }}</h3>
                  <p>{{ SalesOrder.branch_phone }}</p>
                  <p>{{ SalesOrder.branch_email }}</p>
                  <p>{{ SalesOrder.branch_address }}</p>
                </v-col>
                <v-col sm="12" md="4" lg="4" class="from">
                  <h3 class="lead marginbottom">To : {{ SalesOrder.customer_name }}</h3>
                  <p>{{ SalesOrder.customer_phone }}</p>
                  <p>{{ SalesOrder.customer_email }}</p>
                  <p>{{ SalesOrder.customer_address }}</p>
                </v-col>
                <v-col sm="12" md="4" lg="4" class="from">
                  <h3 class="lead marginbottom">Bank Account</h3>
                  <p>{{ SalesOrder.number_account }}</p>
                  <p>{{ SalesOrder.name_account }}</p>
                  <p>{{ SalesOrder.bank_name }}</p>
                </v-col>
              </v-row>
              <v-divider class="mx-4"></v-divider>
              <v-row class="mx-2 table-row">
                <v-col lg="12">
                  <v-simple-table class="mx-2 my-3" width="100%" fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left white--text" style="background-color:#3BA0FF" width="1px">No</th>
                          <th class="text-left white--text" style="background-color:#3BA0FF" width="20px">Product Name
                          </th>
                          <th class="text-left white--text" style="background-color:#3BA0FF" width="25px">Description</th>
                          <th class="text-left white--text" style="background-color:#3BA0FF" width="15px">Unit</th>
                          <th class="text-left white--text" style="background-color:#3BA0FF" width="10px">Price Sell</th>
                          <th class="text-left white--text" style="background-color:#3BA0FF" width="10px">QTY</th>
                          <th class="text-left white--text" style="background-color:#3BA0FF" width="10px">Discount</th>
                          <th class="text-left white--text" style="background-color:#3BA0FF" width="15px">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in SalesOrder.details" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>{{ row?.item?.name }}</td>
                          <td>{{ row?.item?.description }}</td>
                          <td>{{ row?.item?.unit?.name }}</td>
                          <td>
                            {{
                              row.price | currency("", 0, { symbolOnLeft: false })
                            }}
                          </td>
                          <td> {{
                            row.qty
                          }}</td>
                          <td>0</td>
                          <td>
                            {{
                              (row.price * row.qty) | currency("", 0, {
                                symbolOnLeft:
                                  false
                              })
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-divider class="mx-4"></v-divider>
              <br>
              <v-row class="mx-2">
                <v-col sm="12" md="6" lg="6" class="margintop">
                  <p class="lead marginbottom">THANK YOU!</p>
                  <!-- <v-btn color="success"><i class="fa fa-print"></i> Print Invoice</v-btn>
                  <v-btn color="primary" class="ml-2"><i class="fa fa-envelope-o"></i> Mail Invoice</v-btn> -->
                </v-col>
                <v-col sm="12" md="6" lg="6" class="text-right pull-right invoice-total">
                  <p>Subtotal : {{ SalesOrder.subtotal | currency("", 0, {
                    symbolOnLeft:
                      false
                  }) }}</p>
                  <p>Discount ({{ SalesOrder.discount_percent, }}%) : {{ SalesOrder.discount_amount | currency("", 0, {
                    symbolOnLeft:
                      false
                  }) }} </p>
                  <p>VAT (11%) : {{ SalesOrder.vat | currency("", 0, {
                    symbolOnLeft:
                      false
                  }) }} </p>
                  <p>Pph ({{ SalesOrder.pph_percentage }}%) : {{ SalesOrder.pph | currency("", 0, {
                    symbolOnLeft:
                      false
                  }) }} </p>
                  <p>Grand Total : {{ SalesOrder.grand_total | currency("", 0, {
                    symbolOnLeft:
                      false
                  }) }} </p>
                </v-col>
              </v-row>

            </div>
          </div>
        </div>
      </div>
    </v-card>
    <link />
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
import BaseHeader from '@/components/base/BaseHeader.vue';
// import BaseColumn from '@/components/base/BaseColumn.vue';
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: "SalesOrderView",

  data() {
    return {
      loading: false,
      btnLoading: false,
      breadcrumbs: [
        { text: "Transaction", disabled: false },
        { text: "Sales Order", disabled: false },
        { text: "View", disabled: true },

      ],
      // shareableLink: process.env.VUE_APP_API_URL + "/sales_order/view/" + this.$route.params.id,
    };

  },

  created() {
    this.init();
  },

  methods: {
    ...mapMutations("sales_order", ["CLEAR_STATE", "SET_SALES_ORDER"]),
    init() {
      this.loading = true;
      this.$axios
        .get("/transaction/sales-orders/" + this.$route.params.id, {
          headers: {
            Authorization: this.$store.getters["auth/Token"],
          },
        })
        .then(({ data }) => {
          this.SET_SALES_ORDER(data.sales_orders);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    update: async function () {
      this.btnLoading = true;
      await this.$axios
        .put(
          "/transaction/sales-orders/set-to-void/" + this.$route.params.id,
          this.SalesOrder,
          {
            headers: {
              Authorization: this.$store.getters["auth/Token"],
            },
          }
        )
        .then(() => {
          this.btnLoading = false;
          this.$router.push("/sales-order");
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },

    clear() {
      this.CLEAR_STATE();
    },

    backForm() {
      this.CLEAR_STATE();
      this.$router.push("/sales-order");
    },
  },

  computed: {
    ...mapGetters("sales_order", ["SalesOrder"]),
  },

  components: {
    MainLayout,
    BaseHeader,
  },
}
</script>
<style>
body {
  margin-top: 20px;
  background: #eee;
}

/*Invoice*/
.invoice .top-left {
  font-size: 15px;
  color: #3ba0ff;
}

.invoice .top-right {
  text-align: right;
  padding-right: 20px;
  color: #3ba0ff;
}

.invoice .table-row {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 25px;
}

.invoice .payment-info {
  font-weight: 500;
}

.invoice .table-row .table>thead {
  border-top: 1px solid #ddd;
}

.invoice .table-row .table>thead>tr>th {
  border-bottom: none;
}

.invoice .table>tbody>tr>td {
  padding: 8px 20px;
}

.invoice .invoice-total {
  margin-right: -10px;
  font-size: 16px;
}

.invoice .last-row {
  border-bottom: 1px solid #ddd;
}

.invoice-ribbon {
  width: 100px;
  height: 180px;
  top: auto;
  overflow: hidden;
  position: absolute;
  top: 10px;
  right: 20px;
}

.ribbon-inner {
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 9px 0;
  left: -5px;
  top: 18px;
  width: 120px;
  background-color: #66c591;
  font-size: 15px;
  color: #fff;
}

.ribbon-inner:before,
.ribbon-inner:after {
  content: "";
  position: absolute;
}

.ribbon-inner:before {
  left: 0;
}

.ribbon-inner:after {
  right: 0;
}

@media(max-width:575px) {

  .invoice .top-left,
  .invoice .top-right,
  .invoice .payment-details {
    text-align: center;
  }

  .invoice .from,
  .invoice .to,
  .invoice .payment-details {
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
  }

  .invoice p.lead,
  .invoice .from p.lead,
  .invoice .to p.lead,
  .invoice .payment-details p.lead {
    font-size: 22px;
  }

  .invoice .btn {
    margin-top: 10px;
  }
}

@media print {
  .invoice {
    width: 900px;
    height: 800px;
  }
}
</style>