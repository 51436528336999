<template>
    <main-layout>
        <base-header>
            <template #icon> mdi-file</template>
            <template #name>Stock Opname </template>
            <template #breadcrumbs>
                <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                    <template #divider>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>
            </template>
        </base-header>
        <v-row dense class="mx-1">
            <v-responsive width="100%" />
            <v-tabs>
                <v-tab href="#product">Product</v-tab>
                <v-tab-item value="product">
                    <Product />
                </v-tab-item>
                <v-tab href="#material"> Material </v-tab>
                <v-tab-item value="material">
                    <Material />
                </v-tab-item>
            </v-tabs>
        </v-row>
    </main-layout>
</template>
<script>
import MainLayout from "@/layouts/MainLayout.vue";
import BaseHeader from "@/components/base/BaseHeader.vue";
import Product from "./List.vue";
import Material from "./detail.vue";

export default {
    name: "CustomIndex",

    created() {
        this.breadcrumbs = [
            { text: "Transaction", disabled: false, to: "/dashboard" },
            { text: "Stock Opname", disabled: true },
        ];
    },

    components: {
        Product,
        Material,
        MainLayout,
        BaseHeader,
    },
};
</script>